@charset "UTF-8";
/* primary colors */
/* background colors */
/* status colors */
/* Common */
.pi.pi-arrow-circle-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-arrow-circle-right:before {
    content: "play_circle_outline"; }

.pi.pi-arrow-circle-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi.pi-arrow-circle-left:before {
    content: "play_circle_outline"; }

.pi.pi-arrow-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-arrow-down:before {
    content: "arrow_downward"; }

.pi.pi-arrow-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-arrow-up:before {
    content: "arrow_upward"; }

.pi.pi-angle-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi.pi-angle-up:before {
    content: "play_arrow"; }

.pi.pi-angle-double-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi.pi-angle-double-up:before {
    content: "skip_next"; }

.pi.pi-angle-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi.pi-angle-down:before {
    content: "play_arrow"; }

.pi.pi-angle-double-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi.pi-angle-double-down:before {
    content: "skip_previous"; }

.pi.pi-angle-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-angle-right:before {
    content: "play_arrow"; }

.pi.pi-angle-double-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-angle-double-right:before {
    content: "fast_forward"; }

.pi.pi-angle-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi.pi-angle-left:before {
    content: "play_arrow"; }

.pi.pi-angle-double-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-angle-double-left:before {
    content: "fast_rewind"; }

.pi.pi-calendar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-calendar:before {
    content: "date_range"; }

.pi.pi-calendar-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-calendar-plus:before {
    content: "event"; }

.pi.pi-caret-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-caret-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-caret-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-right:before {
    content: "keyboard_arrow_right"; }

.pi.pi-caret-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-left:before {
    content: "keyboard_arrow_left"; }

.pi.pi-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-check:before {
    content: "check"; }

.pi.pi-chevron-circle-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-left:before {
    content: "keyboard_arrow_left"; }

.pi.pi-chevron-circle-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-right:before {
    content: "keyboard_arrow_right"; }

.pi.pi-chevron-circle-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-chevron-circle-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-chevron-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-right:before {
    content: "chevron_right"; }

.pi.pi-chevron-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-left:before {
    content: "chevron_left"; }

.pi.pi-chevron-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-chevron-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-circle-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-circle-off:before {
    content: "radio_button_unchecked"; }

.pi.pi-circle-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-circle-on:before {
    content: "radio_button_checked"; }

.pi.pi-cog {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-cog:before {
    content: "settings"; }

.pi.pi-file {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-file:before {
    content: "attach_file"; }

.pi.pi-minus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-minus:before {
    content: "remove"; }

.pi.pi-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-plus:before {
    content: "add"; }

.pi.pi-info-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-info-circle:before {
    content: "info"; }

.pi.pi-inbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-inbox:before {
    content: "inbox"; }

.pi.pi-upload {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-upload:before {
    content: "file_upload"; }

.pi.pi-home {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-home:before {
    content: "home"; }

.pi.pi-times {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-times:before {
    content: "close"; }

.pi.pi-search {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-search:before {
    content: "search"; }

.pi.pi-sort {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-sort:before {
    content: "sort"; }

.pi.pi-sort-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-sort-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-sort-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-sort-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-star {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-star:before {
    content: "star"; }

.pi.pi-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-video:before {
    content: "videocam"; }

.pi.pi-th-large {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-th-large:before {
    content: "grid_on"; }

.pi.pi-bars {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-bars:before {
    content: "list"; }

.pi-md-carat-2-n-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-2-n-s:before {
    content: "sort"; }

.pi-md-triangle-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-n:before {
    content: "keyboard_arrow_up"; }

.pi-md-triangle-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-e:before {
    content: "keyboard_arrow_right"; }

.pi-md-triangle-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-s:before {
    content: "keyboard_arrow_down"; }

.pi-md-triangle-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-w:before {
    content: "keyboard_arrow_left"; }

.pi-md-carat-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-n:before {
    content: "keyboard_arrow_up"; }

.pi-md-carat-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-e:before {
    content: "keyboard_arrow_right"; }

.pi-md-carat-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-s:before {
    content: "keyboard_arrow_down"; }

.pi-md-carat-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-w:before {
    content: "keyboard_arrow_left"; }

.pi-md-arrow-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrow-1-n:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrowstop-1-n:before {
    content: "skip_next"; }

.pi-md-arrow-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi-md-arrow-1-s:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrowstop-1-s:before {
    content: "skip_previous"; }

.pi-md-arrow-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi-md-arrow-1-w:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrowstop-1-w:before {
    content: "fast_rewind"; }

.pi-md-arrow-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-1-e:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrowstop-1-e:before {
    content: "fast_forward"; }

.pi-md-arrowthick-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi-md-arrowthick-1-s:before {
    content: "play_arrow"; }

.pi-md-arrowthick-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrowthick-1-n:before {
    content: "play_arrow"; }

.pi-md-circle-triangle-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-triangle-e:before {
    content: "play_circle_outline"; }

.pi-md-circle-triangle-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi-md-circle-triangle-w:before {
    content: "play_circle_outline"; }

.pi-md-circle-triangle-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi-md-circle-triangle-s:before {
    content: "play_circle_outline"; }

.pi-md-radio-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-off:before {
    content: "radio_button_unchecked"; }

.pi-md-radio-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-on:before {
    content: "radio_button_checked"; }

.pi-md-folder-collapsed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-collapsed:before {
    content: "folder"; }

.pi-md-document {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-document:before {
    content: "content_paste"; }

.pi-md-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video:before {
    content: "movie"; }

.pi-md-music {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-music:before {
    content: "music_note"; }

.pi-md-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-plus:before {
    content: "add"; }

.pi-md-minus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-minus:before {
    content: "remove"; }

.pi-md-plusthick {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-plusthick:before {
    content: "add"; }

.pi-md-minusthick {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-minusthick:before {
    content: "remove"; }

.pi-md-pencil {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pencil:before {
    content: "edit"; }

.pi-md-closethick {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-closethick:before {
    content: "close"; }

.pi-md-circle-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-close:before {
    content: "cancel"; }

.pi-md-gear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gear:before {
    content: "settings"; }

.pi-md-calendar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-calendar:before {
    content: "date_range"; }

.pi-md-trash {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trash:before {
    content: "delete_forever"; }

.pi-md-notice {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notice:before {
    content: "error_outline"; }

.pi-md-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alert:before {
    content: "warning"; }

.pi-md-circle-zoomin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-zoomin:before {
    content: "zoom_in"; }

.pi-md-circle-zoomout {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-zoomout:before {
    content: "zoom_out"; }

.pi-md-circle-arrow-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-arrow-e:before {
    content: "play_circle_outline"; }

.pi-md-circle-arrow-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi-md-circle-arrow-w:before {
    content: "play_circle_outline"; }

.pi-md-3d-rotation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-3d-rotation:before {
    content: "3d_rotation"; }

.pi-md-ac-unit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ac-unit:before {
    content: "ac_unit"; }

.pi-md-access-alarm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-access-alarm:before {
    content: "access_alarm"; }

.pi-md-access-alarms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-access-alarms:before {
    content: "access_alarms"; }

.pi-md-access-time {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-access-time:before {
    content: "access_time"; }

.pi-md-accessibility {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-accessibility:before {
    content: "accessibility"; }

.pi-md-accessible {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-accessible:before {
    content: "accessible"; }

.pi-md-account-balance {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-balance:before {
    content: "account_balance"; }

.pi-md-account-balance-wallet {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-balance-wallet:before {
    content: "account_balance_wallet"; }

.pi-md-account-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-box:before {
    content: "account_box"; }

.pi-md-account-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-circle:before {
    content: "account_circle"; }

.pi-md-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add:before {
    content: "add"; }

.pi-md-add-a-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-a-photo:before {
    content: "add_a_photo"; }

.pi-md-add-alarm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-alarm:before {
    content: "add_alarm"; }

.pi-md-add-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-alert:before {
    content: "add_alert"; }

.pi-md-add-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-box:before {
    content: "add_box"; }

.pi-md-add-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-circle:before {
    content: "add_circle"; }

.pi-md-add-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-circle-outline:before {
    content: "add_circle_outline"; }

.pi-md-add-location {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-location:before {
    content: "add_location"; }

.pi-md-add-shopping-cart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-shopping-cart:before {
    content: "add_shopping_cart"; }

.pi-md-add-to-photos {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-to-photos:before {
    content: "add_to_photos"; }

.pi-md-add-to-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-to-queue:before {
    content: "add_to_queue"; }

.pi-md-adjust {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-adjust:before {
    content: "adjust"; }

.pi-md-airline-seat-flat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-flat:before {
    content: "airline_seat_flat"; }

.pi-md-airline-seat-flat-angled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-flat-angled:before {
    content: "airline_seat_flat_angled"; }

.pi-md-airline-seat-individual-suite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-individual-suite:before {
    content: "airline_seat_individual_suite"; }

.pi-md-airline-seat-legroom-extra {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-legroom-extra:before {
    content: "airline_seat_legroom_extra"; }

.pi-md-airline-seat-legroom-normal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-legroom-normal:before {
    content: "airline_seat_legroom_normal"; }

.pi-md-airline-seat-legroom-reduced {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-legroom-reduced:before {
    content: "airline_seat_legroom_reduced"; }

.pi-md-airline-seat-recline-extra {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-recline-extra:before {
    content: "airline_seat_recline_extra"; }

.pi-md-airline-seat-recline-normal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-recline-normal:before {
    content: "airline_seat_recline_normal"; }

.pi-md-airplanemode-active {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airplanemode-active:before {
    content: "airplanemode_active"; }

.pi-md-airplanemode-inactive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airplanemode-inactive:before {
    content: "airplanemode_inactive"; }

.pi-md-airplay {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airplay:before {
    content: "airplay"; }

.pi-md-airport-shuttle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airport-shuttle:before {
    content: "airport_shuttle"; }

.pi-md-alarm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm:before {
    content: "alarm"; }

.pi-md-alarm-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm-add:before {
    content: "alarm_add"; }

.pi-md-alarm-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm-off:before {
    content: "alarm_off"; }

.pi-md-alarm-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm-on:before {
    content: "alarm_on"; }

.pi-md-album {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-album:before {
    content: "album"; }

.pi-md-all-inclusive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-all-inclusive:before {
    content: "all_inclusive"; }

.pi-md-all-out {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-all-out:before {
    content: "all_out"; }

.pi-md-android {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-android:before {
    content: "android"; }

.pi-md-announcement {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-announcement:before {
    content: "announcement"; }

.pi-md-apps {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-apps:before {
    content: "apps"; }

.pi-md-archive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-archive:before {
    content: "archive"; }

.pi-md-arrow-back {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-back:before {
    content: "arrow_back"; }

.pi-md-arrow-downward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-downward:before {
    content: "arrow_downward"; }

.pi-md-arrow-drop-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-drop-down:before {
    content: "arrow_drop_down"; }

.pi-md-arrow-drop-down-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-drop-down-circle:before {
    content: "arrow_drop_down_circle"; }

.pi-md-arrow-drop-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-drop-up:before {
    content: "arrow_drop_up"; }

.pi-md-arrow-forward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-forward:before {
    content: "arrow_forward"; }

.pi-md-arrow-upward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-upward:before {
    content: "arrow_upward"; }

.pi-md-art-track {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-art-track:before {
    content: "art_track"; }

.pi-md-aspect-ratio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-aspect-ratio:before {
    content: "aspect_ratio"; }

.pi-md-assessment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assessment:before {
    content: "assessment"; }

.pi-md-assignment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment:before {
    content: "assignment"; }

.pi-md-assignment-ind {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-ind:before {
    content: "assignment_ind"; }

.pi-md-assignment-late {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-late:before {
    content: "assignment_late"; }

.pi-md-assignment-return {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-return:before {
    content: "assignment_return"; }

.pi-md-assignment-returned {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-returned:before {
    content: "assignment_returned"; }

.pi-md-assignment-turned-in {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-turned-in:before {
    content: "assignment_turned_in"; }

.pi-md-assistant {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assistant:before {
    content: "assistant"; }

.pi-md-assistant-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assistant-photo:before {
    content: "assistant_photo"; }

.pi-md-attach-file {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-attach-file:before {
    content: "attach_file"; }

.pi-md-attach-money {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-attach-money:before {
    content: "attach_money"; }

.pi-md-attachment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-attachment:before {
    content: "attachment"; }

.pi-md-audiotrack {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-audiotrack:before {
    content: "audiotrack"; }

.pi-md-autorenew {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-autorenew:before {
    content: "autorenew"; }

.pi-md-av-timer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-av-timer:before {
    content: "av_timer"; }

.pi-md-backspace {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-backspace:before {
    content: "backspace"; }

.pi-md-backup {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-backup:before {
    content: "backup"; }

.pi-md-battery-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-alert:before {
    content: "battery_alert"; }

.pi-md-battery-charging-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-charging-full:before {
    content: "battery_charging_full"; }

.pi-md-battery-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-full:before {
    content: "battery_full"; }

.pi-md-battery-std {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-std:before {
    content: "battery_std"; }

.pi-md-battery-unknown {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-unknown:before {
    content: "battery_unknown"; }

.pi-md-beach-access {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-beach-access:before {
    content: "beach_access"; }

.pi-md-beenhere {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-beenhere:before {
    content: "beenhere"; }

.pi-md-block {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-block:before {
    content: "block"; }

.pi-md-bluetooth {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth:before {
    content: "bluetooth"; }

.pi-md-bluetooth-audio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-audio:before {
    content: "bluetooth_audio"; }

.pi-md-bluetooth-connected {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-connected:before {
    content: "bluetooth_connected"; }

.pi-md-bluetooth-disabled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-disabled:before {
    content: "bluetooth_disabled"; }

.pi-md-bluetooth-searching {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-searching:before {
    content: "bluetooth_searching"; }

.pi-md-blur-circular {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-circular:before {
    content: "blur_circular"; }

.pi-md-blur-linear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-linear:before {
    content: "blur_linear"; }

.pi-md-blur-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-off:before {
    content: "blur_off"; }

.pi-md-blur-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-on:before {
    content: "blur_on"; }

.pi-md-book {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-book:before {
    content: "book"; }

.pi-md-bookmark {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bookmark:before {
    content: "bookmark"; }

.pi-md-bookmark-border {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bookmark-border:before {
    content: "bookmark_border"; }

.pi-md-border-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-all:before {
    content: "border_all"; }

.pi-md-border-bottom {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-bottom:before {
    content: "border_bottom"; }

.pi-md-border-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-clear:before {
    content: "border_clear"; }

.pi-md-border-color {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-color:before {
    content: "border_color"; }

.pi-md-border-horizontal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-horizontal:before {
    content: "border_horizontal"; }

.pi-md-border-inner {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-inner:before {
    content: "border_inner"; }

.pi-md-border-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-left:before {
    content: "border_left"; }

.pi-md-border-outer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-outer:before {
    content: "border_outer"; }

.pi-md-border-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-right:before {
    content: "border_right"; }

.pi-md-border-style {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-style:before {
    content: "border_style"; }

.pi-md-border-top {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-top:before {
    content: "border_top"; }

.pi-md-border-vertical {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-vertical:before {
    content: "border_vertical"; }

.pi-md-branding-watermark {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-branding-watermark:before {
    content: "branding_watermark"; }

.pi-md-brightness-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-1:before {
    content: "brightness_1"; }

.pi-md-brightness-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-2:before {
    content: "brightness_2"; }

.pi-md-brightness-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-3:before {
    content: "brightness_3"; }

.pi-md-brightness-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-4:before {
    content: "brightness_4"; }

.pi-md-brightness-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-5:before {
    content: "brightness_5"; }

.pi-md-brightness-6 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-6:before {
    content: "brightness_6"; }

.pi-md-brightness-7 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-7:before {
    content: "brightness_7"; }

.pi-md-brightness-auto {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-auto:before {
    content: "brightness_auto"; }

.pi-md-brightness-high {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-high:before {
    content: "brightness_high"; }

.pi-md-brightness-low {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-low:before {
    content: "brightness_low"; }

.pi-md-brightness-medium {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-medium:before {
    content: "brightness_medium"; }

.pi-md-broken-image {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-broken-image:before {
    content: "broken_image"; }

.pi-md-brush {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brush:before {
    content: "brush"; }

.pi-md-bubble-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bubble-chart:before {
    content: "bubble_chart"; }

.pi-md-bug-report {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bug-report:before {
    content: "bug_report"; }

.pi-md-build {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-build:before {
    content: "build"; }

.pi-md-burst-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-burst-mode:before {
    content: "burst_mode"; }

.pi-md-business {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-business:before {
    content: "business"; }

.pi-md-business-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-business-center:before {
    content: "business_center"; }

.pi-md-cached {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cached:before {
    content: "cached"; }

.pi-md-cake {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cake:before {
    content: "cake"; }

.pi-md-call {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call:before {
    content: "call"; }

.pi-md-call-end {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-end:before {
    content: "call_end"; }

.pi-md-call-made {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-made:before {
    content: "call_made"; }

.pi-md-call-merge {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-merge:before {
    content: "call_merge"; }

.pi-md-call-missed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-missed:before {
    content: "call_missed"; }

.pi-md-call-missed-outgoing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-missed-outgoing:before {
    content: "call_missed_outgoing"; }

.pi-md-call-received {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-received:before {
    content: "call_received"; }

.pi-md-call-split {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-split:before {
    content: "call_split"; }

.pi-md-call-to-action {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-to-action:before {
    content: "call_to_action"; }

.pi-md-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera:before {
    content: "camera"; }

.pi-md-camera-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-alt:before {
    content: "camera_alt"; }

.pi-md-camera-enhance {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-enhance:before {
    content: "camera_enhance"; }

.pi-md-camera-front {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-front:before {
    content: "camera_front"; }

.pi-md-camera-rear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-rear:before {
    content: "camera_rear"; }

.pi-md-camera-roll {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-roll:before {
    content: "camera_roll"; }

.pi-md-cancel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cancel:before {
    content: "cancel"; }

.pi-md-card-giftcard {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-card-giftcard:before {
    content: "card_giftcard"; }

.pi-md-card-membership {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-card-membership:before {
    content: "card_membership"; }

.pi-md-card-travel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-card-travel:before {
    content: "card_travel"; }

.pi-md-casino {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-casino:before {
    content: "casino"; }

.pi-md-cast {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cast:before {
    content: "cast"; }

.pi-md-cast-connected {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cast-connected:before {
    content: "cast_connected"; }

.pi-md-center-focus-strong {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-center-focus-strong:before {
    content: "center_focus_strong"; }

.pi-md-center-focus-weak {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-center-focus-weak:before {
    content: "center_focus_weak"; }

.pi-md-change-history {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-change-history:before {
    content: "change_history"; }

.pi-md-chat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chat:before {
    content: "chat"; }

.pi-md-chat-bubble {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chat-bubble:before {
    content: "chat_bubble"; }

.pi-md-chat-bubble-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chat-bubble-outline:before {
    content: "chat_bubble_outline"; }

.pi-md-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check:before {
    content: "check"; }

.pi-md-check-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check-box:before {
    content: "check_box"; }

.pi-md-check-box-outline-blank {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check-box-outline-blank:before {
    content: "check_box_outline_blank"; }

.pi-md-check-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check-circle:before {
    content: "check_circle"; }

.pi-md-chevron-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chevron-left:before {
    content: "chevron_left"; }

.pi-md-chevron-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chevron-right:before {
    content: "chevron_right"; }

.pi-md-child-care {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-child-care:before {
    content: "child_care"; }

.pi-md-child-friendly {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-child-friendly:before {
    content: "child_friendly"; }

.pi-md-chrome-reader-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chrome-reader-mode:before {
    content: "chrome_reader_mode"; }

.pi-md-class {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-class:before {
    content: "class"; }

.pi-md-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-clear:before {
    content: "clear"; }

.pi-md-clear-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-clear-all:before {
    content: "clear_all"; }

.pi-md-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-close:before {
    content: "close"; }

.pi-md-closed-caption {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-closed-caption:before {
    content: "closed_caption"; }

.pi-md-cloud {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud:before {
    content: "cloud"; }

.pi-md-cloud-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-circle:before {
    content: "cloud_circle"; }

.pi-md-cloud-done {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-done:before {
    content: "cloud_done"; }

.pi-md-cloud-download {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-download:before {
    content: "cloud_download"; }

.pi-md-cloud-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-off:before {
    content: "cloud_off"; }

.pi-md-cloud-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-queue:before {
    content: "cloud_queue"; }

.pi-md-cloud-upload {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-upload:before {
    content: "cloud_upload"; }

.pi-md-code {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-code:before {
    content: "code"; }

.pi-md-collections {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-collections:before {
    content: "collections"; }

.pi-md-collections-bookmark {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-collections-bookmark:before {
    content: "collections_bookmark"; }

.pi-md-color-lens {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-color-lens:before {
    content: "color_lens"; }

.pi-md-colorize {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-colorize:before {
    content: "colorize"; }

.pi-md-comment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-comment:before {
    content: "comment"; }

.pi-md-compare {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-compare:before {
    content: "compare"; }

.pi-md-compare-arrows {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-compare-arrows:before {
    content: "compare_arrows"; }

.pi-md-computer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-computer:before {
    content: "computer"; }

.pi-md-confirmation-number {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-confirmation-number:before {
    content: "confirmation_number"; }

.pi-md-contact-mail {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-contact-mail:before {
    content: "contact_mail"; }

.pi-md-contact-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-contact-phone:before {
    content: "contact_phone"; }

.pi-md-contacts {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-contacts:before {
    content: "contacts"; }

.pi-md-content-copy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-content-copy:before {
    content: "content_copy"; }

.pi-md-content-cut {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-content-cut:before {
    content: "content_cut"; }

.pi-md-content-paste {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-content-paste:before {
    content: "content_paste"; }

.pi-md-control-point {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-control-point:before {
    content: "control_point"; }

.pi-md-control-point-duplicate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-control-point-duplicate:before {
    content: "control_point_duplicate"; }

.pi-md-copyright {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-copyright:before {
    content: "copyright"; }

.pi-md-create {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-create:before {
    content: "create"; }

.pi-md-create-new-folder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-create-new-folder:before {
    content: "create_new_folder"; }

.pi-md-credit-card {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-credit-card:before {
    content: "credit_card"; }

.pi-md-crop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop:before {
    content: "crop"; }

.pi-md-crop-16-9 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-16-9:before {
    content: "crop_16_9"; }

.pi-md-crop-3-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-3-2:before {
    content: "crop_3_2"; }

.pi-md-crop-5-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-5-4:before {
    content: "crop_5_4"; }

.pi-md-crop-7-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-7-5:before {
    content: "crop_7_5"; }

.pi-md-crop-din {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-din:before {
    content: "crop_din"; }

.pi-md-crop-free {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-free:before {
    content: "crop_free"; }

.pi-md-crop-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-landscape:before {
    content: "crop_landscape"; }

.pi-md-crop-original {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-original:before {
    content: "crop_original"; }

.pi-md-crop-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-portrait:before {
    content: "crop_portrait"; }

.pi-md-crop-rotate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-rotate:before {
    content: "crop_rotate"; }

.pi-md-crop-square {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-square:before {
    content: "crop_square"; }

.pi-md-dashboard {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dashboard:before {
    content: "dashboard"; }

.pi-md-data-usage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-data-usage:before {
    content: "data_usage"; }

.pi-md-date-range {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-date-range:before {
    content: "date_range"; }

.pi-md-dehaze {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dehaze:before {
    content: "dehaze"; }

.pi-md-delete {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-delete:before {
    content: "delete"; }

.pi-md-delete-forever {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-delete-forever:before {
    content: "delete_forever"; }

.pi-md-delete-sweep {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-delete-sweep:before {
    content: "delete_sweep"; }

.pi-md-description {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-description:before {
    content: "description"; }

.pi-md-desktop-mac {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-desktop-mac:before {
    content: "desktop_mac"; }

.pi-md-desktop-windows {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-desktop-windows:before {
    content: "desktop_windows"; }

.pi-md-details {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-details:before {
    content: "details"; }

.pi-md-developer-board {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-developer-board:before {
    content: "developer_board"; }

.pi-md-developer-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-developer-mode:before {
    content: "developer_mode"; }

.pi-md-device-hub {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-device-hub:before {
    content: "device_hub"; }

.pi-md-devices {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-devices:before {
    content: "devices"; }

.pi-md-devices-other {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-devices-other:before {
    content: "devices_other"; }

.pi-md-dialer-sip {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dialer-sip:before {
    content: "dialer_sip"; }

.pi-md-dialpad {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dialpad:before {
    content: "dialpad"; }

.pi-md-directions {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions:before {
    content: "directions"; }

.pi-md-directions-bike {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-bike:before {
    content: "directions_bike"; }

.pi-md-directions-boat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-boat:before {
    content: "directions_boat"; }

.pi-md-directions-bus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-bus:before {
    content: "directions_bus"; }

.pi-md-directions-car {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-car:before {
    content: "directions_car"; }

.pi-md-directions-railway {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-railway:before {
    content: "directions_railway"; }

.pi-md-directions-run {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-run:before {
    content: "directions_run"; }

.pi-md-directions-subway {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-subway:before {
    content: "directions_subway"; }

.pi-md-directions-transit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-transit:before {
    content: "directions_transit"; }

.pi-md-directions-walk {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-walk:before {
    content: "directions_walk"; }

.pi-md-disc-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-disc-full:before {
    content: "disc_full"; }

.pi-md-dns {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dns:before {
    content: "dns"; }

.pi-md-do-not-disturb {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb:before {
    content: "do_not_disturb"; }

.pi-md-do-not-disturb-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb-alt:before {
    content: "do_not_disturb_alt"; }

.pi-md-do-not-disturb-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb-off:before {
    content: "do_not_disturb_off"; }

.pi-md-do-not-disturb-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb-on:before {
    content: "do_not_disturb_on"; }

.pi-md-dock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dock:before {
    content: "dock"; }

.pi-md-domain {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-domain:before {
    content: "domain"; }

.pi-md-done {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-done:before {
    content: "done"; }

.pi-md-done-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-done-all:before {
    content: "done_all"; }

.pi-md-donut-large {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-donut-large:before {
    content: "donut_large"; }

.pi-md-donut-small {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-donut-small:before {
    content: "donut_small"; }

.pi-md-drafts {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-drafts:before {
    content: "drafts"; }

.pi-md-drag-handle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-drag-handle:before {
    content: "drag_handle"; }

.pi-md-drive-eta {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-drive-eta:before {
    content: "drive_eta"; }

.pi-md-dvr {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dvr:before {
    content: "dvr"; }

.pi-md-edit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-edit:before {
    content: "edit"; }

.pi-md-edit-location {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-edit-location:before {
    content: "edit_location"; }

.pi-md-eject {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-eject:before {
    content: "eject"; }

.pi-md-email {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-email:before {
    content: "email"; }

.pi-md-enhanced-encryption {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-enhanced-encryption:before {
    content: "enhanced_encryption"; }

.pi-md-equalizer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-equalizer:before {
    content: "equalizer"; }

.pi-md-error {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-error:before {
    content: "error"; }

.pi-md-error-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-error-outline:before {
    content: "error_outline"; }

.pi-md-euro-symbol {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-euro-symbol:before {
    content: "euro_symbol"; }

.pi-md-ev-station {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ev-station:before {
    content: "ev_station"; }

.pi-md-event {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event:before {
    content: "event"; }

.pi-md-event-available {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-available:before {
    content: "event_available"; }

.pi-md-event-busy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-busy:before {
    content: "event_busy"; }

.pi-md-event-note {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-note:before {
    content: "event_note"; }

.pi-md-event-seat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-seat:before {
    content: "event_seat"; }

.pi-md-exit-to-app {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exit-to-app:before {
    content: "exit_to_app"; }

.pi-md-expand-less {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-expand-less:before {
    content: "expand_less"; }

.pi-md-expand-more {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-expand-more:before {
    content: "expand_more"; }

.pi-md-explicit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-explicit:before {
    content: "explicit"; }

.pi-md-explore {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-explore:before {
    content: "explore"; }

.pi-md-exposure {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure:before {
    content: "exposure"; }

.pi-md-exposure-neg-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-neg-1:before {
    content: "exposure_neg_1"; }

.pi-md-exposure-neg-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-neg-2:before {
    content: "exposure_neg_2"; }

.pi-md-exposure-plus-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-plus-1:before {
    content: "exposure_plus_1"; }

.pi-md-exposure-plus-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-plus-2:before {
    content: "exposure_plus_2"; }

.pi-md-exposure-zero {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-zero:before {
    content: "exposure_zero"; }

.pi-md-extension {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-extension:before {
    content: "extension"; }

.pi-md-face {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-face:before {
    content: "face"; }

.pi-md-fast-forward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fast-forward:before {
    content: "fast_forward"; }

.pi-md-fast-rewind {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fast-rewind:before {
    content: "fast_rewind"; }

.pi-md-favorite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-favorite:before {
    content: "favorite"; }

.pi-md-favorite-border {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-favorite-border:before {
    content: "favorite_border"; }

.pi-md-featured-play-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-featured-play-list:before {
    content: "featured_play_list"; }

.pi-md-featured-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-featured-video:before {
    content: "featured_video"; }

.pi-md-feedback {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-feedback:before {
    content: "feedback"; }

.pi-md-fiber-dvr {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-dvr:before {
    content: "fiber_dvr"; }

.pi-md-fiber-manual-record {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-manual-record:before {
    content: "fiber_manual_record"; }

.pi-md-fiber-new {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-new:before {
    content: "fiber_new"; }

.pi-md-fiber-pin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-pin:before {
    content: "fiber_pin"; }

.pi-md-fiber-smart-record {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-smart-record:before {
    content: "fiber_smart_record"; }

.pi-md-file-download {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-file-download:before {
    content: "file_download"; }

.pi-md-file-upload {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-file-upload:before {
    content: "file_upload"; }

.pi-md-filter {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter:before {
    content: "filter"; }

.pi-md-filter-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-1:before {
    content: "filter_1"; }

.pi-md-filter-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-2:before {
    content: "filter_2"; }

.pi-md-filter-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-3:before {
    content: "filter_3"; }

.pi-md-filter-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-4:before {
    content: "filter_4"; }

.pi-md-filter-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-5:before {
    content: "filter_5"; }

.pi-md-filter-6 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-6:before {
    content: "filter_6"; }

.pi-md-filter-7 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-7:before {
    content: "filter_7"; }

.pi-md-filter-8 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-8:before {
    content: "filter_8"; }

.pi-md-filter-9 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-9:before {
    content: "filter_9"; }

.pi-md-filter-9-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-9-plus:before {
    content: "filter_9_plus"; }

.pi-md-filter-b-and-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-b-and-w:before {
    content: "filter_b_and_w"; }

.pi-md-filter-center-focus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-center-focus:before {
    content: "filter_center_focus"; }

.pi-md-filter-drama {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-drama:before {
    content: "filter_drama"; }

.pi-md-filter-frames {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-frames:before {
    content: "filter_frames"; }

.pi-md-filter-hdr {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-hdr:before {
    content: "filter_hdr"; }

.pi-md-filter-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-list:before {
    content: "filter_list"; }

.pi-md-filter-none {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-none:before {
    content: "filter_none"; }

.pi-md-filter-tilt-shift {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-tilt-shift:before {
    content: "filter_tilt_shift"; }

.pi-md-filter-vintage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-vintage:before {
    content: "filter_vintage"; }

.pi-md-find-in-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-find-in-page:before {
    content: "find_in_page"; }

.pi-md-find-replace {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-find-replace:before {
    content: "find_replace"; }

.pi-md-fingerprint {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fingerprint:before {
    content: "fingerprint"; }

.pi-md-first-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-first-page:before {
    content: "first_page"; }

.pi-md-fitness-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fitness-center:before {
    content: "fitness_center"; }

.pi-md-flag {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flag:before {
    content: "flag"; }

.pi-md-flare {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flare:before {
    content: "flare"; }

.pi-md-flash-auto {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flash-auto:before {
    content: "flash_auto"; }

.pi-md-flash-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flash-off:before {
    content: "flash_off"; }

.pi-md-flash-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flash-on:before {
    content: "flash_on"; }

.pi-md-flight {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flight:before {
    content: "flight"; }

.pi-md-flight-land {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flight-land:before {
    content: "flight_land"; }

.pi-md-flight-takeoff {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flight-takeoff:before {
    content: "flight_takeoff"; }

.pi-md-flip {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flip:before {
    content: "flip"; }

.pi-md-flip-to-back {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flip-to-back:before {
    content: "flip_to_back"; }

.pi-md-flip-to-front {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flip-to-front:before {
    content: "flip_to_front"; }

.pi-md-folder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder:before {
    content: "folder"; }

.pi-md-folder-open {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-open:before {
    content: "folder_open"; }

.pi-md-folder-shared {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-shared:before {
    content: "folder_shared"; }

.pi-md-folder-special {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-special:before {
    content: "folder_special"; }

.pi-md-font-download {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-font-download:before {
    content: "font_download"; }

.pi-md-format-align-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-center:before {
    content: "format_align_center"; }

.pi-md-format-align-justify {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-justify:before {
    content: "format_align_justify"; }

.pi-md-format-align-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-left:before {
    content: "format_align_left"; }

.pi-md-format-align-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-right:before {
    content: "format_align_right"; }

.pi-md-format-bold {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-bold:before {
    content: "format_bold"; }

.pi-md-format-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-clear:before {
    content: "format_clear"; }

.pi-md-format-color-fill {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-color-fill:before {
    content: "format_color_fill"; }

.pi-md-format-color-reset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-color-reset:before {
    content: "format_color_reset"; }

.pi-md-format-color-text {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-color-text:before {
    content: "format_color_text"; }

.pi-md-format-indent-decrease {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-indent-decrease:before {
    content: "format_indent_decrease"; }

.pi-md-format-indent-increase {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-indent-increase:before {
    content: "format_indent_increase"; }

.pi-md-format-italic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-italic:before {
    content: "format_italic"; }

.pi-md-format-line-spacing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-line-spacing:before {
    content: "format_line_spacing"; }

.pi-md-format-list-bulleted {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-list-bulleted:before {
    content: "format_list_bulleted"; }

.pi-md-format-list-numbered {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-list-numbered:before {
    content: "format_list_numbered"; }

.pi-md-format-paint {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-paint:before {
    content: "format_paint"; }

.pi-md-format-quote {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-quote:before {
    content: "format_quote"; }

.pi-md-format-shapes {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-shapes:before {
    content: "format_shapes"; }

.pi-md-format-size {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-size:before {
    content: "format_size"; }

.pi-md-format-strikethrough {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-strikethrough:before {
    content: "format_strikethrough"; }

.pi-md-format-textdirection-l-to-r {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-textdirection-l-to-r:before {
    content: "format_textdirection_l_to_r"; }

.pi-md-format-textdirection-r-to-l {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-textdirection-r-to-l:before {
    content: "format_textdirection_r_to_l"; }

.pi-md-format-underlined {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-underlined:before {
    content: "format_underlined"; }

.pi-md-forum {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forum:before {
    content: "forum"; }

.pi-md-forward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward:before {
    content: "forward"; }

.pi-md-forward-10 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward-10:before {
    content: "forward_10"; }

.pi-md-forward-30 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward-30:before {
    content: "forward_30"; }

.pi-md-forward-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward-5:before {
    content: "forward_5"; }

.pi-md-free-breakfast {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-free-breakfast:before {
    content: "free_breakfast"; }

.pi-md-fullscreen {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fullscreen:before {
    content: "fullscreen"; }

.pi-md-fullscreen-exit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fullscreen-exit:before {
    content: "fullscreen_exit"; }

.pi-md-functions {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-functions:before {
    content: "functions"; }

.pi-md-g-translate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-g-translate:before {
    content: "g_translate"; }

.pi-md-gamepad {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gamepad:before {
    content: "gamepad"; }

.pi-md-games {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-games:before {
    content: "games"; }

.pi-md-gavel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gavel:before {
    content: "gavel"; }

.pi-md-gesture {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gesture:before {
    content: "gesture"; }

.pi-md-get-app {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-get-app:before {
    content: "get_app"; }

.pi-md-gif {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gif:before {
    content: "gif"; }

.pi-md-golf-course {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-golf-course:before {
    content: "golf_course"; }

.pi-md-gps-fixed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gps-fixed:before {
    content: "gps_fixed"; }

.pi-md-gps-not-fixed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gps-not-fixed:before {
    content: "gps_not_fixed"; }

.pi-md-gps-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gps-off:before {
    content: "gps_off"; }

.pi-md-grade {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grade:before {
    content: "grade"; }

.pi-md-gradient {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gradient:before {
    content: "gradient"; }

.pi-md-grain {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grain:before {
    content: "grain"; }

.pi-md-graphic-eq {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-graphic-eq:before {
    content: "graphic_eq"; }

.pi-md-grid-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grid-off:before {
    content: "grid_off"; }

.pi-md-grid-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grid-on:before {
    content: "grid_on"; }

.pi-md-group {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-group:before {
    content: "group"; }

.pi-md-group-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-group-add:before {
    content: "group_add"; }

.pi-md-group-work {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-group-work:before {
    content: "group_work"; }

.pi-md-hd {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hd:before {
    content: "hd"; }

.pi-md-hdr-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-off:before {
    content: "hdr_off"; }

.pi-md-hdr-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-on:before {
    content: "hdr_on"; }

.pi-md-hdr-strong {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-strong:before {
    content: "hdr_strong"; }

.pi-md-hdr-weak {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-weak:before {
    content: "hdr_weak"; }

.pi-md-headset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-headset:before {
    content: "headset"; }

.pi-md-headset-mic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-headset-mic:before {
    content: "headset_mic"; }

.pi-md-healing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-healing:before {
    content: "healing"; }

.pi-md-hearing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hearing:before {
    content: "hearing"; }

.pi-md-help {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-help:before {
    content: "help"; }

.pi-md-help-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-help-outline:before {
    content: "help_outline"; }

.pi-md-high-quality {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-high-quality:before {
    content: "high_quality"; }

.pi-md-highlight {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-highlight:before {
    content: "highlight"; }

.pi-md-highlight-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-highlight-off:before {
    content: "highlight_off"; }

.pi-md-history {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-history:before {
    content: "history"; }

.pi-md-home {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-home:before {
    content: "home"; }

.pi-md-hot-tub {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hot-tub:before {
    content: "hot_tub"; }

.pi-md-hotel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hotel:before {
    content: "hotel"; }

.pi-md-hourglass-empty {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hourglass-empty:before {
    content: "hourglass_empty"; }

.pi-md-hourglass-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hourglass-full:before {
    content: "hourglass_full"; }

.pi-md-http {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-http:before {
    content: "http"; }

.pi-md-https {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-https:before {
    content: "https"; }

.pi-md-image {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-image:before {
    content: "image"; }

.pi-md-image-aspect-ratio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-image-aspect-ratio:before {
    content: "image_aspect_ratio"; }

.pi-md-import-contacts {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-import-contacts:before {
    content: "import_contacts"; }

.pi-md-import-export {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-import-export:before {
    content: "import_export"; }

.pi-md-important-devices {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-important-devices:before {
    content: "important_devices"; }

.pi-md-inbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-inbox:before {
    content: "inbox"; }

.pi-md-indeterminate-check-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-indeterminate-check-box:before {
    content: "indeterminate_check_box"; }

.pi-md-info {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-info:before {
    content: "info"; }

.pi-md-info-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-info-outline:before {
    content: "info_outline"; }

.pi-md-input {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-input:before {
    content: "input"; }

.pi-md-insert-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-chart:before {
    content: "insert_chart"; }

.pi-md-insert-comment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-comment:before {
    content: "insert_comment"; }

.pi-md-insert-drive-file {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-drive-file:before {
    content: "insert_drive_file"; }

.pi-md-insert-emoticon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-emoticon:before {
    content: "insert_emoticon"; }

.pi-md-insert-invitation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-invitation:before {
    content: "insert_invitation"; }

.pi-md-insert-link {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-link:before {
    content: "insert_link"; }

.pi-md-insert-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-photo:before {
    content: "insert_photo"; }

.pi-md-invert-colors {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-invert-colors:before {
    content: "invert_colors"; }

.pi-md-invert-colors-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-invert-colors-off:before {
    content: "invert_colors_off"; }

.pi-md-iso {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-iso:before {
    content: "iso"; }

.pi-md-keyboard {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard:before {
    content: "keyboard"; }

.pi-md-keyboard-arrow-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-down:before {
    content: "keyboard_arrow_down"; }

.pi-md-keyboard-arrow-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-left:before {
    content: "keyboard_arrow_left"; }

.pi-md-keyboard-arrow-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-right:before {
    content: "keyboard_arrow_right"; }

.pi-md-keyboard-arrow-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-up:before {
    content: "keyboard_arrow_up"; }

.pi-md-keyboard-backspace {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-backspace:before {
    content: "keyboard_backspace"; }

.pi-md-keyboard-capslock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-capslock:before {
    content: "keyboard_capslock"; }

.pi-md-keyboard-hide {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-hide:before {
    content: "keyboard_hide"; }

.pi-md-keyboard-return {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-return:before {
    content: "keyboard_return"; }

.pi-md-keyboard-tab {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-tab:before {
    content: "keyboard_tab"; }

.pi-md-keyboard-voice {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-voice:before {
    content: "keyboard_voice"; }

.pi-md-kitchen {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-kitchen:before {
    content: "kitchen"; }

.pi-md-label {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-label:before {
    content: "label"; }

.pi-md-label-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-label-outline:before {
    content: "label_outline"; }

.pi-md-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-landscape:before {
    content: "landscape"; }

.pi-md-language {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-language:before {
    content: "language"; }

.pi-md-laptop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop:before {
    content: "laptop"; }

.pi-md-laptop-chromebook {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop-chromebook:before {
    content: "laptop_chromebook"; }

.pi-md-laptop-mac {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop-mac:before {
    content: "laptop_mac"; }

.pi-md-laptop-windows {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop-windows:before {
    content: "laptop_windows"; }

.pi-md-last-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-last-page:before {
    content: "last_page"; }

.pi-md-launch {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-launch:before {
    content: "launch"; }

.pi-md-layers {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-layers:before {
    content: "layers"; }

.pi-md-layers-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-layers-clear:before {
    content: "layers_clear"; }

.pi-md-leak-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-leak-add:before {
    content: "leak_add"; }

.pi-md-leak-remove {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-leak-remove:before {
    content: "leak_remove"; }

.pi-md-lens {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lens:before {
    content: "lens"; }

.pi-md-library-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-library-add:before {
    content: "library_add"; }

.pi-md-library-books {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-library-books:before {
    content: "library_books"; }

.pi-md-library-music {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-library-music:before {
    content: "library_music"; }

.pi-md-lightbulb-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lightbulb-outline:before {
    content: "lightbulb_outline"; }

.pi-md-line-style {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-line-style:before {
    content: "line_style"; }

.pi-md-line-weight {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-line-weight:before {
    content: "line_weight"; }

.pi-md-linear-scale {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-linear-scale:before {
    content: "linear_scale"; }

.pi-md-link {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-link:before {
    content: "link"; }

.pi-md-linked-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-linked-camera:before {
    content: "linked_camera"; }

.pi-md-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-list:before {
    content: "list"; }

.pi-md-live-help {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-live-help:before {
    content: "live_help"; }

.pi-md-live-tv {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-live-tv:before {
    content: "live_tv"; }

.pi-md-local-activity {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-activity:before {
    content: "local_activity"; }

.pi-md-local-airport {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-airport:before {
    content: "local_airport"; }

.pi-md-local-atm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-atm:before {
    content: "local_atm"; }

.pi-md-local-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-bar:before {
    content: "local_bar"; }

.pi-md-local-cafe {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-cafe:before {
    content: "local_cafe"; }

.pi-md-local-car-wash {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-car-wash:before {
    content: "local_car_wash"; }

.pi-md-local-convenience-store {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-convenience-store:before {
    content: "local_convenience_store"; }

.pi-md-local-dining {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-dining:before {
    content: "local_dining"; }

.pi-md-local-drink {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-drink:before {
    content: "local_drink"; }

.pi-md-local-florist {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-florist:before {
    content: "local_florist"; }

.pi-md-local-gas-station {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-gas-station:before {
    content: "local_gas_station"; }

.pi-md-local-grocery-store {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-grocery-store:before {
    content: "local_grocery_store"; }

.pi-md-local-hospital {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-hospital:before {
    content: "local_hospital"; }

.pi-md-local-hotel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-hotel:before {
    content: "local_hotel"; }

.pi-md-local-laundry-service {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-laundry-service:before {
    content: "local_laundry_service"; }

.pi-md-local-library {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-library:before {
    content: "local_library"; }

.pi-md-local-mall {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-mall:before {
    content: "local_mall"; }

.pi-md-local-movies {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-movies:before {
    content: "local_movies"; }

.pi-md-local-offer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-offer:before {
    content: "local_offer"; }

.pi-md-local-parking {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-parking:before {
    content: "local_parking"; }

.pi-md-local-pharmacy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-pharmacy:before {
    content: "local_pharmacy"; }

.pi-md-local-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-phone:before {
    content: "local_phone"; }

.pi-md-local-pizza {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-pizza:before {
    content: "local_pizza"; }

.pi-md-local-play {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-play:before {
    content: "local_play"; }

.pi-md-local-post-office {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-post-office:before {
    content: "local_post_office"; }

.pi-md-local-printshop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-printshop:before {
    content: "local_printshop"; }

.pi-md-local-see {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-see:before {
    content: "local_see"; }

.pi-md-local-shipping {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-shipping:before {
    content: "local_shipping"; }

.pi-md-local-taxi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-taxi:before {
    content: "local_taxi"; }

.pi-md-location-city {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-city:before {
    content: "location_city"; }

.pi-md-location-disabled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-disabled:before {
    content: "location_disabled"; }

.pi-md-location-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-off:before {
    content: "location_off"; }

.pi-md-location-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-on:before {
    content: "location_on"; }

.pi-md-location-searching {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-searching:before {
    content: "location_searching"; }

.pi-md-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lock:before {
    content: "lock"; }

.pi-md-lock-open {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lock-open:before {
    content: "lock_open"; }

.pi-md-lock-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lock-outline:before {
    content: "lock_outline"; }

.pi-md-looks {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks:before {
    content: "looks"; }

.pi-md-looks-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-3:before {
    content: "looks_3"; }

.pi-md-looks-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-4:before {
    content: "looks_4"; }

.pi-md-looks-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-5:before {
    content: "looks_5"; }

.pi-md-looks-6 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-6:before {
    content: "looks_6"; }

.pi-md-looks-one {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-one:before {
    content: "looks_one"; }

.pi-md-looks-two {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-two:before {
    content: "looks_two"; }

.pi-md-loop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-loop:before {
    content: "loop"; }

.pi-md-loupe {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-loupe:before {
    content: "loupe"; }

.pi-md-low-priority {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-low-priority:before {
    content: "low_priority"; }

.pi-md-loyalty {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-loyalty:before {
    content: "loyalty"; }

.pi-md-mail {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mail:before {
    content: "mail"; }

.pi-md-mail-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mail-outline:before {
    content: "mail_outline"; }

.pi-md-map {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-map:before {
    content: "map"; }

.pi-md-markunread {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-markunread:before {
    content: "markunread"; }

.pi-md-markunread-mailbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-markunread-mailbox:before {
    content: "markunread_mailbox"; }

.pi-md-memory {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-memory:before {
    content: "memory"; }

.pi-md-menu {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-menu:before {
    content: "menu"; }

.pi-md-merge-type {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-merge-type:before {
    content: "merge_type"; }

.pi-md-message {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-message:before {
    content: "message"; }

.pi-md-mic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mic:before {
    content: "mic"; }

.pi-md-mic-none {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mic-none:before {
    content: "mic_none"; }

.pi-md-mic-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mic-off:before {
    content: "mic_off"; }

.pi-md-mms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mms:before {
    content: "mms"; }

.pi-md-mode-comment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mode-comment:before {
    content: "mode_comment"; }

.pi-md-mode-edit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mode-edit:before {
    content: "mode_edit"; }

.pi-md-monetization-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-monetization-on:before {
    content: "monetization_on"; }

.pi-md-money-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-money-off:before {
    content: "money_off"; }

.pi-md-monochrome-photos {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-monochrome-photos:before {
    content: "monochrome_photos"; }

.pi-md-mood {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mood:before {
    content: "mood"; }

.pi-md-mood-bad {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mood-bad:before {
    content: "mood_bad"; }

.pi-md-more {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-more:before {
    content: "more"; }

.pi-md-more-horiz {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-more-horiz:before {
    content: "more_horiz"; }

.pi-md-more-vert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-more-vert:before {
    content: "more_vert"; }

.pi-md-motorcycle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-motorcycle:before {
    content: "motorcycle"; }

.pi-md-mouse {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mouse:before {
    content: "mouse"; }

.pi-md-move-to-inbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-move-to-inbox:before {
    content: "move_to_inbox"; }

.pi-md-movie {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-movie:before {
    content: "movie"; }

.pi-md-movie-creation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-movie-creation:before {
    content: "movie_creation"; }

.pi-md-movie-filter {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-movie-filter:before {
    content: "movie_filter"; }

.pi-md-multiline-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-multiline-chart:before {
    content: "multiline_chart"; }

.pi-md-music-note {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-music-note:before {
    content: "music_note"; }

.pi-md-music-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-music-video:before {
    content: "music_video"; }

.pi-md-my-location {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-my-location:before {
    content: "my_location"; }

.pi-md-nature {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-nature:before {
    content: "nature"; }

.pi-md-nature-people {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-nature-people:before {
    content: "nature_people"; }

.pi-md-navigate-before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-navigate-before:before {
    content: "navigate_before"; }

.pi-md-navigate-next {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-navigate-next:before {
    content: "navigate_next"; }

.pi-md-navigation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-navigation:before {
    content: "navigation"; }

.pi-md-near-me {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-near-me:before {
    content: "near_me"; }

.pi-md-network-cell {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-cell:before {
    content: "network_cell"; }

.pi-md-network-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-check:before {
    content: "network_check"; }

.pi-md-network-locked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-locked:before {
    content: "network_locked"; }

.pi-md-network-wifi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-wifi:before {
    content: "network_wifi"; }

.pi-md-new-releases {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-new-releases:before {
    content: "new_releases"; }

.pi-md-next-week {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-next-week:before {
    content: "next_week"; }

.pi-md-nfc {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-nfc:before {
    content: "nfc"; }

.pi-md-no-encryption {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-no-encryption:before {
    content: "no_encryption"; }

.pi-md-no-sim {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-no-sim:before {
    content: "no_sim"; }

.pi-md-not-interested {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-not-interested:before {
    content: "not_interested"; }

.pi-md-note {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-note:before {
    content: "note"; }

.pi-md-note-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-note-add:before {
    content: "note_add"; }

.pi-md-notifications {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications:before {
    content: "notifications"; }

.pi-md-notifications-active {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-active:before {
    content: "notifications_active"; }

.pi-md-notifications-none {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-none:before {
    content: "notifications_none"; }

.pi-md-notifications-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-off:before {
    content: "notifications_off"; }

.pi-md-notifications-paused {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-paused:before {
    content: "notifications_paused"; }

.pi-md-offline-pin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-offline-pin:before {
    content: "offline_pin"; }

.pi-md-ondemand-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ondemand-video:before {
    content: "ondemand_video"; }

.pi-md-opacity {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-opacity:before {
    content: "opacity"; }

.pi-md-open-in-browser {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-open-in-browser:before {
    content: "open_in_browser"; }

.pi-md-open-in-new {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-open-in-new:before {
    content: "open_in_new"; }

.pi-md-open-with {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-open-with:before {
    content: "open_with"; }

.pi-md-pages {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pages:before {
    content: "pages"; }

.pi-md-pageview {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pageview:before {
    content: "pageview"; }

.pi-md-palette {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-palette:before {
    content: "palette"; }

.pi-md-pan-tool {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pan-tool:before {
    content: "pan_tool"; }

.pi-md-panorama {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama:before {
    content: "panorama"; }

.pi-md-panorama-fish-eye {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-fish-eye:before {
    content: "panorama_fish_eye"; }

.pi-md-panorama-horizontal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-horizontal:before {
    content: "panorama_horizontal"; }

.pi-md-panorama-vertical {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-vertical:before {
    content: "panorama_vertical"; }

.pi-md-panorama-wide-angle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-wide-angle:before {
    content: "panorama_wide_angle"; }

.pi-md-party-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-party-mode:before {
    content: "party_mode"; }

.pi-md-pause {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pause:before {
    content: "pause"; }

.pi-md-pause-circle-filled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pause-circle-filled:before {
    content: "pause_circle_filled"; }

.pi-md-pause-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pause-circle-outline:before {
    content: "pause_circle_outline"; }

.pi-md-payment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-payment:before {
    content: "payment"; }

.pi-md-people {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-people:before {
    content: "people"; }

.pi-md-people-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-people-outline:before {
    content: "people_outline"; }

.pi-md-perm-camera-mic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-camera-mic:before {
    content: "perm_camera_mic"; }

.pi-md-perm-contact-calendar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-contact-calendar:before {
    content: "perm_contact_calendar"; }

.pi-md-perm-data-setting {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-data-setting:before {
    content: "perm_data_setting"; }

.pi-md-perm-device-information {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-device-information:before {
    content: "perm_device_information"; }

.pi-md-perm-identity {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-identity:before {
    content: "perm_identity"; }

.pi-md-perm-media {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-media:before {
    content: "perm_media"; }

.pi-md-perm-phone-msg {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-phone-msg:before {
    content: "perm_phone_msg"; }

.pi-md-perm-scan-wifi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-scan-wifi:before {
    content: "perm_scan_wifi"; }

.pi-md-person {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person:before {
    content: "person"; }

.pi-md-person-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-add:before {
    content: "person_add"; }

.pi-md-person-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-outline:before {
    content: "person_outline"; }

.pi-md-person-pin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-pin:before {
    content: "person_pin"; }

.pi-md-person-pin-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-pin-circle:before {
    content: "person_pin_circle"; }

.pi-md-personal-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-personal-video:before {
    content: "personal_video"; }

.pi-md-pets {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pets:before {
    content: "pets"; }

.pi-md-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone:before {
    content: "phone"; }

.pi-md-phone-android {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-android:before {
    content: "phone_android"; }

.pi-md-phone-bluetooth-speaker {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-bluetooth-speaker:before {
    content: "phone_bluetooth_speaker"; }

.pi-md-phone-forwarded {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-forwarded:before {
    content: "phone_forwarded"; }

.pi-md-phone-in-talk {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-in-talk:before {
    content: "phone_in_talk"; }

.pi-md-phone-iphone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-iphone:before {
    content: "phone_iphone"; }

.pi-md-phone-locked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-locked:before {
    content: "phone_locked"; }

.pi-md-phone-missed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-missed:before {
    content: "phone_missed"; }

.pi-md-phone-paused {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-paused:before {
    content: "phone_paused"; }

.pi-md-phonelink {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink:before {
    content: "phonelink"; }

.pi-md-phonelink-erase {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-erase:before {
    content: "phonelink_erase"; }

.pi-md-phonelink-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-lock:before {
    content: "phonelink_lock"; }

.pi-md-phonelink-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-off:before {
    content: "phonelink_off"; }

.pi-md-phonelink-ring {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-ring:before {
    content: "phonelink_ring"; }

.pi-md-phonelink-setup {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-setup:before {
    content: "phonelink_setup"; }

.pi-md-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo:before {
    content: "photo"; }

.pi-md-photo-album {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-album:before {
    content: "photo_album"; }

.pi-md-photo-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-camera:before {
    content: "photo_camera"; }

.pi-md-photo-filter {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-filter:before {
    content: "photo_filter"; }

.pi-md-photo-library {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-library:before {
    content: "photo_library"; }

.pi-md-photo-size-select-actual {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-size-select-actual:before {
    content: "photo_size_select_actual"; }

.pi-md-photo-size-select-large {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-size-select-large:before {
    content: "photo_size_select_large"; }

.pi-md-photo-size-select-small {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-size-select-small:before {
    content: "photo_size_select_small"; }

.pi-md-picture-as-pdf {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-picture-as-pdf:before {
    content: "picture_as_pdf"; }

.pi-md-picture-in-picture {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-picture-in-picture:before {
    content: "picture_in_picture"; }

.pi-md-picture-in-picture-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-picture-in-picture-alt:before {
    content: "picture_in_picture_alt"; }

.pi-md-pie-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pie-chart:before {
    content: "pie_chart"; }

.pi-md-pie-chart-outlined {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pie-chart-outlined:before {
    content: "pie_chart_outlined"; }

.pi-md-pin-drop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pin-drop:before {
    content: "pin_drop"; }

.pi-md-place {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-place:before {
    content: "place"; }

.pi-md-play-arrow {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-arrow:before {
    content: "play_arrow"; }

.pi-md-play-circle-filled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-circle-filled:before {
    content: "play_circle_filled"; }

.pi-md-play-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-circle-outline:before {
    content: "play_circle_outline"; }

.pi-md-play-for-work {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-for-work:before {
    content: "play_for_work"; }

.pi-md-playlist-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-playlist-add:before {
    content: "playlist_add"; }

.pi-md-playlist-add-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-playlist-add-check:before {
    content: "playlist_add_check"; }

.pi-md-playlist-play {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-playlist-play:before {
    content: "playlist_play"; }

.pi-md-plus-one {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-plus-one:before {
    content: "plus_one"; }

.pi-md-poll {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-poll:before {
    content: "poll"; }

.pi-md-polymer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-polymer:before {
    content: "polymer"; }

.pi-md-pool {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pool:before {
    content: "pool"; }

.pi-md-portable-wifi-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-portable-wifi-off:before {
    content: "portable_wifi_off"; }

.pi-md-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-portrait:before {
    content: "portrait"; }

.pi-md-power {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-power:before {
    content: "power"; }

.pi-md-power-input {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-power-input:before {
    content: "power_input"; }

.pi-md-power-settings-new {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-power-settings-new:before {
    content: "power_settings_new"; }

.pi-md-pregnant-woman {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pregnant-woman:before {
    content: "pregnant_woman"; }

.pi-md-present-to-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-present-to-all:before {
    content: "present_to_all"; }

.pi-md-print {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-print:before {
    content: "print"; }

.pi-md-priority-high {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-priority-high:before {
    content: "priority_high"; }

.pi-md-public {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-public:before {
    content: "public"; }

.pi-md-publish {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-publish:before {
    content: "publish"; }

.pi-md-query-builder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-query-builder:before {
    content: "query_builder"; }

.pi-md-question-answer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-question-answer:before {
    content: "question_answer"; }

.pi-md-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-queue:before {
    content: "queue"; }

.pi-md-queue-music {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-queue-music:before {
    content: "queue_music"; }

.pi-md-queue-play-next {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-queue-play-next:before {
    content: "queue_play_next"; }

.pi-md-radio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio:before {
    content: "radio"; }

.pi-md-radio-button-checked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-button-checked:before {
    content: "radio_button_checked"; }

.pi-md-radio-button-unchecked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-button-unchecked:before {
    content: "radio_button_unchecked"; }

.pi-md-rate-review {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rate-review:before {
    content: "rate_review"; }

.pi-md-receipt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-receipt:before {
    content: "receipt"; }

.pi-md-recent-actors {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-recent-actors:before {
    content: "recent_actors"; }

.pi-md-record-voice-over {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-record-voice-over:before {
    content: "record_voice_over"; }

.pi-md-redeem {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-redeem:before {
    content: "redeem"; }

.pi-md-redo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-redo:before {
    content: "redo"; }

.pi-md-refresh {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-refresh:before {
    content: "refresh"; }

.pi-md-remove {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove:before {
    content: "remove"; }

.pi-md-remove-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-circle:before {
    content: "remove_circle"; }

.pi-md-remove-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-circle-outline:before {
    content: "remove_circle_outline"; }

.pi-md-remove-from-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-from-queue:before {
    content: "remove_from_queue"; }

.pi-md-remove-red-eye {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-red-eye:before {
    content: "remove_red_eye"; }

.pi-md-remove-shopping-cart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-shopping-cart:before {
    content: "remove_shopping_cart"; }

.pi-md-reorder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-reorder:before {
    content: "reorder"; }

.pi-md-repeat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-repeat:before {
    content: "repeat"; }

.pi-md-repeat-one {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-repeat-one:before {
    content: "repeat_one"; }

.pi-md-replay {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay:before {
    content: "replay"; }

.pi-md-replay-10 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay-10:before {
    content: "replay_10"; }

.pi-md-replay-30 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay-30:before {
    content: "replay_30"; }

.pi-md-replay-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay-5:before {
    content: "replay_5"; }

.pi-md-reply {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-reply:before {
    content: "reply"; }

.pi-md-reply-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-reply-all:before {
    content: "reply_all"; }

.pi-md-report {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-report:before {
    content: "report"; }

.pi-md-report-problem {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-report-problem:before {
    content: "report_problem"; }

.pi-md-restaurant {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restaurant:before {
    content: "restaurant"; }

.pi-md-restaurant-menu {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restaurant-menu:before {
    content: "restaurant_menu"; }

.pi-md-restore {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restore:before {
    content: "restore"; }

.pi-md-restore-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restore-page:before {
    content: "restore_page"; }

.pi-md-ring-volume {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ring-volume:before {
    content: "ring_volume"; }

.pi-md-room {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-room:before {
    content: "room"; }

.pi-md-room-service {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-room-service:before {
    content: "room_service"; }

.pi-md-rotate-90-degrees-ccw {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rotate-90-degrees-ccw:before {
    content: "rotate_90_degrees_ccw"; }

.pi-md-rotate-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rotate-left:before {
    content: "rotate_left"; }

.pi-md-rotate-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rotate-right:before {
    content: "rotate_right"; }

.pi-md-rounded-corner {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rounded-corner:before {
    content: "rounded_corner"; }

.pi-md-router {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-router:before {
    content: "router"; }

.pi-md-rowing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rowing:before {
    content: "rowing"; }

.pi-md-rss-feed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rss-feed:before {
    content: "rss_feed"; }

.pi-md-rv-hookup {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rv-hookup:before {
    content: "rv_hookup"; }

.pi-md-satellite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-satellite:before {
    content: "satellite"; }

.pi-md-save {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-save:before {
    content: "save"; }

.pi-md-scanner {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-scanner:before {
    content: "scanner"; }

.pi-md-schedule {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-schedule:before {
    content: "schedule"; }

.pi-md-school {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-school:before {
    content: "school"; }

.pi-md-screen-lock-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-lock-landscape:before {
    content: "screen_lock_landscape"; }

.pi-md-screen-lock-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-lock-portrait:before {
    content: "screen_lock_portrait"; }

.pi-md-screen-lock-rotation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-lock-rotation:before {
    content: "screen_lock_rotation"; }

.pi-md-screen-rotation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-rotation:before {
    content: "screen_rotation"; }

.pi-md-screen-share {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-share:before {
    content: "screen_share"; }

.pi-md-sd-card {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sd-card:before {
    content: "sd_card"; }

.pi-md-sd-storage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sd-storage:before {
    content: "sd_storage"; }

.pi-md-search {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-search:before {
    content: "search"; }

.pi-md-security {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-security:before {
    content: "security"; }

.pi-md-select-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-select-all:before {
    content: "select_all"; }

.pi-md-send {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-send:before {
    content: "send"; }

.pi-md-sentiment-dissatisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-dissatisfied:before {
    content: "sentiment_dissatisfied"; }

.pi-md-sentiment-neutral {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-neutral:before {
    content: "sentiment_neutral"; }

.pi-md-sentiment-satisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-satisfied:before {
    content: "sentiment_satisfied"; }

.pi-md-sentiment-very-dissatisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-very-dissatisfied:before {
    content: "sentiment_very_dissatisfied"; }

.pi-md-sentiment-very-satisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-very-satisfied:before {
    content: "sentiment_very_satisfied"; }

.pi-md-settings {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings:before {
    content: "settings"; }

.pi-md-settings-applications {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-applications:before {
    content: "settings_applications"; }

.pi-md-settings-backup-restore {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-backup-restore:before {
    content: "settings_backup_restore"; }

.pi-md-settings-bluetooth {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-bluetooth:before {
    content: "settings_bluetooth"; }

.pi-md-settings-brightness {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-brightness:before {
    content: "settings_brightness"; }

.pi-md-settings-cell {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-cell:before {
    content: "settings_cell"; }

.pi-md-settings-ethernet {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-ethernet:before {
    content: "settings_ethernet"; }

.pi-md-settings-input-antenna {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-antenna:before {
    content: "settings_input_antenna"; }

.pi-md-settings-input-component {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-component:before {
    content: "settings_input_component"; }

.pi-md-settings-input-composite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-composite:before {
    content: "settings_input_composite"; }

.pi-md-settings-input-hdmi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-hdmi:before {
    content: "settings_input_hdmi"; }

.pi-md-settings-input-svideo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-svideo:before {
    content: "settings_input_svideo"; }

.pi-md-settings-overscan {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-overscan:before {
    content: "settings_overscan"; }

.pi-md-settings-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-phone:before {
    content: "settings_phone"; }

.pi-md-settings-power {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-power:before {
    content: "settings_power"; }

.pi-md-settings-remote {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-remote:before {
    content: "settings_remote"; }

.pi-md-settings-system-daydream {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-system-daydream:before {
    content: "settings_system_daydream"; }

.pi-md-settings-voice {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-voice:before {
    content: "settings_voice"; }

.pi-md-share {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-share:before {
    content: "share"; }

.pi-md-shop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shop:before {
    content: "shop"; }

.pi-md-shop-two {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shop-two:before {
    content: "shop_two"; }

.pi-md-shopping-basket {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shopping-basket:before {
    content: "shopping_basket"; }

.pi-md-shopping-cart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shopping-cart:before {
    content: "shopping_cart"; }

.pi-md-short-text {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-short-text:before {
    content: "short_text"; }

.pi-md-show-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-show-chart:before {
    content: "show_chart"; }

.pi-md-shuffle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shuffle:before {
    content: "shuffle"; }

.pi-md-signal-cellular-4-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-4-bar:before {
    content: "signal_cellular_4_bar"; }

.pi-md-signal-cellular-connected-no-internet-4-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-connected-no-internet-4-bar:before {
    content: "signal_cellular_connected_no_internet_4_bar"; }

.pi-md-signal-cellular-no-sim {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-no-sim:before {
    content: "signal_cellular_no_sim"; }

.pi-md-signal-cellular-null {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-null:before {
    content: "signal_cellular_null"; }

.pi-md-signal-cellular-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-off:before {
    content: "signal_cellular_off"; }

.pi-md-signal-wifi-4-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-wifi-4-bar:before {
    content: "signal_wifi_4_bar"; }

.pi-md-signal-wifi-4-bar-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-wifi-4-bar-lock:before {
    content: "signal_wifi_4_bar_lock"; }

.pi-md-signal-wifi-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-wifi-off:before {
    content: "signal_wifi_off"; }

.pi-md-sim-card {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sim-card:before {
    content: "sim_card"; }

.pi-md-sim-card-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sim-card-alert:before {
    content: "sim_card_alert"; }

.pi-md-skip-next {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-skip-next:before {
    content: "skip_next"; }

.pi-md-skip-previous {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-skip-previous:before {
    content: "skip_previous"; }

.pi-md-slideshow {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-slideshow:before {
    content: "slideshow"; }

.pi-md-slow-motion-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-slow-motion-video:before {
    content: "slow_motion_video"; }

.pi-md-smartphone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-smartphone:before {
    content: "smartphone"; }

.pi-md-smoke-free {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-smoke-free:before {
    content: "smoke_free"; }

.pi-md-smoking-rooms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-smoking-rooms:before {
    content: "smoking_rooms"; }

.pi-md-sms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sms:before {
    content: "sms"; }

.pi-md-sms-failed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sms-failed:before {
    content: "sms_failed"; }

.pi-md-snooze {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-snooze:before {
    content: "snooze"; }

.pi-md-sort {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sort:before {
    content: "sort"; }

.pi-md-sort-by-alpha {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sort-by-alpha:before {
    content: "sort_by_alpha"; }

.pi-md-spa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-spa:before {
    content: "spa"; }

.pi-md-space-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-space-bar:before {
    content: "space_bar"; }

.pi-md-speaker {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker:before {
    content: "speaker"; }

.pi-md-speaker-group {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-group:before {
    content: "speaker_group"; }

.pi-md-speaker-notes {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-notes:before {
    content: "speaker_notes"; }

.pi-md-speaker-notes-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-notes-off:before {
    content: "speaker_notes_off"; }

.pi-md-speaker-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-phone:before {
    content: "speaker_phone"; }

.pi-md-spellcheck {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-spellcheck:before {
    content: "spellcheck"; }

.pi-md-star {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-star:before {
    content: "star"; }

.pi-md-star-border {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-star-border:before {
    content: "star_border"; }

.pi-md-star-half {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-star-half:before {
    content: "star_half"; }

.pi-md-stars {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stars:before {
    content: "stars"; }

.pi-md-stay-current-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-current-landscape:before {
    content: "stay_current_landscape"; }

.pi-md-stay-current-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-current-portrait:before {
    content: "stay_current_portrait"; }

.pi-md-stay-primary-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-primary-landscape:before {
    content: "stay_primary_landscape"; }

.pi-md-stay-primary-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-primary-portrait:before {
    content: "stay_primary_portrait"; }

.pi-md-stop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stop:before {
    content: "stop"; }

.pi-md-stop-screen-share {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stop-screen-share:before {
    content: "stop_screen_share"; }

.pi-md-storage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-storage:before {
    content: "storage"; }

.pi-md-store {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-store:before {
    content: "store"; }

.pi-md-store-mall-directory {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-store-mall-directory:before {
    content: "store_mall_directory"; }

.pi-md-straighten {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-straighten:before {
    content: "straighten"; }

.pi-md-streetview {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-streetview:before {
    content: "streetview"; }

.pi-md-strikethrough-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-strikethrough-s:before {
    content: "strikethrough_s"; }

.pi-md-style {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-style:before {
    content: "style"; }

.pi-md-subdirectory-arrow-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subdirectory-arrow-left:before {
    content: "subdirectory_arrow_left"; }

.pi-md-subdirectory-arrow-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subdirectory-arrow-right:before {
    content: "subdirectory_arrow_right"; }

.pi-md-subject {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subject:before {
    content: "subject"; }

.pi-md-subscriptions {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subscriptions:before {
    content: "subscriptions"; }

.pi-md-subtitles {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subtitles:before {
    content: "subtitles"; }

.pi-md-subway {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subway:before {
    content: "subway"; }

.pi-md-supervisor-account {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-supervisor-account:before {
    content: "supervisor_account"; }

.pi-md-surround-sound {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-surround-sound:before {
    content: "surround_sound"; }

.pi-md-swap-calls {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-calls:before {
    content: "swap_calls"; }

.pi-md-swap-horiz {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-horiz:before {
    content: "swap_horiz"; }

.pi-md-swap-vert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-vert:before {
    content: "swap_vert"; }

.pi-md-swap-vertical-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-vertical-circle:before {
    content: "swap_vertical_circle"; }

.pi-md-switch-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-switch-camera:before {
    content: "switch_camera"; }

.pi-md-switch-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-switch-video:before {
    content: "switch_video"; }

.pi-md-sync {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sync:before {
    content: "sync"; }

.pi-md-sync-disabled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sync-disabled:before {
    content: "sync_disabled"; }

.pi-md-sync-problem {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sync-problem:before {
    content: "sync_problem"; }

.pi-md-system-update {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-system-update:before {
    content: "system_update"; }

.pi-md-system-update-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-system-update-alt:before {
    content: "system_update_alt"; }

.pi-md-tab {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tab:before {
    content: "tab"; }

.pi-md-tab-unselected {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tab-unselected:before {
    content: "tab_unselected"; }

.pi-md-tablet {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tablet:before {
    content: "tablet"; }

.pi-md-tablet-android {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tablet-android:before {
    content: "tablet_android"; }

.pi-md-tablet-mac {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tablet-mac:before {
    content: "tablet_mac"; }

.pi-md-tag-faces {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tag-faces:before {
    content: "tag_faces"; }

.pi-md-tap-and-play {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tap-and-play:before {
    content: "tap_and_play"; }

.pi-md-terrain {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-terrain:before {
    content: "terrain"; }

.pi-md-text-fields {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-text-fields:before {
    content: "text_fields"; }

.pi-md-text-format {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-text-format:before {
    content: "text_format"; }

.pi-md-textsms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-textsms:before {
    content: "textsms"; }

.pi-md-texture {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-texture:before {
    content: "texture"; }

.pi-md-theaters {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-theaters:before {
    content: "theaters"; }

.pi-md-thumb-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-thumb-down:before {
    content: "thumb_down"; }

.pi-md-thumb-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-thumb-up:before {
    content: "thumb_up"; }

.pi-md-thumbs-up-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-thumbs-up-down:before {
    content: "thumbs_up_down"; }

.pi-md-time-to-leave {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-time-to-leave:before {
    content: "time_to_leave"; }

.pi-md-timelapse {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timelapse:before {
    content: "timelapse"; }

.pi-md-timeline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timeline:before {
    content: "timeline"; }

.pi-md-timer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer:before {
    content: "timer"; }

.pi-md-timer-10 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer-10:before {
    content: "timer_10"; }

.pi-md-timer-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer-3:before {
    content: "timer_3"; }

.pi-md-timer-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer-off:before {
    content: "timer_off"; }

.pi-md-title {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-title:before {
    content: "title"; }

.pi-md-toc {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-toc:before {
    content: "toc"; }

.pi-md-today {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-today:before {
    content: "today"; }

.pi-md-toll {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-toll:before {
    content: "toll"; }

.pi-md-tonality {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tonality:before {
    content: "tonality"; }

.pi-md-touch-app {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-touch-app:before {
    content: "touch_app"; }

.pi-md-toys {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-toys:before {
    content: "toys"; }

.pi-md-track-changes {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-track-changes:before {
    content: "track_changes"; }

.pi-md-traffic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-traffic:before {
    content: "traffic"; }

.pi-md-train {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-train:before {
    content: "train"; }

.pi-md-tram {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tram:before {
    content: "tram"; }

.pi-md-transfer-within-a-station {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-transfer-within-a-station:before {
    content: "transfer_within_a_station"; }

.pi-md-transform {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-transform:before {
    content: "transform"; }

.pi-md-translate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-translate:before {
    content: "translate"; }

.pi-md-trending-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trending-down:before {
    content: "trending_down"; }

.pi-md-trending-flat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trending-flat:before {
    content: "trending_flat"; }

.pi-md-trending-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trending-up:before {
    content: "trending_up"; }

.pi-md-tune {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tune:before {
    content: "tune"; }

.pi-md-turned-in {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-turned-in:before {
    content: "turned_in"; }

.pi-md-turned-in-not {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-turned-in-not:before {
    content: "turned_in_not"; }

.pi-md-tv {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tv:before {
    content: "tv"; }

.pi-md-unarchive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-unarchive:before {
    content: "unarchive"; }

.pi-md-undo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-undo:before {
    content: "undo"; }

.pi-md-unfold-less {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-unfold-less:before {
    content: "unfold_less"; }

.pi-md-unfold-more {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-unfold-more:before {
    content: "unfold_more"; }

.pi-md-update {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-update:before {
    content: "update"; }

.pi-md-usb {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-usb:before {
    content: "usb"; }

.pi-md-verified-user {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-verified-user:before {
    content: "verified_user"; }

.pi-md-vertical-align-bottom {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vertical-align-bottom:before {
    content: "vertical_align_bottom"; }

.pi-md-vertical-align-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vertical-align-center:before {
    content: "vertical_align_center"; }

.pi-md-vertical-align-top {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vertical-align-top:before {
    content: "vertical_align_top"; }

.pi-md-vibration {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vibration:before {
    content: "vibration"; }

.pi-md-video-call {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video-call:before {
    content: "video_call"; }

.pi-md-video-label {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video-label:before {
    content: "video_label"; }

.pi-md-video-library {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video-library:before {
    content: "video_library"; }

.pi-md-videocam {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-videocam:before {
    content: "videocam"; }

.pi-md-videocam-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-videocam-off:before {
    content: "videocam_off"; }

.pi-md-videogame-asset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-videogame-asset:before {
    content: "videogame_asset"; }

.pi-md-view-agenda {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-agenda:before {
    content: "view_agenda"; }

.pi-md-view-array {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-array:before {
    content: "view_array"; }

.pi-md-view-carousel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-carousel:before {
    content: "view_carousel"; }

.pi-md-view-column {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-column:before {
    content: "view_column"; }

.pi-md-view-comfy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-comfy:before {
    content: "view_comfy"; }

.pi-md-view-compact {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-compact:before {
    content: "view_compact"; }

.pi-md-view-day {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-day:before {
    content: "view_day"; }

.pi-md-view-headline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-headline:before {
    content: "view_headline"; }

.pi-md-view-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-list:before {
    content: "view_list"; }

.pi-md-view-module {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-module:before {
    content: "view_module"; }

.pi-md-view-quilt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-quilt:before {
    content: "view_quilt"; }

.pi-md-view-stream {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-stream:before {
    content: "view_stream"; }

.pi-md-view-week {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-week:before {
    content: "view_week"; }

.pi-md-vignette {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vignette:before {
    content: "vignette"; }

.pi-md-visibility {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-visibility:before {
    content: "visibility"; }

.pi-md-visibility-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-visibility-off:before {
    content: "visibility_off"; }

.pi-md-voice-chat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-voice-chat:before {
    content: "voice_chat"; }

.pi-md-voicemail {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-voicemail:before {
    content: "voicemail"; }

.pi-md-volume-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-down:before {
    content: "volume_down"; }

.pi-md-volume-mute {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-mute:before {
    content: "volume_mute"; }

.pi-md-volume-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-off:before {
    content: "volume_off"; }

.pi-md-volume-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-up:before {
    content: "volume_up"; }

.pi-md-vpn-key {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vpn-key:before {
    content: "vpn_key"; }

.pi-md-vpn-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vpn-lock:before {
    content: "vpn_lock"; }

.pi-md-wallpaper {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wallpaper:before {
    content: "wallpaper"; }

.pi-md-warning {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-warning:before {
    content: "warning"; }

.pi-md-watch {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-watch:before {
    content: "watch"; }

.pi-md-watch-later {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-watch-later:before {
    content: "watch_later"; }

.pi-md-wb-auto {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-auto:before {
    content: "wb_auto"; }

.pi-md-wb-cloudy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-cloudy:before {
    content: "wb_cloudy"; }

.pi-md-wb-incandescent {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-incandescent:before {
    content: "wb_incandescent"; }

.pi-md-wb-iridescent {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-iridescent:before {
    content: "wb_iridescent"; }

.pi-md-wb-sunny {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-sunny:before {
    content: "wb_sunny"; }

.pi-md-wc {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wc:before {
    content: "wc"; }

.pi-md-web {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-web:before {
    content: "web"; }

.pi-md-web-asset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-web-asset:before {
    content: "web_asset"; }

.pi-md-weekend {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-weekend:before {
    content: "weekend"; }

.pi-md-whatshot {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-whatshot:before {
    content: "whatshot"; }

.pi-md-widgets {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-widgets:before {
    content: "widgets"; }

.pi-md-wifi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wifi:before {
    content: "wifi"; }

.pi-md-wifi-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wifi-lock:before {
    content: "wifi_lock"; }

.pi-md-wifi-tethering {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wifi-tethering:before {
    content: "wifi_tethering"; }

.pi-md-work {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-work:before {
    content: "work"; }

.pi-md-wrap-text {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wrap-text:before {
    content: "wrap_text"; }

.pi-md-youtube-searched-for {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-youtube-searched-for:before {
    content: "youtube_searched_for"; }

.pi-md-zoom-in {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-zoom-in:before {
    content: "zoom_in"; }

.pi-md-zoom-out {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-zoom-out:before {
    content: "zoom_out"; }

.pi-md-zoom-out-map {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-zoom-out-map:before {
    content: "zoom_out_map"; }

.material-icons, .fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

body .p-component {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  text-decoration: none; }

body .p-widget-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }

body .p-widget-header {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .p-widget-header .pi {
    color: #ffffff; }

body .p-highlight {
  background-color: #4699ce;
  color: #ffffff; }

body .p-component:disabled,
body .p-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: auto !important; }

body .p-component-overlay {
  background-color: #424242;
  opacity: 0.8;
  filter: alpha(opacity=80); }

body a {
  color: #00529e; }

body {
  /* InputGroup */
  /* Validation */ }
  body .p-inputtext {
    background: transparent no-repeat;
    background-image: linear-gradient(to bottom, #00529e, #00529e), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-width: 0;
    padding: 2px;
    font-size: 14px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px; }
    body .p-inputtext:focus, body .p-inputtext.p-focus {
      border-width: 0;
      background-size: 100% 2px, 100% 1px;
      padding-bottom: 2px; }
    body .p-inputtext:disabled {
      border-bottom: 1px dotted; }
    body .p-inputtext.p-widget-content {
      border-width: 1px;
      background: transparent;
      background-image: none; }
    body .p-inputtext.p-error {
      background-image: linear-gradient(to bottom, #e62a10, #e62a10), linear-gradient(to bottom, #e62a10, #e62a10); }
  body .p-multiselect-filter-container .p-inputtext, body .p-listbox-filter-container .p-inputtext, body .p-dropdown-filter-container .p-inputtext, body .p-datatable-header .p-inputtext {
    background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #1f93ff, #1f93ff); }
  body .md-inputfield {
    display: block;
    position: relative; }
    body .md-inputfield label {
      color: #999999;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 1px;
      transition: 0.3s ease all;
      -moz-transition: 0.3s ease all;
      -webkit-transition: 0.3s ease all; }
    body .md-inputfield input:focus ~ label,
    body .md-inputfield input.p-filled ~ label,
    body .md-inputfield .p-inputwrapper-focus ~ label,
    body .md-inputfield .p-inputwrapper-filled ~ label {
      top: -20px;
      font-size: 12px;
      color: #00529e; }
    body .md-inputfield .input:-webkit-autofill ~ label {
      top: -20px;
      font-size: 12px;
      color: #00529e; }
    body .md-inputfield input.ng-dirty.ng-invalid ~ label {
      color: #e62a10; }
    body .md-inputfield .p-message.p-messages-error {
      background-color: transparent;
      border: 0 none;
      margin: 0px;
      color: #e62a10;
      font-size: .75em; }
    body .md-inputfield.md-inputfield-fill input {
      background-color: #f7f7f7;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 4px; }
    body .md-inputfield.md-inputfield-fill label {
      top: 2px; }
    body .md-inputfield.md-inputfield-box input {
      background-color: #f7f7f7;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 24px; }
    body .md-inputfield.md-inputfield-box label {
      top: 23px; }
    body .md-inputfield.md-inputfield-box input:focus ~ label,
    body .md-inputfield.md-inputfield-box input.p-filled ~ label,
    body .md-inputfield.md-inputfield-box .p-inputwrapper-focus ~ label,
    body .md-inputfield.md-inputfield-box .p-inputwrapper-filled ~ label {
      top: 1px; }
    body .md-inputfield.md-inputfield-box .input:-webkit-autofill ~ label {
      top: 1px; }
  body .p-material-message.p-message.p-messages-error {
    background-color: transparent;
    border: 0 none;
    margin: 0px;
    color: #e62a10;
    font-size: .75em; }
  body .p-listbox {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    min-width: 12.5em;
    background-color: #ffffff;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-listbox.p-inputtext {
      padding: 0; }
    body .p-listbox .p-listbox-list {
      padding: 0; }
    body .p-listbox .p-listbox-item {
      font-size: 14px;
      padding: 0.625em 0.875em;
      margin: 0;
      color: #212121;
      position: relative;
      overflow: hidden;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-listbox .p-listbox-item.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
    body .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-listbox .p-listbox-header {
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      padding: 0.625em 1em; }
      body .p-listbox .p-listbox-header .pi {
        color: #ffffff; }
      body .p-listbox .p-listbox-header .p-checkbox .p-checkbox-box {
        border-color: #ffffff; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container .p-inputtext {
        padding: 2px;
        padding-left: 1.5em; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container .pi {
        top: 0;
        left: 0; }
  body .p-button {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    background-color: #00529e;
    color: #ffffff;
    font-size: 1em;
    height: 2.25em;
    padding: 0 1em;
    border: 0 none;
    -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    vertical-align: middle; }
    body .p-button:enabled:not(:focus):hover {
      background-color: #0f2765; }
    body .p-button:focus {
      outline: 0 none;
      background-color: #006cd1; }
    body .p-button .p-button-text {
      padding: 0;
      line-height: 2.25em; }
    body .p-button.p-button-icon-only {
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em; }
      body .p-button.p-button-icon-only .pi {
        margin-left: -.5em; }
    body .p-button.p-button-text-icon-left {
      padding-left: 2.5em; }
    body .p-button.p-button-text-icon-right {
      padding-right: 2.5em; }
    body .p-button.secondary-btn {
      background-color: #4699ce;
      color: #ffffff; }
      body .p-button.secondary-btn:enabled:not(:focus):hover {
        background-color: #274d8d; }
      body .p-button.secondary-btn:focus {
        outline: 0 none;
        background-color: #6eafd9; }
    body .p-button.blue-grey-btn {
      background-color: #607D8B; }
      body .p-button.blue-grey-btn:enabled:not(:focus):hover {
        background-color: #37474F; }
      body .p-button.blue-grey-btn:focus {
        outline: 0 none;
        background-color: #7b96a3; }
    body .p-button.cyan-btn {
      background-color: #00BCD4; }
      body .p-button.cyan-btn:enabled:not(:focus):hover {
        background-color: #00838F; }
      body .p-button.cyan-btn:focus {
        outline: 0 none;
        background-color: #08e3ff; }
    body .p-button.teal-btn {
      background-color: #009688; }
      body .p-button.teal-btn:enabled:not(:focus):hover {
        background-color: #009688; }
      body .p-button.teal-btn:focus {
        outline: 0 none;
        background-color: #00c9b6; }
    body .p-button.red-btn {
      background-color: #F44336; }
      body .p-button.red-btn:enabled:not(:focus):hover {
        background-color: #C62828; }
      body .p-button.red-btn:focus {
        outline: 0 none;
        background-color: #f77066; }
    body .p-button.green-btn {
      background-color: #4CAF50; }
      body .p-button.green-btn:enabled:not(:focus):hover {
        background-color: #2E7D32; }
      body .p-button.green-btn:focus {
        outline: 0 none;
        background-color: #6ec071; }
    body .p-button.deep-orange-btn {
      background-color: #FF5722; }
      body .p-button.deep-orange-btn:enabled:not(:focus):hover {
        background-color: #D84315; }
      body .p-button.deep-orange-btn:focus {
        outline: 0 none;
        background-color: #ff7e55; }
    body .p-button.purple-btn {
      background-color: #673AB7; }
      body .p-button.purple-btn:enabled:not(:focus):hover {
        background-color: #4527A0; }
      body .p-button.purple-btn:focus {
        outline: 0 none;
        background-color: #8259cb; }
    body .p-button.pink-btn {
      background-color: #E91E63; }
      body .p-button.pink-btn:enabled:not(:focus):hover {
        background-color: #AD1457; }
      body .p-button.pink-btn:focus {
        outline: 0 none;
        background-color: #ee4c83; }
    body .p-button.amber-btn {
      background-color: #FFC107;
      color: #212121; }
      body .p-button.amber-btn:enabled:not(:focus):hover {
        background-color: #FF8F00; }
      body .p-button.amber-btn:focus {
        outline: 0 none;
        background-color: #ffce3a; }
    body .p-button.orange-btn {
      background-color: #FF9800; }
      body .p-button.orange-btn:enabled:not(:focus):hover {
        background-color: #EF6C00; }
      body .p-button.orange-btn:focus {
        outline: 0 none;
        background-color: #ffad33; }
    body .p-button.brown-btn {
      background-color: #795548; }
      body .p-button.brown-btn:enabled:not(:focus):hover {
        background-color: #4E342E; }
      body .p-button.brown-btn:focus {
        outline: 0 none;
        background-color: #996b5b; }
    body .p-button.indigo-btn {
      background-color: #3F51B5; }
      body .p-button.indigo-btn:enabled:not(:focus):hover {
        background-color: #283593; }
      body .p-button.indigo-btn:focus {
        outline: 0 none;
        background-color: #606fc7; }
    body .p-button.blue-btn, body .p-button.ui-info-button {
      background-color: #03A9F4;
      color: #ffffff; }
      body .p-button.blue-btn:enabled:not(:focus):hover, body .p-button.ui-info-button:enabled:not(:focus):hover {
        background-color: #0277BD; }
      body .p-button.blue-btn:focus, body .p-button.ui-info-button:focus {
        outline: 0 none;
        background-color: #2ebcfc; }
    body .p-button.flat-btn {
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none; }
  body .p-button.p-button-secondary, body .p-buttonset.p-button-secondary > .p-button {
    color: #212121;
    background-color: #ffffff; }
    body .p-button.p-button-secondary .p-button-icon-left, body .p-buttonset.p-button-secondary > .p-button .p-button-icon-left {
      color: #757575; }
    body .p-button.p-button-secondary .p-button-icon-right, body .p-buttonset.p-button-secondary > .p-button .p-button-icon-right {
      color: #757575; }
    body .p-button.p-button-secondary:enabled:hover, body .p-buttonset.p-button-secondary > .p-button:enabled:hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-button.p-button-secondary:enabled:focus, body .p-buttonset.p-button-secondary > .p-button:enabled:focus {
      background-color: #e6e6e6; }
  body .p-button.p-button-info, body .p-buttonset.p-button-info > .p-button {
    color: #ffffff;
    background-color: #03A9F4; }
    body .p-button.p-button-info:enabled:hover, body .p-buttonset.p-button-info > .p-button:enabled:hover {
      background-color: #0277BD; }
    body .p-button.p-button-info:enabled:focus, body .p-buttonset.p-button-info > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #2ebcfc; }
  body .p-button.p-button-success, body .p-buttonset.p-button-success > .p-button {
    color: #ffffff;
    background-color: #4CAF50; }
    body .p-button.p-button-success:enabled:hover, body .p-buttonset.p-button-success > .p-button:enabled:hover {
      background-color: #2E7D32; }
    body .p-button.p-button-success:enabled:focus, body .p-buttonset.p-button-success > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #6ec071; }
  body .p-button.p-button-warning, body .p-buttonset.p-button-warning > .p-button {
    color: #212121;
    background-color: #FFC107; }
    body .p-button.p-button-warning:enabled:hover, body .p-buttonset.p-button-warning > .p-button:enabled:hover {
      background-color: #FF8F00; }
    body .p-button.p-button-warning:enabled:focus, body .p-buttonset.p-button-warning > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #ffce3a; }
  body .p-button.p-button-danger, body .p-buttonset.p-button-danger > .p-button {
    color: #ffffff;
    background-color: #F44336; }
    body .p-button.p-button-danger:enabled:hover, body .p-buttonset.p-button-danger > .p-button:enabled:hover {
      background-color: #C62828; }
    body .p-button.p-button-danger:enabled:focus, body .p-buttonset.p-button-danger > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #f77066; }
  body .p-buttonset .p-button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-buttonset .p-button:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    body .p-buttonset .p-button:last-child {
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
    body .p-buttonset .p-button:not(.p-disabled):not(.p-highlight):hover {
      background-color: #0f2765; }
    body .p-buttonset .p-button.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }
  body .p-togglebutton:not(.p-highlight):not(.p-disabled):hover {
    background-color: #0f2765; }
  body .p-togglebutton.p-highlight {
    background-color: #4699ce;
    color: #ffffff; }
  body .p-splitbutton {
    line-height: 18px;
    vertical-align: middle;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none; }
    body .p-splitbutton > .p-button:first-child {
      vertical-align: top;
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px;
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px;
      -moz-border-radius-topright: 0;
      -webkit-border-top-right-radius: 0;
      border-top-right-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
    body .p-splitbutton > .p-button:active {
      background-color: #006cd1; }
    body .p-splitbutton > .p-button.p-splitbutton-menubutton {
      height: 2.25em;
      width: 2.5em;
      -moz-border-radius-topleft: 0;
      -webkit-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
  body .p-fluid .p-splitbutton .p-button:first-child {
    width: calc(100% - 2.5em); }
  body .p-fluid .p-splitbutton .p-button.p-splitbutton-menubutton {
    width: 2.5em; }
  body .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.25em;
    width: 1.25em;
    height: 1.25em;
    cursor: default;
    margin-right: .5em; }
    body .p-checkbox .p-checkbox-box {
      border: 2px solid #757575;
      width: 1.25em;
      height: 1.25em;
      overflow: hidden;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      body .p-checkbox .p-checkbox-box .p-checkbox-icon {
        font-size: 1.25em;
        margin-left: -2px;
        margin-top: -2px; }
      body .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #00529e;
        background-color: #00529e; }
      body .p-checkbox .p-checkbox-box.p-focus {
        border-color: #00529e;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-transition: box-shadow 0.3s, background-color 0.3s;
        -o-transition: box-shadow 0.3s, background-color 0.3s;
        -webkit-transition: box-shadow 0.3s, background-color 0.3s;
        transition: box-shadow 0.3s, background-color 0.3s; }
  body .p-radiobutton {
    position: relative;
    margin: 0 .5em 0 0;
    vertical-align: middle; }
    body .p-radiobutton .p-radiobutton-box {
      border: 2px solid #757575;
      width: 1.286em;
      height: 1.286em;
      -moz-transition: box-shadow 0.3s;
      -o-transition: box-shadow 0.3s;
      -webkit-transition: box-shadow 0.3s;
      transition: box-shadow 0.3s;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; }
      body .p-radiobutton .p-radiobutton-box.p-focus {
        border-color: #00529e;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-transition: box-shadow 0.3s, background-color 0.3s;
        -o-transition: box-shadow 0.3s, background-color 0.3s;
        -webkit-transition: box-shadow 0.3s, background-color 0.3s;
        transition: box-shadow 0.3s, background-color 0.3s; }
      body .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: #00529e;
        background-color: transparent; }
      body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 1em;
        transition: -webkit-transform ease .28s;
        transition: transform ease .28s;
        -webkit-transform: scale(0);
        transform: scale(0); }
      body .p-radiobutton .p-radiobutton-box .pi-circle-on {
        background-color: #00529e;
        -webkit-transform: scale(0.5);
        transform: scale(0.5); }
        body .p-radiobutton .p-radiobutton-box .pi-circle-on.pi:before {
          content: "circle"; }
  body .p-autocomplete-panel {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-autocomplete-panel.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-autocomplete-panel .p-autocomplete-list {
      padding: 0; }
      body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item {
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        padding: 0.625em 1em;
        color: #212121; }
        body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item .p-autocomplete-query {
          font-weight: 700; }
        body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item:hover, body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item.p-highlight {
          background-color: #4699ce;
          color: #ffffff; }
      body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-group {
        padding: 0.625em 1em; }
  body .p-autocomplete .p-autocomplete-loader {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-name: spin;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-delay: 0;
    -moz-animation-duration: 1000ms;
    -moz-animation-name: spin;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-delay: 0;
    animation-duration: 1000ms;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: #757575;
    right: 0; }
    body .p-autocomplete .p-autocomplete-loader:before {
      content: "refresh"; }
  body .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 1em; }
  body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only {
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    height: 1.5em;
    width: 1.5em;
    padding: 0;
    right: 0;
    top: 0;
    margin-right: 0;
    position: absolute; }
    body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only .p-button-text {
      display: none; }
    body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only .p-button-icon {
      line-height: 1; }
    body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only .pi {
      color: #757575; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-inputtext {
    box-sizing: border-box;
    padding: 2px; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-disabled {
    border-bottom: 1px dotted; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token {
    float: none;
    display: inline-block;
    margin: 0 1px;
    vertical-align: middle; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token > input {
      padding: 0;
      font-size: 14px;
      margin: 0;
      vertical-align: bottom; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token {
    display: inline-block;
    float: none;
    vertical-align: middle;
    font-size: 1em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token .p-autocomplete-token-label {
      display: inline-block;
      vertical-align: middle; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-dropdown.p-button.p-button-icon-only {
    top: auto;
    bottom: 0; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-loader {
    margin-top: -.35em; }
  body .p-fluid .p-autocomplete.p-autocomplete-dd > .p-inputtext {
    width: 100%; }
  body .p-dropdown {
    border-width: 0;
    background: transparent no-repeat;
    background-image: linear-gradient(to bottom, #00529e, #00529e), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    padding-bottom: 2px;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-dropdown .p-dropdown-trigger-icon {
      height: 1.5em;
      width: 1.5em;
      margin-top: 0;
      padding: 0;
      margin-right: 0;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px;
      color: #757575; }
    body .p-dropdown .p-dropdown-label {
      background: none;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
    body .p-dropdown.p-focus {
      border-width: 0;
      background-size: 100% 2px, 100% 1px;
      padding-bottom: 2px; }
      body .p-dropdown.p-focus .p-dropdown-label {
        padding-bottom: 2px; }
  body .p-dropdown-panel {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-dropdown-panel .p-dropdown-list {
      padding: 0;
      background-color: #ffffff;
      border: 1px solid #d8d8d8;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
    body .p-dropdown-panel .p-dropdown-item {
      margin: 0;
      padding: 0.625em 1em;
      color: #212121;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-dropdown-panel .p-dropdown-item:hover {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-dropdown-panel .p-dropdown-item.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
    body .p-dropdown-panel.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-dropdown-panel .p-dropdown-filter-container {
      display: block;
      border-bottom: 1px solid #bdbdbd;
      background-color: #00529e;
      padding: 0.625em 1em; }
      body .p-dropdown-panel .p-dropdown-filter-container input {
        color: #ffffff; }
        body .p-dropdown-panel .p-dropdown-filter-container input:focus {
          border-bottom-color: #ffffff; }
      body .p-dropdown-panel .p-dropdown-filter-container .pi {
        top: .325em;
        right: .75em;
        color: #ffffff; }
  body .p-multiselect {
    border-bottom: 1px solid #bdbdbd;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-transition: border-color 0.3s;
    -o-transition: border-color 0.3s;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s; }
    body .p-multiselect .p-multiselect-label-container {
      padding-right: 1.5em; }
      body .p-multiselect .p-multiselect-label-container .p-multiselect-label {
        padding: 0px 2px 0px 2px; }
    body .p-multiselect .p-multiselect-trigger-icon {
      height: 1.5em;
      width: 1.5em;
      margin-top: 0;
      padding: 0;
      color: #757575; }
    body .p-multiselect.p-focus {
      border-bottom: 2px solid #00529e; }
  body .p-multiselect-panel {
    padding: 0;
    -moz-border-radius: 3;
    -webkit-border-radius: 3;
    border-radius: 3;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    min-width: 12.5em;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-multiselect-panel .p-multiselect-header {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      padding: 0.625em 1em;
      position: relative;
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-multiselect-panel .p-multiselect-header .pi {
        color: #ffffff; }
      body .p-multiselect-panel .p-multiselect-header .p-checkbox {
        float: none;
        margin: 0 .5em 0 -.125em;
        position: static; }
        body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box {
          border-color: #ffffff; }
          body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box .p-checkbox-icon {
            border-color: #ffffff; }
          body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
            border-color: #ffffff; }
          body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box.p-focus {
            background-color: #8197a5;
            -moz-transition: background-color 0.3s;
            -o-transition: background-color 0.3s;
            -webkit-transition: background-color 0.3s;
            transition: background-color 0.3s; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin-left: 0; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .pi {
          top: 0;
          left: 0; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
          padding: 2px;
          padding-left: 1.5em;
          color: #ffffff;
          width: 100%;
          box-sizing: border-box; }
          body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext:focus {
            border-bottom-color: #ffffff; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
        position: absolute;
        right: .5em;
        height: 1.5em;
        width: 1.5em;
        top: .625em; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-close span {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1em;
          height: 1em;
          margin-top: -.5em;
          margin-left: -.5em;
          color: #ffffff; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
          background-color: #8197a5;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          -moz-transition: background-color 0.3s;
          -o-transition: background-color 0.3s;
          -webkit-transition: background-color 0.3s;
          transition: background-color 0.3s; }
    body .p-multiselect-panel .p-multiselect-item {
      padding: .625em .875em;
      color: #212121;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-multiselect-panel .p-multiselect-item:not(.p-highlight):hover {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-multiselect-panel .p-multiselect-item.p-highlight {
        color: #ffffff; }
  body .p-spinner {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-spinner .p-spinner-button {
      position: absolute;
      width: 1em;
      height: .75em;
      padding: 0;
      margin-right: .25em;
      background-color: transparent;
      color: #212121;
      z-index: auto;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none; }
      body .p-spinner .p-spinner-button .pi {
        height: .5em;
        color: #757575; }
      body .p-spinner .p-spinner-button:enabled:hover, body .p-spinner .p-spinner-button:active {
        background-color: transparent; }
    body .p-spinner .p-spinner-up .pi {
      top: .25em; }
    body .p-spinner .p-spinner-down .pi {
      top: .167em; }
    body .p-spinner .p-spinner-input {
      padding-right: 30px; }
  body .p-fluid .p-spinner .p-spinner-button {
    width: 1em;
    height: .75em; }
    body .p-fluid .p-spinner .p-spinner-button .pi {
      left: auto; }
  body .p-inputswitch {
    height: 14px;
    width: 34px !important; }
    body .p-inputswitch .p-inputswitch-slider {
      background-color: #9e9e9e;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      body .p-inputswitch .p-inputswitch-slider:before {
        background-color: #ffffff;
        height: 1.429em;
        width: 1.429em;
        left: 0;
        bottom: -0.214em;
        border-radius: 50%;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
        -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px; }
    body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      -webkit-transform: translateX(1.25em);
      -ms-transform: translateX(1.25em);
      transform: translateX(1.25em); }
    body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
      background-color: #9e9e9e; }
    body .p-inputswitch:not(.p-state-disabled):hover .p-inputswitch-slider {
      background-color: #9e9e9e; }
    body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: #4699ce; }
      body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        background-color: #4699ce; }
    body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider {
      background-color: #4699ce; }
    body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
      background: #4699ce; }
  body .p-slider {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-slider .p-slider-handle {
      background-color: #4699ce;
      color: #ffffff;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      width: 1.25em;
      height: 1.25em;
      transform: scale(0.7);
      -moz-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      -o-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
      body .p-slider .p-slider-handle.p-focus {
        transform: scale(1); }
      body .p-slider .p-slider-handle:focus {
        outline: 0 none; }
    body .p-slider.p-slider-horizontal {
      height: 2px;
      border: 0 none;
      background-color: #bdbdbd; }
      body .p-slider.p-slider-horizontal .p-slider-handle {
        top: -.55em; }
    body .p-slider.p-slider-vertical {
      width: 2px;
      border: 0 none;
      background-color: #bdbdbd; }
      body .p-slider.p-slider-vertical .p-slider-handle {
        left: -.55em; }
    body .p-slider .p-slider-range {
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background-color: #4699ce;
      color: #ffffff; }
    body .p-slider:not(.p-disabled) .p-slider-handle:hover {
      transform: scale(1); }
  body .p-calendar .p-datepicker-trigger.p-button-icon-only {
    bottom: 2px;
    right: 0;
    top: auto;
    background-color: transparent;
    color: #212121;
    height: 1.5em;
    width: 1.5em;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-calendar .p-datepicker-trigger.p-button-icon-only .pi {
      color: #757575; }
    body .p-calendar .p-datepicker-trigger.p-button-icon-only:enabled:hover {
      background-color: transparent; }
  body .p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
    width: 100%; }
  body .p-datepicker {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 0;
    width: 17.5em; }
    body .p-datepicker.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
    body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
      color: #8197a5; }
    body .p-datepicker:not(.p-disabled) table td a:not(.p-highlight):not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-datepicker:not(.p-disabled) .p-monthpicker a.p-monthpicker-month:not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-datepicker .p-datepicker-header {
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      padding: 0.625em 1em;
      background: #0f2765;
      border-color: #0f2765;
      -moz-border-radius-topleft: 2px;
      -webkit-border-top-left-radius: 2px;
      border-top-left-radius: 2px;
      -moz-border-radius-topright: 2px;
      -webkit-border-top-right-radius: 2px;
      border-top-right-radius: 2px;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
      body .p-datepicker .p-datepicker-header .pi {
        color: #ffffff; }
      body .p-datepicker .p-datepicker-header .p-datepicker-prev {
        cursor: pointer;
        top: .4em;
        left: .2em;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        color: #ffffff; }
        body .p-datepicker .p-datepicker-header .p-datepicker-prev:before {
          content: "keyboard_arrow_left"; }
        body .p-datepicker .p-datepicker-header .p-datepicker-prev .pi {
          display: none; }
      body .p-datepicker .p-datepicker-header .p-datepicker-next {
        cursor: pointer;
        top: .4em;
        right: .2em;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        color: #ffffff; }
        body .p-datepicker .p-datepicker-header .p-datepicker-next:before {
          content: "keyboard_arrow_right"; }
        body .p-datepicker .p-datepicker-header .p-datepicker-next .pi {
          display: none; }
      body .p-datepicker .p-datepicker-header select.p-datepicker-month,
      body .p-datepicker .p-datepicker-header select.p-datepicker-year {
        font-size: 12px; }
    body .p-datepicker table {
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: collapse;
      font-size: 12px; }
    body .p-datepicker thead tr {
      color: #ffffff;
      background: #00529e; }
    body .p-datepicker tbody td {
      padding: .25em .125em;
      box-sizing: border-box; }
      body .p-datepicker tbody td.p-datepicker-today a {
        color: #ffffff;
        background: #0587ff; }
        body .p-datepicker tbody td.p-datepicker-today a.p-highlight {
          background-color: #4699ce;
          color: #ffffff; }
      body .p-datepicker tbody td a {
        padding: .25em;
        margin: 0;
        text-align: center;
        color: #212121;
        display: inline-block;
        height: 2.250em;
        width: 2.250em;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        line-height: 1.8em; }
        body .p-datepicker tbody td a.p-highlight {
          background-color: #4699ce;
          color: #ffffff; }
        body .p-datepicker tbody td a:not(.p-highlight):not(.p-highlight):hover {
          background-color: #e8e8e8;
          color: #000000; }
        body .p-datepicker tbody td a span {
          display: inline;
          padding: 0;
          text-align: center; }
    body .p-datepicker .p-timepicker {
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-datepicker .p-timepicker .pi {
        color: #ffffff; }
      body .p-datepicker .p-timepicker > div a {
        height: 1.5em; }
        body .p-datepicker .p-timepicker > div a > span {
          display: inline-block;
          margin-bottom: .25em; }
        body .p-datepicker .p-timepicker > div a .pi.pi-angle-up {
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -o-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.5em;
          /* Preferred icon size */
          display: inline-block;
          width: 1em;
          height: 1em;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          text-indent: 0;
          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: 'liga'; }
          body .p-datepicker .p-timepicker > div a .pi.pi-angle-up:before {
            content: "keyboard_arrow_up"; }
        body .p-datepicker .p-timepicker > div a .pi.pi-angle-down {
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -o-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.5em;
          /* Preferred icon size */
          display: inline-block;
          width: 1em;
          height: 1em;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          text-indent: 0;
          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: 'liga'; }
          body .p-datepicker .p-timepicker > div a .pi.pi-angle-down:before {
            content: "keyboard_arrow_down"; }
    body .p-datepicker .p-monthpicker .p-monthpicker-month {
      color: #212121; }
      body .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
        color: #ffffff;
        background-color: #4699ce; }
    body .p-datepicker.p-datepicker-multiple-month {
      width: auto; }
      body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
        border-right: 1px solid #dbdbdb;
        padding-top: 0;
        padding-bottom: 0; }
        body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group .p-datepicker-calendar-container tbody {
          padding-right: .25em;
          padding-left: .25em; }
        body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
          padding-left: 0; }
        body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
          padding-right: 0;
          border-right: 0 none; }
  body #p-datepicker-div {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-rating {
    font-size: inherit;
    min-height: 1.5em; }
    body .p-rating .pi-ban {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      color: #757575; }
      body .p-rating .pi-ban:before {
        content: "cancel"; }
    body .p-rating .pi-star {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      color: #757575; }
      body .p-rating .pi-star:before {
        content: "star"; }
    body .p-rating .pi-star-o {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      color: #757575; }
      body .p-rating .pi-star-o:before {
        content: "star_border"; }
  body .p-chips > ul.p-inputtext .p-chips-input-token {
    padding-bottom: 2px;
    margin-bottom: 2px; }
    body .p-chips > ul.p-inputtext .p-chips-input-token > input {
      padding: 0;
      font-size: 14px;
      margin: 0;
      background: transparent;
      vertical-align: bottom; }
    body .p-chips > ul.p-inputtext .p-chips-input-token input.p-disabled {
      width: 0; }
  body .p-chips > ul.p-inputtext .p-chips-token {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    float: none;
    vertical-align: middle;
    font-size: 1em;
    margin-bottom: 2px; }
    body .p-chips > ul.p-inputtext .p-chips-token .p-chips-token-label {
      display: inline-block;
      vertical-align: middle; }
  body .p-chips > ul.p-inputtext.p-disabled {
    border-bottom: 1px dotted; }
  body .p-password-panel {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-password-panel.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-fileupload .p-fileupload-buttonbar {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0.625em 1em; }
    body .p-fileupload .p-fileupload-buttonbar .pi {
      color: #ffffff; }
    body .p-fileupload .p-fileupload-buttonbar .pi-arrowreturnthick-1-n {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga'; }
      body .p-fileupload .p-fileupload-buttonbar .pi-arrowreturnthick-1-n:before {
        content: "file_upload"; }
    body .p-fileupload .p-fileupload-buttonbar .p-button {
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background-color: #4699ce; }
      body .p-fileupload .p-fileupload-buttonbar .p-button:enabled:hover {
        background-color: #274d8d; }
      body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose .pi {
        vertical-align: middle; }
  body .p-fileupload .p-fileupload-content {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
    body .p-fileupload .p-fileupload-content .p-messages-error .pi {
      color: #ffffff; }
  body .p-fileupload-choose:not(.p-disabled):hover.p-button,
  body .p-fileupload-choose.p-focus.p-button {
    background-color: #348dc6; }
  body .p-fileupload-choose:not(.p-disabled):active.p-button {
    background-color: #2f7fb2; }
  body .p-editor-container .p-editor-toolbar {
    background-color: #e8e8e8; }
  body .p-editor-container .ql-picker.ql-expanded .ql-picker-label {
    color: #444444; }
  body .p-inputgroup .p-inputgroup-addon {
    border-width: 0 0 1px 0;
    border-color: #bdbdbd;
    color: #757575;
    padding: 2px 2px 1px 2px;
    align-self: flex-end; }
    body .p-inputgroup .p-inputgroup-addon > i {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-self: flex-end; }
  body .p-inputgroup .p-inputtext {
    align-self: flex-end; }
  body .p-inputgroup .md-inputfield {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    display: inherit; }
    body .p-inputgroup .md-inputfield label {
      top: 5px; }
  body .p-inputgroup .p-checkbox, body .p-inputgroup .p-radiobutton {
    margin-bottom: .125em; }
  body .p-fluid .p-inputgroup .p-button-icon-only {
    width: 1.643em;
    height: 1.643em;
    padding: 0;
    line-height: 1; }
  body .p-inputtext.p-error,
  body .p-dropdown.p-error,
  body .p-autocomplete.p-error > .p-inputtext,
  body .p-calendar.p-error > .p-inputtext,
  body .p-chips.p-error > .p-inputtext,
  body .p-checkbox.p-error > .p-checkbox-box,
  body .p-radiobutton.p-error > .p-radiobutton-box,
  body .p-inputswitch.p-error,
  body .p-listbox.p-error,
  body .p-multiselect.p-error,
  body .p-spinner.p-error > .p-inputtext,
  body .p-selectbutton.p-error > .p-button,
  body .p-togglebutton.p-error > .p-button {
    background-image: linear-gradient(to bottom, #e62a10, #e62a10), linear-gradient(to bottom, #e62a10, #e62a10); }
  body .p-calendar .p-datepicker {
    min-width: auto; }

@media (max-width: 640px) {
  body .p-buttonset > .p-button {
    display: block;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-buttonset > .p-button:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px; }
    body .p-buttonset > .p-button:last-child {
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
  body .p-splitbutton > .p-button {
    display: inline-block; }
    body .p-splitbutton > .p-button:first-child {
      -moz-border-radius-topleft: 0;
      -webkit-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-radius-topright: 0;
      -webkit-border-top-right-radius: 0;
      border-top-right-radius: 0; }
    body .p-splitbutton > .p-button:last-child {
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; } }

body .p-panel {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-panel .p-panel-titlebar {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    border: 0 none;
    border-bottom: 1px solid #00529e;
    padding: 0.625em 1em;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
    body .p-panel .p-panel-titlebar .pi {
      color: #ffffff; }
    body .p-panel .p-panel-titlebar .p-panel-title {
      line-height: 1.5em; }
    body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
      position: relative;
      width: 1.5em;
      height: 1.5em;
      color: #ffffff;
      margin: 0;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
        background-color: #8197a5; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon span {
        margin-top: 1px; }
  body .p-panel .p-panel-content {
    height: 100%;
    padding: 0.625em 1em;
    line-height: 18px; }
  body .p-panel .p-panel-footer {
    padding: 0.625em 1em;
    border: 0 none;
    border-top: 1px solid #dbdbdb;
    margin: 0; }

body .p-fieldset {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0.625em 1em; }
  body .p-fieldset .p-fieldset-legend {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 0.625em 1em;
    padding-left: .125em;
    padding-right: .5em;
    color: #00529e; }
    body .p-fieldset .p-fieldset-legend .p-fieldset-toggler {
      height: 1.5em;
      width: 1.5em;
      line-height: 1.5em;
      background-color: #00529e;
      color: #ffffff;
      font-size: 1.5em;
      margin-right: .5em;
      text-align: center;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }
      body .p-fieldset .p-fieldset-legend .p-fieldset-toggler:before {
        line-height: inherit; }
      body .p-fieldset .p-fieldset-legend .p-fieldset-toggler:hover {
        background-color: #0f2765; }
    body .p-fieldset .p-fieldset-legend .p-fieldset-legend-text {
      color: #00529e; }
    body .p-fieldset .p-fieldset-legend.p-focus {
      background-color: transparent; }
  body .p-fieldset .p-fieldset-content {
    line-height: 18px; }

body .p-accordion .p-accordion-header {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-accordion .p-accordion-header a {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid transparent;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    body .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
      color: #ffffff; }
  body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a {
    background-color: #0f2765;
    color: #ffffff; }
    body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a .p-accordion-toggle-icon {
      color: #ffffff; }
  body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight {
    margin-bottom: 0; }
    body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
      background-color: #4699ce;
      color: #ffffff;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
      body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a .p-accordion-toggle-icon {
        color: #ffffff; }
    body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a {
      background-color: #4699ce;
      color: #ffffff; }
      body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a .p-accordion-toggle-icon {
        color: #ffffff; }

body .p-accordion .p-accordion-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  padding: 0.625em 1em;
  line-height: 18px;
  border-top: 0;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }

body .p-toolbar {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  background-color: #0f2765;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: .625em; }
  body .p-toolbar .pi {
    color: #ffffff; }
  body .p-toolbar .p-toolbar-separator {
    vertical-align: middle;
    margin: 0 .5em; }
  body .p-toolbar .pi {
    color: inherit; }

body .p-tabview {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0; }
  body .p-tabview .p-tabview-nav {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    background-color: #ffffff;
    border: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-tabview .p-tabview-nav .pi {
      color: #ffffff; }
    body .p-tabview .p-tabview-nav > li {
      padding: 0;
      -moz-transition: border-color 0.3s;
      -o-transition: border-color 0.3s;
      -webkit-transition: border-color 0.3s;
      transition: border-color 0.3s; }
      body .p-tabview .p-tabview-nav > li > a {
        padding: 0.625em 1em; }
        body .p-tabview .p-tabview-nav > li > a:focus {
          outline: 0 none; }
      body .p-tabview .p-tabview-nav > li > .pi-times {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        margin: .325em 0 0 0;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
        color: #757575; }
        body .p-tabview .p-tabview-nav > li > .pi-times:before {
          content: "close"; }
      body .p-tabview .p-tabview-nav > li .p-tabview-left-icon, body .p-tabview .p-tabview-nav > li .p-tabview-right-icon {
        color: #757575;
        display: inline-block;
        vertical-align: middle; }
      body .p-tabview .p-tabview-nav > li .p-tabview-title {
        line-height: 1.5em;
        vertical-align: middle;
        color: #757575; }
      body .p-tabview .p-tabview-nav > li:not(.p-highlight):not(.p-disabled):hover {
        background-color: #ffffff; }
      body .p-tabview .p-tabview-nav > li.p-highlight {
        background-color: #ffffff;
        border-color: #4699ce;
        border-style: solid; }
        body .p-tabview .p-tabview-nav > li.p-highlight .p-tabview-title {
          color: #00529e; }
        body .p-tabview .p-tabview-nav > li.p-highlight a {
          color: #00529e; }
        body .p-tabview .p-tabview-nav > li.p-highlight > .pi-times {
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.5em;
          /* Preferred icon size */
          display: inline-block;
          width: 1em;
          height: 1em;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          text-indent: 0;
          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: 'liga';
          color: #4699ce; }
          body .p-tabview .p-tabview-nav > li.p-highlight > .pi-times:before {
            content: "close"; }
      body .p-tabview .p-tabview-nav > li.p-tabview-outline {
        outline: 0 none;
        border-color: #4699ce; }
  body .p-tabview .p-tabview-panel {
    padding: 0.625em 1em;
    line-height: 18px; }
  body .p-tabview.p-tabview-top > .p-tabview-nav {
    padding: 0;
    margin: 0;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-top > .p-tabview-nav > li {
      border-style: solid;
      border-width: 0 0 2px 0; }
  body .p-tabview.p-tabview-bottom > .p-tabview-nav {
    padding: 0;
    margin: 0;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-bottom > .p-tabview-nav > li {
      border-width: 2px 0 0 0; }
  body .p-tabview.p-tabview-left > .p-tabview-nav {
    padding: 0;
    margin: 0;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-left > .p-tabview-nav > li {
      box-sizing: border-box;
      border-width: 0 2px 0 0; }
      body .p-tabview.p-tabview-left > .p-tabview-nav > li > a {
        width: 100%;
        box-sizing: border-box; }
  body .p-tabview.p-tabview-right > .p-tabview-nav {
    padding: 0;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-right > .p-tabview-nav > li {
      box-sizing: border-box;
      border-width: 0 0 0 2px; }
      body .p-tabview.p-tabview-right > .p-tabview-nav > li > a {
        width: 100%;
        box-sizing: border-box; }
      body .p-tabview.p-tabview-right > .p-tabview-nav > li.p-highlight > a {
        padding-left: 0.875em; }

body .p-card {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

body .p-scrollpanel {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

@media (max-width: 640px) {
  body .p-toolbar .p-toolbar-group-right {
    margin-top: 1em; } }

body .p-picklist .p-picklist-caption {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em; }
  body .p-picklist .p-picklist-caption .pi {
    color: #ffffff; }

body .p-picklist .p-picklist-list {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0; }

body .p-picklist li.p-picklist-item {
  padding: 0.625em 1em;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #212121; }
  body .p-picklist li.p-picklist-item:not(.p-highlight):hover {
    background-color: #e8e8e8;
    color: #000000; }
  body .p-picklist li.p-picklist-item.p-highlight {
    background-color: #4699ce;
    color: #ffffff; }

body .p-picklist .p-picklist-buttons {
  width: 3em; }
  body .p-picklist .p-picklist-buttons .p-button.p-button-icon-only {
    width: 2.5em;
    margin-right: 0;
    display: inline-block;
    margin-bottom: .5em;
    line-height: 1;
    vertical-align: middle; }

body .p-picklist .p-picklist-buttons-cell {
  text-align: center; }

body .p-picklist.p-picklist-responsive .p-picklist-buttons .p-button.p-button-icon-only {
  margin: 0 auto;
  display: block;
  margin-bottom: .5em; }

body .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-chkbox {
  margin-right: .5em;
  vertical-align: top; }

body .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-chkbox, body .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-chkbox * {
  box-sizing: content-box; }

body .p-organizationchart .p-organizationchart-node-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background-color: #e8e8e8;
    color: #000000; }
  body .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #1f5475; }

body .p-organizationchart .p-organizationchart-line-down {
  background-color: #dbdbdb; }

body .p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dbdbdb; }

body .p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dbdbdb; }

body .p-organizationchart .p-organizationchart-node-content {
  border-color: #dbdbdb; }

body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  bottom: -1.3em;
  margin-left: -.75em;
  color: #dbdbdb; }
  body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-down {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-down:before {
      content: "keyboard_arrow_down"; }
  body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-up {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-up:before {
      content: "keyboard_arrow_up"; }

body .p-orderlist .p-orderlist-caption {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em;
  box-sizing: border-box; }
  body .p-orderlist .p-orderlist-caption .pi {
    color: #ffffff; }

body .p-orderlist .p-orderlist-list {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0;
  box-sizing: border-box; }
  body .p-orderlist .p-orderlist-list li.p-orderlist-item {
    padding: 0.625em 1em;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #212121; }
    body .p-orderlist .p-orderlist-list li.p-orderlist-item:not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-orderlist .p-orderlist-list li.p-orderlist-item.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }

body .p-orderlist.p-orderlist-responsive .p-orderlist-controls {
  width: 3.75em; }

body .p-orderlist .p-orderlist-controls {
  width: 3.75em;
  text-align: center; }
  body .p-orderlist .p-orderlist-controls .p-button.p-button-icon-only {
    width: 2.5em;
    margin-bottom: .5em;
    margin-right: 0;
    line-height: 1;
    vertical-align: middle; }

body .p-paginator {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  background-color: #0f2765;
  padding: 0.625em 1em; }
  body .p-paginator .pi {
    color: #ffffff; }
  body .p-paginator > a {
    color: #ffffff; }
    body .p-paginator > a .pi {
      display: none; }
    body .p-paginator > a:not(.p-disabled):not(.p-highlight):hover {
      background-color: #8197a5;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
  body .p-paginator .p-paginator-next {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-next:before {
      content: "navigate_next"; }
  body .p-paginator .p-paginator-last {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-last:before {
      content: "last_page"; }
  body .p-paginator .p-paginator-prev {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-prev:before {
      content: "navigate_before"; }
  body .p-paginator .p-paginator-first {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-first:before {
      content: "first_page"; }
  body .p-paginator .p-paginator-pages {
    vertical-align: middle;
    margin: 0 .375em 0 .375em; }
    body .p-paginator .p-paginator-pages a {
      padding: 0;
      color: #ffffff;
      min-width: 1.5em;
      min-height: 1.5em;
      line-height: 1.5em;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      body .p-paginator .p-paginator-pages a:not(.p-highlight):hover {
        background-color: #8197a5; }
      body .p-paginator .p-paginator-pages a.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }

body .p-dataview .p-dataview-layout-options .p-button {
  margin-left: 0.5em;
  border-radius: 50%; }

body .p-dataview .p-dataview-header {
  background-color: #ffffff;
  color: #212121;
  padding: 0.625em 1em;
  border: 1px solid #cacaca;
  border-bottom: 0 none; }

body .p-dataview .p-dataview-content {
  background-color: #ffffff;
  color: #212121;
  border: 1px solid #cacaca; }

body .p-dataview .p-dataview-footer {
  background-color: #ffffff;
  color: #212121;
  padding: 0.625em 1em;
  border: 1px solid #cacaca;
  border-top: 0 none; }

@media (max-width: 40em) {
  body .p-dataview-layout-options.p-buttonset > .p-button {
    margin-left: 0;
    display: inline-block; }
    body .p-dataview-layout-options.p-buttonset > .p-button:first-child {
      border-radius: 50%; }
    body .p-dataview-layout-options.p-buttonset > .p-button:last-child {
      border-radius: 50%; } }

body .p-datagrid .p-datagrid-header {
  padding: 0.625em 1em; }

body .p-datagrid .p-panel .p-panel-titlebar {
  background-color: #ffffff;
  color: #212121;
  border-color: #dbdbdb; }

body .p-datalist .p-datalist-header {
  padding: 0.625em 1em; }

body .p-datascroller .p-datascroller-header {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  border-bottom: 0 none;
  padding: 0.625em 1em; }
  body .p-datascroller .p-datascroller-header .pi {
    color: #ffffff; }

body .p-datascroller .p-datascroller-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }

body .p-datascroller .p-datascroller-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  border-top: 0 none;
  padding: 0.625em 1em; }
  body .p-datascroller .p-datascroller-footer .pi {
    color: #ffffff; }

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em;
  text-align: center; }
  body .p-datatable .p-datatable-header .pi,
  body .p-datatable .p-datatable-footer .pi {
    color: #ffffff; }
  body .p-datatable .p-datatable-header .p-inputtext,
  body .p-datatable .p-datatable-footer .p-inputtext {
    color: #ffffff; }
    body .p-datatable .p-datatable-header .p-inputtext:focus,
    body .p-datatable .p-datatable-footer .p-inputtext:focus {
      border-color: #ffffff; }

body .p-datatable .p-datatable-header {
  border-bottom: 0 none; }

body .p-datatable .p-datatable-footer {
  border-top: 0 none; }

body .p-datatable .p-datatable-thead > tr > th {
  padding: .625em .875em;
  border: 1px solid #cacaca;
  background-color: #ffffff;
  text-align: center; }

body .p-datatable .p-datatable-tfoot > tr > td {
  padding: .625em .875em;
  border: 1px solid #cacaca;
  background-color: #ffffff;
  text-align: center; }

body .p-datatable .p-sortable-column a, body .p-datatable .p-sortable-column a:hover {
  color: #757575;
  margin-left: .125em; }

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: #e8e8e8;
  color: #000000; }
  body .p-datatable .p-sortable-column:not(.p-highlight):hover a, body .p-datatable .p-sortable-column:not(.p-highlight):hover a:hover {
    background-color: #e8e8e8;
    color: #000000; }

body .p-datatable .p-sortable-column.p-highlight {
  background-color: #4699ce;
  color: #ffffff;
  border-top-color: #4699ce; }
  body .p-datatable .p-sortable-column.p-highlight a, body .p-datatable .p-sortable-column.p-highlight a:hover {
    color: #ffffff; }

body .p-datatable .p-sortable-column .p-column-title {
  font-weight: 400;
  vertical-align: super; }

body .p-datatable .p-editable-column input {
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", sans-serif; }
  body .p-datatable .p-editable-column input:focus {
    outline: 1px solid #ffffff;
    outline-offset: 2px; }

body .p-datatable .p-datatable-tbody {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }
  body .p-datatable .p-datatable-tbody > tr {
    border-color: #cacaca; }
    body .p-datatable .p-datatable-tbody > tr > td {
      border: 1px solid #cacaca;
      padding: .625em .875em; }
      body .p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
        cursor: pointer;
        color: #757575;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s; }
        body .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:hover {
          background-color: #e8e8e8;
          color: #000000; }
    body .p-datatable .p-datatable-tbody > tr.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }
    body .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
      box-shadow: inset 0 -2px 0 0 #4699ce; }
  body .p-datatable .p-datatable-tbody > tr.p-datatable-even {
    background-color: #f4f4f4; }
    body .p-datatable .p-datatable-tbody > tr.p-datatable-even.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #000000; }

body .p-datatable .p-column-resizer-helper {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .p-datatable .p-column-resizer-helper .pi {
    color: #ffffff; }

body .p-datatable .p-datatable-scrollable-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .p-datatable .p-datatable-scrollable-footer .pi {
    color: #ffffff; }

body .p-carousel {
  padding: 0; }
  body .p-carousel .p-carousel-header {
    padding: 0.625em 1em;
    overflow: visible; }
    body .p-carousel .p-carousel-header .p-carousel-header-title {
      overflow: visible; }
    body .p-carousel .p-carousel-header .pi {
      color: #ffffff; }
    body .p-carousel .p-carousel-header .p-carousel-button {
      margin: -1px 0 0 0; }
    body .p-carousel .p-carousel-header .p-carousel-page-links {
      margin: -1px 0 0 0; }

body .p-tree {
  border: 1px solid #cacaca;
  padding: 0.625em 1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-tree .p-tree-container {
    padding: 0.286em;
    margin: 0; }
    body .p-tree .p-tree-container .p-treenode .p-treenode-content {
      padding: 0;
      border: 1px solid transparent;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
        vertical-align: middle;
        display: inline-block;
        float: none;
        color: #757575; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
          outline: 0; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
        vertical-align: middle;
        display: inline-block;
        margin: 0 0 0 .25em;
        color: #757575; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
        margin: 0;
        vertical-align: middle;
        display: inline-block;
        padding: 0.625em 1em; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
        margin: 0 .25em; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
          color: #ffffff; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight-contextmenu {
        cursor: pointer;
        border: 1px dashed #4699ce; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background-color: #e8e8e8;
        color: #000000; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover .p-treenode-icon {
          background-color: #e8e8e8;
          color: #000000; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
        background-color: #e8e8e8;
        color: #000000; }
    body .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
      background-color: #4699ce; }
  body .p-tree.p-tree-horizontal {
    padding-left: 0;
    padding-right: 0; }
    body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
      background-color: #ffffff;
      border: 1px solid #dbdbdb;
      padding: 0.625em 1em;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .pi-md {
        color: #00529e; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
        background-color: inherit;
        color: inherit; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background-color: #e8e8e8;
        color: #000000; }

body .p-tree-draghelper {
  border: 1px solid #00529e; }

body .fc button {
  height: 2.25em;
  padding: 0 1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .fc button.ui-state-active {
    background-color: #4699ce;
    color: #ffffff; }
  body .fc button.ui-state-disabled {
    opacity: .35;
    filter: Alpha(Opacity=35);
    background-image: none;
    cursor: auto !important; }

body .fc td {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }

body .fc .fc-toolbar .ui-button {
  color: #ffffff;
  background-color: #00529e;
  border: #00529e;
  font-size: 14px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16); }
  body .fc .fc-toolbar .ui-button:enabled:not(.ui-state-active):hover {
    background-color: #0f2765;
    color: #ffffff;
    border-color: #0f2765; }
  body .fc .fc-toolbar .ui-button.ui-state-active {
    background-color: #4699ce;
    color: #ffffff; }
  body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-w {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    border-radius: 0;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-indent: 0;
    position: relative;
    top: 1px;
    vertical-align: middle; }
    body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-w:before {
      content: "play_circle_outline"; }
  body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-e {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    border-radius: 0;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    text-indent: 0;
    position: relative;
    top: 1px;
    vertical-align: middle; }
    body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-e:before {
      content: "play_circle_outline"; }
  body .fc .fc-toolbar .ui-button:focus {
    outline: 0 none; }

body .fc .ui-state-highlight {
  background-color: #4699ce;
  color: #ffffff; }

body .fc .fc-button-group .ui-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .fc .fc-button-group .ui-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .fc .fc-button-group .ui-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }

body .fc .fc-head-container {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .fc .fc-head-container .pi {
    color: #ffffff; }
  body .fc .fc-head-container .fc-row, body .fc .fc-head-container .fc-axis {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e; }
    body .fc .fc-head-container .fc-row .pi, body .fc .fc-head-container .fc-axis .pi {
      color: #ffffff; }

body .fc .fc-day-header, body .fc .fc-divider {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .fc .fc-day-header .pi, body .fc .fc-divider .pi {
    color: #ffffff; }

body .fc .fc-event {
  background-color: #8197a5;
  color: #ffffff; }

body .fc table th {
  padding: 0.625em 1em; }

body .p-treetable .p-treetable-header,
body .p-treetable .p-treetable-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em;
  text-align: center; }
  body .p-treetable .p-treetable-header .pi,
  body .p-treetable .p-treetable-footer .pi {
    color: #ffffff; }

body .p-treetable .p-treetable-header {
  border-bottom: 0 none; }

body .p-treetable .p-treetable-footer {
  border-top: 0 none; }

body .p-treetable thead > tr > th {
  padding: .625em .875em;
  border: 1px solid #dbdbdb;
  background-color: #ffffff; }

body .p-treetable tfoot > tr > td {
  border: 0 none;
  padding: .625em .875em; }

body .p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #757575; }

body .p-treetable .p-sortable-column:not(.p-highlight):hover {
  background-color: #e8e8e8;
  color: #000000; }
  body .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    background-color: #e8e8e8;
    color: #000000; }

body .p-treetable .p-sortable-column.p-highlight {
  background-color: #4699ce;
  color: #ffffff; }
  body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #ffffff; }

body .p-treetable .p-sortable-column .p-column-title {
  vertical-align: super; }

body .p-treetable .p-treetable-tablewrapper > table {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }
  body .p-treetable .p-treetable-tablewrapper > table > tbody {
    background: #ffffff;
    padding: .625em .875em;
    border-bottom: 0 none;
    border-top: 0 none; }
    body .p-treetable .p-treetable-tablewrapper > table > tbody:last-child {
      border-bottom: 1px solid #dbdbdb; }
    body .p-treetable .p-treetable-tablewrapper > table > tbody .p-treetable-row td {
      line-height: 2em; }

body .p-treetable .p-treetable-toggler {
  color: #757575; }

body .p-treetable .p-treetable-row-selectable:not(.p-highlight):hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #000000; }

body .p-treetable .p-treetable-row-selectable.p-highlight {
  background-color: #4699ce;
  color: #ffffff; }
  body .p-treetable .p-treetable-row-selectable.p-highlight .p-treetable-toggler {
    color: #ffffff; }

body .p-treetable .p-treetable-checkbox .p-checkbox-icon {
  color: #212121; }

@media (max-width: 40em) {
  body .p-orderlist.p-orderlist-responsive .p-orderlist-controls {
    width: 100%; } }

@media (max-width: 640px) {
  body .p-picklist.p-picklist-responsive .p-picklist-list-wrapper {
    margin-bottom: .5em; }
  body .p-picklist.p-picklist-responsive .p-picklist-buttons {
    padding: .5em 0; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .p-button.p-button-icon-only {
      display: inline-block;
      margin-right: .25em;
      margin-bottom: 0; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-right {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-right:before {
        content: "play_arrow"; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-right {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-right:before {
        content: "skip_previous"; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-left {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-left:before {
        content: "play_arrow"; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-left {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-left:before {
        content: "skip_next"; }
  body .p-orderlist.p-grid-responsive .p-orderlist-controls {
    text-align: center;
    width: auto; }
    body .p-orderlist.p-grid-responsive .p-orderlist-controls .p-button {
      margin-right: .25em; } }

body .p-breadcrumb {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0.625em 1em; }
  body .p-breadcrumb .pi {
    color: #ffffff; }
  body .p-breadcrumb ul li {
    margin: 0; }
    body .p-breadcrumb ul li a {
      color: #ffffff; }

body .p-steps {
  position: relative; }
  body .p-steps .p-steps-item {
    background-color: transparent; }
    body .p-steps .p-steps-item.p-disabled {
      opacity: 1;
      filter: alpha(opacity=100); }
    body .p-steps .p-steps-item .p-menuitem-link {
      display: inline-block;
      text-align: left;
      background-color: #ffffff;
      overflow: hidden; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        display: inline-block;
        background-color: #757575;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 1em;
        color: #ffffff;
        vertical-align: middle;
        text-align: center;
        width: 2em;
        height: 2em;
        padding: 0;
        line-height: 2em; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        display: inline;
        margin-left: .625em;
        color: #757575; }
    body .p-steps .p-steps-item.p-highlight .p-steps-number {
      background-color: #4699ce; }
    body .p-steps .p-steps-item.p-highlight .p-steps-title {
      font-weight: 700;
      color: #212121; }
    body .p-steps .p-steps-item:last-child .p-menuitem-link {
      display: block; }
  body .p-steps:before {
    content: ' ';
    border: 1px solid #dbdbdb;
    width: 90%;
    top: 45%;
    left: 0;
    display: block;
    position: absolute; }

body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-megamenu .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
  padding: .625em 1em;
  color: #212121;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .p-menu .p-menuitem-link .p-menuitem-text,
  body .p-menubar .p-menuitem-link .p-menuitem-text,
  body .p-tieredmenu .p-menuitem-link .p-menuitem-text,
  body .p-contextmenu .p-menuitem-link .p-menuitem-text,
  body .p-megamenu .p-menuitem-link .p-menuitem-text,
  body .p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #212121; }
  body .p-menu .p-menuitem-link .p-menuitem-icon,
  body .p-menubar .p-menuitem-link .p-menuitem-icon,
  body .p-tieredmenu .p-menuitem-link .p-menuitem-icon,
  body .p-contextmenu .p-menuitem-link .p-menuitem-icon,
  body .p-megamenu .p-menuitem-link .p-menuitem-icon,
  body .p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #757575;
    margin-right: 0.5em; }
  body .p-menu .p-menuitem-link:hover,
  body .p-menubar .p-menuitem-link:hover,
  body .p-tieredmenu .p-menuitem-link:hover,
  body .p-contextmenu .p-menuitem-link:hover,
  body .p-megamenu .p-menuitem-link:hover,
  body .p-slidemenu .p-menuitem-link:hover {
    background-color: #e8e8e8;
    color: #000000; }
    body .p-menu .p-menuitem-link:hover .p-menuitem-text,
    body .p-menubar .p-menuitem-link:hover .p-menuitem-text,
    body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-contextmenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-megamenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-slidemenu .p-menuitem-link:hover .p-menuitem-text {
      color: #000000; }
    body .p-menu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-menubar .p-menuitem-link:hover .p-menuitem-icon,
    body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-contextmenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-megamenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-slidemenu .p-menuitem-link:hover .p-menuitem-icon {
      color: #000000; }

body .p-menu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: .5em 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-menu .p-shadow, body .p-menu.p-shadow {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-menu .p-menuitem {
    margin: 0; }
  body .p-menu.p-menu-overlay {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-menu .p-submenu-header {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    margin: 0 0 1px 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
    width: 100%;
    box-sizing: border-box;
    padding: .625em 1em; }
    body .p-menu .p-submenu-header .pi {
      color: #ffffff; }
  body .p-menu .p-menu-separator {
    height: 1px;
    background-color: #dbdbdb;
    width: 100%;
    box-sizing: border-box; }

body .p-menubar {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: .625em 1em; }
  body .p-menubar .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-menubar .p-submenu-list .p-menuitem {
      margin: 0; }
    body .p-menubar .p-submenu-list .p-menu-separator {
      border: solid #dbdbdb;
      border-width: 1px 0 0 0; }
  body .p-menubar .p-menuitem {
    margin: 0; }
    body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }

body .p-contextmenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: .5em 0; }
  body .p-contextmenu .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-contextmenu .p-menuitem {
    margin: 0; }
    body .p-contextmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-contextmenu .p-menu-separator {
    border: solid #dbdbdb;
    border-width: 1px 0 0 0; }

body .p-tieredmenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: .5em 0; }
  body .p-tieredmenu .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-tieredmenu .p-menuitem {
    margin: 0; }
    body .p-tieredmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-tieredmenu .p-menu-separator {
    border: solid #dbdbdb;
    border-width: 1px 0 0 0; }
  body .p-tieredmenu.p-menu-overlay {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

body .p-slidemenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: .5em 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-slidemenu .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    border: 0 none; }
  body .p-slidemenu .p-menuitem {
    margin: 0; }
    body .p-slidemenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-slidemenu .p-slidemenu-backward {
    background-color: #00529e;
    color: #ffffff;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none; }
    body .p-slidemenu .p-slidemenu-backward:hover {
      background-color: #0f2765;
      color: #ffffff; }
  body .p-slidemenu .p-menu-separator {
    border: solid #dbdbdb;
    border-width: 1px 0 0 0; }
  body .p-slidemenu.p-menu-overlay {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

body .p-megamenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-megamenu .p-megamenu-submenu-header {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    margin: 0 0 1px 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
    width: 100%;
    box-sizing: border-box;
    padding: .625em 1em; }
    body .p-megamenu .p-megamenu-submenu-header .pi {
      color: #ffffff; }
  body .p-megamenu .p-megamenu-panel {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-megamenu .p-menuitem {
    margin: 0; }
    body .p-megamenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-megamenu.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    right: 0.429em; }

body .p-tabmenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-tabmenu .p-tabmenu-nav {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    padding: 0;
    background-color: #ffffff;
    border: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-tabmenu .p-tabmenu-nav .pi {
      color: #ffffff; }
    body .p-tabmenu .p-tabmenu-nav:after {
      content: "";
      display: table;
      clear: both; }
    body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
      position: static;
      border-style: solid;
      border-width: 0 0 2px 0;
      margin: 0;
      -moz-border-radius-topleft: 0;
      -webkit-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-radius-topright: 0;
      -webkit-border-top-right-radius: 0;
      border-top-right-radius: 0;
      -moz-transition: border-color 0.3s;
      -o-transition: border-color 0.3s;
      -webkit-transition: border-color 0.3s;
      transition: border-color 0.3s; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        color: #212121;
        padding: .625em 1em; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
          color: #212121; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
          color: #757575; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover {
        background-color: #e8e8e8;
        color: #000000; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-text {
          color: #000000; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-icon {
          color: #000000; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
        background-color: #ffffff;
        border-color: #4699ce;
        border-style: solid; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
          color: #00529e; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-icon {
          color: #00529e; }

body .p-panelmenu .p-panelmenu-header {
  background-color: #00529e;
  margin-bottom: 1px; }
  body .p-panelmenu .p-panelmenu-header a {
    padding: .625em 1em;
    color: #ffffff;
    font-size: 16px;
    border-bottom: 1px solid #ffffff; }
  body .p-panelmenu .p-panelmenu-header .pi {
    color: #ffffff; }
  body .p-panelmenu .p-panelmenu-header.p-highlight {
    background-color: #4699ce;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
    body .p-panelmenu .p-panelmenu-header.p-highlight a, body .p-panelmenu .p-panelmenu-header.p-highlight .pi {
      color: #ffffff; }

body .p-panelmenu .p-panelmenu-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  padding: 0; }
  body .p-panelmenu .p-panelmenu-content .p-menuitem {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin: 0; }
    body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
      border: 0 none;
      padding: .625em 1em;
      width: 100%;
      min-height: 2.750em;
      box-sizing: border-box;
      color: #212121;
      line-height: 1.5em;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      position: relative; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
        display: inline-block;
        vertical-align: middle;
        float: none; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .pi {
        position: static;
        display: inline-block;
        vertical-align: middle; }

body .p-panelmenu .p-menu-parent .p-menu-list {
  margin-left: 28px; }

@media (max-width: 640px) {
  body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    display: none; } }

body .p-messages {
  margin: 1em 0; }
  body .p-messages .p-messages-wrapper {
    padding: 0.625em 1em; }
  body .p-messages ul {
    display: inline-block;
    margin-left: 0; }
  body .p-messages.p-messages-info {
    background-color: #2196F3;
    border-color: #2196F3;
    color: #ffffff; }
    body .p-messages.p-messages-info .p-messages-close {
      color: #ffffff; }
  body .p-messages.p-messages-warn {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #ffffff; }
    body .p-messages.p-messages-warn .p-messages-close {
      color: #ffffff; }
  body .p-messages.p-messages-error {
    background-color: #e62a10;
    border-color: #e62a10;
    color: #ffffff; }
    body .p-messages.p-messages-error .p-messages-close {
      color: #ffffff; }
  body .p-messages.p-messages-success {
    background-color: #8BC34A;
    border-color: #8BC34A;
    color: #ffffff; }
    body .p-messages.p-messages-success .p-messages-close {
      color: #ffffff; }
  body .p-messages .p-messages-close {
    text-decoration: none;
    right: .25em; }

body .p-messages .p-messages-icon {
  background: none;
  color: #ffffff;
  margin-top: -2px; }
  body .p-messages .p-messages-icon.pi-info-circle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-info-circle:before {
      content: "info"; }
  body .p-messages .p-messages-icon.pi-exclamation-triangle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-exclamation-triangle:before {
      content: "warning"; }
  body .p-messages .p-messages-icon.pi-times {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-times:before {
      content: "error_outline"; }
  body .p-messages .p-messages-icon.pi-check {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-check:before {
      content: "check_circle"; }

body .p-message {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-message.p-message-info {
    background-color: #2196F3;
    border: 1px solid #2196F3;
    color: #ffffff; }
    body .p-message.p-message-info .p-message-icon {
      color: #ffffff; }
  body .p-message.p-message-success {
    background-color: #8BC34A;
    border: 1px solid #8BC34A;
    color: #ffffff; }
    body .p-message.p-message-success .p-message-icon {
      color: #ffffff; }
  body .p-message.p-message-warn {
    background-color: #ffc107;
    border: 1px solid #ffc107;
    color: #ffffff; }
    body .p-message.p-message-warn .p-message-icon {
      color: #ffffff; }
  body .p-message.p-message-error {
    background-color: #e62a10;
    border: 1px solid #e62a10;
    color: #ffffff; }
    body .p-message.p-message-error .p-message-icon {
      color: #ffffff; }

body .p-message .p-message-icon {
  background: none;
  color: #ffffff; }
  body .p-message .p-message-icon.pi-info-circle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-info-circle:before {
      content: "info"; }
  body .p-message .p-message-icon.pi-exclamation-triangle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-exclamation-triangle:before {
      content: "warning"; }
  body .p-message .p-message-icon.pi-times {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-times:before {
      content: "error_outline"; }
  body .p-message .p-message-icon.pi-check {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-check:before {
      content: "check_circle"; }

body .p-growl {
  top: 90px; }
  body .p-growl .p-growl-item-container {
    margin: 0 0 1em 0;
    opacity: 1; }
    body .p-growl .p-growl-item-container.p-growl-message-info {
      background-color: #2196F3;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-growl-message-warn {
      background-color: #ffc107;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-growl-message-error {
      background-color: #e62a10;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-growl-message-success {
      background-color: #8BC34A;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-shadow {
      -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-growl .p-growl-item .p-growl-image {
    background: none;
    font-size: 36px; }
    body .p-growl .p-growl-item .p-growl-image.pi-info-circle {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-info-circle:before {
        content: "info"; }
    body .p-growl .p-growl-item .p-growl-image.pi-exclamation-triangle {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-exclamation-triangle:before {
        content: "warning"; }
    body .p-growl .p-growl-item .p-growl-image.pi-times {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-times:before {
        content: "error_outline"; }
    body .p-growl .p-growl-item .p-growl-image.pi-check {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-check:before {
        content: "check_circle"; }
  body .p-growl .p-growl-item .p-growl-icon-close {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 24px; }
    body .p-growl .p-growl-item .p-growl-icon-close:before {
      content: "close"; }

body .p-dialog {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-dialog .p-dialog-titlebar {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #ffffff;
    color: #212121;
    padding: 0.625em 1em; }
    body .p-dialog .p-dialog-titlebar .p-dialog-title {
      font-size: 1.25em;
      letter-spacing: .005em;
      margin-top: .25em;
      line-height: 2em; }
    body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
      border: 0 none;
      padding: 0;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      height: 1.5em;
      width: 1.5em;
      text-align: center;
      margin-top: .5em; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover, body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:focus {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi {
        color: #757575;
        display: inline-block; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga'; }
        body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize:before {
          content: "fullscreen"; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga'; }
        body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize:before {
          content: "fullscreen_exit"; }
  body .p-dialog .p-dialog-content {
    padding: 0.625em 1em; }
  body .p-dialog .p-dialog-footer {
    text-align: right;
    border: 0 none;
    border-top: 1px solid #dbdbdb; }
    body .p-dialog .p-dialog-footer .p-button {
      background-color: #ffffff;
      color: #212121;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      width: auto; }
      body .p-dialog .p-dialog-footer .p-button .pi {
        color: #212121; }
      body .p-dialog .p-dialog-footer .p-button:hover {
        background-color: #e8e8e8;
        color: #000000; }
  body .p-dialog .p-confirm-dialog-severity {
    margin: 0px .75em; }

body .p-lightbox {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-lightbox .p-lightbox-caption {
    padding: 0.625em 1em; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
      margin: 0; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-close {
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      padding: 0;
      margin: 0;
      width: 1.5em;
      height: 1.5em; }
      body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
        background-color: #8197a5;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s; }
  body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left {
    top: 40%; }
    body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right .pi, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left .pi {
      -moz-transition: color 0.3s;
      -o-transition: color 0.3s;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
      font-size: 3em;
      color: #8197a5; }
  body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }

body .p-overlaypanel {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-overlaypanel .p-overlaypanel-close {
    background-color: #4699ce;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    right: -16px;
    top: -16px;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }
    body .p-overlaypanel .p-overlaypanel-close span {
      color: #ffffff;
      line-height: inherit; }
    body .p-overlaypanel .p-overlaypanel-close:hover {
      background-color: #274d8d; }

body .p-tooltip {
  opacity: 0.9;
  filter: alpha(opacity=90); }
  body .p-tooltip .p-tooltip-text {
    background-color: #323232; }
  body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #323232; }
  body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #323232; }
  body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #323232; }
  body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #323232; }

body .p-sidebar {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }
  body .p-sidebar .p-sidebar-close {
    color: #757575; }
    body .p-sidebar .p-sidebar-close:hover {
      color: #212121; }

body .p-draggable-dragging.p-default {
  padding: 0.625em 1em !important;
  background-color: #00529e; }

body .jqplot-target {
  font-family: "Roboto","Helvetica Neue",sans-serif; }

body .p-progressbar {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  height: .5em;
  background-color: #8197a5;
  overflow: hidden;
  border: 0 none; }
  body .p-progressbar .p-progressbar-value {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    height: .5em; }
    body .p-progressbar .p-progressbar-value .pi {
      color: #ffffff; }
  body .p-progressbar .p-progressbar-label {
    display: none !important; }

body .p-galleria .p-galleria-nav-prev {
  left: 0; }

body .p-galleria .p-galleria-nav-next {
  right: 0; }

body .p-inplace .p-inplace-display {
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-inplace .p-inplace-display:hover {
    background-color: #e8e8e8; }

body .p-terminal .p-terminal-input {
  font-size: 14px; }

/* Add your customizations of theme here */
@font-face {
  font-family: "WALTER-Icons";
  src: url("./walter-icons-font/WALTER-Icons.eot");
  src: url("./walter-icons-font/WALTER-Icons.woff2") format("woff2"), url("./walter-icons-font/WALTER-Icons.woff") format("woff"), url("./walter-icons-font/WALTER-Icons.ttf") format("truetype"), url("./walter-icons-font/WALTER-Icons.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="lkw-"],
[class*=" lkw-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "WALTER-Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lkw-external-link:before {
  content: ""; }

.lkw-chain:before {
  content: ""; }

.lkw-minus-square-o:before {
  content: ""; }

.lkw-check-square-o:before {
  content: ""; }

.lkw-flag:before {
  content: ""; }

.lkw-history:before {
  content: ""; }

.lkw-square-o:before {
  content: ""; }

.lkw-bell:before {
  content: ""; }

.lkw-bell-square:before {
  content: ""; }

.lkw-laptop:before {
  content: ""; }

.lkw-puzzle:before {
  content: ""; }

.lkw-schindlauer:before {
  content: ""; }

.lkw-bookmark-alternative:before {
  content: ""; }

.lkw-bookmark-star:before {
  content: ""; }

.lkw-mobile-envelope:before {
  content: ""; }

.lkw-signal-lost:before {
  content: ""; }

.lkw-signal:before {
  content: ""; }

.lkw-licenseplate:before {
  content: ""; }

.lkw-trailer-full:before {
  content: ""; }

.lkw-trailer-half:before {
  content: ""; }

.lkw-truck-trailer-full:before {
  content: ""; }

.lkw-truck-trailer-half:before {
  content: ""; }

.lkw-truck:before {
  content: ""; }

.lkw-truck-marker:before {
  content: ""; }

.lkw-circle-o:before {
  content: ""; }

.lkw-duplicate:before {
  content: ""; }

.lkw-filter:before {
  content: ""; }

.lkw-forward:before {
  content: ""; }

.lkw-map:before {
  content: ""; }

.lkw-refresh:before {
  content: ""; }

.lkw-reload:before {
  content: ""; }

.lkw-train:before {
  content: ""; }

.lkw-lorry:before {
  content: ""; }

.lkw-whatsapp:before {
  content: ""; }

.lkw-map-marker:before {
  content: ""; }

.lkw-anchor:before {
  content: ""; }

.lkw-arrow-down:before {
  content: ""; }

.lkw-arrow-up:before {
  content: ""; }

.lkw-angle-bold-down:before {
  content: ""; }

.lkw-angle-bold-left:before {
  content: ""; }

.lkw-angle-bold-right:before {
  content: ""; }

.lkw-angle-bold-up:before {
  content: ""; }

.lkw-angle-down:before {
  content: ""; }

.lkw-angle-left:before {
  content: ""; }

.lkw-angle-right:before {
  content: ""; }

.lkw-angle-up:before {
  content: ""; }

.lkw-triangle-down:before {
  content: ""; }

.lkw-triangle-left:before {
  content: ""; }

.lkw-triangle-right:before {
  content: ""; }

.lkw-triangle-up:before {
  content: ""; }

.lkw-sort:before {
  content: ""; }

.lkw-plus-square:before {
  content: ""; }

.lkw-minus-circle:before {
  content: ""; }

.lkw-plus:before {
  content: ""; }

.lkw-minus-square:before {
  content: ""; }

.lkw-minus:before {
  content: ""; }

.lkw-check-circle:before {
  content: ""; }

.lkw-check:before {
  content: ""; }

.lkw-question-circle:before {
  content: ""; }

.lkw-question:before {
  content: ""; }

.lkw-info-circle:before {
  content: ""; }

.lkw-info:before {
  content: ""; }

.lkw-close-circle:before {
  content: ""; }

.lkw-close:before {
  content: ""; }

.lkw-exclamation-circle:before {
  content: ""; }

.lkw-exclamation-triangle:before {
  content: ""; }

.lkw-thumbview:before {
  content: ""; }

.lkw-align-justify:before {
  content: ""; }

.lkw-navicon:before {
  content: ""; }

.lkw-burger:before {
  content: ""; }

.lkw-more:before {
  content: ""; }

.lkw-house-small:before {
  content: ""; }

.lkw-arrow-return:before {
  content: ""; }

.lkw-mail-closed:before {
  content: ""; }

.lkw-mail-open:before {
  content: ""; }

.lkw-attachment:before {
  content: ""; }

.lkw-fax:before {
  content: ""; }

.lkw-phone:before {
  content: ""; }

.lkw-phone-book:before {
  content: ""; }

.lkw-print:before {
  content: ""; }

.lkw-smartphone-portrait:before {
  content: ""; }

.lkw-smartphone-landscape:before {
  content: ""; }

.lkw-comment:before {
  content: ""; }

.lkw-comment-o:before {
  content: ""; }

.lkw-speech-bubbles:before {
  content: ""; }

.lkw-pencil:before {
  content: ""; }

.lkw-trashcan:before {
  content: ""; }

.lkw-table:before {
  content: ""; }

.lkw-calendar:before {
  content: ""; }

.lkw-search:before {
  content: ""; }

.lkw-move:before {
  content: ""; }

.lkw-star:before {
  content: ""; }

.lkw-location-arrow:before {
  content: ""; }

.lkw-lock-close:before {
  content: ""; }

.lkw-user:before {
  content: ""; }

.lkw-user-group:before {
  content: ""; }

.lkw-settings:before {
  content: ""; }

.lkw-logout:before {
  content: ""; }

/* primary colors */
/* background colors */
/* status colors */
/* Common */
.pi.pi-arrow-circle-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-arrow-circle-right:before {
    content: "play_circle_outline"; }

.pi.pi-arrow-circle-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi.pi-arrow-circle-left:before {
    content: "play_circle_outline"; }

.pi.pi-arrow-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-arrow-down:before {
    content: "arrow_downward"; }

.pi.pi-arrow-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-arrow-up:before {
    content: "arrow_upward"; }

.pi.pi-angle-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi.pi-angle-up:before {
    content: "play_arrow"; }

.pi.pi-angle-double-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi.pi-angle-double-up:before {
    content: "skip_next"; }

.pi.pi-angle-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi.pi-angle-down:before {
    content: "play_arrow"; }

.pi.pi-angle-double-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi.pi-angle-double-down:before {
    content: "skip_previous"; }

.pi.pi-angle-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-angle-right:before {
    content: "play_arrow"; }

.pi.pi-angle-double-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-angle-double-right:before {
    content: "fast_forward"; }

.pi.pi-angle-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi.pi-angle-left:before {
    content: "play_arrow"; }

.pi.pi-angle-double-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-angle-double-left:before {
    content: "fast_rewind"; }

.pi.pi-calendar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-calendar:before {
    content: "date_range"; }

.pi.pi-calendar-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-calendar-plus:before {
    content: "event"; }

.pi.pi-caret-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-caret-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-caret-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-right:before {
    content: "keyboard_arrow_right"; }

.pi.pi-caret-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-caret-left:before {
    content: "keyboard_arrow_left"; }

.pi.pi-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-check:before {
    content: "check"; }

.pi.pi-chevron-circle-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-left:before {
    content: "keyboard_arrow_left"; }

.pi.pi-chevron-circle-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-right:before {
    content: "keyboard_arrow_right"; }

.pi.pi-chevron-circle-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-chevron-circle-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-circle-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-chevron-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-right:before {
    content: "chevron_right"; }

.pi.pi-chevron-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-left:before {
    content: "chevron_left"; }

.pi.pi-chevron-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-chevron-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-chevron-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-circle-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-circle-off:before {
    content: "radio_button_unchecked"; }

.pi.pi-circle-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-circle-on:before {
    content: "radio_button_checked"; }

.pi.pi-cog {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-cog:before {
    content: "settings"; }

.pi.pi-file {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-file:before {
    content: "attach_file"; }

.pi.pi-minus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-minus:before {
    content: "remove"; }

.pi.pi-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-plus:before {
    content: "add"; }

.pi.pi-info-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-info-circle:before {
    content: "info"; }

.pi.pi-inbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-inbox:before {
    content: "inbox"; }

.pi.pi-upload {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-upload:before {
    content: "file_upload"; }

.pi.pi-home {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-home:before {
    content: "home"; }

.pi.pi-times {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-times:before {
    content: "close"; }

.pi.pi-search {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-search:before {
    content: "search"; }

.pi.pi-sort {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-sort:before {
    content: "sort"; }

.pi.pi-sort-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-sort-up:before {
    content: "keyboard_arrow_up"; }

.pi.pi-sort-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-sort-down:before {
    content: "keyboard_arrow_down"; }

.pi.pi-star {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-star:before {
    content: "star"; }

.pi.pi-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-video:before {
    content: "videocam"; }

.pi.pi-th-large {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-th-large:before {
    content: "grid_on"; }

.pi.pi-bars {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi.pi-bars:before {
    content: "list"; }

.pi-md-carat-2-n-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-2-n-s:before {
    content: "sort"; }

.pi-md-triangle-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-n:before {
    content: "keyboard_arrow_up"; }

.pi-md-triangle-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-e:before {
    content: "keyboard_arrow_right"; }

.pi-md-triangle-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-s:before {
    content: "keyboard_arrow_down"; }

.pi-md-triangle-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-triangle-1-w:before {
    content: "keyboard_arrow_left"; }

.pi-md-carat-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-n:before {
    content: "keyboard_arrow_up"; }

.pi-md-carat-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-e:before {
    content: "keyboard_arrow_right"; }

.pi-md-carat-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-s:before {
    content: "keyboard_arrow_down"; }

.pi-md-carat-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-carat-1-w:before {
    content: "keyboard_arrow_left"; }

.pi-md-arrow-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrow-1-n:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrowstop-1-n:before {
    content: "skip_next"; }

.pi-md-arrow-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi-md-arrow-1-s:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrowstop-1-s:before {
    content: "skip_previous"; }

.pi-md-arrow-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi-md-arrow-1-w:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrowstop-1-w:before {
    content: "fast_rewind"; }

.pi-md-arrow-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-1-e:before {
    content: "play_arrow"; }

.pi-md-arrowstop-1-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrowstop-1-e:before {
    content: "fast_forward"; }

.pi-md-arrowthick-1-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi-md-arrowthick-1-s:before {
    content: "play_arrow"; }

.pi-md-arrowthick-1-n {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg); }
  .pi-md-arrowthick-1-n:before {
    content: "play_arrow"; }

.pi-md-circle-triangle-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-triangle-e:before {
    content: "play_circle_outline"; }

.pi-md-circle-triangle-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi-md-circle-triangle-w:before {
    content: "play_circle_outline"; }

.pi-md-circle-triangle-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }
  .pi-md-circle-triangle-s:before {
    content: "play_circle_outline"; }

.pi-md-radio-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-off:before {
    content: "radio_button_unchecked"; }

.pi-md-radio-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-on:before {
    content: "radio_button_checked"; }

.pi-md-folder-collapsed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-collapsed:before {
    content: "folder"; }

.pi-md-document {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-document:before {
    content: "content_paste"; }

.pi-md-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video:before {
    content: "movie"; }

.pi-md-music {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-music:before {
    content: "music_note"; }

.pi-md-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-plus:before {
    content: "add"; }

.pi-md-minus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-minus:before {
    content: "remove"; }

.pi-md-plusthick {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-plusthick:before {
    content: "add"; }

.pi-md-minusthick {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-minusthick:before {
    content: "remove"; }

.pi-md-pencil {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pencil:before {
    content: "edit"; }

.pi-md-closethick {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-closethick:before {
    content: "close"; }

.pi-md-circle-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-close:before {
    content: "cancel"; }

.pi-md-gear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gear:before {
    content: "settings"; }

.pi-md-calendar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-calendar:before {
    content: "date_range"; }

.pi-md-trash {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trash:before {
    content: "delete_forever"; }

.pi-md-notice {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notice:before {
    content: "error_outline"; }

.pi-md-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alert:before {
    content: "warning"; }

.pi-md-circle-zoomin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-zoomin:before {
    content: "zoom_in"; }

.pi-md-circle-zoomout {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-zoomout:before {
    content: "zoom_out"; }

.pi-md-circle-arrow-e {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-circle-arrow-e:before {
    content: "play_circle_outline"; }

.pi-md-circle-arrow-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }
  .pi-md-circle-arrow-w:before {
    content: "play_circle_outline"; }

.pi-md-3d-rotation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-3d-rotation:before {
    content: "3d_rotation"; }

.pi-md-ac-unit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ac-unit:before {
    content: "ac_unit"; }

.pi-md-access-alarm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-access-alarm:before {
    content: "access_alarm"; }

.pi-md-access-alarms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-access-alarms:before {
    content: "access_alarms"; }

.pi-md-access-time {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-access-time:before {
    content: "access_time"; }

.pi-md-accessibility {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-accessibility:before {
    content: "accessibility"; }

.pi-md-accessible {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-accessible:before {
    content: "accessible"; }

.pi-md-account-balance {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-balance:before {
    content: "account_balance"; }

.pi-md-account-balance-wallet {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-balance-wallet:before {
    content: "account_balance_wallet"; }

.pi-md-account-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-box:before {
    content: "account_box"; }

.pi-md-account-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-account-circle:before {
    content: "account_circle"; }

.pi-md-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add:before {
    content: "add"; }

.pi-md-add-a-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-a-photo:before {
    content: "add_a_photo"; }

.pi-md-add-alarm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-alarm:before {
    content: "add_alarm"; }

.pi-md-add-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-alert:before {
    content: "add_alert"; }

.pi-md-add-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-box:before {
    content: "add_box"; }

.pi-md-add-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-circle:before {
    content: "add_circle"; }

.pi-md-add-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-circle-outline:before {
    content: "add_circle_outline"; }

.pi-md-add-location {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-location:before {
    content: "add_location"; }

.pi-md-add-shopping-cart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-shopping-cart:before {
    content: "add_shopping_cart"; }

.pi-md-add-to-photos {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-to-photos:before {
    content: "add_to_photos"; }

.pi-md-add-to-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-add-to-queue:before {
    content: "add_to_queue"; }

.pi-md-adjust {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-adjust:before {
    content: "adjust"; }

.pi-md-airline-seat-flat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-flat:before {
    content: "airline_seat_flat"; }

.pi-md-airline-seat-flat-angled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-flat-angled:before {
    content: "airline_seat_flat_angled"; }

.pi-md-airline-seat-individual-suite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-individual-suite:before {
    content: "airline_seat_individual_suite"; }

.pi-md-airline-seat-legroom-extra {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-legroom-extra:before {
    content: "airline_seat_legroom_extra"; }

.pi-md-airline-seat-legroom-normal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-legroom-normal:before {
    content: "airline_seat_legroom_normal"; }

.pi-md-airline-seat-legroom-reduced {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-legroom-reduced:before {
    content: "airline_seat_legroom_reduced"; }

.pi-md-airline-seat-recline-extra {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-recline-extra:before {
    content: "airline_seat_recline_extra"; }

.pi-md-airline-seat-recline-normal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airline-seat-recline-normal:before {
    content: "airline_seat_recline_normal"; }

.pi-md-airplanemode-active {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airplanemode-active:before {
    content: "airplanemode_active"; }

.pi-md-airplanemode-inactive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airplanemode-inactive:before {
    content: "airplanemode_inactive"; }

.pi-md-airplay {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airplay:before {
    content: "airplay"; }

.pi-md-airport-shuttle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-airport-shuttle:before {
    content: "airport_shuttle"; }

.pi-md-alarm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm:before {
    content: "alarm"; }

.pi-md-alarm-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm-add:before {
    content: "alarm_add"; }

.pi-md-alarm-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm-off:before {
    content: "alarm_off"; }

.pi-md-alarm-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-alarm-on:before {
    content: "alarm_on"; }

.pi-md-album {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-album:before {
    content: "album"; }

.pi-md-all-inclusive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-all-inclusive:before {
    content: "all_inclusive"; }

.pi-md-all-out {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-all-out:before {
    content: "all_out"; }

.pi-md-android {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-android:before {
    content: "android"; }

.pi-md-announcement {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-announcement:before {
    content: "announcement"; }

.pi-md-apps {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-apps:before {
    content: "apps"; }

.pi-md-archive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-archive:before {
    content: "archive"; }

.pi-md-arrow-back {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-back:before {
    content: "arrow_back"; }

.pi-md-arrow-downward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-downward:before {
    content: "arrow_downward"; }

.pi-md-arrow-drop-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-drop-down:before {
    content: "arrow_drop_down"; }

.pi-md-arrow-drop-down-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-drop-down-circle:before {
    content: "arrow_drop_down_circle"; }

.pi-md-arrow-drop-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-drop-up:before {
    content: "arrow_drop_up"; }

.pi-md-arrow-forward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-forward:before {
    content: "arrow_forward"; }

.pi-md-arrow-upward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-arrow-upward:before {
    content: "arrow_upward"; }

.pi-md-art-track {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-art-track:before {
    content: "art_track"; }

.pi-md-aspect-ratio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-aspect-ratio:before {
    content: "aspect_ratio"; }

.pi-md-assessment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assessment:before {
    content: "assessment"; }

.pi-md-assignment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment:before {
    content: "assignment"; }

.pi-md-assignment-ind {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-ind:before {
    content: "assignment_ind"; }

.pi-md-assignment-late {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-late:before {
    content: "assignment_late"; }

.pi-md-assignment-return {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-return:before {
    content: "assignment_return"; }

.pi-md-assignment-returned {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-returned:before {
    content: "assignment_returned"; }

.pi-md-assignment-turned-in {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assignment-turned-in:before {
    content: "assignment_turned_in"; }

.pi-md-assistant {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assistant:before {
    content: "assistant"; }

.pi-md-assistant-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-assistant-photo:before {
    content: "assistant_photo"; }

.pi-md-attach-file {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-attach-file:before {
    content: "attach_file"; }

.pi-md-attach-money {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-attach-money:before {
    content: "attach_money"; }

.pi-md-attachment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-attachment:before {
    content: "attachment"; }

.pi-md-audiotrack {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-audiotrack:before {
    content: "audiotrack"; }

.pi-md-autorenew {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-autorenew:before {
    content: "autorenew"; }

.pi-md-av-timer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-av-timer:before {
    content: "av_timer"; }

.pi-md-backspace {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-backspace:before {
    content: "backspace"; }

.pi-md-backup {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-backup:before {
    content: "backup"; }

.pi-md-battery-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-alert:before {
    content: "battery_alert"; }

.pi-md-battery-charging-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-charging-full:before {
    content: "battery_charging_full"; }

.pi-md-battery-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-full:before {
    content: "battery_full"; }

.pi-md-battery-std {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-std:before {
    content: "battery_std"; }

.pi-md-battery-unknown {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-battery-unknown:before {
    content: "battery_unknown"; }

.pi-md-beach-access {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-beach-access:before {
    content: "beach_access"; }

.pi-md-beenhere {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-beenhere:before {
    content: "beenhere"; }

.pi-md-block {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-block:before {
    content: "block"; }

.pi-md-bluetooth {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth:before {
    content: "bluetooth"; }

.pi-md-bluetooth-audio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-audio:before {
    content: "bluetooth_audio"; }

.pi-md-bluetooth-connected {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-connected:before {
    content: "bluetooth_connected"; }

.pi-md-bluetooth-disabled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-disabled:before {
    content: "bluetooth_disabled"; }

.pi-md-bluetooth-searching {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bluetooth-searching:before {
    content: "bluetooth_searching"; }

.pi-md-blur-circular {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-circular:before {
    content: "blur_circular"; }

.pi-md-blur-linear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-linear:before {
    content: "blur_linear"; }

.pi-md-blur-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-off:before {
    content: "blur_off"; }

.pi-md-blur-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-blur-on:before {
    content: "blur_on"; }

.pi-md-book {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-book:before {
    content: "book"; }

.pi-md-bookmark {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bookmark:before {
    content: "bookmark"; }

.pi-md-bookmark-border {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bookmark-border:before {
    content: "bookmark_border"; }

.pi-md-border-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-all:before {
    content: "border_all"; }

.pi-md-border-bottom {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-bottom:before {
    content: "border_bottom"; }

.pi-md-border-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-clear:before {
    content: "border_clear"; }

.pi-md-border-color {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-color:before {
    content: "border_color"; }

.pi-md-border-horizontal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-horizontal:before {
    content: "border_horizontal"; }

.pi-md-border-inner {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-inner:before {
    content: "border_inner"; }

.pi-md-border-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-left:before {
    content: "border_left"; }

.pi-md-border-outer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-outer:before {
    content: "border_outer"; }

.pi-md-border-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-right:before {
    content: "border_right"; }

.pi-md-border-style {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-style:before {
    content: "border_style"; }

.pi-md-border-top {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-top:before {
    content: "border_top"; }

.pi-md-border-vertical {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-border-vertical:before {
    content: "border_vertical"; }

.pi-md-branding-watermark {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-branding-watermark:before {
    content: "branding_watermark"; }

.pi-md-brightness-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-1:before {
    content: "brightness_1"; }

.pi-md-brightness-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-2:before {
    content: "brightness_2"; }

.pi-md-brightness-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-3:before {
    content: "brightness_3"; }

.pi-md-brightness-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-4:before {
    content: "brightness_4"; }

.pi-md-brightness-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-5:before {
    content: "brightness_5"; }

.pi-md-brightness-6 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-6:before {
    content: "brightness_6"; }

.pi-md-brightness-7 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-7:before {
    content: "brightness_7"; }

.pi-md-brightness-auto {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-auto:before {
    content: "brightness_auto"; }

.pi-md-brightness-high {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-high:before {
    content: "brightness_high"; }

.pi-md-brightness-low {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-low:before {
    content: "brightness_low"; }

.pi-md-brightness-medium {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brightness-medium:before {
    content: "brightness_medium"; }

.pi-md-broken-image {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-broken-image:before {
    content: "broken_image"; }

.pi-md-brush {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-brush:before {
    content: "brush"; }

.pi-md-bubble-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bubble-chart:before {
    content: "bubble_chart"; }

.pi-md-bug-report {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-bug-report:before {
    content: "bug_report"; }

.pi-md-build {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-build:before {
    content: "build"; }

.pi-md-burst-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-burst-mode:before {
    content: "burst_mode"; }

.pi-md-business {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-business:before {
    content: "business"; }

.pi-md-business-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-business-center:before {
    content: "business_center"; }

.pi-md-cached {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cached:before {
    content: "cached"; }

.pi-md-cake {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cake:before {
    content: "cake"; }

.pi-md-call {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call:before {
    content: "call"; }

.pi-md-call-end {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-end:before {
    content: "call_end"; }

.pi-md-call-made {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-made:before {
    content: "call_made"; }

.pi-md-call-merge {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-merge:before {
    content: "call_merge"; }

.pi-md-call-missed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-missed:before {
    content: "call_missed"; }

.pi-md-call-missed-outgoing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-missed-outgoing:before {
    content: "call_missed_outgoing"; }

.pi-md-call-received {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-received:before {
    content: "call_received"; }

.pi-md-call-split {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-split:before {
    content: "call_split"; }

.pi-md-call-to-action {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-call-to-action:before {
    content: "call_to_action"; }

.pi-md-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera:before {
    content: "camera"; }

.pi-md-camera-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-alt:before {
    content: "camera_alt"; }

.pi-md-camera-enhance {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-enhance:before {
    content: "camera_enhance"; }

.pi-md-camera-front {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-front:before {
    content: "camera_front"; }

.pi-md-camera-rear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-rear:before {
    content: "camera_rear"; }

.pi-md-camera-roll {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-camera-roll:before {
    content: "camera_roll"; }

.pi-md-cancel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cancel:before {
    content: "cancel"; }

.pi-md-card-giftcard {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-card-giftcard:before {
    content: "card_giftcard"; }

.pi-md-card-membership {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-card-membership:before {
    content: "card_membership"; }

.pi-md-card-travel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-card-travel:before {
    content: "card_travel"; }

.pi-md-casino {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-casino:before {
    content: "casino"; }

.pi-md-cast {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cast:before {
    content: "cast"; }

.pi-md-cast-connected {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cast-connected:before {
    content: "cast_connected"; }

.pi-md-center-focus-strong {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-center-focus-strong:before {
    content: "center_focus_strong"; }

.pi-md-center-focus-weak {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-center-focus-weak:before {
    content: "center_focus_weak"; }

.pi-md-change-history {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-change-history:before {
    content: "change_history"; }

.pi-md-chat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chat:before {
    content: "chat"; }

.pi-md-chat-bubble {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chat-bubble:before {
    content: "chat_bubble"; }

.pi-md-chat-bubble-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chat-bubble-outline:before {
    content: "chat_bubble_outline"; }

.pi-md-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check:before {
    content: "check"; }

.pi-md-check-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check-box:before {
    content: "check_box"; }

.pi-md-check-box-outline-blank {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check-box-outline-blank:before {
    content: "check_box_outline_blank"; }

.pi-md-check-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-check-circle:before {
    content: "check_circle"; }

.pi-md-chevron-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chevron-left:before {
    content: "chevron_left"; }

.pi-md-chevron-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chevron-right:before {
    content: "chevron_right"; }

.pi-md-child-care {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-child-care:before {
    content: "child_care"; }

.pi-md-child-friendly {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-child-friendly:before {
    content: "child_friendly"; }

.pi-md-chrome-reader-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-chrome-reader-mode:before {
    content: "chrome_reader_mode"; }

.pi-md-class {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-class:before {
    content: "class"; }

.pi-md-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-clear:before {
    content: "clear"; }

.pi-md-clear-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-clear-all:before {
    content: "clear_all"; }

.pi-md-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-close:before {
    content: "close"; }

.pi-md-closed-caption {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-closed-caption:before {
    content: "closed_caption"; }

.pi-md-cloud {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud:before {
    content: "cloud"; }

.pi-md-cloud-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-circle:before {
    content: "cloud_circle"; }

.pi-md-cloud-done {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-done:before {
    content: "cloud_done"; }

.pi-md-cloud-download {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-download:before {
    content: "cloud_download"; }

.pi-md-cloud-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-off:before {
    content: "cloud_off"; }

.pi-md-cloud-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-queue:before {
    content: "cloud_queue"; }

.pi-md-cloud-upload {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-cloud-upload:before {
    content: "cloud_upload"; }

.pi-md-code {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-code:before {
    content: "code"; }

.pi-md-collections {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-collections:before {
    content: "collections"; }

.pi-md-collections-bookmark {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-collections-bookmark:before {
    content: "collections_bookmark"; }

.pi-md-color-lens {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-color-lens:before {
    content: "color_lens"; }

.pi-md-colorize {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-colorize:before {
    content: "colorize"; }

.pi-md-comment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-comment:before {
    content: "comment"; }

.pi-md-compare {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-compare:before {
    content: "compare"; }

.pi-md-compare-arrows {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-compare-arrows:before {
    content: "compare_arrows"; }

.pi-md-computer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-computer:before {
    content: "computer"; }

.pi-md-confirmation-number {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-confirmation-number:before {
    content: "confirmation_number"; }

.pi-md-contact-mail {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-contact-mail:before {
    content: "contact_mail"; }

.pi-md-contact-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-contact-phone:before {
    content: "contact_phone"; }

.pi-md-contacts {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-contacts:before {
    content: "contacts"; }

.pi-md-content-copy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-content-copy:before {
    content: "content_copy"; }

.pi-md-content-cut {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-content-cut:before {
    content: "content_cut"; }

.pi-md-content-paste {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-content-paste:before {
    content: "content_paste"; }

.pi-md-control-point {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-control-point:before {
    content: "control_point"; }

.pi-md-control-point-duplicate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-control-point-duplicate:before {
    content: "control_point_duplicate"; }

.pi-md-copyright {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-copyright:before {
    content: "copyright"; }

.pi-md-create {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-create:before {
    content: "create"; }

.pi-md-create-new-folder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-create-new-folder:before {
    content: "create_new_folder"; }

.pi-md-credit-card {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-credit-card:before {
    content: "credit_card"; }

.pi-md-crop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop:before {
    content: "crop"; }

.pi-md-crop-16-9 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-16-9:before {
    content: "crop_16_9"; }

.pi-md-crop-3-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-3-2:before {
    content: "crop_3_2"; }

.pi-md-crop-5-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-5-4:before {
    content: "crop_5_4"; }

.pi-md-crop-7-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-7-5:before {
    content: "crop_7_5"; }

.pi-md-crop-din {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-din:before {
    content: "crop_din"; }

.pi-md-crop-free {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-free:before {
    content: "crop_free"; }

.pi-md-crop-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-landscape:before {
    content: "crop_landscape"; }

.pi-md-crop-original {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-original:before {
    content: "crop_original"; }

.pi-md-crop-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-portrait:before {
    content: "crop_portrait"; }

.pi-md-crop-rotate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-rotate:before {
    content: "crop_rotate"; }

.pi-md-crop-square {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-crop-square:before {
    content: "crop_square"; }

.pi-md-dashboard {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dashboard:before {
    content: "dashboard"; }

.pi-md-data-usage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-data-usage:before {
    content: "data_usage"; }

.pi-md-date-range {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-date-range:before {
    content: "date_range"; }

.pi-md-dehaze {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dehaze:before {
    content: "dehaze"; }

.pi-md-delete {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-delete:before {
    content: "delete"; }

.pi-md-delete-forever {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-delete-forever:before {
    content: "delete_forever"; }

.pi-md-delete-sweep {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-delete-sweep:before {
    content: "delete_sweep"; }

.pi-md-description {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-description:before {
    content: "description"; }

.pi-md-desktop-mac {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-desktop-mac:before {
    content: "desktop_mac"; }

.pi-md-desktop-windows {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-desktop-windows:before {
    content: "desktop_windows"; }

.pi-md-details {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-details:before {
    content: "details"; }

.pi-md-developer-board {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-developer-board:before {
    content: "developer_board"; }

.pi-md-developer-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-developer-mode:before {
    content: "developer_mode"; }

.pi-md-device-hub {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-device-hub:before {
    content: "device_hub"; }

.pi-md-devices {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-devices:before {
    content: "devices"; }

.pi-md-devices-other {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-devices-other:before {
    content: "devices_other"; }

.pi-md-dialer-sip {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dialer-sip:before {
    content: "dialer_sip"; }

.pi-md-dialpad {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dialpad:before {
    content: "dialpad"; }

.pi-md-directions {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions:before {
    content: "directions"; }

.pi-md-directions-bike {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-bike:before {
    content: "directions_bike"; }

.pi-md-directions-boat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-boat:before {
    content: "directions_boat"; }

.pi-md-directions-bus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-bus:before {
    content: "directions_bus"; }

.pi-md-directions-car {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-car:before {
    content: "directions_car"; }

.pi-md-directions-railway {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-railway:before {
    content: "directions_railway"; }

.pi-md-directions-run {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-run:before {
    content: "directions_run"; }

.pi-md-directions-subway {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-subway:before {
    content: "directions_subway"; }

.pi-md-directions-transit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-transit:before {
    content: "directions_transit"; }

.pi-md-directions-walk {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-directions-walk:before {
    content: "directions_walk"; }

.pi-md-disc-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-disc-full:before {
    content: "disc_full"; }

.pi-md-dns {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dns:before {
    content: "dns"; }

.pi-md-do-not-disturb {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb:before {
    content: "do_not_disturb"; }

.pi-md-do-not-disturb-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb-alt:before {
    content: "do_not_disturb_alt"; }

.pi-md-do-not-disturb-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb-off:before {
    content: "do_not_disturb_off"; }

.pi-md-do-not-disturb-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-do-not-disturb-on:before {
    content: "do_not_disturb_on"; }

.pi-md-dock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dock:before {
    content: "dock"; }

.pi-md-domain {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-domain:before {
    content: "domain"; }

.pi-md-done {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-done:before {
    content: "done"; }

.pi-md-done-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-done-all:before {
    content: "done_all"; }

.pi-md-donut-large {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-donut-large:before {
    content: "donut_large"; }

.pi-md-donut-small {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-donut-small:before {
    content: "donut_small"; }

.pi-md-drafts {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-drafts:before {
    content: "drafts"; }

.pi-md-drag-handle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-drag-handle:before {
    content: "drag_handle"; }

.pi-md-drive-eta {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-drive-eta:before {
    content: "drive_eta"; }

.pi-md-dvr {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-dvr:before {
    content: "dvr"; }

.pi-md-edit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-edit:before {
    content: "edit"; }

.pi-md-edit-location {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-edit-location:before {
    content: "edit_location"; }

.pi-md-eject {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-eject:before {
    content: "eject"; }

.pi-md-email {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-email:before {
    content: "email"; }

.pi-md-enhanced-encryption {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-enhanced-encryption:before {
    content: "enhanced_encryption"; }

.pi-md-equalizer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-equalizer:before {
    content: "equalizer"; }

.pi-md-error {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-error:before {
    content: "error"; }

.pi-md-error-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-error-outline:before {
    content: "error_outline"; }

.pi-md-euro-symbol {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-euro-symbol:before {
    content: "euro_symbol"; }

.pi-md-ev-station {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ev-station:before {
    content: "ev_station"; }

.pi-md-event {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event:before {
    content: "event"; }

.pi-md-event-available {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-available:before {
    content: "event_available"; }

.pi-md-event-busy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-busy:before {
    content: "event_busy"; }

.pi-md-event-note {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-note:before {
    content: "event_note"; }

.pi-md-event-seat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-event-seat:before {
    content: "event_seat"; }

.pi-md-exit-to-app {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exit-to-app:before {
    content: "exit_to_app"; }

.pi-md-expand-less {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-expand-less:before {
    content: "expand_less"; }

.pi-md-expand-more {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-expand-more:before {
    content: "expand_more"; }

.pi-md-explicit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-explicit:before {
    content: "explicit"; }

.pi-md-explore {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-explore:before {
    content: "explore"; }

.pi-md-exposure {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure:before {
    content: "exposure"; }

.pi-md-exposure-neg-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-neg-1:before {
    content: "exposure_neg_1"; }

.pi-md-exposure-neg-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-neg-2:before {
    content: "exposure_neg_2"; }

.pi-md-exposure-plus-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-plus-1:before {
    content: "exposure_plus_1"; }

.pi-md-exposure-plus-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-plus-2:before {
    content: "exposure_plus_2"; }

.pi-md-exposure-zero {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-exposure-zero:before {
    content: "exposure_zero"; }

.pi-md-extension {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-extension:before {
    content: "extension"; }

.pi-md-face {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-face:before {
    content: "face"; }

.pi-md-fast-forward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fast-forward:before {
    content: "fast_forward"; }

.pi-md-fast-rewind {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fast-rewind:before {
    content: "fast_rewind"; }

.pi-md-favorite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-favorite:before {
    content: "favorite"; }

.pi-md-favorite-border {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-favorite-border:before {
    content: "favorite_border"; }

.pi-md-featured-play-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-featured-play-list:before {
    content: "featured_play_list"; }

.pi-md-featured-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-featured-video:before {
    content: "featured_video"; }

.pi-md-feedback {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-feedback:before {
    content: "feedback"; }

.pi-md-fiber-dvr {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-dvr:before {
    content: "fiber_dvr"; }

.pi-md-fiber-manual-record {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-manual-record:before {
    content: "fiber_manual_record"; }

.pi-md-fiber-new {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-new:before {
    content: "fiber_new"; }

.pi-md-fiber-pin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-pin:before {
    content: "fiber_pin"; }

.pi-md-fiber-smart-record {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fiber-smart-record:before {
    content: "fiber_smart_record"; }

.pi-md-file-download {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-file-download:before {
    content: "file_download"; }

.pi-md-file-upload {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-file-upload:before {
    content: "file_upload"; }

.pi-md-filter {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter:before {
    content: "filter"; }

.pi-md-filter-1 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-1:before {
    content: "filter_1"; }

.pi-md-filter-2 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-2:before {
    content: "filter_2"; }

.pi-md-filter-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-3:before {
    content: "filter_3"; }

.pi-md-filter-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-4:before {
    content: "filter_4"; }

.pi-md-filter-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-5:before {
    content: "filter_5"; }

.pi-md-filter-6 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-6:before {
    content: "filter_6"; }

.pi-md-filter-7 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-7:before {
    content: "filter_7"; }

.pi-md-filter-8 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-8:before {
    content: "filter_8"; }

.pi-md-filter-9 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-9:before {
    content: "filter_9"; }

.pi-md-filter-9-plus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-9-plus:before {
    content: "filter_9_plus"; }

.pi-md-filter-b-and-w {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-b-and-w:before {
    content: "filter_b_and_w"; }

.pi-md-filter-center-focus {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-center-focus:before {
    content: "filter_center_focus"; }

.pi-md-filter-drama {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-drama:before {
    content: "filter_drama"; }

.pi-md-filter-frames {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-frames:before {
    content: "filter_frames"; }

.pi-md-filter-hdr {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-hdr:before {
    content: "filter_hdr"; }

.pi-md-filter-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-list:before {
    content: "filter_list"; }

.pi-md-filter-none {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-none:before {
    content: "filter_none"; }

.pi-md-filter-tilt-shift {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-tilt-shift:before {
    content: "filter_tilt_shift"; }

.pi-md-filter-vintage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-filter-vintage:before {
    content: "filter_vintage"; }

.pi-md-find-in-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-find-in-page:before {
    content: "find_in_page"; }

.pi-md-find-replace {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-find-replace:before {
    content: "find_replace"; }

.pi-md-fingerprint {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fingerprint:before {
    content: "fingerprint"; }

.pi-md-first-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-first-page:before {
    content: "first_page"; }

.pi-md-fitness-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fitness-center:before {
    content: "fitness_center"; }

.pi-md-flag {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flag:before {
    content: "flag"; }

.pi-md-flare {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flare:before {
    content: "flare"; }

.pi-md-flash-auto {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flash-auto:before {
    content: "flash_auto"; }

.pi-md-flash-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flash-off:before {
    content: "flash_off"; }

.pi-md-flash-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flash-on:before {
    content: "flash_on"; }

.pi-md-flight {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flight:before {
    content: "flight"; }

.pi-md-flight-land {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flight-land:before {
    content: "flight_land"; }

.pi-md-flight-takeoff {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flight-takeoff:before {
    content: "flight_takeoff"; }

.pi-md-flip {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flip:before {
    content: "flip"; }

.pi-md-flip-to-back {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flip-to-back:before {
    content: "flip_to_back"; }

.pi-md-flip-to-front {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-flip-to-front:before {
    content: "flip_to_front"; }

.pi-md-folder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder:before {
    content: "folder"; }

.pi-md-folder-open {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-open:before {
    content: "folder_open"; }

.pi-md-folder-shared {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-shared:before {
    content: "folder_shared"; }

.pi-md-folder-special {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-folder-special:before {
    content: "folder_special"; }

.pi-md-font-download {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-font-download:before {
    content: "font_download"; }

.pi-md-format-align-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-center:before {
    content: "format_align_center"; }

.pi-md-format-align-justify {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-justify:before {
    content: "format_align_justify"; }

.pi-md-format-align-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-left:before {
    content: "format_align_left"; }

.pi-md-format-align-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-align-right:before {
    content: "format_align_right"; }

.pi-md-format-bold {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-bold:before {
    content: "format_bold"; }

.pi-md-format-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-clear:before {
    content: "format_clear"; }

.pi-md-format-color-fill {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-color-fill:before {
    content: "format_color_fill"; }

.pi-md-format-color-reset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-color-reset:before {
    content: "format_color_reset"; }

.pi-md-format-color-text {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-color-text:before {
    content: "format_color_text"; }

.pi-md-format-indent-decrease {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-indent-decrease:before {
    content: "format_indent_decrease"; }

.pi-md-format-indent-increase {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-indent-increase:before {
    content: "format_indent_increase"; }

.pi-md-format-italic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-italic:before {
    content: "format_italic"; }

.pi-md-format-line-spacing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-line-spacing:before {
    content: "format_line_spacing"; }

.pi-md-format-list-bulleted {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-list-bulleted:before {
    content: "format_list_bulleted"; }

.pi-md-format-list-numbered {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-list-numbered:before {
    content: "format_list_numbered"; }

.pi-md-format-paint {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-paint:before {
    content: "format_paint"; }

.pi-md-format-quote {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-quote:before {
    content: "format_quote"; }

.pi-md-format-shapes {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-shapes:before {
    content: "format_shapes"; }

.pi-md-format-size {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-size:before {
    content: "format_size"; }

.pi-md-format-strikethrough {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-strikethrough:before {
    content: "format_strikethrough"; }

.pi-md-format-textdirection-l-to-r {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-textdirection-l-to-r:before {
    content: "format_textdirection_l_to_r"; }

.pi-md-format-textdirection-r-to-l {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-textdirection-r-to-l:before {
    content: "format_textdirection_r_to_l"; }

.pi-md-format-underlined {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-format-underlined:before {
    content: "format_underlined"; }

.pi-md-forum {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forum:before {
    content: "forum"; }

.pi-md-forward {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward:before {
    content: "forward"; }

.pi-md-forward-10 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward-10:before {
    content: "forward_10"; }

.pi-md-forward-30 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward-30:before {
    content: "forward_30"; }

.pi-md-forward-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-forward-5:before {
    content: "forward_5"; }

.pi-md-free-breakfast {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-free-breakfast:before {
    content: "free_breakfast"; }

.pi-md-fullscreen {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fullscreen:before {
    content: "fullscreen"; }

.pi-md-fullscreen-exit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-fullscreen-exit:before {
    content: "fullscreen_exit"; }

.pi-md-functions {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-functions:before {
    content: "functions"; }

.pi-md-g-translate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-g-translate:before {
    content: "g_translate"; }

.pi-md-gamepad {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gamepad:before {
    content: "gamepad"; }

.pi-md-games {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-games:before {
    content: "games"; }

.pi-md-gavel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gavel:before {
    content: "gavel"; }

.pi-md-gesture {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gesture:before {
    content: "gesture"; }

.pi-md-get-app {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-get-app:before {
    content: "get_app"; }

.pi-md-gif {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gif:before {
    content: "gif"; }

.pi-md-golf-course {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-golf-course:before {
    content: "golf_course"; }

.pi-md-gps-fixed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gps-fixed:before {
    content: "gps_fixed"; }

.pi-md-gps-not-fixed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gps-not-fixed:before {
    content: "gps_not_fixed"; }

.pi-md-gps-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gps-off:before {
    content: "gps_off"; }

.pi-md-grade {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grade:before {
    content: "grade"; }

.pi-md-gradient {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-gradient:before {
    content: "gradient"; }

.pi-md-grain {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grain:before {
    content: "grain"; }

.pi-md-graphic-eq {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-graphic-eq:before {
    content: "graphic_eq"; }

.pi-md-grid-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grid-off:before {
    content: "grid_off"; }

.pi-md-grid-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-grid-on:before {
    content: "grid_on"; }

.pi-md-group {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-group:before {
    content: "group"; }

.pi-md-group-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-group-add:before {
    content: "group_add"; }

.pi-md-group-work {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-group-work:before {
    content: "group_work"; }

.pi-md-hd {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hd:before {
    content: "hd"; }

.pi-md-hdr-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-off:before {
    content: "hdr_off"; }

.pi-md-hdr-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-on:before {
    content: "hdr_on"; }

.pi-md-hdr-strong {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-strong:before {
    content: "hdr_strong"; }

.pi-md-hdr-weak {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hdr-weak:before {
    content: "hdr_weak"; }

.pi-md-headset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-headset:before {
    content: "headset"; }

.pi-md-headset-mic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-headset-mic:before {
    content: "headset_mic"; }

.pi-md-healing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-healing:before {
    content: "healing"; }

.pi-md-hearing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hearing:before {
    content: "hearing"; }

.pi-md-help {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-help:before {
    content: "help"; }

.pi-md-help-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-help-outline:before {
    content: "help_outline"; }

.pi-md-high-quality {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-high-quality:before {
    content: "high_quality"; }

.pi-md-highlight {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-highlight:before {
    content: "highlight"; }

.pi-md-highlight-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-highlight-off:before {
    content: "highlight_off"; }

.pi-md-history {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-history:before {
    content: "history"; }

.pi-md-home {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-home:before {
    content: "home"; }

.pi-md-hot-tub {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hot-tub:before {
    content: "hot_tub"; }

.pi-md-hotel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hotel:before {
    content: "hotel"; }

.pi-md-hourglass-empty {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hourglass-empty:before {
    content: "hourglass_empty"; }

.pi-md-hourglass-full {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-hourglass-full:before {
    content: "hourglass_full"; }

.pi-md-http {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-http:before {
    content: "http"; }

.pi-md-https {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-https:before {
    content: "https"; }

.pi-md-image {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-image:before {
    content: "image"; }

.pi-md-image-aspect-ratio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-image-aspect-ratio:before {
    content: "image_aspect_ratio"; }

.pi-md-import-contacts {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-import-contacts:before {
    content: "import_contacts"; }

.pi-md-import-export {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-import-export:before {
    content: "import_export"; }

.pi-md-important-devices {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-important-devices:before {
    content: "important_devices"; }

.pi-md-inbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-inbox:before {
    content: "inbox"; }

.pi-md-indeterminate-check-box {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-indeterminate-check-box:before {
    content: "indeterminate_check_box"; }

.pi-md-info {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-info:before {
    content: "info"; }

.pi-md-info-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-info-outline:before {
    content: "info_outline"; }

.pi-md-input {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-input:before {
    content: "input"; }

.pi-md-insert-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-chart:before {
    content: "insert_chart"; }

.pi-md-insert-comment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-comment:before {
    content: "insert_comment"; }

.pi-md-insert-drive-file {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-drive-file:before {
    content: "insert_drive_file"; }

.pi-md-insert-emoticon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-emoticon:before {
    content: "insert_emoticon"; }

.pi-md-insert-invitation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-invitation:before {
    content: "insert_invitation"; }

.pi-md-insert-link {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-link:before {
    content: "insert_link"; }

.pi-md-insert-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-insert-photo:before {
    content: "insert_photo"; }

.pi-md-invert-colors {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-invert-colors:before {
    content: "invert_colors"; }

.pi-md-invert-colors-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-invert-colors-off:before {
    content: "invert_colors_off"; }

.pi-md-iso {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-iso:before {
    content: "iso"; }

.pi-md-keyboard {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard:before {
    content: "keyboard"; }

.pi-md-keyboard-arrow-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-down:before {
    content: "keyboard_arrow_down"; }

.pi-md-keyboard-arrow-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-left:before {
    content: "keyboard_arrow_left"; }

.pi-md-keyboard-arrow-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-right:before {
    content: "keyboard_arrow_right"; }

.pi-md-keyboard-arrow-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-arrow-up:before {
    content: "keyboard_arrow_up"; }

.pi-md-keyboard-backspace {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-backspace:before {
    content: "keyboard_backspace"; }

.pi-md-keyboard-capslock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-capslock:before {
    content: "keyboard_capslock"; }

.pi-md-keyboard-hide {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-hide:before {
    content: "keyboard_hide"; }

.pi-md-keyboard-return {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-return:before {
    content: "keyboard_return"; }

.pi-md-keyboard-tab {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-tab:before {
    content: "keyboard_tab"; }

.pi-md-keyboard-voice {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-keyboard-voice:before {
    content: "keyboard_voice"; }

.pi-md-kitchen {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-kitchen:before {
    content: "kitchen"; }

.pi-md-label {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-label:before {
    content: "label"; }

.pi-md-label-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-label-outline:before {
    content: "label_outline"; }

.pi-md-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-landscape:before {
    content: "landscape"; }

.pi-md-language {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-language:before {
    content: "language"; }

.pi-md-laptop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop:before {
    content: "laptop"; }

.pi-md-laptop-chromebook {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop-chromebook:before {
    content: "laptop_chromebook"; }

.pi-md-laptop-mac {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop-mac:before {
    content: "laptop_mac"; }

.pi-md-laptop-windows {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-laptop-windows:before {
    content: "laptop_windows"; }

.pi-md-last-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-last-page:before {
    content: "last_page"; }

.pi-md-launch {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-launch:before {
    content: "launch"; }

.pi-md-layers {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-layers:before {
    content: "layers"; }

.pi-md-layers-clear {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-layers-clear:before {
    content: "layers_clear"; }

.pi-md-leak-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-leak-add:before {
    content: "leak_add"; }

.pi-md-leak-remove {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-leak-remove:before {
    content: "leak_remove"; }

.pi-md-lens {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lens:before {
    content: "lens"; }

.pi-md-library-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-library-add:before {
    content: "library_add"; }

.pi-md-library-books {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-library-books:before {
    content: "library_books"; }

.pi-md-library-music {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-library-music:before {
    content: "library_music"; }

.pi-md-lightbulb-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lightbulb-outline:before {
    content: "lightbulb_outline"; }

.pi-md-line-style {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-line-style:before {
    content: "line_style"; }

.pi-md-line-weight {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-line-weight:before {
    content: "line_weight"; }

.pi-md-linear-scale {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-linear-scale:before {
    content: "linear_scale"; }

.pi-md-link {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-link:before {
    content: "link"; }

.pi-md-linked-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-linked-camera:before {
    content: "linked_camera"; }

.pi-md-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-list:before {
    content: "list"; }

.pi-md-live-help {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-live-help:before {
    content: "live_help"; }

.pi-md-live-tv {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-live-tv:before {
    content: "live_tv"; }

.pi-md-local-activity {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-activity:before {
    content: "local_activity"; }

.pi-md-local-airport {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-airport:before {
    content: "local_airport"; }

.pi-md-local-atm {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-atm:before {
    content: "local_atm"; }

.pi-md-local-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-bar:before {
    content: "local_bar"; }

.pi-md-local-cafe {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-cafe:before {
    content: "local_cafe"; }

.pi-md-local-car-wash {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-car-wash:before {
    content: "local_car_wash"; }

.pi-md-local-convenience-store {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-convenience-store:before {
    content: "local_convenience_store"; }

.pi-md-local-dining {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-dining:before {
    content: "local_dining"; }

.pi-md-local-drink {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-drink:before {
    content: "local_drink"; }

.pi-md-local-florist {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-florist:before {
    content: "local_florist"; }

.pi-md-local-gas-station {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-gas-station:before {
    content: "local_gas_station"; }

.pi-md-local-grocery-store {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-grocery-store:before {
    content: "local_grocery_store"; }

.pi-md-local-hospital {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-hospital:before {
    content: "local_hospital"; }

.pi-md-local-hotel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-hotel:before {
    content: "local_hotel"; }

.pi-md-local-laundry-service {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-laundry-service:before {
    content: "local_laundry_service"; }

.pi-md-local-library {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-library:before {
    content: "local_library"; }

.pi-md-local-mall {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-mall:before {
    content: "local_mall"; }

.pi-md-local-movies {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-movies:before {
    content: "local_movies"; }

.pi-md-local-offer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-offer:before {
    content: "local_offer"; }

.pi-md-local-parking {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-parking:before {
    content: "local_parking"; }

.pi-md-local-pharmacy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-pharmacy:before {
    content: "local_pharmacy"; }

.pi-md-local-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-phone:before {
    content: "local_phone"; }

.pi-md-local-pizza {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-pizza:before {
    content: "local_pizza"; }

.pi-md-local-play {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-play:before {
    content: "local_play"; }

.pi-md-local-post-office {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-post-office:before {
    content: "local_post_office"; }

.pi-md-local-printshop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-printshop:before {
    content: "local_printshop"; }

.pi-md-local-see {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-see:before {
    content: "local_see"; }

.pi-md-local-shipping {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-shipping:before {
    content: "local_shipping"; }

.pi-md-local-taxi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-local-taxi:before {
    content: "local_taxi"; }

.pi-md-location-city {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-city:before {
    content: "location_city"; }

.pi-md-location-disabled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-disabled:before {
    content: "location_disabled"; }

.pi-md-location-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-off:before {
    content: "location_off"; }

.pi-md-location-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-on:before {
    content: "location_on"; }

.pi-md-location-searching {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-location-searching:before {
    content: "location_searching"; }

.pi-md-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lock:before {
    content: "lock"; }

.pi-md-lock-open {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lock-open:before {
    content: "lock_open"; }

.pi-md-lock-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-lock-outline:before {
    content: "lock_outline"; }

.pi-md-looks {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks:before {
    content: "looks"; }

.pi-md-looks-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-3:before {
    content: "looks_3"; }

.pi-md-looks-4 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-4:before {
    content: "looks_4"; }

.pi-md-looks-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-5:before {
    content: "looks_5"; }

.pi-md-looks-6 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-6:before {
    content: "looks_6"; }

.pi-md-looks-one {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-one:before {
    content: "looks_one"; }

.pi-md-looks-two {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-looks-two:before {
    content: "looks_two"; }

.pi-md-loop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-loop:before {
    content: "loop"; }

.pi-md-loupe {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-loupe:before {
    content: "loupe"; }

.pi-md-low-priority {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-low-priority:before {
    content: "low_priority"; }

.pi-md-loyalty {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-loyalty:before {
    content: "loyalty"; }

.pi-md-mail {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mail:before {
    content: "mail"; }

.pi-md-mail-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mail-outline:before {
    content: "mail_outline"; }

.pi-md-map {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-map:before {
    content: "map"; }

.pi-md-markunread {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-markunread:before {
    content: "markunread"; }

.pi-md-markunread-mailbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-markunread-mailbox:before {
    content: "markunread_mailbox"; }

.pi-md-memory {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-memory:before {
    content: "memory"; }

.pi-md-menu {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-menu:before {
    content: "menu"; }

.pi-md-merge-type {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-merge-type:before {
    content: "merge_type"; }

.pi-md-message {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-message:before {
    content: "message"; }

.pi-md-mic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mic:before {
    content: "mic"; }

.pi-md-mic-none {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mic-none:before {
    content: "mic_none"; }

.pi-md-mic-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mic-off:before {
    content: "mic_off"; }

.pi-md-mms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mms:before {
    content: "mms"; }

.pi-md-mode-comment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mode-comment:before {
    content: "mode_comment"; }

.pi-md-mode-edit {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mode-edit:before {
    content: "mode_edit"; }

.pi-md-monetization-on {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-monetization-on:before {
    content: "monetization_on"; }

.pi-md-money-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-money-off:before {
    content: "money_off"; }

.pi-md-monochrome-photos {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-monochrome-photos:before {
    content: "monochrome_photos"; }

.pi-md-mood {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mood:before {
    content: "mood"; }

.pi-md-mood-bad {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mood-bad:before {
    content: "mood_bad"; }

.pi-md-more {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-more:before {
    content: "more"; }

.pi-md-more-horiz {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-more-horiz:before {
    content: "more_horiz"; }

.pi-md-more-vert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-more-vert:before {
    content: "more_vert"; }

.pi-md-motorcycle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-motorcycle:before {
    content: "motorcycle"; }

.pi-md-mouse {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-mouse:before {
    content: "mouse"; }

.pi-md-move-to-inbox {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-move-to-inbox:before {
    content: "move_to_inbox"; }

.pi-md-movie {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-movie:before {
    content: "movie"; }

.pi-md-movie-creation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-movie-creation:before {
    content: "movie_creation"; }

.pi-md-movie-filter {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-movie-filter:before {
    content: "movie_filter"; }

.pi-md-multiline-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-multiline-chart:before {
    content: "multiline_chart"; }

.pi-md-music-note {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-music-note:before {
    content: "music_note"; }

.pi-md-music-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-music-video:before {
    content: "music_video"; }

.pi-md-my-location {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-my-location:before {
    content: "my_location"; }

.pi-md-nature {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-nature:before {
    content: "nature"; }

.pi-md-nature-people {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-nature-people:before {
    content: "nature_people"; }

.pi-md-navigate-before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-navigate-before:before {
    content: "navigate_before"; }

.pi-md-navigate-next {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-navigate-next:before {
    content: "navigate_next"; }

.pi-md-navigation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-navigation:before {
    content: "navigation"; }

.pi-md-near-me {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-near-me:before {
    content: "near_me"; }

.pi-md-network-cell {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-cell:before {
    content: "network_cell"; }

.pi-md-network-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-check:before {
    content: "network_check"; }

.pi-md-network-locked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-locked:before {
    content: "network_locked"; }

.pi-md-network-wifi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-network-wifi:before {
    content: "network_wifi"; }

.pi-md-new-releases {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-new-releases:before {
    content: "new_releases"; }

.pi-md-next-week {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-next-week:before {
    content: "next_week"; }

.pi-md-nfc {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-nfc:before {
    content: "nfc"; }

.pi-md-no-encryption {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-no-encryption:before {
    content: "no_encryption"; }

.pi-md-no-sim {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-no-sim:before {
    content: "no_sim"; }

.pi-md-not-interested {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-not-interested:before {
    content: "not_interested"; }

.pi-md-note {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-note:before {
    content: "note"; }

.pi-md-note-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-note-add:before {
    content: "note_add"; }

.pi-md-notifications {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications:before {
    content: "notifications"; }

.pi-md-notifications-active {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-active:before {
    content: "notifications_active"; }

.pi-md-notifications-none {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-none:before {
    content: "notifications_none"; }

.pi-md-notifications-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-off:before {
    content: "notifications_off"; }

.pi-md-notifications-paused {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-notifications-paused:before {
    content: "notifications_paused"; }

.pi-md-offline-pin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-offline-pin:before {
    content: "offline_pin"; }

.pi-md-ondemand-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ondemand-video:before {
    content: "ondemand_video"; }

.pi-md-opacity {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-opacity:before {
    content: "opacity"; }

.pi-md-open-in-browser {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-open-in-browser:before {
    content: "open_in_browser"; }

.pi-md-open-in-new {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-open-in-new:before {
    content: "open_in_new"; }

.pi-md-open-with {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-open-with:before {
    content: "open_with"; }

.pi-md-pages {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pages:before {
    content: "pages"; }

.pi-md-pageview {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pageview:before {
    content: "pageview"; }

.pi-md-palette {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-palette:before {
    content: "palette"; }

.pi-md-pan-tool {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pan-tool:before {
    content: "pan_tool"; }

.pi-md-panorama {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama:before {
    content: "panorama"; }

.pi-md-panorama-fish-eye {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-fish-eye:before {
    content: "panorama_fish_eye"; }

.pi-md-panorama-horizontal {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-horizontal:before {
    content: "panorama_horizontal"; }

.pi-md-panorama-vertical {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-vertical:before {
    content: "panorama_vertical"; }

.pi-md-panorama-wide-angle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-panorama-wide-angle:before {
    content: "panorama_wide_angle"; }

.pi-md-party-mode {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-party-mode:before {
    content: "party_mode"; }

.pi-md-pause {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pause:before {
    content: "pause"; }

.pi-md-pause-circle-filled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pause-circle-filled:before {
    content: "pause_circle_filled"; }

.pi-md-pause-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pause-circle-outline:before {
    content: "pause_circle_outline"; }

.pi-md-payment {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-payment:before {
    content: "payment"; }

.pi-md-people {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-people:before {
    content: "people"; }

.pi-md-people-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-people-outline:before {
    content: "people_outline"; }

.pi-md-perm-camera-mic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-camera-mic:before {
    content: "perm_camera_mic"; }

.pi-md-perm-contact-calendar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-contact-calendar:before {
    content: "perm_contact_calendar"; }

.pi-md-perm-data-setting {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-data-setting:before {
    content: "perm_data_setting"; }

.pi-md-perm-device-information {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-device-information:before {
    content: "perm_device_information"; }

.pi-md-perm-identity {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-identity:before {
    content: "perm_identity"; }

.pi-md-perm-media {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-media:before {
    content: "perm_media"; }

.pi-md-perm-phone-msg {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-phone-msg:before {
    content: "perm_phone_msg"; }

.pi-md-perm-scan-wifi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-perm-scan-wifi:before {
    content: "perm_scan_wifi"; }

.pi-md-person {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person:before {
    content: "person"; }

.pi-md-person-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-add:before {
    content: "person_add"; }

.pi-md-person-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-outline:before {
    content: "person_outline"; }

.pi-md-person-pin {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-pin:before {
    content: "person_pin"; }

.pi-md-person-pin-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-person-pin-circle:before {
    content: "person_pin_circle"; }

.pi-md-personal-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-personal-video:before {
    content: "personal_video"; }

.pi-md-pets {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pets:before {
    content: "pets"; }

.pi-md-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone:before {
    content: "phone"; }

.pi-md-phone-android {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-android:before {
    content: "phone_android"; }

.pi-md-phone-bluetooth-speaker {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-bluetooth-speaker:before {
    content: "phone_bluetooth_speaker"; }

.pi-md-phone-forwarded {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-forwarded:before {
    content: "phone_forwarded"; }

.pi-md-phone-in-talk {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-in-talk:before {
    content: "phone_in_talk"; }

.pi-md-phone-iphone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-iphone:before {
    content: "phone_iphone"; }

.pi-md-phone-locked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-locked:before {
    content: "phone_locked"; }

.pi-md-phone-missed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-missed:before {
    content: "phone_missed"; }

.pi-md-phone-paused {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phone-paused:before {
    content: "phone_paused"; }

.pi-md-phonelink {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink:before {
    content: "phonelink"; }

.pi-md-phonelink-erase {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-erase:before {
    content: "phonelink_erase"; }

.pi-md-phonelink-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-lock:before {
    content: "phonelink_lock"; }

.pi-md-phonelink-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-off:before {
    content: "phonelink_off"; }

.pi-md-phonelink-ring {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-ring:before {
    content: "phonelink_ring"; }

.pi-md-phonelink-setup {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-phonelink-setup:before {
    content: "phonelink_setup"; }

.pi-md-photo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo:before {
    content: "photo"; }

.pi-md-photo-album {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-album:before {
    content: "photo_album"; }

.pi-md-photo-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-camera:before {
    content: "photo_camera"; }

.pi-md-photo-filter {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-filter:before {
    content: "photo_filter"; }

.pi-md-photo-library {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-library:before {
    content: "photo_library"; }

.pi-md-photo-size-select-actual {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-size-select-actual:before {
    content: "photo_size_select_actual"; }

.pi-md-photo-size-select-large {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-size-select-large:before {
    content: "photo_size_select_large"; }

.pi-md-photo-size-select-small {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-photo-size-select-small:before {
    content: "photo_size_select_small"; }

.pi-md-picture-as-pdf {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-picture-as-pdf:before {
    content: "picture_as_pdf"; }

.pi-md-picture-in-picture {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-picture-in-picture:before {
    content: "picture_in_picture"; }

.pi-md-picture-in-picture-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-picture-in-picture-alt:before {
    content: "picture_in_picture_alt"; }

.pi-md-pie-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pie-chart:before {
    content: "pie_chart"; }

.pi-md-pie-chart-outlined {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pie-chart-outlined:before {
    content: "pie_chart_outlined"; }

.pi-md-pin-drop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pin-drop:before {
    content: "pin_drop"; }

.pi-md-place {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-place:before {
    content: "place"; }

.pi-md-play-arrow {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-arrow:before {
    content: "play_arrow"; }

.pi-md-play-circle-filled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-circle-filled:before {
    content: "play_circle_filled"; }

.pi-md-play-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-circle-outline:before {
    content: "play_circle_outline"; }

.pi-md-play-for-work {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-play-for-work:before {
    content: "play_for_work"; }

.pi-md-playlist-add {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-playlist-add:before {
    content: "playlist_add"; }

.pi-md-playlist-add-check {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-playlist-add-check:before {
    content: "playlist_add_check"; }

.pi-md-playlist-play {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-playlist-play:before {
    content: "playlist_play"; }

.pi-md-plus-one {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-plus-one:before {
    content: "plus_one"; }

.pi-md-poll {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-poll:before {
    content: "poll"; }

.pi-md-polymer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-polymer:before {
    content: "polymer"; }

.pi-md-pool {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pool:before {
    content: "pool"; }

.pi-md-portable-wifi-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-portable-wifi-off:before {
    content: "portable_wifi_off"; }

.pi-md-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-portrait:before {
    content: "portrait"; }

.pi-md-power {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-power:before {
    content: "power"; }

.pi-md-power-input {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-power-input:before {
    content: "power_input"; }

.pi-md-power-settings-new {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-power-settings-new:before {
    content: "power_settings_new"; }

.pi-md-pregnant-woman {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-pregnant-woman:before {
    content: "pregnant_woman"; }

.pi-md-present-to-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-present-to-all:before {
    content: "present_to_all"; }

.pi-md-print {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-print:before {
    content: "print"; }

.pi-md-priority-high {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-priority-high:before {
    content: "priority_high"; }

.pi-md-public {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-public:before {
    content: "public"; }

.pi-md-publish {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-publish:before {
    content: "publish"; }

.pi-md-query-builder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-query-builder:before {
    content: "query_builder"; }

.pi-md-question-answer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-question-answer:before {
    content: "question_answer"; }

.pi-md-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-queue:before {
    content: "queue"; }

.pi-md-queue-music {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-queue-music:before {
    content: "queue_music"; }

.pi-md-queue-play-next {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-queue-play-next:before {
    content: "queue_play_next"; }

.pi-md-radio {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio:before {
    content: "radio"; }

.pi-md-radio-button-checked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-button-checked:before {
    content: "radio_button_checked"; }

.pi-md-radio-button-unchecked {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-radio-button-unchecked:before {
    content: "radio_button_unchecked"; }

.pi-md-rate-review {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rate-review:before {
    content: "rate_review"; }

.pi-md-receipt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-receipt:before {
    content: "receipt"; }

.pi-md-recent-actors {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-recent-actors:before {
    content: "recent_actors"; }

.pi-md-record-voice-over {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-record-voice-over:before {
    content: "record_voice_over"; }

.pi-md-redeem {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-redeem:before {
    content: "redeem"; }

.pi-md-redo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-redo:before {
    content: "redo"; }

.pi-md-refresh {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-refresh:before {
    content: "refresh"; }

.pi-md-remove {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove:before {
    content: "remove"; }

.pi-md-remove-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-circle:before {
    content: "remove_circle"; }

.pi-md-remove-circle-outline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-circle-outline:before {
    content: "remove_circle_outline"; }

.pi-md-remove-from-queue {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-from-queue:before {
    content: "remove_from_queue"; }

.pi-md-remove-red-eye {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-red-eye:before {
    content: "remove_red_eye"; }

.pi-md-remove-shopping-cart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-remove-shopping-cart:before {
    content: "remove_shopping_cart"; }

.pi-md-reorder {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-reorder:before {
    content: "reorder"; }

.pi-md-repeat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-repeat:before {
    content: "repeat"; }

.pi-md-repeat-one {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-repeat-one:before {
    content: "repeat_one"; }

.pi-md-replay {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay:before {
    content: "replay"; }

.pi-md-replay-10 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay-10:before {
    content: "replay_10"; }

.pi-md-replay-30 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay-30:before {
    content: "replay_30"; }

.pi-md-replay-5 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-replay-5:before {
    content: "replay_5"; }

.pi-md-reply {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-reply:before {
    content: "reply"; }

.pi-md-reply-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-reply-all:before {
    content: "reply_all"; }

.pi-md-report {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-report:before {
    content: "report"; }

.pi-md-report-problem {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-report-problem:before {
    content: "report_problem"; }

.pi-md-restaurant {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restaurant:before {
    content: "restaurant"; }

.pi-md-restaurant-menu {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restaurant-menu:before {
    content: "restaurant_menu"; }

.pi-md-restore {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restore:before {
    content: "restore"; }

.pi-md-restore-page {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-restore-page:before {
    content: "restore_page"; }

.pi-md-ring-volume {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-ring-volume:before {
    content: "ring_volume"; }

.pi-md-room {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-room:before {
    content: "room"; }

.pi-md-room-service {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-room-service:before {
    content: "room_service"; }

.pi-md-rotate-90-degrees-ccw {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rotate-90-degrees-ccw:before {
    content: "rotate_90_degrees_ccw"; }

.pi-md-rotate-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rotate-left:before {
    content: "rotate_left"; }

.pi-md-rotate-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rotate-right:before {
    content: "rotate_right"; }

.pi-md-rounded-corner {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rounded-corner:before {
    content: "rounded_corner"; }

.pi-md-router {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-router:before {
    content: "router"; }

.pi-md-rowing {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rowing:before {
    content: "rowing"; }

.pi-md-rss-feed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rss-feed:before {
    content: "rss_feed"; }

.pi-md-rv-hookup {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-rv-hookup:before {
    content: "rv_hookup"; }

.pi-md-satellite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-satellite:before {
    content: "satellite"; }

.pi-md-save {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-save:before {
    content: "save"; }

.pi-md-scanner {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-scanner:before {
    content: "scanner"; }

.pi-md-schedule {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-schedule:before {
    content: "schedule"; }

.pi-md-school {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-school:before {
    content: "school"; }

.pi-md-screen-lock-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-lock-landscape:before {
    content: "screen_lock_landscape"; }

.pi-md-screen-lock-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-lock-portrait:before {
    content: "screen_lock_portrait"; }

.pi-md-screen-lock-rotation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-lock-rotation:before {
    content: "screen_lock_rotation"; }

.pi-md-screen-rotation {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-rotation:before {
    content: "screen_rotation"; }

.pi-md-screen-share {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-screen-share:before {
    content: "screen_share"; }

.pi-md-sd-card {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sd-card:before {
    content: "sd_card"; }

.pi-md-sd-storage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sd-storage:before {
    content: "sd_storage"; }

.pi-md-search {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-search:before {
    content: "search"; }

.pi-md-security {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-security:before {
    content: "security"; }

.pi-md-select-all {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-select-all:before {
    content: "select_all"; }

.pi-md-send {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-send:before {
    content: "send"; }

.pi-md-sentiment-dissatisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-dissatisfied:before {
    content: "sentiment_dissatisfied"; }

.pi-md-sentiment-neutral {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-neutral:before {
    content: "sentiment_neutral"; }

.pi-md-sentiment-satisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-satisfied:before {
    content: "sentiment_satisfied"; }

.pi-md-sentiment-very-dissatisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-very-dissatisfied:before {
    content: "sentiment_very_dissatisfied"; }

.pi-md-sentiment-very-satisfied {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sentiment-very-satisfied:before {
    content: "sentiment_very_satisfied"; }

.pi-md-settings {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings:before {
    content: "settings"; }

.pi-md-settings-applications {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-applications:before {
    content: "settings_applications"; }

.pi-md-settings-backup-restore {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-backup-restore:before {
    content: "settings_backup_restore"; }

.pi-md-settings-bluetooth {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-bluetooth:before {
    content: "settings_bluetooth"; }

.pi-md-settings-brightness {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-brightness:before {
    content: "settings_brightness"; }

.pi-md-settings-cell {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-cell:before {
    content: "settings_cell"; }

.pi-md-settings-ethernet {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-ethernet:before {
    content: "settings_ethernet"; }

.pi-md-settings-input-antenna {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-antenna:before {
    content: "settings_input_antenna"; }

.pi-md-settings-input-component {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-component:before {
    content: "settings_input_component"; }

.pi-md-settings-input-composite {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-composite:before {
    content: "settings_input_composite"; }

.pi-md-settings-input-hdmi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-hdmi:before {
    content: "settings_input_hdmi"; }

.pi-md-settings-input-svideo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-input-svideo:before {
    content: "settings_input_svideo"; }

.pi-md-settings-overscan {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-overscan:before {
    content: "settings_overscan"; }

.pi-md-settings-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-phone:before {
    content: "settings_phone"; }

.pi-md-settings-power {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-power:before {
    content: "settings_power"; }

.pi-md-settings-remote {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-remote:before {
    content: "settings_remote"; }

.pi-md-settings-system-daydream {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-system-daydream:before {
    content: "settings_system_daydream"; }

.pi-md-settings-voice {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-settings-voice:before {
    content: "settings_voice"; }

.pi-md-share {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-share:before {
    content: "share"; }

.pi-md-shop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shop:before {
    content: "shop"; }

.pi-md-shop-two {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shop-two:before {
    content: "shop_two"; }

.pi-md-shopping-basket {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shopping-basket:before {
    content: "shopping_basket"; }

.pi-md-shopping-cart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shopping-cart:before {
    content: "shopping_cart"; }

.pi-md-short-text {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-short-text:before {
    content: "short_text"; }

.pi-md-show-chart {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-show-chart:before {
    content: "show_chart"; }

.pi-md-shuffle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-shuffle:before {
    content: "shuffle"; }

.pi-md-signal-cellular-4-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-4-bar:before {
    content: "signal_cellular_4_bar"; }

.pi-md-signal-cellular-connected-no-internet-4-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-connected-no-internet-4-bar:before {
    content: "signal_cellular_connected_no_internet_4_bar"; }

.pi-md-signal-cellular-no-sim {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-no-sim:before {
    content: "signal_cellular_no_sim"; }

.pi-md-signal-cellular-null {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-null:before {
    content: "signal_cellular_null"; }

.pi-md-signal-cellular-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-cellular-off:before {
    content: "signal_cellular_off"; }

.pi-md-signal-wifi-4-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-wifi-4-bar:before {
    content: "signal_wifi_4_bar"; }

.pi-md-signal-wifi-4-bar-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-wifi-4-bar-lock:before {
    content: "signal_wifi_4_bar_lock"; }

.pi-md-signal-wifi-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-signal-wifi-off:before {
    content: "signal_wifi_off"; }

.pi-md-sim-card {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sim-card:before {
    content: "sim_card"; }

.pi-md-sim-card-alert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sim-card-alert:before {
    content: "sim_card_alert"; }

.pi-md-skip-next {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-skip-next:before {
    content: "skip_next"; }

.pi-md-skip-previous {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-skip-previous:before {
    content: "skip_previous"; }

.pi-md-slideshow {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-slideshow:before {
    content: "slideshow"; }

.pi-md-slow-motion-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-slow-motion-video:before {
    content: "slow_motion_video"; }

.pi-md-smartphone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-smartphone:before {
    content: "smartphone"; }

.pi-md-smoke-free {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-smoke-free:before {
    content: "smoke_free"; }

.pi-md-smoking-rooms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-smoking-rooms:before {
    content: "smoking_rooms"; }

.pi-md-sms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sms:before {
    content: "sms"; }

.pi-md-sms-failed {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sms-failed:before {
    content: "sms_failed"; }

.pi-md-snooze {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-snooze:before {
    content: "snooze"; }

.pi-md-sort {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sort:before {
    content: "sort"; }

.pi-md-sort-by-alpha {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sort-by-alpha:before {
    content: "sort_by_alpha"; }

.pi-md-spa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-spa:before {
    content: "spa"; }

.pi-md-space-bar {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-space-bar:before {
    content: "space_bar"; }

.pi-md-speaker {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker:before {
    content: "speaker"; }

.pi-md-speaker-group {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-group:before {
    content: "speaker_group"; }

.pi-md-speaker-notes {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-notes:before {
    content: "speaker_notes"; }

.pi-md-speaker-notes-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-notes-off:before {
    content: "speaker_notes_off"; }

.pi-md-speaker-phone {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-speaker-phone:before {
    content: "speaker_phone"; }

.pi-md-spellcheck {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-spellcheck:before {
    content: "spellcheck"; }

.pi-md-star {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-star:before {
    content: "star"; }

.pi-md-star-border {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-star-border:before {
    content: "star_border"; }

.pi-md-star-half {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-star-half:before {
    content: "star_half"; }

.pi-md-stars {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stars:before {
    content: "stars"; }

.pi-md-stay-current-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-current-landscape:before {
    content: "stay_current_landscape"; }

.pi-md-stay-current-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-current-portrait:before {
    content: "stay_current_portrait"; }

.pi-md-stay-primary-landscape {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-primary-landscape:before {
    content: "stay_primary_landscape"; }

.pi-md-stay-primary-portrait {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stay-primary-portrait:before {
    content: "stay_primary_portrait"; }

.pi-md-stop {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stop:before {
    content: "stop"; }

.pi-md-stop-screen-share {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-stop-screen-share:before {
    content: "stop_screen_share"; }

.pi-md-storage {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-storage:before {
    content: "storage"; }

.pi-md-store {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-store:before {
    content: "store"; }

.pi-md-store-mall-directory {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-store-mall-directory:before {
    content: "store_mall_directory"; }

.pi-md-straighten {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-straighten:before {
    content: "straighten"; }

.pi-md-streetview {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-streetview:before {
    content: "streetview"; }

.pi-md-strikethrough-s {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-strikethrough-s:before {
    content: "strikethrough_s"; }

.pi-md-style {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-style:before {
    content: "style"; }

.pi-md-subdirectory-arrow-left {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subdirectory-arrow-left:before {
    content: "subdirectory_arrow_left"; }

.pi-md-subdirectory-arrow-right {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subdirectory-arrow-right:before {
    content: "subdirectory_arrow_right"; }

.pi-md-subject {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subject:before {
    content: "subject"; }

.pi-md-subscriptions {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subscriptions:before {
    content: "subscriptions"; }

.pi-md-subtitles {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subtitles:before {
    content: "subtitles"; }

.pi-md-subway {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-subway:before {
    content: "subway"; }

.pi-md-supervisor-account {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-supervisor-account:before {
    content: "supervisor_account"; }

.pi-md-surround-sound {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-surround-sound:before {
    content: "surround_sound"; }

.pi-md-swap-calls {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-calls:before {
    content: "swap_calls"; }

.pi-md-swap-horiz {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-horiz:before {
    content: "swap_horiz"; }

.pi-md-swap-vert {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-vert:before {
    content: "swap_vert"; }

.pi-md-swap-vertical-circle {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-swap-vertical-circle:before {
    content: "swap_vertical_circle"; }

.pi-md-switch-camera {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-switch-camera:before {
    content: "switch_camera"; }

.pi-md-switch-video {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-switch-video:before {
    content: "switch_video"; }

.pi-md-sync {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sync:before {
    content: "sync"; }

.pi-md-sync-disabled {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sync-disabled:before {
    content: "sync_disabled"; }

.pi-md-sync-problem {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-sync-problem:before {
    content: "sync_problem"; }

.pi-md-system-update {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-system-update:before {
    content: "system_update"; }

.pi-md-system-update-alt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-system-update-alt:before {
    content: "system_update_alt"; }

.pi-md-tab {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tab:before {
    content: "tab"; }

.pi-md-tab-unselected {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tab-unselected:before {
    content: "tab_unselected"; }

.pi-md-tablet {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tablet:before {
    content: "tablet"; }

.pi-md-tablet-android {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tablet-android:before {
    content: "tablet_android"; }

.pi-md-tablet-mac {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tablet-mac:before {
    content: "tablet_mac"; }

.pi-md-tag-faces {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tag-faces:before {
    content: "tag_faces"; }

.pi-md-tap-and-play {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tap-and-play:before {
    content: "tap_and_play"; }

.pi-md-terrain {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-terrain:before {
    content: "terrain"; }

.pi-md-text-fields {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-text-fields:before {
    content: "text_fields"; }

.pi-md-text-format {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-text-format:before {
    content: "text_format"; }

.pi-md-textsms {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-textsms:before {
    content: "textsms"; }

.pi-md-texture {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-texture:before {
    content: "texture"; }

.pi-md-theaters {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-theaters:before {
    content: "theaters"; }

.pi-md-thumb-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-thumb-down:before {
    content: "thumb_down"; }

.pi-md-thumb-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-thumb-up:before {
    content: "thumb_up"; }

.pi-md-thumbs-up-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-thumbs-up-down:before {
    content: "thumbs_up_down"; }

.pi-md-time-to-leave {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-time-to-leave:before {
    content: "time_to_leave"; }

.pi-md-timelapse {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timelapse:before {
    content: "timelapse"; }

.pi-md-timeline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timeline:before {
    content: "timeline"; }

.pi-md-timer {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer:before {
    content: "timer"; }

.pi-md-timer-10 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer-10:before {
    content: "timer_10"; }

.pi-md-timer-3 {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer-3:before {
    content: "timer_3"; }

.pi-md-timer-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-timer-off:before {
    content: "timer_off"; }

.pi-md-title {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-title:before {
    content: "title"; }

.pi-md-toc {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-toc:before {
    content: "toc"; }

.pi-md-today {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-today:before {
    content: "today"; }

.pi-md-toll {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-toll:before {
    content: "toll"; }

.pi-md-tonality {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tonality:before {
    content: "tonality"; }

.pi-md-touch-app {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-touch-app:before {
    content: "touch_app"; }

.pi-md-toys {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-toys:before {
    content: "toys"; }

.pi-md-track-changes {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-track-changes:before {
    content: "track_changes"; }

.pi-md-traffic {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-traffic:before {
    content: "traffic"; }

.pi-md-train {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-train:before {
    content: "train"; }

.pi-md-tram {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tram:before {
    content: "tram"; }

.pi-md-transfer-within-a-station {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-transfer-within-a-station:before {
    content: "transfer_within_a_station"; }

.pi-md-transform {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-transform:before {
    content: "transform"; }

.pi-md-translate {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-translate:before {
    content: "translate"; }

.pi-md-trending-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trending-down:before {
    content: "trending_down"; }

.pi-md-trending-flat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trending-flat:before {
    content: "trending_flat"; }

.pi-md-trending-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-trending-up:before {
    content: "trending_up"; }

.pi-md-tune {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tune:before {
    content: "tune"; }

.pi-md-turned-in {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-turned-in:before {
    content: "turned_in"; }

.pi-md-turned-in-not {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-turned-in-not:before {
    content: "turned_in_not"; }

.pi-md-tv {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-tv:before {
    content: "tv"; }

.pi-md-unarchive {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-unarchive:before {
    content: "unarchive"; }

.pi-md-undo {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-undo:before {
    content: "undo"; }

.pi-md-unfold-less {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-unfold-less:before {
    content: "unfold_less"; }

.pi-md-unfold-more {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-unfold-more:before {
    content: "unfold_more"; }

.pi-md-update {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-update:before {
    content: "update"; }

.pi-md-usb {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-usb:before {
    content: "usb"; }

.pi-md-verified-user {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-verified-user:before {
    content: "verified_user"; }

.pi-md-vertical-align-bottom {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vertical-align-bottom:before {
    content: "vertical_align_bottom"; }

.pi-md-vertical-align-center {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vertical-align-center:before {
    content: "vertical_align_center"; }

.pi-md-vertical-align-top {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vertical-align-top:before {
    content: "vertical_align_top"; }

.pi-md-vibration {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vibration:before {
    content: "vibration"; }

.pi-md-video-call {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video-call:before {
    content: "video_call"; }

.pi-md-video-label {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video-label:before {
    content: "video_label"; }

.pi-md-video-library {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-video-library:before {
    content: "video_library"; }

.pi-md-videocam {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-videocam:before {
    content: "videocam"; }

.pi-md-videocam-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-videocam-off:before {
    content: "videocam_off"; }

.pi-md-videogame-asset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-videogame-asset:before {
    content: "videogame_asset"; }

.pi-md-view-agenda {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-agenda:before {
    content: "view_agenda"; }

.pi-md-view-array {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-array:before {
    content: "view_array"; }

.pi-md-view-carousel {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-carousel:before {
    content: "view_carousel"; }

.pi-md-view-column {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-column:before {
    content: "view_column"; }

.pi-md-view-comfy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-comfy:before {
    content: "view_comfy"; }

.pi-md-view-compact {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-compact:before {
    content: "view_compact"; }

.pi-md-view-day {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-day:before {
    content: "view_day"; }

.pi-md-view-headline {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-headline:before {
    content: "view_headline"; }

.pi-md-view-list {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-list:before {
    content: "view_list"; }

.pi-md-view-module {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-module:before {
    content: "view_module"; }

.pi-md-view-quilt {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-quilt:before {
    content: "view_quilt"; }

.pi-md-view-stream {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-stream:before {
    content: "view_stream"; }

.pi-md-view-week {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-view-week:before {
    content: "view_week"; }

.pi-md-vignette {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vignette:before {
    content: "vignette"; }

.pi-md-visibility {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-visibility:before {
    content: "visibility"; }

.pi-md-visibility-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-visibility-off:before {
    content: "visibility_off"; }

.pi-md-voice-chat {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-voice-chat:before {
    content: "voice_chat"; }

.pi-md-voicemail {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-voicemail:before {
    content: "voicemail"; }

.pi-md-volume-down {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-down:before {
    content: "volume_down"; }

.pi-md-volume-mute {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-mute:before {
    content: "volume_mute"; }

.pi-md-volume-off {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-off:before {
    content: "volume_off"; }

.pi-md-volume-up {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-volume-up:before {
    content: "volume_up"; }

.pi-md-vpn-key {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vpn-key:before {
    content: "vpn_key"; }

.pi-md-vpn-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-vpn-lock:before {
    content: "vpn_lock"; }

.pi-md-wallpaper {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wallpaper:before {
    content: "wallpaper"; }

.pi-md-warning {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-warning:before {
    content: "warning"; }

.pi-md-watch {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-watch:before {
    content: "watch"; }

.pi-md-watch-later {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-watch-later:before {
    content: "watch_later"; }

.pi-md-wb-auto {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-auto:before {
    content: "wb_auto"; }

.pi-md-wb-cloudy {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-cloudy:before {
    content: "wb_cloudy"; }

.pi-md-wb-incandescent {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-incandescent:before {
    content: "wb_incandescent"; }

.pi-md-wb-iridescent {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-iridescent:before {
    content: "wb_iridescent"; }

.pi-md-wb-sunny {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wb-sunny:before {
    content: "wb_sunny"; }

.pi-md-wc {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wc:before {
    content: "wc"; }

.pi-md-web {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-web:before {
    content: "web"; }

.pi-md-web-asset {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-web-asset:before {
    content: "web_asset"; }

.pi-md-weekend {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-weekend:before {
    content: "weekend"; }

.pi-md-whatshot {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-whatshot:before {
    content: "whatshot"; }

.pi-md-widgets {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-widgets:before {
    content: "widgets"; }

.pi-md-wifi {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wifi:before {
    content: "wifi"; }

.pi-md-wifi-lock {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wifi-lock:before {
    content: "wifi_lock"; }

.pi-md-wifi-tethering {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wifi-tethering:before {
    content: "wifi_tethering"; }

.pi-md-work {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-work:before {
    content: "work"; }

.pi-md-wrap-text {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-wrap-text:before {
    content: "wrap_text"; }

.pi-md-youtube-searched-for {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-youtube-searched-for:before {
    content: "youtube_searched_for"; }

.pi-md-zoom-in {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-zoom-in:before {
    content: "zoom_in"; }

.pi-md-zoom-out {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-zoom-out:before {
    content: "zoom_out"; }

.pi-md-zoom-out-map {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-indent: 0;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
  .pi-md-zoom-out-map:before {
    content: "zoom_out_map"; }

.material-icons, .fa {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5em;
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

body .p-component {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  text-decoration: none; }

body .p-widget-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }

body .p-widget-header {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .p-widget-header .pi {
    color: #ffffff; }

body .p-highlight {
  background-color: #4699ce;
  color: #ffffff; }

body .p-component:disabled,
body .p-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: auto !important; }

body .p-component-overlay {
  background-color: #424242;
  opacity: 0.8;
  filter: alpha(opacity=80); }

body a {
  color: #00529e; }

body {
  /* InputGroup */
  /* Validation */ }
  body .p-inputtext {
    background: transparent no-repeat;
    background-image: linear-gradient(to bottom, #00529e, #00529e), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    border-width: 0;
    padding: 2px;
    font-size: 14px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px; }
    body .p-inputtext:focus, body .p-inputtext.p-focus {
      border-width: 0;
      background-size: 100% 2px, 100% 1px;
      padding-bottom: 2px; }
    body .p-inputtext:disabled {
      border-bottom: 1px dotted; }
    body .p-inputtext.p-widget-content {
      border-width: 1px;
      background: transparent;
      background-image: none; }
    body .p-inputtext.p-error {
      background-image: linear-gradient(to bottom, #e62a10, #e62a10), linear-gradient(to bottom, #e62a10, #e62a10); }
  body .p-multiselect-filter-container .p-inputtext, body .p-listbox-filter-container .p-inputtext, body .p-dropdown-filter-container .p-inputtext, body .p-datatable-header .p-inputtext {
    background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, #1f93ff, #1f93ff); }
  body .md-inputfield {
    display: block;
    position: relative; }
    body .md-inputfield label {
      color: #999999;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 5px;
      top: 1px;
      transition: 0.3s ease all;
      -moz-transition: 0.3s ease all;
      -webkit-transition: 0.3s ease all; }
    body .md-inputfield input:focus ~ label,
    body .md-inputfield input.p-filled ~ label,
    body .md-inputfield .p-inputwrapper-focus ~ label,
    body .md-inputfield .p-inputwrapper-filled ~ label {
      top: -20px;
      font-size: 12px;
      color: #00529e; }
    body .md-inputfield .input:-webkit-autofill ~ label {
      top: -20px;
      font-size: 12px;
      color: #00529e; }
    body .md-inputfield input.ng-dirty.ng-invalid ~ label {
      color: #e62a10; }
    body .md-inputfield .p-message.p-messages-error {
      background-color: transparent;
      border: 0 none;
      margin: 0px;
      color: #e62a10;
      font-size: .75em; }
    body .md-inputfield.md-inputfield-fill input {
      background-color: #f7f7f7;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 4px; }
    body .md-inputfield.md-inputfield-fill label {
      top: 2px; }
    body .md-inputfield.md-inputfield-box input {
      background-color: #f7f7f7;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: 24px; }
    body .md-inputfield.md-inputfield-box label {
      top: 23px; }
    body .md-inputfield.md-inputfield-box input:focus ~ label,
    body .md-inputfield.md-inputfield-box input.p-filled ~ label,
    body .md-inputfield.md-inputfield-box .p-inputwrapper-focus ~ label,
    body .md-inputfield.md-inputfield-box .p-inputwrapper-filled ~ label {
      top: 1px; }
    body .md-inputfield.md-inputfield-box .input:-webkit-autofill ~ label {
      top: 1px; }
  body .p-material-message.p-message.p-messages-error {
    background-color: transparent;
    border: 0 none;
    margin: 0px;
    color: #e62a10;
    font-size: .75em; }
  body .p-listbox {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    min-width: 12.5em;
    background-color: #ffffff;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-listbox.p-inputtext {
      padding: 0; }
    body .p-listbox .p-listbox-list {
      padding: 0; }
    body .p-listbox .p-listbox-item {
      font-size: 14px;
      padding: 0.625em 0.875em;
      margin: 0;
      color: #212121;
      position: relative;
      overflow: hidden;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-listbox .p-listbox-item.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
    body .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-listbox .p-listbox-header {
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      padding: 0.625em 1em; }
      body .p-listbox .p-listbox-header .pi {
        color: #ffffff; }
      body .p-listbox .p-listbox-header .p-checkbox .p-checkbox-box {
        border-color: #ffffff; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container .p-inputtext {
        padding: 2px;
        padding-left: 1.5em; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container .pi {
        top: 0;
        left: 0; }
  body .p-button {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
    background-color: #00529e;
    color: #ffffff;
    font-size: 1em;
    height: 2.25em;
    padding: 0 1em;
    border: 0 none;
    -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    vertical-align: middle; }
    body .p-button:enabled:not(:focus):hover {
      background-color: #0f2765; }
    body .p-button:focus {
      outline: 0 none;
      background-color: #006cd1; }
    body .p-button .p-button-text {
      padding: 0;
      line-height: 2.25em; }
    body .p-button.p-button-icon-only {
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      width: 2.5em;
      height: 2.5em; }
      body .p-button.p-button-icon-only .pi {
        margin-left: -.5em; }
    body .p-button.p-button-text-icon-left {
      padding-left: 2.5em; }
    body .p-button.p-button-text-icon-right {
      padding-right: 2.5em; }
    body .p-button.secondary-btn {
      background-color: #4699ce;
      color: #ffffff; }
      body .p-button.secondary-btn:enabled:not(:focus):hover {
        background-color: #274d8d; }
      body .p-button.secondary-btn:focus {
        outline: 0 none;
        background-color: #6eafd9; }
    body .p-button.blue-grey-btn {
      background-color: #607D8B; }
      body .p-button.blue-grey-btn:enabled:not(:focus):hover {
        background-color: #37474F; }
      body .p-button.blue-grey-btn:focus {
        outline: 0 none;
        background-color: #7b96a3; }
    body .p-button.cyan-btn {
      background-color: #00BCD4; }
      body .p-button.cyan-btn:enabled:not(:focus):hover {
        background-color: #00838F; }
      body .p-button.cyan-btn:focus {
        outline: 0 none;
        background-color: #08e3ff; }
    body .p-button.teal-btn {
      background-color: #009688; }
      body .p-button.teal-btn:enabled:not(:focus):hover {
        background-color: #009688; }
      body .p-button.teal-btn:focus {
        outline: 0 none;
        background-color: #00c9b6; }
    body .p-button.red-btn {
      background-color: #F44336; }
      body .p-button.red-btn:enabled:not(:focus):hover {
        background-color: #C62828; }
      body .p-button.red-btn:focus {
        outline: 0 none;
        background-color: #f77066; }
    body .p-button.green-btn {
      background-color: #4CAF50; }
      body .p-button.green-btn:enabled:not(:focus):hover {
        background-color: #2E7D32; }
      body .p-button.green-btn:focus {
        outline: 0 none;
        background-color: #6ec071; }
    body .p-button.deep-orange-btn {
      background-color: #FF5722; }
      body .p-button.deep-orange-btn:enabled:not(:focus):hover {
        background-color: #D84315; }
      body .p-button.deep-orange-btn:focus {
        outline: 0 none;
        background-color: #ff7e55; }
    body .p-button.purple-btn {
      background-color: #673AB7; }
      body .p-button.purple-btn:enabled:not(:focus):hover {
        background-color: #4527A0; }
      body .p-button.purple-btn:focus {
        outline: 0 none;
        background-color: #8259cb; }
    body .p-button.pink-btn {
      background-color: #E91E63; }
      body .p-button.pink-btn:enabled:not(:focus):hover {
        background-color: #AD1457; }
      body .p-button.pink-btn:focus {
        outline: 0 none;
        background-color: #ee4c83; }
    body .p-button.amber-btn {
      background-color: #FFC107;
      color: #212121; }
      body .p-button.amber-btn:enabled:not(:focus):hover {
        background-color: #FF8F00; }
      body .p-button.amber-btn:focus {
        outline: 0 none;
        background-color: #ffce3a; }
    body .p-button.orange-btn {
      background-color: #FF9800; }
      body .p-button.orange-btn:enabled:not(:focus):hover {
        background-color: #EF6C00; }
      body .p-button.orange-btn:focus {
        outline: 0 none;
        background-color: #ffad33; }
    body .p-button.brown-btn {
      background-color: #795548; }
      body .p-button.brown-btn:enabled:not(:focus):hover {
        background-color: #4E342E; }
      body .p-button.brown-btn:focus {
        outline: 0 none;
        background-color: #996b5b; }
    body .p-button.indigo-btn {
      background-color: #3F51B5; }
      body .p-button.indigo-btn:enabled:not(:focus):hover {
        background-color: #283593; }
      body .p-button.indigo-btn:focus {
        outline: 0 none;
        background-color: #606fc7; }
    body .p-button.blue-btn, body .p-button.ui-info-button {
      background-color: #03A9F4;
      color: #ffffff; }
      body .p-button.blue-btn:enabled:not(:focus):hover, body .p-button.ui-info-button:enabled:not(:focus):hover {
        background-color: #0277BD; }
      body .p-button.blue-btn:focus, body .p-button.ui-info-button:focus {
        outline: 0 none;
        background-color: #2ebcfc; }
    body .p-button.flat-btn {
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none; }
  body .p-button.p-button-secondary, body .p-buttonset.p-button-secondary > .p-button {
    color: #212121;
    background-color: #ffffff; }
    body .p-button.p-button-secondary .p-button-icon-left, body .p-buttonset.p-button-secondary > .p-button .p-button-icon-left {
      color: #757575; }
    body .p-button.p-button-secondary .p-button-icon-right, body .p-buttonset.p-button-secondary > .p-button .p-button-icon-right {
      color: #757575; }
    body .p-button.p-button-secondary:enabled:hover, body .p-buttonset.p-button-secondary > .p-button:enabled:hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-button.p-button-secondary:enabled:focus, body .p-buttonset.p-button-secondary > .p-button:enabled:focus {
      background-color: #e6e6e6; }
  body .p-button.p-button-info, body .p-buttonset.p-button-info > .p-button {
    color: #ffffff;
    background-color: #03A9F4; }
    body .p-button.p-button-info:enabled:hover, body .p-buttonset.p-button-info > .p-button:enabled:hover {
      background-color: #0277BD; }
    body .p-button.p-button-info:enabled:focus, body .p-buttonset.p-button-info > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #2ebcfc; }
  body .p-button.p-button-success, body .p-buttonset.p-button-success > .p-button {
    color: #ffffff;
    background-color: #4CAF50; }
    body .p-button.p-button-success:enabled:hover, body .p-buttonset.p-button-success > .p-button:enabled:hover {
      background-color: #2E7D32; }
    body .p-button.p-button-success:enabled:focus, body .p-buttonset.p-button-success > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #6ec071; }
  body .p-button.p-button-warning, body .p-buttonset.p-button-warning > .p-button {
    color: #212121;
    background-color: #FFC107; }
    body .p-button.p-button-warning:enabled:hover, body .p-buttonset.p-button-warning > .p-button:enabled:hover {
      background-color: #FF8F00; }
    body .p-button.p-button-warning:enabled:focus, body .p-buttonset.p-button-warning > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #ffce3a; }
  body .p-button.p-button-danger, body .p-buttonset.p-button-danger > .p-button {
    color: #ffffff;
    background-color: #F44336; }
    body .p-button.p-button-danger:enabled:hover, body .p-buttonset.p-button-danger > .p-button:enabled:hover {
      background-color: #C62828; }
    body .p-button.p-button-danger:enabled:focus, body .p-buttonset.p-button-danger > .p-button:enabled:focus {
      outline: 0 none;
      background-color: #f77066; }
  body .p-buttonset .p-button {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-buttonset .p-button:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    body .p-buttonset .p-button:last-child {
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
    body .p-buttonset .p-button:not(.p-disabled):not(.p-highlight):hover {
      background-color: #0f2765; }
    body .p-buttonset .p-button.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }
  body .p-togglebutton:not(.p-highlight):not(.p-disabled):hover {
    background-color: #0f2765; }
  body .p-togglebutton.p-highlight {
    background-color: #4699ce;
    color: #ffffff; }
  body .p-splitbutton {
    line-height: 18px;
    vertical-align: middle;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none; }
    body .p-splitbutton > .p-button:first-child {
      vertical-align: top;
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px;
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px;
      -moz-border-radius-topright: 0;
      -webkit-border-top-right-radius: 0;
      border-top-right-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
    body .p-splitbutton > .p-button:active {
      background-color: #006cd1; }
    body .p-splitbutton > .p-button.p-splitbutton-menubutton {
      height: 2.25em;
      width: 2.5em;
      -moz-border-radius-topleft: 0;
      -webkit-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
  body .p-fluid .p-splitbutton .p-button:first-child {
    width: calc(100% - 2.5em); }
  body .p-fluid .p-splitbutton .p-button.p-splitbutton-menubutton {
    width: 2.5em; }
  body .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.25em;
    width: 1.25em;
    height: 1.25em;
    cursor: default;
    margin-right: .5em; }
    body .p-checkbox .p-checkbox-box {
      border: 2px solid #757575;
      width: 1.25em;
      height: 1.25em;
      overflow: hidden;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      border-radius: 2px;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      body .p-checkbox .p-checkbox-box .p-checkbox-icon {
        font-size: 1.25em;
        margin-left: -2px;
        margin-top: -2px; }
      body .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #00529e;
        background-color: #00529e; }
      body .p-checkbox .p-checkbox-box.p-focus {
        border-color: #00529e;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-transition: box-shadow 0.3s, background-color 0.3s;
        -o-transition: box-shadow 0.3s, background-color 0.3s;
        -webkit-transition: box-shadow 0.3s, background-color 0.3s;
        transition: box-shadow 0.3s, background-color 0.3s; }
  body .p-radiobutton {
    position: relative;
    margin: 0 .5em 0 0;
    vertical-align: middle; }
    body .p-radiobutton .p-radiobutton-box {
      border: 2px solid #757575;
      width: 1.286em;
      height: 1.286em;
      -moz-transition: box-shadow 0.3s;
      -o-transition: box-shadow 0.3s;
      -webkit-transition: box-shadow 0.3s;
      transition: box-shadow 0.3s;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; }
      body .p-radiobutton .p-radiobutton-box.p-focus {
        border-color: #00529e;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
        -moz-transition: box-shadow 0.3s, background-color 0.3s;
        -o-transition: box-shadow 0.3s, background-color 0.3s;
        -webkit-transition: box-shadow 0.3s, background-color 0.3s;
        transition: box-shadow 0.3s, background-color 0.3s; }
      body .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: #00529e;
        background-color: transparent; }
      body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 1em;
        transition: -webkit-transform ease .28s;
        transition: transform ease .28s;
        -webkit-transform: scale(0);
        transform: scale(0); }
      body .p-radiobutton .p-radiobutton-box .pi-circle-on {
        background-color: #00529e;
        -webkit-transform: scale(0.5);
        transform: scale(0.5); }
        body .p-radiobutton .p-radiobutton-box .pi-circle-on.pi:before {
          content: "circle"; }
  body .p-autocomplete-panel {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-autocomplete-panel.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-autocomplete-panel .p-autocomplete-list {
      padding: 0; }
      body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item {
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0;
        padding: 0.625em 1em;
        color: #212121; }
        body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item .p-autocomplete-query {
          font-weight: 700; }
        body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item:hover, body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-list-item.p-highlight {
          background-color: #4699ce;
          color: #ffffff; }
      body .p-autocomplete-panel .p-autocomplete-list .p-autocomplete-group {
        padding: 0.625em 1em; }
  body .p-autocomplete .p-autocomplete-loader {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 1000ms;
    -webkit-animation-name: spin;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-delay: 0;
    -moz-animation-duration: 1000ms;
    -moz-animation-name: spin;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-delay: 0;
    animation-duration: 1000ms;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: #757575;
    right: 0; }
    body .p-autocomplete .p-autocomplete-loader:before {
      content: "refresh"; }
  body .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 1em; }
  body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only {
    background-color: transparent;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    height: 1.5em;
    width: 1.5em;
    padding: 0;
    right: 0;
    top: 0;
    margin-right: 0;
    position: absolute; }
    body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only .p-button-text {
      display: none; }
    body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only .p-button-icon {
      line-height: 1; }
    body .p-autocomplete .p-autocomplete-dropdown.p-button.p-button-icon-only .pi {
      color: #757575; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-inputtext {
    box-sizing: border-box;
    padding: 2px; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container.p-disabled {
    border-bottom: 1px dotted; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token {
    float: none;
    display: inline-block;
    margin: 0 1px;
    vertical-align: middle; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token > input {
      padding: 0;
      font-size: 14px;
      margin: 0;
      vertical-align: bottom; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token {
    display: inline-block;
    float: none;
    vertical-align: middle;
    font-size: 1em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token .p-autocomplete-token-label {
      display: inline-block;
      vertical-align: middle; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-dropdown.p-button.p-button-icon-only {
    top: auto;
    bottom: 0; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-loader {
    margin-top: -.35em; }
  body .p-fluid .p-autocomplete.p-autocomplete-dd > .p-inputtext {
    width: 100%; }
  body .p-dropdown {
    border-width: 0;
    background: transparent no-repeat;
    background-image: linear-gradient(to bottom, #00529e, #00529e), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
    padding-bottom: 2px;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-dropdown .p-dropdown-trigger-icon {
      height: 1.5em;
      width: 1.5em;
      margin-top: 0;
      padding: 0;
      margin-right: 0;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px;
      color: #757575; }
    body .p-dropdown .p-dropdown-label {
      background: none;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
    body .p-dropdown.p-focus {
      border-width: 0;
      background-size: 100% 2px, 100% 1px;
      padding-bottom: 2px; }
      body .p-dropdown.p-focus .p-dropdown-label {
        padding-bottom: 2px; }
  body .p-dropdown-panel {
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-dropdown-panel .p-dropdown-list {
      padding: 0;
      background-color: #ffffff;
      border: 1px solid #d8d8d8;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
    body .p-dropdown-panel .p-dropdown-item {
      margin: 0;
      padding: 0.625em 1em;
      color: #212121;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-dropdown-panel .p-dropdown-item:hover {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-dropdown-panel .p-dropdown-item.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
    body .p-dropdown-panel.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-dropdown-panel .p-dropdown-filter-container {
      display: block;
      border-bottom: 1px solid #bdbdbd;
      background-color: #00529e;
      padding: 0.625em 1em; }
      body .p-dropdown-panel .p-dropdown-filter-container input {
        color: #ffffff; }
        body .p-dropdown-panel .p-dropdown-filter-container input:focus {
          border-bottom-color: #ffffff; }
      body .p-dropdown-panel .p-dropdown-filter-container .pi {
        top: .325em;
        right: .75em;
        color: #ffffff; }
  body .p-multiselect {
    border-bottom: 1px solid #bdbdbd;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-transition: border-color 0.3s;
    -o-transition: border-color 0.3s;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s; }
    body .p-multiselect .p-multiselect-label-container {
      padding-right: 1.5em; }
      body .p-multiselect .p-multiselect-label-container .p-multiselect-label {
        padding: 0px 2px 0px 2px; }
    body .p-multiselect .p-multiselect-trigger-icon {
      height: 1.5em;
      width: 1.5em;
      margin-top: 0;
      padding: 0;
      color: #757575; }
    body .p-multiselect.p-focus {
      border-bottom: 2px solid #00529e; }
  body .p-multiselect-panel {
    padding: 0;
    -moz-border-radius: 3;
    -webkit-border-radius: 3;
    border-radius: 3;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    min-width: 12.5em;
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-multiselect-panel .p-multiselect-header {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      padding: 0.625em 1em;
      position: relative;
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-multiselect-panel .p-multiselect-header .pi {
        color: #ffffff; }
      body .p-multiselect-panel .p-multiselect-header .p-checkbox {
        float: none;
        margin: 0 .5em 0 -.125em;
        position: static; }
        body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box {
          border-color: #ffffff; }
          body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box .p-checkbox-icon {
            border-color: #ffffff; }
          body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
            border-color: #ffffff; }
          body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box.p-focus {
            background-color: #8197a5;
            -moz-transition: background-color 0.3s;
            -o-transition: background-color 0.3s;
            -webkit-transition: background-color 0.3s;
            transition: background-color 0.3s; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        float: none;
        margin-left: 0; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .pi {
          top: 0;
          left: 0; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
          padding: 2px;
          padding-left: 1.5em;
          color: #ffffff;
          width: 100%;
          box-sizing: border-box; }
          body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext:focus {
            border-bottom-color: #ffffff; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
        position: absolute;
        right: .5em;
        height: 1.5em;
        width: 1.5em;
        top: .625em; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-close span {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1em;
          height: 1em;
          margin-top: -.5em;
          margin-left: -.5em;
          color: #ffffff; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-close:hover {
          background-color: #8197a5;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          -moz-transition: background-color 0.3s;
          -o-transition: background-color 0.3s;
          -webkit-transition: background-color 0.3s;
          transition: background-color 0.3s; }
    body .p-multiselect-panel .p-multiselect-item {
      padding: .625em .875em;
      color: #212121;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-multiselect-panel .p-multiselect-item:not(.p-highlight):hover {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-multiselect-panel .p-multiselect-item.p-highlight {
        color: #ffffff; }
  body .p-spinner {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-spinner .p-spinner-button {
      position: absolute;
      width: 1em;
      height: .75em;
      padding: 0;
      margin-right: .25em;
      background-color: transparent;
      color: #212121;
      z-index: auto;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none; }
      body .p-spinner .p-spinner-button .pi {
        height: .5em;
        color: #757575; }
      body .p-spinner .p-spinner-button:enabled:hover, body .p-spinner .p-spinner-button:active {
        background-color: transparent; }
    body .p-spinner .p-spinner-up .pi {
      top: .25em; }
    body .p-spinner .p-spinner-down .pi {
      top: .167em; }
    body .p-spinner .p-spinner-input {
      padding-right: 30px; }
  body .p-fluid .p-spinner .p-spinner-button {
    width: 1em;
    height: .75em; }
    body .p-fluid .p-spinner .p-spinner-button .pi {
      left: auto; }
  body .p-inputswitch {
    height: 14px;
    width: 34px !important; }
    body .p-inputswitch .p-inputswitch-slider {
      background-color: #9e9e9e;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      body .p-inputswitch .p-inputswitch-slider:before {
        background-color: #ffffff;
        height: 1.429em;
        width: 1.429em;
        left: 0;
        bottom: -0.214em;
        border-radius: 50%;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
        -moz-box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.137255) 0px 1px 1px 0px, rgba(0, 0, 0, 0.117647) 0px 2px 1px -1px; }
    body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      -webkit-transform: translateX(1.25em);
      -ms-transform: translateX(1.25em);
      transform: translateX(1.25em); }
    body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
      background-color: #9e9e9e; }
    body .p-inputswitch:not(.p-state-disabled):hover .p-inputswitch-slider {
      background-color: #9e9e9e; }
    body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: #4699ce; }
      body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
        background-color: #4699ce; }
    body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider {
      background-color: #4699ce; }
    body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
      background: #4699ce; }
  body .p-slider {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-slider .p-slider-handle {
      background-color: #4699ce;
      color: #ffffff;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      width: 1.25em;
      height: 1.25em;
      transform: scale(0.7);
      -moz-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      -o-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      -webkit-transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
      body .p-slider .p-slider-handle.p-focus {
        transform: scale(1); }
      body .p-slider .p-slider-handle:focus {
        outline: 0 none; }
    body .p-slider.p-slider-horizontal {
      height: 2px;
      border: 0 none;
      background-color: #bdbdbd; }
      body .p-slider.p-slider-horizontal .p-slider-handle {
        top: -.55em; }
    body .p-slider.p-slider-vertical {
      width: 2px;
      border: 0 none;
      background-color: #bdbdbd; }
      body .p-slider.p-slider-vertical .p-slider-handle {
        left: -.55em; }
    body .p-slider .p-slider-range {
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background-color: #4699ce;
      color: #ffffff; }
    body .p-slider:not(.p-disabled) .p-slider-handle:hover {
      transform: scale(1); }
  body .p-calendar .p-datepicker-trigger.p-button-icon-only {
    bottom: 2px;
    right: 0;
    top: auto;
    background-color: transparent;
    color: #212121;
    height: 1.5em;
    width: 1.5em;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-calendar .p-datepicker-trigger.p-button-icon-only .pi {
      color: #757575; }
    body .p-calendar .p-datepicker-trigger.p-button-icon-only:enabled:hover {
      background-color: transparent; }
  body .p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
    width: 100%; }
  body .p-datepicker {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 0;
    width: 17.5em; }
    body .p-datepicker.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
    body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
      color: #8197a5; }
    body .p-datepicker:not(.p-disabled) table td a:not(.p-highlight):not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-datepicker:not(.p-disabled) .p-monthpicker a.p-monthpicker-month:not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-datepicker .p-datepicker-header {
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      padding: 0.625em 1em;
      background: #0f2765;
      border-color: #0f2765;
      -moz-border-radius-topleft: 2px;
      -webkit-border-top-left-radius: 2px;
      border-top-left-radius: 2px;
      -moz-border-radius-topright: 2px;
      -webkit-border-top-right-radius: 2px;
      border-top-right-radius: 2px;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
      body .p-datepicker .p-datepicker-header .pi {
        color: #ffffff; }
      body .p-datepicker .p-datepicker-header .p-datepicker-prev {
        cursor: pointer;
        top: .4em;
        left: .2em;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        color: #ffffff; }
        body .p-datepicker .p-datepicker-header .p-datepicker-prev:before {
          content: "keyboard_arrow_left"; }
        body .p-datepicker .p-datepicker-header .p-datepicker-prev .pi {
          display: none; }
      body .p-datepicker .p-datepicker-header .p-datepicker-next {
        cursor: pointer;
        top: .4em;
        right: .2em;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        color: #ffffff; }
        body .p-datepicker .p-datepicker-header .p-datepicker-next:before {
          content: "keyboard_arrow_right"; }
        body .p-datepicker .p-datepicker-header .p-datepicker-next .pi {
          display: none; }
      body .p-datepicker .p-datepicker-header select.p-datepicker-month,
      body .p-datepicker .p-datepicker-header select.p-datepicker-year {
        font-size: 12px; }
    body .p-datepicker table {
      table-layout: fixed;
      border-spacing: 0;
      border-collapse: collapse;
      font-size: 12px; }
    body .p-datepicker thead tr {
      color: #ffffff;
      background: #00529e; }
    body .p-datepicker tbody td {
      padding: .25em .125em;
      box-sizing: border-box; }
      body .p-datepicker tbody td.p-datepicker-today a {
        color: #ffffff;
        background: #0587ff; }
        body .p-datepicker tbody td.p-datepicker-today a.p-highlight {
          background-color: #4699ce;
          color: #ffffff; }
      body .p-datepicker tbody td a {
        padding: .25em;
        margin: 0;
        text-align: center;
        color: #212121;
        display: inline-block;
        height: 2.250em;
        width: 2.250em;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        line-height: 1.8em; }
        body .p-datepicker tbody td a.p-highlight {
          background-color: #4699ce;
          color: #ffffff; }
        body .p-datepicker tbody td a:not(.p-highlight):not(.p-highlight):hover {
          background-color: #e8e8e8;
          color: #000000; }
        body .p-datepicker tbody td a span {
          display: inline;
          padding: 0;
          text-align: center; }
    body .p-datepicker .p-timepicker {
      background-color: #00529e;
      color: #ffffff;
      border: 1px solid #00529e;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-datepicker .p-timepicker .pi {
        color: #ffffff; }
      body .p-datepicker .p-timepicker > div a {
        height: 1.5em; }
        body .p-datepicker .p-timepicker > div a > span {
          display: inline-block;
          margin-bottom: .25em; }
        body .p-datepicker .p-timepicker > div a .pi.pi-angle-up {
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -o-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.5em;
          /* Preferred icon size */
          display: inline-block;
          width: 1em;
          height: 1em;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          text-indent: 0;
          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: 'liga'; }
          body .p-datepicker .p-timepicker > div a .pi.pi-angle-up:before {
            content: "keyboard_arrow_up"; }
        body .p-datepicker .p-timepicker > div a .pi.pi-angle-down {
          -webkit-transform: rotate(0);
          -moz-transform: rotate(0);
          -o-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.5em;
          /* Preferred icon size */
          display: inline-block;
          width: 1em;
          height: 1em;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          text-indent: 0;
          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: 'liga'; }
          body .p-datepicker .p-timepicker > div a .pi.pi-angle-down:before {
            content: "keyboard_arrow_down"; }
    body .p-datepicker .p-monthpicker .p-monthpicker-month {
      color: #212121; }
      body .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
        color: #ffffff;
        background-color: #4699ce; }
    body .p-datepicker.p-datepicker-multiple-month {
      width: auto; }
      body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
        border-right: 1px solid #dbdbdb;
        padding-top: 0;
        padding-bottom: 0; }
        body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group .p-datepicker-calendar-container tbody {
          padding-right: .25em;
          padding-left: .25em; }
        body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
          padding-left: 0; }
        body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
          padding-right: 0;
          border-right: 0 none; }
  body #p-datepicker-div {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-rating {
    font-size: inherit;
    min-height: 1.5em; }
    body .p-rating .pi-ban {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      color: #757575; }
      body .p-rating .pi-ban:before {
        content: "cancel"; }
    body .p-rating .pi-star {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      color: #757575; }
      body .p-rating .pi-star:before {
        content: "star"; }
    body .p-rating .pi-star-o {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      color: #757575; }
      body .p-rating .pi-star-o:before {
        content: "star_border"; }
  body .p-chips > ul.p-inputtext .p-chips-input-token {
    padding-bottom: 2px;
    margin-bottom: 2px; }
    body .p-chips > ul.p-inputtext .p-chips-input-token > input {
      padding: 0;
      font-size: 14px;
      margin: 0;
      background: transparent;
      vertical-align: bottom; }
    body .p-chips > ul.p-inputtext .p-chips-input-token input.p-disabled {
      width: 0; }
  body .p-chips > ul.p-inputtext .p-chips-token {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    float: none;
    vertical-align: middle;
    font-size: 1em;
    margin-bottom: 2px; }
    body .p-chips > ul.p-inputtext .p-chips-token .p-chips-token-label {
      display: inline-block;
      vertical-align: middle; }
  body .p-chips > ul.p-inputtext.p-disabled {
    border-bottom: 1px dotted; }
  body .p-password-panel {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-password-panel.p-shadow {
      -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-fileupload .p-fileupload-buttonbar {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    padding: 0.625em 1em; }
    body .p-fileupload .p-fileupload-buttonbar .pi {
      color: #ffffff; }
    body .p-fileupload .p-fileupload-buttonbar .pi-arrowreturnthick-1-n {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga'; }
      body .p-fileupload .p-fileupload-buttonbar .pi-arrowreturnthick-1-n:before {
        content: "file_upload"; }
    body .p-fileupload .p-fileupload-buttonbar .p-button {
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      background-color: #4699ce; }
      body .p-fileupload .p-fileupload-buttonbar .p-button:enabled:hover {
        background-color: #274d8d; }
      body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose .pi {
        vertical-align: middle; }
  body .p-fileupload .p-fileupload-content {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
    body .p-fileupload .p-fileupload-content .p-messages-error .pi {
      color: #ffffff; }
  body .p-fileupload-choose:not(.p-disabled):hover.p-button,
  body .p-fileupload-choose.p-focus.p-button {
    background-color: #348dc6; }
  body .p-fileupload-choose:not(.p-disabled):active.p-button {
    background-color: #2f7fb2; }
  body .p-editor-container .p-editor-toolbar {
    background-color: #e8e8e8; }
  body .p-editor-container .ql-picker.ql-expanded .ql-picker-label {
    color: #444444; }
  body .p-inputgroup .p-inputgroup-addon {
    border-width: 0 0 1px 0;
    border-color: #bdbdbd;
    color: #757575;
    padding: 2px 2px 1px 2px;
    align-self: flex-end; }
    body .p-inputgroup .p-inputgroup-addon > i {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-self: flex-end; }
  body .p-inputgroup .p-inputtext {
    align-self: flex-end; }
  body .p-inputgroup .md-inputfield {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    display: inherit; }
    body .p-inputgroup .md-inputfield label {
      top: 5px; }
  body .p-inputgroup .p-checkbox, body .p-inputgroup .p-radiobutton {
    margin-bottom: .125em; }
  body .p-fluid .p-inputgroup .p-button-icon-only {
    width: 1.643em;
    height: 1.643em;
    padding: 0;
    line-height: 1; }
  body .p-inputtext.p-error,
  body .p-dropdown.p-error,
  body .p-autocomplete.p-error > .p-inputtext,
  body .p-calendar.p-error > .p-inputtext,
  body .p-chips.p-error > .p-inputtext,
  body .p-checkbox.p-error > .p-checkbox-box,
  body .p-radiobutton.p-error > .p-radiobutton-box,
  body .p-inputswitch.p-error,
  body .p-listbox.p-error,
  body .p-multiselect.p-error,
  body .p-spinner.p-error > .p-inputtext,
  body .p-selectbutton.p-error > .p-button,
  body .p-togglebutton.p-error > .p-button {
    background-image: linear-gradient(to bottom, #e62a10, #e62a10), linear-gradient(to bottom, #e62a10, #e62a10); }
  body .p-calendar .p-datepicker {
    min-width: auto; }

@media (max-width: 640px) {
  body .p-buttonset > .p-button {
    display: block;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-buttonset > .p-button:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px; }
    body .p-buttonset > .p-button:last-child {
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
  body .p-splitbutton > .p-button {
    display: inline-block; }
    body .p-splitbutton > .p-button:first-child {
      -moz-border-radius-topleft: 0;
      -webkit-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-radius-topright: 0;
      -webkit-border-top-right-radius: 0;
      border-top-right-radius: 0; }
    body .p-splitbutton > .p-button:last-child {
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; } }

body .p-panel {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-panel .p-panel-titlebar {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    border: 0 none;
    border-bottom: 1px solid #00529e;
    padding: 0.625em 1em;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
    body .p-panel .p-panel-titlebar .pi {
      color: #ffffff; }
    body .p-panel .p-panel-titlebar .p-panel-title {
      line-height: 1.5em; }
    body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
      position: relative;
      width: 1.5em;
      height: 1.5em;
      color: #ffffff;
      margin: 0;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
        background-color: #8197a5; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon span {
        margin-top: 1px; }
  body .p-panel .p-panel-content {
    height: 100%;
    padding: 0.625em 1em;
    line-height: 18px; }
  body .p-panel .p-panel-footer {
    padding: 0.625em 1em;
    border: 0 none;
    border-top: 1px solid #dbdbdb;
    margin: 0; }

body .p-fieldset {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0.625em 1em; }
  body .p-fieldset .p-fieldset-legend {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 0.625em 1em;
    padding-left: .125em;
    padding-right: .5em;
    color: #00529e; }
    body .p-fieldset .p-fieldset-legend .p-fieldset-toggler {
      height: 1.5em;
      width: 1.5em;
      line-height: 1.5em;
      background-color: #00529e;
      color: #ffffff;
      font-size: 1.5em;
      margin-right: .5em;
      text-align: center;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }
      body .p-fieldset .p-fieldset-legend .p-fieldset-toggler:before {
        line-height: inherit; }
      body .p-fieldset .p-fieldset-legend .p-fieldset-toggler:hover {
        background-color: #0f2765; }
    body .p-fieldset .p-fieldset-legend .p-fieldset-legend-text {
      color: #00529e; }
    body .p-fieldset .p-fieldset-legend.p-focus {
      background-color: transparent; }
  body .p-fieldset .p-fieldset-content {
    line-height: 18px; }

body .p-accordion .p-accordion-header {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-accordion .p-accordion-header a {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid transparent;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s; }
    body .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
      color: #ffffff; }
  body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a {
    background-color: #0f2765;
    color: #ffffff; }
    body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a .p-accordion-toggle-icon {
      color: #ffffff; }
  body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight {
    margin-bottom: 0; }
    body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
      background-color: #4699ce;
      color: #ffffff;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
      body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a .p-accordion-toggle-icon {
        color: #ffffff; }
    body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a {
      background-color: #4699ce;
      color: #ffffff; }
      body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a .p-accordion-toggle-icon {
        color: #ffffff; }

body .p-accordion .p-accordion-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  padding: 0.625em 1em;
  line-height: 18px;
  border-top: 0;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }

body .p-toolbar {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  background-color: #0f2765;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: .625em; }
  body .p-toolbar .pi {
    color: #ffffff; }
  body .p-toolbar .p-toolbar-separator {
    vertical-align: middle;
    margin: 0 .5em; }
  body .p-toolbar .pi {
    color: inherit; }

body .p-tabview {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0; }
  body .p-tabview .p-tabview-nav {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    background-color: #ffffff;
    border: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-tabview .p-tabview-nav .pi {
      color: #ffffff; }
    body .p-tabview .p-tabview-nav > li {
      padding: 0;
      -moz-transition: border-color 0.3s;
      -o-transition: border-color 0.3s;
      -webkit-transition: border-color 0.3s;
      transition: border-color 0.3s; }
      body .p-tabview .p-tabview-nav > li > a {
        padding: 0.625em 1em; }
        body .p-tabview .p-tabview-nav > li > a:focus {
          outline: 0 none; }
      body .p-tabview .p-tabview-nav > li > .pi-times {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga';
        margin: .325em 0 0 0;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s;
        color: #757575; }
        body .p-tabview .p-tabview-nav > li > .pi-times:before {
          content: "close"; }
      body .p-tabview .p-tabview-nav > li .p-tabview-left-icon, body .p-tabview .p-tabview-nav > li .p-tabview-right-icon {
        color: #757575;
        display: inline-block;
        vertical-align: middle; }
      body .p-tabview .p-tabview-nav > li .p-tabview-title {
        line-height: 1.5em;
        vertical-align: middle;
        color: #757575; }
      body .p-tabview .p-tabview-nav > li:not(.p-highlight):not(.p-disabled):hover {
        background-color: #ffffff; }
      body .p-tabview .p-tabview-nav > li.p-highlight {
        background-color: #ffffff;
        border-color: #4699ce;
        border-style: solid; }
        body .p-tabview .p-tabview-nav > li.p-highlight .p-tabview-title {
          color: #00529e; }
        body .p-tabview .p-tabview-nav > li.p-highlight a {
          color: #00529e; }
        body .p-tabview .p-tabview-nav > li.p-highlight > .pi-times {
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          font-size: 1.5em;
          /* Preferred icon size */
          display: inline-block;
          width: 1em;
          height: 1em;
          line-height: 1;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          text-indent: 0;
          /* Support for all WebKit browsers. */
          -webkit-font-smoothing: antialiased;
          /* Support for Safari and Chrome. */
          text-rendering: optimizeLegibility;
          /* Support for Firefox. */
          -moz-osx-font-smoothing: grayscale;
          /* Support for IE. */
          font-feature-settings: 'liga';
          color: #4699ce; }
          body .p-tabview .p-tabview-nav > li.p-highlight > .pi-times:before {
            content: "close"; }
      body .p-tabview .p-tabview-nav > li.p-tabview-outline {
        outline: 0 none;
        border-color: #4699ce; }
  body .p-tabview .p-tabview-panel {
    padding: 0.625em 1em;
    line-height: 18px; }
  body .p-tabview.p-tabview-top > .p-tabview-nav {
    padding: 0;
    margin: 0;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-top > .p-tabview-nav > li {
      border-style: solid;
      border-width: 0 0 2px 0; }
  body .p-tabview.p-tabview-bottom > .p-tabview-nav {
    padding: 0;
    margin: 0;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-bottom > .p-tabview-nav > li {
      border-width: 2px 0 0 0; }
  body .p-tabview.p-tabview-left > .p-tabview-nav {
    padding: 0;
    margin: 0;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-left > .p-tabview-nav > li {
      box-sizing: border-box;
      border-width: 0 2px 0 0; }
      body .p-tabview.p-tabview-left > .p-tabview-nav > li > a {
        width: 100%;
        box-sizing: border-box; }
  body .p-tabview.p-tabview-right > .p-tabview-nav {
    padding: 0;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #dbdbdb; }
    body .p-tabview.p-tabview-right > .p-tabview-nav > li {
      box-sizing: border-box;
      border-width: 0 0 0 2px; }
      body .p-tabview.p-tabview-right > .p-tabview-nav > li > a {
        width: 100%;
        box-sizing: border-box; }
      body .p-tabview.p-tabview-right > .p-tabview-nav > li.p-highlight > a {
        padding-left: 0.875em; }

body .p-card {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

body .p-scrollpanel {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

@media (max-width: 640px) {
  body .p-toolbar .p-toolbar-group-right {
    margin-top: 1em; } }

body .p-picklist .p-picklist-caption {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em; }
  body .p-picklist .p-picklist-caption .pi {
    color: #ffffff; }

body .p-picklist .p-picklist-list {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0; }

body .p-picklist li.p-picklist-item {
  padding: 0.625em 1em;
  margin: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  color: #212121; }
  body .p-picklist li.p-picklist-item:not(.p-highlight):hover {
    background-color: #e8e8e8;
    color: #000000; }
  body .p-picklist li.p-picklist-item.p-highlight {
    background-color: #4699ce;
    color: #ffffff; }

body .p-picklist .p-picklist-buttons {
  width: 3em; }
  body .p-picklist .p-picklist-buttons .p-button.p-button-icon-only {
    width: 2.5em;
    margin-right: 0;
    display: inline-block;
    margin-bottom: .5em;
    line-height: 1;
    vertical-align: middle; }

body .p-picklist .p-picklist-buttons-cell {
  text-align: center; }

body .p-picklist.p-picklist-responsive .p-picklist-buttons .p-button.p-button-icon-only {
  margin: 0 auto;
  display: block;
  margin-bottom: .5em; }

body .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-chkbox {
  margin-right: .5em;
  vertical-align: top; }

body .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-chkbox, body .p-picklist.p-picklist-responsive .p-picklist-list .p-picklist-item .p-chkbox * {
  box-sizing: content-box; }

body .p-organizationchart .p-organizationchart-node-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background-color: #e8e8e8;
    color: #000000; }
  body .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #1f5475; }

body .p-organizationchart .p-organizationchart-line-down {
  background-color: #dbdbdb; }

body .p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dbdbdb; }

body .p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dbdbdb; }

body .p-organizationchart .p-organizationchart-node-content {
  border-color: #dbdbdb; }

body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  bottom: -1.3em;
  margin-left: -.75em;
  color: #dbdbdb; }
  body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-down {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-down:before {
      content: "keyboard_arrow_down"; }
  body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-up {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-organizationchart .p-organizationchart-node-content .p-node-toggler .pi-chevron-up:before {
      content: "keyboard_arrow_up"; }

body .p-orderlist .p-orderlist-caption {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em;
  box-sizing: border-box; }
  body .p-orderlist .p-orderlist-caption .pi {
    color: #ffffff; }

body .p-orderlist .p-orderlist-list {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 0;
  box-sizing: border-box; }
  body .p-orderlist .p-orderlist-list li.p-orderlist-item {
    padding: 0.625em 1em;
    margin: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    color: #212121; }
    body .p-orderlist .p-orderlist-list li.p-orderlist-item:not(.p-highlight):hover {
      background-color: #e8e8e8;
      color: #000000; }
    body .p-orderlist .p-orderlist-list li.p-orderlist-item.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }

body .p-orderlist.p-orderlist-responsive .p-orderlist-controls {
  width: 3.75em; }

body .p-orderlist .p-orderlist-controls {
  width: 3.75em;
  text-align: center; }
  body .p-orderlist .p-orderlist-controls .p-button.p-button-icon-only {
    width: 2.5em;
    margin-bottom: .5em;
    margin-right: 0;
    line-height: 1;
    vertical-align: middle; }

body .p-paginator {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  background-color: #0f2765;
  padding: 0.625em 1em; }
  body .p-paginator .pi {
    color: #ffffff; }
  body .p-paginator > a {
    color: #ffffff; }
    body .p-paginator > a .pi {
      display: none; }
    body .p-paginator > a:not(.p-disabled):not(.p-highlight):hover {
      background-color: #8197a5;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
  body .p-paginator .p-paginator-next {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-next:before {
      content: "navigate_next"; }
  body .p-paginator .p-paginator-last {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-last:before {
      content: "last_page"; }
  body .p-paginator .p-paginator-prev {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-prev:before {
      content: "navigate_before"; }
  body .p-paginator .p-paginator-first {
    padding: 0;
    vertical-align: middle;
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga'; }
    body .p-paginator .p-paginator-first:before {
      content: "first_page"; }
  body .p-paginator .p-paginator-pages {
    vertical-align: middle;
    margin: 0 .375em 0 .375em; }
    body .p-paginator .p-paginator-pages a {
      padding: 0;
      color: #ffffff;
      min-width: 1.5em;
      min-height: 1.5em;
      line-height: 1.5em;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s; }
      body .p-paginator .p-paginator-pages a:not(.p-highlight):hover {
        background-color: #8197a5; }
      body .p-paginator .p-paginator-pages a.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }

body .p-dataview .p-dataview-layout-options .p-button {
  margin-left: 0.5em;
  border-radius: 50%; }

body .p-dataview .p-dataview-header {
  background-color: #ffffff;
  color: #212121;
  padding: 0.625em 1em;
  border: 1px solid #cacaca;
  border-bottom: 0 none; }

body .p-dataview .p-dataview-content {
  background-color: #ffffff;
  color: #212121;
  border: 1px solid #cacaca; }

body .p-dataview .p-dataview-footer {
  background-color: #ffffff;
  color: #212121;
  padding: 0.625em 1em;
  border: 1px solid #cacaca;
  border-top: 0 none; }

@media (max-width: 40em) {
  body .p-dataview-layout-options.p-buttonset > .p-button {
    margin-left: 0;
    display: inline-block; }
    body .p-dataview-layout-options.p-buttonset > .p-button:first-child {
      border-radius: 50%; }
    body .p-dataview-layout-options.p-buttonset > .p-button:last-child {
      border-radius: 50%; } }

body .p-datagrid .p-datagrid-header {
  padding: 0.625em 1em; }

body .p-datagrid .p-panel .p-panel-titlebar {
  background-color: #ffffff;
  color: #212121;
  border-color: #dbdbdb; }

body .p-datalist .p-datalist-header {
  padding: 0.625em 1em; }

body .p-datascroller .p-datascroller-header {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  border-bottom: 0 none;
  padding: 0.625em 1em; }
  body .p-datascroller .p-datascroller-header .pi {
    color: #ffffff; }

body .p-datascroller .p-datascroller-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }

body .p-datascroller .p-datascroller-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  border-top: 0 none;
  padding: 0.625em 1em; }
  body .p-datascroller .p-datascroller-footer .pi {
    color: #ffffff; }

body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em;
  text-align: center; }
  body .p-datatable .p-datatable-header .pi,
  body .p-datatable .p-datatable-footer .pi {
    color: #ffffff; }
  body .p-datatable .p-datatable-header .p-inputtext,
  body .p-datatable .p-datatable-footer .p-inputtext {
    color: #ffffff; }
    body .p-datatable .p-datatable-header .p-inputtext:focus,
    body .p-datatable .p-datatable-footer .p-inputtext:focus {
      border-color: #ffffff; }

body .p-datatable .p-datatable-header {
  border-bottom: 0 none; }

body .p-datatable .p-datatable-footer {
  border-top: 0 none; }

body .p-datatable .p-datatable-thead > tr > th {
  padding: .625em .875em;
  border: 1px solid #cacaca;
  background-color: #ffffff;
  text-align: center; }

body .p-datatable .p-datatable-tfoot > tr > td {
  padding: .625em .875em;
  border: 1px solid #cacaca;
  background-color: #ffffff;
  text-align: center; }

body .p-datatable .p-sortable-column a, body .p-datatable .p-sortable-column a:hover {
  color: #757575;
  margin-left: .125em; }

body .p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: #e8e8e8;
  color: #000000; }
  body .p-datatable .p-sortable-column:not(.p-highlight):hover a, body .p-datatable .p-sortable-column:not(.p-highlight):hover a:hover {
    background-color: #e8e8e8;
    color: #000000; }

body .p-datatable .p-sortable-column.p-highlight {
  background-color: #4699ce;
  color: #ffffff;
  border-top-color: #4699ce; }
  body .p-datatable .p-sortable-column.p-highlight a, body .p-datatable .p-sortable-column.p-highlight a:hover {
    color: #ffffff; }

body .p-datatable .p-sortable-column .p-column-title {
  font-weight: 400;
  vertical-align: super; }

body .p-datatable .p-editable-column input {
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", sans-serif; }
  body .p-datatable .p-editable-column input:focus {
    outline: 1px solid #ffffff;
    outline-offset: 2px; }

body .p-datatable .p-datatable-tbody {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }
  body .p-datatable .p-datatable-tbody > tr {
    border-color: #cacaca; }
    body .p-datatable .p-datatable-tbody > tr > td {
      border: 1px solid #cacaca;
      padding: .625em .875em; }
      body .p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
        cursor: pointer;
        color: #757575;
        -moz-transition: color 0.3s;
        -o-transition: color 0.3s;
        -webkit-transition: color 0.3s;
        transition: color 0.3s; }
        body .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:hover {
          background-color: #e8e8e8;
          color: #000000; }
    body .p-datatable .p-datatable-tbody > tr.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }
    body .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
      box-shadow: inset 0 -2px 0 0 #4699ce; }
  body .p-datatable .p-datatable-tbody > tr.p-datatable-even {
    background-color: #f4f4f4; }
    body .p-datatable .p-datatable-tbody > tr.p-datatable-even.p-highlight {
      background-color: #4699ce;
      color: #ffffff; }

body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #000000; }

body .p-datatable .p-column-resizer-helper {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .p-datatable .p-column-resizer-helper .pi {
    color: #ffffff; }

body .p-datatable .p-datatable-scrollable-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .p-datatable .p-datatable-scrollable-footer .pi {
    color: #ffffff; }

body .p-carousel {
  padding: 0; }
  body .p-carousel .p-carousel-header {
    padding: 0.625em 1em;
    overflow: visible; }
    body .p-carousel .p-carousel-header .p-carousel-header-title {
      overflow: visible; }
    body .p-carousel .p-carousel-header .pi {
      color: #ffffff; }
    body .p-carousel .p-carousel-header .p-carousel-button {
      margin: -1px 0 0 0; }
    body .p-carousel .p-carousel-header .p-carousel-page-links {
      margin: -1px 0 0 0; }

body .p-tree {
  border: 1px solid #cacaca;
  padding: 0.625em 1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-tree .p-tree-container {
    padding: 0.286em;
    margin: 0; }
    body .p-tree .p-tree-container .p-treenode .p-treenode-content {
      padding: 0;
      border: 1px solid transparent;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
        vertical-align: middle;
        display: inline-block;
        float: none;
        color: #757575; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
          outline: 0; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
        vertical-align: middle;
        display: inline-block;
        margin: 0 0 0 .25em;
        color: #757575; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
        margin: 0;
        vertical-align: middle;
        display: inline-block;
        padding: 0.625em 1em; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
        margin: 0 .25em; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
          color: #ffffff; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight-contextmenu {
        cursor: pointer;
        border: 1px dashed #4699ce; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background-color: #e8e8e8;
        color: #000000; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover .p-treenode-icon {
          background-color: #e8e8e8;
          color: #000000; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
        background-color: #e8e8e8;
        color: #000000; }
    body .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
      background-color: #4699ce; }
  body .p-tree.p-tree-horizontal {
    padding-left: 0;
    padding-right: 0; }
    body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
      background-color: #ffffff;
      border: 1px solid #dbdbdb;
      padding: 0.625em 1em;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
        background-color: #4699ce;
        color: #ffffff; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .pi-md {
        color: #00529e; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
        background-color: inherit;
        color: inherit; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
        background-color: #e8e8e8;
        color: #000000; }

body .p-tree-draghelper {
  border: 1px solid #00529e; }

body .fc button {
  height: 2.25em;
  padding: 0 1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .fc button.ui-state-active {
    background-color: #4699ce;
    color: #ffffff; }
  body .fc button.ui-state-disabled {
    opacity: .35;
    filter: Alpha(Opacity=35);
    background-image: none;
    cursor: auto !important; }

body .fc td {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }

body .fc .fc-toolbar .ui-button {
  color: #ffffff;
  background-color: #00529e;
  border: #00529e;
  font-size: 14px;
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16); }
  body .fc .fc-toolbar .ui-button:enabled:not(.ui-state-active):hover {
    background-color: #0f2765;
    color: #ffffff;
    border-color: #0f2765; }
  body .fc .fc-toolbar .ui-button.ui-state-active {
    background-color: #4699ce;
    color: #ffffff; }
  body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-w {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    border-radius: 0;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    text-indent: 0;
    position: relative;
    top: 1px;
    vertical-align: middle; }
    body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-w:before {
      content: "play_circle_outline"; }
  body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-e {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    border-radius: 0;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    text-indent: 0;
    position: relative;
    top: 1px;
    vertical-align: middle; }
    body .fc .fc-toolbar .ui-button .ui-icon-circle-triangle-e:before {
      content: "play_circle_outline"; }
  body .fc .fc-toolbar .ui-button:focus {
    outline: 0 none; }

body .fc .ui-state-highlight {
  background-color: #4699ce;
  color: #ffffff; }

body .fc .fc-button-group .ui-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .fc .fc-button-group .ui-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .fc .fc-button-group .ui-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }

body .fc .fc-head-container {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .fc .fc-head-container .pi {
    color: #ffffff; }
  body .fc .fc-head-container .fc-row, body .fc .fc-head-container .fc-axis {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e; }
    body .fc .fc-head-container .fc-row .pi, body .fc .fc-head-container .fc-axis .pi {
      color: #ffffff; }

body .fc .fc-day-header, body .fc .fc-divider {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e; }
  body .fc .fc-day-header .pi, body .fc .fc-divider .pi {
    color: #ffffff; }

body .fc .fc-event {
  background-color: #8197a5;
  color: #ffffff; }

body .fc table th {
  padding: 0.625em 1em; }

body .p-treetable .p-treetable-header,
body .p-treetable .p-treetable-footer {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  padding: 0.625em 1em;
  text-align: center; }
  body .p-treetable .p-treetable-header .pi,
  body .p-treetable .p-treetable-footer .pi {
    color: #ffffff; }

body .p-treetable .p-treetable-header {
  border-bottom: 0 none; }

body .p-treetable .p-treetable-footer {
  border-top: 0 none; }

body .p-treetable thead > tr > th {
  padding: .625em .875em;
  border: 1px solid #dbdbdb;
  background-color: #ffffff; }

body .p-treetable tfoot > tr > td {
  border: 0 none;
  padding: .625em .875em; }

body .p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #757575; }

body .p-treetable .p-sortable-column:not(.p-highlight):hover {
  background-color: #e8e8e8;
  color: #000000; }
  body .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    background-color: #e8e8e8;
    color: #000000; }

body .p-treetable .p-sortable-column.p-highlight {
  background-color: #4699ce;
  color: #ffffff; }
  body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #ffffff; }

body .p-treetable .p-sortable-column .p-column-title {
  vertical-align: super; }

body .p-treetable .p-treetable-tablewrapper > table {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }
  body .p-treetable .p-treetable-tablewrapper > table > tbody {
    background: #ffffff;
    padding: .625em .875em;
    border-bottom: 0 none;
    border-top: 0 none; }
    body .p-treetable .p-treetable-tablewrapper > table > tbody:last-child {
      border-bottom: 1px solid #dbdbdb; }
    body .p-treetable .p-treetable-tablewrapper > table > tbody .p-treetable-row td {
      line-height: 2em; }

body .p-treetable .p-treetable-toggler {
  color: #757575; }

body .p-treetable .p-treetable-row-selectable:not(.p-highlight):hover {
  cursor: pointer;
  background-color: #e8e8e8;
  color: #000000; }

body .p-treetable .p-treetable-row-selectable.p-highlight {
  background-color: #4699ce;
  color: #ffffff; }
  body .p-treetable .p-treetable-row-selectable.p-highlight .p-treetable-toggler {
    color: #ffffff; }

body .p-treetable .p-treetable-checkbox .p-checkbox-icon {
  color: #212121; }

@media (max-width: 40em) {
  body .p-orderlist.p-orderlist-responsive .p-orderlist-controls {
    width: 100%; } }

@media (max-width: 640px) {
  body .p-picklist.p-picklist-responsive .p-picklist-list-wrapper {
    margin-bottom: .5em; }
  body .p-picklist.p-picklist-responsive .p-picklist-buttons {
    padding: .5em 0; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .p-button.p-button-icon-only {
      display: inline-block;
      margin-right: .25em;
      margin-bottom: 0; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-right {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-right:before {
        content: "play_arrow"; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-right {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-right:before {
        content: "skip_previous"; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-left {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-left:before {
        content: "play_arrow"; }
    body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-left {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg); }
      body .p-picklist.p-picklist-responsive .p-picklist-buttons .pi-angle-double-left:before {
        content: "skip_next"; }
  body .p-orderlist.p-grid-responsive .p-orderlist-controls {
    text-align: center;
    width: auto; }
    body .p-orderlist.p-grid-responsive .p-orderlist-controls .p-button {
      margin-right: .25em; } }

body .p-breadcrumb {
  background-color: #00529e;
  color: #ffffff;
  border: 1px solid #00529e;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0.625em 1em; }
  body .p-breadcrumb .pi {
    color: #ffffff; }
  body .p-breadcrumb ul li {
    margin: 0; }
    body .p-breadcrumb ul li a {
      color: #ffffff; }

body .p-steps {
  position: relative; }
  body .p-steps .p-steps-item {
    background-color: transparent; }
    body .p-steps .p-steps-item.p-disabled {
      opacity: 1;
      filter: alpha(opacity=100); }
    body .p-steps .p-steps-item .p-menuitem-link {
      display: inline-block;
      text-align: left;
      background-color: #ffffff;
      overflow: hidden; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        display: inline-block;
        background-color: #757575;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        font-size: 1em;
        color: #ffffff;
        vertical-align: middle;
        text-align: center;
        width: 2em;
        height: 2em;
        padding: 0;
        line-height: 2em; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        display: inline;
        margin-left: .625em;
        color: #757575; }
    body .p-steps .p-steps-item.p-highlight .p-steps-number {
      background-color: #4699ce; }
    body .p-steps .p-steps-item.p-highlight .p-steps-title {
      font-weight: 700;
      color: #212121; }
    body .p-steps .p-steps-item:last-child .p-menuitem-link {
      display: block; }
  body .p-steps:before {
    content: ' ';
    border: 1px solid #dbdbdb;
    width: 90%;
    top: 45%;
    left: 0;
    display: block;
    position: absolute; }

body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-megamenu .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
  padding: .625em 1em;
  color: #212121;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .p-menu .p-menuitem-link .p-menuitem-text,
  body .p-menubar .p-menuitem-link .p-menuitem-text,
  body .p-tieredmenu .p-menuitem-link .p-menuitem-text,
  body .p-contextmenu .p-menuitem-link .p-menuitem-text,
  body .p-megamenu .p-menuitem-link .p-menuitem-text,
  body .p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #212121; }
  body .p-menu .p-menuitem-link .p-menuitem-icon,
  body .p-menubar .p-menuitem-link .p-menuitem-icon,
  body .p-tieredmenu .p-menuitem-link .p-menuitem-icon,
  body .p-contextmenu .p-menuitem-link .p-menuitem-icon,
  body .p-megamenu .p-menuitem-link .p-menuitem-icon,
  body .p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #757575;
    margin-right: 0.5em; }
  body .p-menu .p-menuitem-link:hover,
  body .p-menubar .p-menuitem-link:hover,
  body .p-tieredmenu .p-menuitem-link:hover,
  body .p-contextmenu .p-menuitem-link:hover,
  body .p-megamenu .p-menuitem-link:hover,
  body .p-slidemenu .p-menuitem-link:hover {
    background-color: #e8e8e8;
    color: #000000; }
    body .p-menu .p-menuitem-link:hover .p-menuitem-text,
    body .p-menubar .p-menuitem-link:hover .p-menuitem-text,
    body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-contextmenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-megamenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-slidemenu .p-menuitem-link:hover .p-menuitem-text {
      color: #000000; }
    body .p-menu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-menubar .p-menuitem-link:hover .p-menuitem-icon,
    body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-contextmenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-megamenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-slidemenu .p-menuitem-link:hover .p-menuitem-icon {
      color: #000000; }

body .p-menu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: .5em 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-menu .p-shadow, body .p-menu.p-shadow {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-menu .p-menuitem {
    margin: 0; }
  body .p-menu.p-menu-overlay {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-menu .p-submenu-header {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    margin: 0 0 1px 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
    width: 100%;
    box-sizing: border-box;
    padding: .625em 1em; }
    body .p-menu .p-submenu-header .pi {
      color: #ffffff; }
  body .p-menu .p-menu-separator {
    height: 1px;
    background-color: #dbdbdb;
    width: 100%;
    box-sizing: border-box; }

body .p-menubar {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: .625em 1em; }
  body .p-menubar .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
    body .p-menubar .p-submenu-list .p-menuitem {
      margin: 0; }
    body .p-menubar .p-submenu-list .p-menu-separator {
      border: solid #dbdbdb;
      border-width: 1px 0 0 0; }
  body .p-menubar .p-menuitem {
    margin: 0; }
    body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }

body .p-contextmenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: .5em 0; }
  body .p-contextmenu .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-contextmenu .p-menuitem {
    margin: 0; }
    body .p-contextmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-contextmenu .p-menu-separator {
    border: solid #dbdbdb;
    border-width: 1px 0 0 0; }

body .p-tieredmenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: .5em 0; }
  body .p-tieredmenu .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-tieredmenu .p-menuitem {
    margin: 0; }
    body .p-tieredmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-tieredmenu .p-menu-separator {
    border: solid #dbdbdb;
    border-width: 1px 0 0 0; }
  body .p-tieredmenu.p-menu-overlay {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

body .p-slidemenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: .5em 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-slidemenu .p-submenu-list {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em 0;
    border: 0 none; }
  body .p-slidemenu .p-menuitem {
    margin: 0; }
    body .p-slidemenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-slidemenu .p-slidemenu-backward {
    background-color: #00529e;
    color: #ffffff;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none; }
    body .p-slidemenu .p-slidemenu-backward:hover {
      background-color: #0f2765;
      color: #ffffff; }
  body .p-slidemenu .p-menu-separator {
    border: solid #dbdbdb;
    border-width: 1px 0 0 0; }
  body .p-slidemenu.p-menu-overlay {
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

body .p-megamenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-megamenu .p-megamenu-submenu-header {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    margin: 0 0 1px 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border: 0 none;
    width: 100%;
    box-sizing: border-box;
    padding: .625em 1em; }
    body .p-megamenu .p-megamenu-submenu-header .pi {
      color: #ffffff; }
  body .p-megamenu .p-megamenu-panel {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: .5em;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  body .p-megamenu .p-menuitem {
    margin: 0; }
    body .p-megamenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #e8e8e8;
      color: #000000; }
      body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #000000; }
      body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon {
        color: #000000; }
  body .p-megamenu.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    right: 0.429em; }

body .p-tabmenu {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-tabmenu .p-tabmenu-nav {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    padding: 0;
    background-color: #ffffff;
    border: 0 none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-tabmenu .p-tabmenu-nav .pi {
      color: #ffffff; }
    body .p-tabmenu .p-tabmenu-nav:after {
      content: "";
      display: table;
      clear: both; }
    body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
      position: static;
      border-style: solid;
      border-width: 0 0 2px 0;
      margin: 0;
      -moz-border-radius-topleft: 0;
      -webkit-border-top-left-radius: 0;
      border-top-left-radius: 0;
      -moz-border-radius-topright: 0;
      -webkit-border-top-right-radius: 0;
      border-top-right-radius: 0;
      -moz-transition: border-color 0.3s;
      -o-transition: border-color 0.3s;
      -webkit-transition: border-color 0.3s;
      transition: border-color 0.3s; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
        color: #212121;
        padding: .625em 1em; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
          color: #212121; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
          color: #757575; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover {
        background-color: #e8e8e8;
        color: #000000; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-text {
          color: #000000; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-icon {
          color: #000000; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
        background-color: #ffffff;
        border-color: #4699ce;
        border-style: solid; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
          color: #00529e; }
        body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-icon {
          color: #00529e; }

body .p-panelmenu .p-panelmenu-header {
  background-color: #00529e;
  margin-bottom: 1px; }
  body .p-panelmenu .p-panelmenu-header a {
    padding: .625em 1em;
    color: #ffffff;
    font-size: 16px;
    border-bottom: 1px solid #ffffff; }
  body .p-panelmenu .p-panelmenu-header .pi {
    color: #ffffff; }
  body .p-panelmenu .p-panelmenu-header.p-highlight {
    background-color: #4699ce;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
    body .p-panelmenu .p-panelmenu-header.p-highlight a, body .p-panelmenu .p-panelmenu-header.p-highlight .pi {
      color: #ffffff; }

body .p-panelmenu .p-panelmenu-content {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  padding: 0; }
  body .p-panelmenu .p-panelmenu-content .p-menuitem {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin: 0; }
    body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
      border: 0 none;
      padding: .625em 1em;
      width: 100%;
      min-height: 2.750em;
      box-sizing: border-box;
      color: #212121;
      line-height: 1.5em;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0;
      position: relative; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
        display: inline-block;
        vertical-align: middle;
        float: none; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .pi {
        position: static;
        display: inline-block;
        vertical-align: middle; }

body .p-panelmenu .p-menu-parent .p-menu-list {
  margin-left: 28px; }

@media (max-width: 640px) {
  body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    display: none; } }

body .p-messages {
  margin: 1em 0; }
  body .p-messages .p-messages-wrapper {
    padding: 0.625em 1em; }
  body .p-messages ul {
    display: inline-block;
    margin-left: 0; }
  body .p-messages.p-messages-info {
    background-color: #2196F3;
    border-color: #2196F3;
    color: #ffffff; }
    body .p-messages.p-messages-info .p-messages-close {
      color: #ffffff; }
  body .p-messages.p-messages-warn {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #ffffff; }
    body .p-messages.p-messages-warn .p-messages-close {
      color: #ffffff; }
  body .p-messages.p-messages-error {
    background-color: #e62a10;
    border-color: #e62a10;
    color: #ffffff; }
    body .p-messages.p-messages-error .p-messages-close {
      color: #ffffff; }
  body .p-messages.p-messages-success {
    background-color: #8BC34A;
    border-color: #8BC34A;
    color: #ffffff; }
    body .p-messages.p-messages-success .p-messages-close {
      color: #ffffff; }
  body .p-messages .p-messages-close {
    text-decoration: none;
    right: .25em; }

body .p-messages .p-messages-icon {
  background: none;
  color: #ffffff;
  margin-top: -2px; }
  body .p-messages .p-messages-icon.pi-info-circle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-info-circle:before {
      content: "info"; }
  body .p-messages .p-messages-icon.pi-exclamation-triangle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-exclamation-triangle:before {
      content: "warning"; }
  body .p-messages .p-messages-icon.pi-times {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-times:before {
      content: "error_outline"; }
  body .p-messages .p-messages-icon.pi-check {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-messages .p-messages-icon.pi-check:before {
      content: "check_circle"; }

body .p-message {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-message.p-message-info {
    background-color: #2196F3;
    border: 1px solid #2196F3;
    color: #ffffff; }
    body .p-message.p-message-info .p-message-icon {
      color: #ffffff; }
  body .p-message.p-message-success {
    background-color: #8BC34A;
    border: 1px solid #8BC34A;
    color: #ffffff; }
    body .p-message.p-message-success .p-message-icon {
      color: #ffffff; }
  body .p-message.p-message-warn {
    background-color: #ffc107;
    border: 1px solid #ffc107;
    color: #ffffff; }
    body .p-message.p-message-warn .p-message-icon {
      color: #ffffff; }
  body .p-message.p-message-error {
    background-color: #e62a10;
    border: 1px solid #e62a10;
    color: #ffffff; }
    body .p-message.p-message-error .p-message-icon {
      color: #ffffff; }

body .p-message .p-message-icon {
  background: none;
  color: #ffffff; }
  body .p-message .p-message-icon.pi-info-circle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-info-circle:before {
      content: "info"; }
  body .p-message .p-message-icon.pi-exclamation-triangle {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-exclamation-triangle:before {
      content: "warning"; }
  body .p-message .p-message-icon.pi-times {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-times:before {
      content: "error_outline"; }
  body .p-message .p-message-icon.pi-check {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 1.75em; }
    body .p-message .p-message-icon.pi-check:before {
      content: "check_circle"; }

body .p-growl {
  top: 90px; }
  body .p-growl .p-growl-item-container {
    margin: 0 0 1em 0;
    opacity: 1; }
    body .p-growl .p-growl-item-container.p-growl-message-info {
      background-color: #2196F3;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-growl-message-warn {
      background-color: #ffc107;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-growl-message-error {
      background-color: #e62a10;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-growl-message-success {
      background-color: #8BC34A;
      color: #ffffff; }
      body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-icon-close {
        color: #ffffff; }
    body .p-growl .p-growl-item-container.p-shadow {
      -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-growl .p-growl-item .p-growl-image {
    background: none;
    font-size: 36px; }
    body .p-growl .p-growl-item .p-growl-image.pi-info-circle {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-info-circle:before {
        content: "info"; }
    body .p-growl .p-growl-item .p-growl-image.pi-exclamation-triangle {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-exclamation-triangle:before {
        content: "warning"; }
    body .p-growl .p-growl-item .p-growl-image.pi-times {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-times:before {
        content: "error_outline"; }
    body .p-growl .p-growl-item .p-growl-image.pi-check {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: 1.5em;
      /* Preferred icon size */
      display: inline-block;
      width: 1em;
      height: 1em;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      text-indent: 0;
      /* Support for all WebKit browsers. */
      -webkit-font-smoothing: antialiased;
      /* Support for Safari and Chrome. */
      text-rendering: optimizeLegibility;
      /* Support for Firefox. */
      -moz-osx-font-smoothing: grayscale;
      /* Support for IE. */
      font-feature-settings: 'liga';
      font-size: 1.75em; }
      body .p-growl .p-growl-item .p-growl-image.pi-check:before {
        content: "check_circle"; }
  body .p-growl .p-growl-item .p-growl-icon-close {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 1.5em;
    /* Preferred icon size */
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-indent: 0;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
    font-size: 24px; }
    body .p-growl .p-growl-item .p-growl-icon-close:before {
      content: "close"; }

body .p-dialog {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-dialog .p-dialog-titlebar {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #ffffff;
    color: #212121;
    padding: 0.625em 1em; }
    body .p-dialog .p-dialog-titlebar .p-dialog-title {
      font-size: 1.25em;
      letter-spacing: .005em;
      margin-top: .25em;
      line-height: 2em; }
    body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
      border: 0 none;
      padding: 0;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      height: 1.5em;
      width: 1.5em;
      text-align: center;
      margin-top: .5em; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover, body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:focus {
        background-color: #e8e8e8;
        color: #000000; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi {
        color: #757575;
        display: inline-block; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga'; }
        body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-maximize:before {
          content: "fullscreen"; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5em;
        /* Preferred icon size */
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        text-indent: 0;
        /* Support for all WebKit browsers. */
        -webkit-font-smoothing: antialiased;
        /* Support for Safari and Chrome. */
        text-rendering: optimizeLegibility;
        /* Support for Firefox. */
        -moz-osx-font-smoothing: grayscale;
        /* Support for IE. */
        font-feature-settings: 'liga'; }
        body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon .pi-window-minimize:before {
          content: "fullscreen_exit"; }
  body .p-dialog .p-dialog-content {
    padding: 0.625em 1em; }
  body .p-dialog .p-dialog-footer {
    text-align: right;
    border: 0 none;
    border-top: 1px solid #dbdbdb; }
    body .p-dialog .p-dialog-footer .p-button {
      background-color: #ffffff;
      color: #212121;
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      width: auto; }
      body .p-dialog .p-dialog-footer .p-button .pi {
        color: #212121; }
      body .p-dialog .p-dialog-footer .p-button:hover {
        background-color: #e8e8e8;
        color: #000000; }
  body .p-dialog .p-confirm-dialog-severity {
    margin: 0px .75em; }

body .p-lightbox {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-lightbox .p-lightbox-caption {
    padding: 0.625em 1em; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
      margin: 0; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-close {
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      -moz-transition: background-color 0.3s;
      -o-transition: background-color 0.3s;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      padding: 0;
      margin: 0;
      width: 1.5em;
      height: 1.5em; }
      body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
        background-color: #8197a5;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        -moz-transition: background-color 0.3s;
        -o-transition: background-color 0.3s;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s; }
  body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left {
    top: 40%; }
    body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right .pi, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left .pi {
      -moz-transition: color 0.3s;
      -o-transition: color 0.3s;
      -webkit-transition: color 0.3s;
      transition: color 0.3s;
      font-size: 3em;
      color: #8197a5; }
  body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }

body .p-overlaypanel {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  body .p-overlaypanel .p-overlaypanel-close {
    background-color: #4699ce;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
    right: -16px;
    top: -16px;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }
    body .p-overlaypanel .p-overlaypanel-close span {
      color: #ffffff;
      line-height: inherit; }
    body .p-overlaypanel .p-overlaypanel-close:hover {
      background-color: #274d8d; }

body .p-tooltip {
  opacity: 0.9;
  filter: alpha(opacity=90); }
  body .p-tooltip .p-tooltip-text {
    background-color: #323232; }
  body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #323232; }
  body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #323232; }
  body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #323232; }
  body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #323232; }

body .p-sidebar {
  background-color: #ffffff;
  border: 1px solid #d8d8d8; }
  body .p-sidebar .p-sidebar-close {
    color: #757575; }
    body .p-sidebar .p-sidebar-close:hover {
      color: #212121; }

body .p-draggable-dragging.p-default {
  padding: 0.625em 1em !important;
  background-color: #00529e; }

body .jqplot-target {
  font-family: "Roboto","Helvetica Neue",sans-serif; }

body .p-progressbar {
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  height: .5em;
  background-color: #8197a5;
  overflow: hidden;
  border: 0 none; }
  body .p-progressbar .p-progressbar-value {
    background-color: #00529e;
    color: #ffffff;
    border: 1px solid #00529e;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    height: .5em; }
    body .p-progressbar .p-progressbar-value .pi {
      color: #ffffff; }
  body .p-progressbar .p-progressbar-label {
    display: none !important; }

body .p-galleria .p-galleria-nav-prev {
  left: 0; }

body .p-galleria .p-galleria-nav-next {
  right: 0; }

body .p-inplace .p-inplace-display {
  -moz-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-inplace .p-inplace-display:hover {
    background-color: #e8e8e8; }

body .p-terminal .p-terminal-input {
  font-size: 14px; }

/* Add your customizations of theme here */
@font-face {
  font-family: "WALTER-Icons";
  src: url("./walter-icons-font/WALTER-Icons.eot");
  src: url("./walter-icons-font/WALTER-Icons.woff2") format("woff2"), url("./walter-icons-font/WALTER-Icons.woff") format("woff"), url("./walter-icons-font/WALTER-Icons.ttf") format("truetype"), url("./walter-icons-font/WALTER-Icons.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="lkw-"],
[class*=" lkw-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "WALTER-Icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lkw-external-link:before {
  content: ""; }

.lkw-chain:before {
  content: ""; }

.lkw-minus-square-o:before {
  content: ""; }

.lkw-check-square-o:before {
  content: ""; }

.lkw-flag:before {
  content: ""; }

.lkw-history:before {
  content: ""; }

.lkw-square-o:before {
  content: ""; }

.lkw-bell:before {
  content: ""; }

.lkw-bell-square:before {
  content: ""; }

.lkw-laptop:before {
  content: ""; }

.lkw-puzzle:before {
  content: ""; }

.lkw-schindlauer:before {
  content: ""; }

.lkw-bookmark-alternative:before {
  content: ""; }

.lkw-bookmark-star:before {
  content: ""; }

.lkw-mobile-envelope:before {
  content: ""; }

.lkw-signal-lost:before {
  content: ""; }

.lkw-signal:before {
  content: ""; }

.lkw-licenseplate:before {
  content: ""; }

.lkw-trailer-full:before {
  content: ""; }

.lkw-trailer-half:before {
  content: ""; }

.lkw-truck-trailer-full:before {
  content: ""; }

.lkw-truck-trailer-half:before {
  content: ""; }

.lkw-truck:before {
  content: ""; }

.lkw-truck-marker:before {
  content: ""; }

.lkw-circle-o:before {
  content: ""; }

.lkw-duplicate:before {
  content: ""; }

.lkw-filter:before {
  content: ""; }

.lkw-forward:before {
  content: ""; }

.lkw-map:before {
  content: ""; }

.lkw-refresh:before {
  content: ""; }

.lkw-reload:before {
  content: ""; }

.lkw-train:before {
  content: ""; }

.lkw-lorry:before {
  content: ""; }

.lkw-whatsapp:before {
  content: ""; }

.lkw-map-marker:before {
  content: ""; }

.lkw-anchor:before {
  content: ""; }

.lkw-arrow-down:before {
  content: ""; }

.lkw-arrow-up:before {
  content: ""; }

.lkw-angle-bold-down:before {
  content: ""; }

.lkw-angle-bold-left:before {
  content: ""; }

.lkw-angle-bold-right:before {
  content: ""; }

.lkw-angle-bold-up:before {
  content: ""; }

.lkw-angle-down:before {
  content: ""; }

.lkw-angle-left:before {
  content: ""; }

.lkw-angle-right:before {
  content: ""; }

.lkw-angle-up:before {
  content: ""; }

.lkw-triangle-down:before {
  content: ""; }

.lkw-triangle-left:before {
  content: ""; }

.lkw-triangle-right:before {
  content: ""; }

.lkw-triangle-up:before {
  content: ""; }

.lkw-sort:before {
  content: ""; }

.lkw-plus-square:before {
  content: ""; }

.lkw-minus-circle:before {
  content: ""; }

.lkw-plus:before {
  content: ""; }

.lkw-minus-square:before {
  content: ""; }

.lkw-minus:before {
  content: ""; }

.lkw-check-circle:before {
  content: ""; }

.lkw-check:before {
  content: ""; }

.lkw-question-circle:before {
  content: ""; }

.lkw-question:before {
  content: ""; }

.lkw-info-circle:before {
  content: ""; }

.lkw-info:before {
  content: ""; }

.lkw-close-circle:before {
  content: ""; }

.lkw-close:before {
  content: ""; }

.lkw-exclamation-circle:before {
  content: ""; }

.lkw-exclamation-triangle:before {
  content: ""; }

.lkw-thumbview:before {
  content: ""; }

.lkw-align-justify:before {
  content: ""; }

.lkw-navicon:before {
  content: ""; }

.lkw-burger:before {
  content: ""; }

.lkw-more:before {
  content: ""; }

.lkw-house-small:before {
  content: ""; }

.lkw-arrow-return:before {
  content: ""; }

.lkw-mail-closed:before {
  content: ""; }

.lkw-mail-open:before {
  content: ""; }

.lkw-attachment:before {
  content: ""; }

.lkw-fax:before {
  content: ""; }

.lkw-phone:before {
  content: ""; }

.lkw-phone-book:before {
  content: ""; }

.lkw-print:before {
  content: ""; }

.lkw-smartphone-portrait:before {
  content: ""; }

.lkw-smartphone-landscape:before {
  content: ""; }

.lkw-comment:before {
  content: ""; }

.lkw-comment-o:before {
  content: ""; }

.lkw-speech-bubbles:before {
  content: ""; }

.lkw-pencil:before {
  content: ""; }

.lkw-trashcan:before {
  content: ""; }

.lkw-table:before {
  content: ""; }

.lkw-calendar:before {
  content: ""; }

.lkw-search:before {
  content: ""; }

.lkw-move:before {
  content: ""; }

.lkw-star:before {
  content: ""; }

.lkw-location-arrow:before {
  content: ""; }

.lkw-lock-close:before {
  content: ""; }

.lkw-user:before {
  content: ""; }

.lkw-user-group:before {
  content: ""; }

.lkw-settings:before {
  content: ""; }

.lkw-logout:before {
  content: ""; }

.f-smartview .layout-container.menu-layout-static .layout-main {
  margin-left: 0; }

.f-smartview .layout-container .topbar .topbar-left {
  padding-right: 5px; }

.f-smartview .p-dialog {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  max-height: 100vh !important;
  max-width: 100vw !important;
  min-width: 100vw !important;
  overflow-y: auto; }

.f-smartview .f-global-messages {
  position: fixed;
  z-index: 10000;
  width: 100%;
  right: 0;
  top: 0; }

.f-smartview .f-smartview-body {
  margin: 0.5em;
  margin-bottom: 0em;
  background-color: #f7f7f7; }
  .f-smartview .f-smartview-body .p-card {
    margin-bottom: 0.5em; }
  .f-smartview .f-smartview-body .f-smartview-trailer-selection {
    margin-bottom: 0.5em; }
  .f-smartview .f-smartview-body .p-button {
    width: 100%; }
  .f-smartview .f-smartview-body .f-card-damages .p-tree .p-tree-container .p-treenode .p-treenode-content {
    display: flex; }
    .f-smartview .f-smartview-body .f-card-damages .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
      display: flex;
      flex-grow: 1; }
  .f-smartview .f-smartview-body .f-card-damages .f-smart-damage {
    display: flex;
    flex-grow: 1;
    position: relative; }
    .f-smartview .f-smartview-body .f-card-damages .f-smart-damage .f-smart-damage-label {
      flex-grow: 1; }
      .f-smartview .f-smartview-body .f-card-damages .f-smart-damage .f-smart-damage-label.f-smart-damage-label-readonly {
        color: #7d7d7d; }
    .f-smartview .f-smartview-body .f-card-damages .f-smart-damage .f-smart-damage-foto {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      font-size: 28px; }
      .f-smartview .f-smartview-body .f-card-damages .f-smart-damage .f-smart-damage-foto.f-smart-damage-foto-disabled {
        color: lightgrey; }

.f-smartview .f-view .f-view-container .f-view-header {
  flex-direction: column;
  min-height: 4em; }
  .f-smartview .f-view .f-view-container .f-view-header .f-view-header-title {
    position: relative;
    font-size: 16px;
    flex-grow: 0;
    padding: 0; }
    .f-smartview .f-view .f-view-container .f-view-header .f-view-header-title .f-smartview-header-orderID {
      margin-left: auto;
      font-size: 12px; }
    .f-smartview .f-view .f-view-container .f-view-header .f-view-header-title .f-smartview-header-refresh {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      max-height: 28px; }
      .f-smartview .f-view .f-view-container .f-view-header .f-view-header-title .f-smartview-header-refresh > i {
        font-size: 28px;
        cursor: pointer; }
  .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header {
    display: flex;
    margin: -0.5em;
    margin-bottom: 0.5em;
    padding: 0.5em;
    text-align: right;
    background-color: white;
    border-bottom: 1px solid #cacaca; }
    .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header .f-smartview-header-trailer,
    .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header .f-smartview-header-inspection {
      color: #00529e;
      white-space: nowrap; }
    .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header .f-smartview-header-license {
      font-weight: bold; }
    .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header .f-smartview-header-ilu,
    .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header .f-smartview-header-inspection-entry {
      font-size: 11px; }
    .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header .f-smartview-header-trailer {
      text-align: left; }
    .f-smartview .f-view .f-view-container .f-view-header .f-smartview-header .f-smartview-header-inspection {
      text-align: right;
      flex-grow: 1; }

.f-smartview .p-autocomplete-panel {
  position: fixed !important;
  max-height: 50vh !important;
  top: unset !important;
  left: 0 !important;
  bottom: 0 !important; }

.f-smartview .p-menubar .p-menubar-root-list {
  width: 100%;
  display: flex; }
  .f-smartview .p-menubar .p-menubar-root-list > .p-menuitem {
    flex-grow: 1; }

input:disabled {
  color: black; }

.f-highlight-animation {
  -webkit-animation: highlighting 0.5s infinite;
  -moz-animation: highlighting 0.5s infinite;
  -o-animation: highlighting 0.5s infinite;
  animation: highlighting 0.5s infinite; }

@-webkit-keyframes highlighting {
  0% {
    color: #dd1111;
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 100% 2px, 100% 1px; }
  50% {
    color: #dd1111; }
  99% {
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd); } }

@-moz-keyframes highlighting {
  0% {
    color: #dd1111;
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 100% 2px, 100% 1px; }
  50% {
    color: #dd1111; }
  99% {
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd); } }

@-o-keyframes highlighting {
  0% {
    color: #dd1111;
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 100% 2px, 100% 1px; }
  50% {
    color: #dd1111; }
  99% {
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd); } }

@keyframes highlighting {
  0% {
    color: #dd1111;
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd);
    background-size: 100% 2px, 100% 1px; }
  50% {
    color: #dd1111; }
  99% {
    background-image: linear-gradient(to bottom, #aa0000, #aa0000), linear-gradient(to bottom, #bdbdbd, #bdbdbd); } }

.login-body {
  position: relative;
  min-height: 640px;
  height: 100vh;
  display: flex;
  flex-direction: column; }
  .login-body .f-login-background {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 50%;
    width: 100%;
    background: url("./login-background2.png") top left no-repeat #f7f7f7;
    background-size: cover;
    background-position: center; }
  .login-body .login-panel {
    z-index: 100; }
    .login-body .login-panel .f-login-card {
      margin: auto; }
  .login-body .f-login-footer {
    position: absolute;
    bottom: 1em;
    margin: auto;
    width: 100%;
    text-align: center; }

.menu-layout-static.layout-container .layout-menu {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }
  .menu-layout-static.layout-container .layout-menu .p-scrollpanel .menu-scroll-content {
    width: 100%; }

.menu-layout-static.layout-container .ultima-menu > li > a,
.menu-layout-static.layout-container .ultima-menu > li > ul > li > a,
.menu-layout-static.layout-container .ultima-menu > li > ul > li > ul > li > a {
  display: flex;
  padding-right: 0.5em; }
  .menu-layout-static.layout-container .ultima-menu > li > a > span,
  .menu-layout-static.layout-container .ultima-menu > li > ul > li > a > span,
  .menu-layout-static.layout-container .ultima-menu > li > ul > li > ul > li > a > span {
    margin-top: auto;
    text-align: left;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

.menu-layout-static.layout-container .ultima-menu .active-menuitem {
  border-top: 1px #bdbdbd solid;
  border-bottom: 1px #bdbdbd solid;
  margin-bottom: 1px; }

.menu-layout-static.layout-container.layout-menu-static-icons .layout-main, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-main {
  margin-left: 50px; }

.menu-layout-static.layout-container.layout-menu-static-icons .layout-menu, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu {
  width: 50px; }
  .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > a,
  .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > a,
  .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > ul > li > a, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > a,
  .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > a,
  .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > ul > li > a {
    padding: 0.5em 1em;
    text-align: center;
    position: relative; }
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > a > span,
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > a > span,
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > ul > li > a > span, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > a > span,
    .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > a > span,
    .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > ul > li > a > span {
      text-overflow: unset; }
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > a .menuitem-badge,
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > a .menuitem-badge,
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > ul > li > a .menuitem-badge, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > a .menuitem-badge,
    .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > a .menuitem-badge,
    .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > ul > li > a .menuitem-badge {
      display: none; }
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > a .submenu-icon,
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > a .submenu-icon,
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > ul > li > a .submenu-icon, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > a .submenu-icon,
    .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > a .submenu-icon,
    .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > ul > li > a .submenu-icon {
      position: absolute;
      top: 0;
      right: 5px; }
  .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > a, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > a {
    background-color: #e8e8e8; }
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > a:hover, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > a:hover {
      background-color: #dfdfdf; }
  .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > ul > li > a, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > ul > li > a {
    background-color: #dfdfdf; }
    .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu .ultima-menu > li > ul > li > ul > li > a:hover, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu .ultima-menu > li > ul > li > ul > li > a:hover {
      background-color: #d8d8d8; }
  .menu-layout-static.layout-container.layout-menu-static-icons .layout-menu h3, .menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu h3 {
    display: none; }

.menu-layout-static.layout-container.layout-menu-static-inactive .layout-main {
  margin-left: unset; }

.menu-layout-static.layout-container.layout-menu-static-inactive .layout-menu {
  margin-left: -50px; }

.f-login-logo {
  background: #00529e;
  padding: 15px; }

body .p-tabview .p-tabview-nav > .f-tab-header-badged {
  font-weight: bold; }
  body .p-tabview .p-tabview-nav > .f-tab-header-badged .p-tabview-right-icon::before {
    color: #00529e; }

.f-settings .f-settings-card > .p-card-body > .p-card-content {
  display: flex;
  flex-direction: column; }
  .f-settings .f-settings-card > .p-card-body > .p-card-content .f-settings-tabview {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
    .f-settings .f-settings-card > .p-card-body > .p-card-content .f-settings-tabview .p-tabview-panels {
      flex-grow: 1; }
      .f-settings .f-settings-card > .p-card-body > .p-card-content .f-settings-tabview .p-tabview-panels .p-tabview-panel {
        height: 100%; }

.f-settings .f-settings-description {
  margin-bottom: 1em; }

@media (max-width: 1300px) {
  .f-settings .f-settings-inspection-tab {
    flex-direction: column; } }

.f-settings .f-settings-inspection-tab {
  display: flex; }
  .f-settings .f-settings-inspection-tab .f-settings-inspection-type {
    flex-basis: 33%;
    flex-grow: 1;
    padding: 1em;
    height: 100%; }
    .f-settings .f-settings-inspection-tab .f-settings-inspection-type .f-settings-inspection-header {
      display: flex;
      margin: 0 -0.5em 1em -0.5em;
      padding: 0.25em 0.25em;
      background-color: #dfe5e8;
      border: 1px solid #dbdbdb;
      border-radius: 4px; }
      .f-settings .f-settings-inspection-tab .f-settings-inspection-type .f-settings-inspection-header:hover {
        cursor: pointer; }
      .f-settings .f-settings-inspection-tab .f-settings-inspection-type .f-settings-inspection-header .f-settings-inspection-header-title {
        flex-grow: 1;
        text-align: center;
        font-weight: bold; }
    .f-settings .f-settings-inspection-tab .f-settings-inspection-type .f-settings-inspection-trigger-description {
      margin-bottom: 1em; }

.f-settings .f-settings-inspection-example {
  margin-top: 1em; }
  .f-settings .f-settings-inspection-example .f-settings-inspection-example-title {
    font-weight: bold; }
  .f-settings .f-settings-inspection-example .f-settings-inspection-example-date {
    margin-bottom: 0.5em; }
  .f-settings .f-settings-inspection-example .f-settings-inspection-examples {
    display: flex; }
    .f-settings .f-settings-inspection-example .f-settings-inspection-examples .f-settings-inspection-example-entry {
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex-grow: 1; }
      .f-settings .f-settings-inspection-example .f-settings-inspection-examples .f-settings-inspection-example-entry > div {
        margin: auto; }

.f-settings .f-settings-bcm-card {
  display: flex;
  max-height: 10em; }

.f-settings .f-settings-tire-tab {
  display: flex;
  flex-direction: row;
  height: 100%; }
  .f-settings .f-settings-tire-tab .f-settings-tire-tab-section {
    margin-right: 0.5em;
    padding: 1em;
    border: 1px solid #dbdbdb;
    background-color: #f7f7f7;
    width: 50%; }
    .f-settings .f-settings-tire-tab .f-settings-tire-tab-section .f-input-label {
      width: 50%; }
    .f-settings .f-settings-tire-tab .f-settings-tire-tab-section .f-input-addon {
      display: none; }
  .f-settings .f-settings-tire-tab .f-settings-tire-tab-table {
    width: 50%; }
    .f-settings .f-settings-tire-tab .f-settings-tire-tab-table .f-settings-tire-tab-table-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 0.5em; }
      .f-settings .f-settings-tire-tab .f-settings-tire-tab-table .f-settings-tire-tab-table-header .f-settings-tire-tab-table-header-title {
        float: left;
        font-size: 1.5em;
        color: #00529e;
        font-weight: normal; }
      .f-settings .f-settings-tire-tab .f-settings-tire-tab-table .f-settings-tire-tab-table-header .p-menuitem-link {
        background-color: #efefef; }
    .f-settings .f-settings-tire-tab .f-settings-tire-tab-table td {
      padding: 0.5em; }

body .f-solvency-critical .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
  background-color: #aa0000; }

.f-badge-cell {
  display: flex; }
  .f-badge-cell .f-badge-content {
    display: flex;
    margin: auto; }
  .f-badge-cell .f-badge-number {
    color: #00529e;
    margin-top: -0.25em; }

.f-grid.f-order-shipment-content {
  display: flex;
  flex-direction: column; }
  .f-grid.f-order-shipment-content .f-order-shipment-title {
    display: flex;
    flex-direction: column;
    font-size: 16px; }
    .f-grid.f-order-shipment-content .f-order-shipment-title > .p-card > .p-card-body {
      padding: 0.5em 1em; }
      .f-grid.f-order-shipment-content .f-order-shipment-title > .p-card > .p-card-body > .p-card-content {
        display: flex; }
    .f-grid.f-order-shipment-content .f-order-shipment-title .f-order-shipment-title-text {
      flex-grow: 1;
      margin: auto 0; }
    .f-grid.f-order-shipment-content .f-order-shipment-title .f-order-shipment-title-print {
      display: flex;
      font-size: 16px;
      background-color: #00529e;
      color: white;
      padding: 0.25em;
      border-radius: 5px;
      cursor: pointer; }
      .f-grid.f-order-shipment-content .f-order-shipment-title .f-order-shipment-title-print .f-print-button-text {
        margin: auto 0.5em; }
      .f-grid.f-order-shipment-content .f-order-shipment-title .f-order-shipment-title-print:hover {
        background-color: #0f2765; }
  .f-grid.f-order-shipment-content .f-order-shipment-tables {
    flex-grow: 1;
    display: flex;
    flex-direction: column; }
    .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-thead tr th:nth-last-child(2), .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-thead tr th:nth-last-child(7),
    .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-thead tr td:nth-last-child(2),
    .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-thead tr td:nth-last-child(7),
    .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-tbody tr th:nth-last-child(2),
    .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-tbody tr th:nth-last-child(7),
    .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-tbody tr td:nth-last-child(2),
    .f-grid.f-order-shipment-content .f-order-shipment-tables .f-order-shipment-table table .p-datatable-tbody tr td:nth-last-child(7) {
      border-left: 2px #00529e solid; }

.p-progress-spinner.f-task-spinner {
  height: 20px;
  width: 20px;
  margin-right: 1em; }

.f-topbar-task-title {
  margin: 0.5em 1em;
  font-size: 16px; }

.f-task .f-task-sidebar {
  padding: 0; }
  .f-task .f-task-sidebar .p-sidebar-close {
    position: absolute;
    right: 0.5em;
    color: white; }
  .f-task .f-task-sidebar > div {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden; }
    .f-task .f-task-sidebar > div .f-task-area {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 100%;
      max-height: 100%;
      overflow: hidden; }
      .f-task .f-task-sidebar > div .f-task-area .f-task-title {
        font-size: 16px;
        padding: 1em;
        padding-top: 1em;
        color: white;
        background-color: #00529e; }
      .f-task .f-task-sidebar > div .f-task-area .f-task-summary {
        padding: 1em;
        background-color: #dfe5e8;
        border: 1px #dbdbdb solid;
        border-bottom: 0px; }
        .f-task .f-task-sidebar > div .f-task-area .f-task-summary .f-task-summary-entry {
          font-weight: bold;
          text-align: center;
          padding: 0.25em; }
      .f-task .f-task-sidebar > div .f-task-area .f-task-container {
        flex-grow: 1;
        overflow-y: auto;
        border-top: 1px white solid; }
        .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item {
          display: flex;
          flex-direction: column;
          padding: 1em 0.5em;
          position: relative;
          border-bottom: 1px white solid; }
          .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-date {
            position: absolute;
            font-size: 10px;
            top: 0;
            right: 0.25em; }
          .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-description {
            display: flex; }
            .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-description .f-task-item-icon {
              margin: auto 0.5em auto 0; }
            .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-description .f-task-item-content {
              display: flex;
              flex-direction: column;
              flex-grow: 1; }
              .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-description .f-task-item-content .f-task-item-title {
                flex-grow: 1;
                font-weight: bold;
                margin: auto auto auto 0; }
          .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks {
            margin-top: 0.5em; }
            .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset {
              padding: 0.5em;
              border: 0;
              border-radius: 0;
              background-color: white; }
              .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset > .p-fieldset-legend {
                padding: 0;
                margin-left: -0.5em;
                border-radius: 0;
                background-color: white; }
                .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset > .p-fieldset-legend > a {
                  padding: 0.5em 0.25em; }
                .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset > .p-fieldset-legend .p-fieldset-toggler {
                  background-color: transparent;
                  color: #00529e;
                  border-radius: 0;
                  margin: 0;
                  box-shadow: none;
                  font-size: 1em; }
                .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset > .p-fieldset-legend .p-fieldset-legend-text {
                  margin-right: 0.5em; }
              .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset .p-fieldset-content > .f-task-item {
                padding: 0.25em;
                border-bottom: 0;
                font-size: 12px; }
                .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset .p-fieldset-content > .f-task-item .f-task-item-date {
                  font-size: 9px; }
                .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-item .f-task-item-subtasks > .p-fieldset .p-fieldset-content > .f-task-item .f-task-item-title {
                  font-weight: unset; }
        .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-no-items {
          display: flex;
          height: 100%; }
          .f-task .f-task-sidebar > div .f-task-area .f-task-container .f-task-no-items .f-task-no-items-text {
            flex-grow: 1;
            margin: auto;
            text-align: center;
            font-size: 16px;
            color: #8197a5; }
      .f-task .f-task-sidebar > div .f-task-area .f-task-active {
        background-color: white;
        color: #00529e; }
      .f-task .f-task-sidebar > div .f-task-area .f-task-failed {
        background-color: #ffcccc;
        color: #aa0000; }
      .f-task .f-task-sidebar > div .f-task-area .f-task-done {
        background-color: #ccffcc;
        color: #00aa00; }

.f-task .f-task-item-render .f-task-license-plate {
  font-size: 12px; }

.f-task .f-task-item-render .f-task-identifier {
  font-size: 12px; }

.f-topbar-task-show-all {
  border-top: 1px #dbdbdb solid; }

.f-topbar-task-item,
.f-topbar-task-no-item {
  display: flex;
  border-top: 1px #dbdbdb solid;
  background-color: #efefef;
  padding: 0.5em 1em;
  position: relative; }
  .f-topbar-task-item > .f-topbar-task-item-text,
  .f-topbar-task-no-item > .f-topbar-task-item-text {
    flex-grow: 1;
    margin: auto 0em;
    padding: 0.6em 0em 0em 0em; }
  .f-topbar-task-item > .f-topbar-task-item-date,
  .f-topbar-task-no-item > .f-topbar-task-item-date {
    color: #4699ce;
    font-size: 10px;
    position: absolute;
    top: 2px; }
  .f-topbar-task-item > .f-topbar-task-item-more,
  .f-topbar-task-no-item > .f-topbar-task-item-more {
    margin: auto; }

.f-overview-refresh {
  color: orange;
  position: relative;
  overflow: visible;
  min-height: 1.5em; }
  .f-overview-refresh > i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .f-overview-refresh:hover {
    cursor: pointer;
    color: red; }

.f-bug-report-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 100000;
  background-image: radial-gradient(white, rgba(255, 255, 255, 0.5)); }
  .f-bug-report-overlay .f-bug-report-content {
    margin: auto;
    text-align: center; }

.f-email-dialog .p-dialog-content {
  position: relative; }
  .f-email-dialog .p-dialog-content .f-email-dialog-attachments {
    display: flex;
    flex-direction: row; }
    .f-email-dialog .p-dialog-content .f-email-dialog-attachments .f-doc-list {
      width: 100%; }

.f-email-sending,
.f-executing-overlay {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 10; }
  .f-email-sending .f-email-sending-content,
  .f-executing-overlay .f-email-sending-content, .f-email-sending
  .f-executing-overlay-content,
  .f-executing-overlay
  .f-executing-overlay-content {
    display: flex;
    flex-direction: column;
    margin: auto;
    text-align: center; }
    .f-email-sending .f-email-sending-content > span,
    .f-executing-overlay .f-email-sending-content > span, .f-email-sending
    .f-executing-overlay-content > span,
    .f-executing-overlay
    .f-executing-overlay-content > span {
      color: #7f7f7f; }

.f-timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%; }
  .f-timeline .f-timeline-topbar {
    top: 10.95em;
    margin: -0.3em;
    margin-bottom: 0.3em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0.75em;
    z-index: 10;
    background-color: white;
    border-bottom: 1px solid #dbdbdb; }
    .f-timeline .f-timeline-topbar .f-timeline-scroll,
    .f-timeline .f-timeline-topbar .f-timeline-scroll-disabled {
      display: flex;
      margin-right: 0.5em; }
      .f-timeline .f-timeline-topbar .f-timeline-scroll .f-timeline-scroll-label,
      .f-timeline .f-timeline-topbar .f-timeline-scroll-disabled .f-timeline-scroll-label {
        margin: auto;
        margin-right: 0.5em; }
    .f-timeline .f-timeline-topbar .f-timeline-scroll-disabled {
      color: #d5d5d5; }
    .f-timeline .f-timeline-topbar .f-timeline-scroll:hover {
      color: #00529e;
      cursor: pointer; }
    .f-timeline .f-timeline-topbar .f-timeline-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0.5em; }
      .f-timeline .f-timeline-topbar .f-timeline-filter .f-timeline-filter-label {
        margin-right: 1em; }
    .f-timeline .f-timeline-topbar .f-timeline-view,
    .f-timeline .f-timeline-topbar .f-timeline-view-active {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0.5em;
      cursor: pointer; }
    .f-timeline .f-timeline-topbar .f-timeline-view-active {
      color: #00529e; }
  .f-timeline .f-timeline-card {
    flex-grow: 1; }
    .f-timeline .f-timeline-card.p-card {
      margin-bottom: 0; }
      .f-timeline .f-timeline-card.p-card .p-card-body {
        height: 100%; }
        .f-timeline .f-timeline-card.p-card .p-card-body > .p-card-content {
          height: 100%;
          display: flex;
          flex-direction: column; }
    .f-timeline .f-timeline-card .f-timeline-container {
      display: flex;
      flex-direction: column;
      min-height: 500px;
      flex-grow: 1; }
      .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list {
        display: flex;
        width: 100%;
        justify-content: center; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-label-content {
          max-width: 70%;
          word-wrap: break-word;
          font-size: 14px;
          margin: 0 1em;
          flex-grow: 1; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-label-content .f-timeline-item-label-title {
            font-size: 1.1em;
            text-transform: uppercase;
            white-space: nowrap; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-label-content .f-timeline-item-label-subtitle {
            font-size: 0.8em; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-target {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-align: right;
          color: #333333; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-target.f-timeline-item-target-clickable {
            color: #00529e;
            cursor: pointer; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-target .f-timeline-item-target-id,
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-target .f-timeline-item-target-icon {
            margin: 0 0.5em; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-icon.f-timeline-item-red {
          color: #d84a38; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-icon.f-timeline-item-orange {
          color: #f89406; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-icon.f-timeline-item-green {
          color: #51a351; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-list .f-timeline-events-list-table .f-timeline-item-icon.f-timeline-item-blue {
          color: #2f96b4; }
      .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 1;
        border: 1px #dbdbdb solid;
        background-color: #efefef;
        margin-bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        padding-top: 10em;
        cursor: default; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-progressspinner {
          margin: auto; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-no-entries {
          margin: auto;
          font-size: 16px; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-event-line {
          position: absolute;
          top: 1em;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 0;
          background-image: linear-gradient(to top, #8197a5 0%, #8197a5 calc(100% - 10em), transparent calc(100% - 1em));
          background-size: 2px 100%;
          background-repeat: no-repeat;
          background-position: center;
          pointer-events: none; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-start;
          margin-bottom: 2em;
          height: 100%; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-dot {
            position: relative;
            min-height: 3em;
            max-height: 3em;
            min-width: 3em;
            max-width: 3em;
            margin: 0.5em;
            overflow: visible;
            border-radius: 50%; }
            .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-dot > i {
              color: #333333;
              font-size: 2em; }
              .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-dot > i.f-timeline-item-icon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
                cursor: pointer; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-red {
            background-color: #d84a38; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-orange {
            background-color: #f89406; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-green {
            background-color: #51a351; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-blue {
            background-color: #2f96b4; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-placeholder {
            min-width: calc(50% - 2em);
            max-width: calc(50% - 2em);
            height: 0; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content {
            display: flex;
            width: 50%;
            white-space: nowrap;
            overflow: visible; }
            .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card {
              min-width: 50%;
              max-width: 100%;
              padding: 0.5em;
              margin-top: 2em;
              margin-left: -1.5em;
              margin-bottom: 0;
              display: flex;
              flex-direction: row;
              overflow-x: hidden; }
              .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body,
              .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0.25em;
                margin: 0; }
                .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary,
                .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary {
                  display: flex;
                  width: 100%; }
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-time,
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-source,
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-time,
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-source {
                    font-size: 12px;
                    color: #333333;
                    text-align: left;
                    padding: 0;
                    margin: 0; }
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-time > div,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-source > div,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-time > div,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-source > div {
                      text-align: left; }
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-target,
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-target {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 12px;
                    text-align: right;
                    color: #333333; }
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-target.f-timeline-item-target-clickable,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-target.f-timeline-item-target-clickable {
                      color: #00529e;
                      cursor: pointer; }
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-target .f-timeline-item-target-id,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-target .f-timeline-item-target-icon,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-target .f-timeline-item-target-id,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-target .f-timeline-item-target-icon {
                      margin: 0 0.5em; }
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-label,
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-label {
                    max-width: 70%;
                    word-wrap: break-word;
                    font-size: 14px;
                    color: #00529e;
                    margin: 0 1em;
                    flex-grow: 1; }
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-label .f-timeline-item-label-title,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-label .f-timeline-item-label-title {
                      font-size: 1.1em;
                      text-transform: uppercase;
                      white-space: nowrap; }
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-label .f-timeline-item-label-subtitle,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-label .f-timeline-item-label-subtitle {
                      font-size: 0.8em; }
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-expander,
                  .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-expander {
                    margin-left: 1em; }
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-content-summary .f-timeline-item-expander-hidden,
                    .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-content-summary .f-timeline-item-expander-hidden {
                      display: none; }
            .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content .f-timeline-item-content-detail {
              display: flex;
              flex-direction: column;
              width: 100%;
              margin-top: 1em;
              overflow: hidden; }
              .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content .f-timeline-item-content-detail .f-timeline-item-content-detail-info .p-progress-spinner {
                width: 100%; }
              .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item .f-timeline-item-content .f-timeline-item-content-detail .f-timeline-item-content-detail-images {
                margin-top: 0.5em; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) {
            flex-direction: row-reverse; }
            .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content {
              flex-direction: column;
              align-items: flex-end; }
              .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content > .p-card {
                margin-right: -1.5em; }
                .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content > .p-card .f-timeline-item-content-summary {
                  flex-direction: row-reverse; }
                .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-target,
                .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-target {
                  flex-direction: row-reverse; }
                .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content > .p-card .p-card-body .f-timeline-item-expander,
                .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content > .p-card .p-card-content .f-timeline-item-expander {
                  margin-left: 0;
                  margin-right: 1em; }
              .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-item:nth-of-type(even) .f-timeline-item-content .f-timeline-item-content-detail {
                margin-right: 0; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container .f-timeline-position-marker {
          position: absolute;
          left: 50%;
          width: 0;
          height: 0; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container #timelineStart {
          top: 0; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-events-container #timelineEnd {
          bottom: 0; }
      .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-top,
      .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-bottom {
        position: fixed;
        background-color: #00529e;
        right: 5em;
        min-height: 3em;
        max-height: 3em;
        min-width: 3em;
        max-width: 3em;
        margin: 0;
        border-radius: 50%; }
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-top > i,
        .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-bottom > i {
          color: #333333;
          font-size: 2em; }
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-top > i.f-timeline-position-arrow-icon,
          .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-bottom > i.f-timeline-position-arrow-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            cursor: pointer; }
      .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-top {
        top: 250px; }
      .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-bottom {
        bottom: 100px; }
      .f-timeline .f-timeline-card .f-timeline-container .f-timeline-position-arrow-hidden {
        display: none; }

.f-chat {
  height: 100%; }
  .f-chat.p-card .p-card-body {
    height: 100%;
    padding: 0.5em; }
    .f-chat.p-card .p-card-body > .p-card-content {
      height: 100%;
      display: flex;
      flex-direction: column; }
  .f-chat .f-chat-container {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    flex-grow: 1; }
    .f-chat .f-chat-container .f-chat-area-container {
      flex-grow: 1;
      border: 1px #dbdbdb solid;
      margin-bottom: 1em;
      overflow-y: auto; }
      .f-chat .f-chat-container .f-chat-area-container .f-chat-area {
        display: flex;
        flex-direction: column-reverse;
        background-color: #efefef;
        padding: 1em;
        min-height: 100%; }
        .f-chat .f-chat-container .f-chat-area-container .f-chat-area .f-chat-msg {
          display: flex; }
          .f-chat .f-chat-container .f-chat-area-container .f-chat-area .f-chat-msg > .p-card {
            min-width: 25%;
            max-width: 75%; }
            .f-chat .f-chat-container .f-chat-area-container .f-chat-area .f-chat-msg > .p-card .p-card-body {
              padding-top: 0; }
          .f-chat .f-chat-container .f-chat-area-container .f-chat-area .f-chat-msg .f-chat-msg-space {
            flex-grow: 1; }
          .f-chat .f-chat-container .f-chat-area-container .f-chat-area .f-chat-msg .f-chat-msg-header {
            display: flex;
            font-size: 12px;
            padding: 0.5em;
            color: #00529e; }
            .f-chat .f-chat-container .f-chat-area-container .f-chat-area .f-chat-msg .f-chat-msg-header .f-chat-username {
              flex-grow: 1;
              margin-right: 2em; }
        .f-chat .f-chat-container .f-chat-area-container .f-chat-area .f-chat-msg-own > .p-card {
          background-color: rgba(0, 82, 158, 0.25); }
    .f-chat .f-chat-container .f-chat-field {
      display: flex; }
      .f-chat .f-chat-container .f-chat-field .f-chat-field-area {
        flex-grow: 1; }
      .f-chat .f-chat-container .f-chat-field .f-chat-field-send {
        margin: 0 1em;
        padding-top: 2.2em;
        padding-bottom: 0.8em; }
        .f-chat .f-chat-container .f-chat-field .f-chat-field-send .f-chat-field-send-content {
          height: 100%;
          display: flex;
          flex-direction: column;
          text-align: center; }
          .f-chat .f-chat-container .f-chat-field .f-chat-field-send .f-chat-field-send-content > i {
            margin: auto;
            font-size: 2.5em;
            width: 100%; }
          .f-chat .f-chat-container .f-chat-field .f-chat-field-send .f-chat-field-send-content > span {
            flex-basis: 25%; }
        .f-chat .f-chat-container .f-chat-field .f-chat-field-send:hover .f-chat-field-send-content > i {
          color: #00529e; }
        .f-chat .f-chat-container .f-chat-field .f-chat-field-send:hover .f-chat-field-send-content > span {
          color: #00529e; }
  .f-chat.f-chat-internal .f-chat-container .f-chat-area-container .f-chat-area {
    background-color: #efffff; }

.f-dashboard .f-dashboard-loading {
  display: flex;
  height: 100%; }
  .f-dashboard .f-dashboard-loading .f-dashboard-loading-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto; }
    .f-dashboard .f-dashboard-loading .f-dashboard-loading-content > .p-progress-spinner {
      margin-bottom: 1em; }

.f-dashboard .f-dashboard-filter {
  display: flex;
  margin-top: -2.5em;
  margin-bottom: 0.5em; }
  .f-dashboard .f-dashboard-filter .f-dashboard-filter-input-label {
    margin-top: auto; }
  .f-dashboard .f-dashboard-filter .f-filter-date {
    margin: auto 0.5em; }

.f-dashboard .f-dashboard-overview .f-dashboard-chart-container > div,
.f-dashboard .f-dashboard-container .f-dashboard-chart-container > div {
  padding: 0.5em; }
  .f-dashboard .f-dashboard-overview .f-dashboard-chart-container > div .f-dashboard-no-entries,
  .f-dashboard .f-dashboard-container .f-dashboard-chart-container > div .f-dashboard-no-entries {
    display: flex;
    flex-grow: 1; }
    .f-dashboard .f-dashboard-overview .f-dashboard-chart-container > div .f-dashboard-no-entries .f-dashboard-no-entries-text,
    .f-dashboard .f-dashboard-container .f-dashboard-chart-container > div .f-dashboard-no-entries .f-dashboard-no-entries-text {
      margin: auto; }

.f-dashboard .f-dashboard-overview .f-dashboard-chart-container .f-dashboard-chart,
.f-dashboard .f-dashboard-container .f-dashboard-chart-container .f-dashboard-chart {
  flex-grow: 1;
  display: flex;
  flex-direction: column; }
  .f-dashboard .f-dashboard-overview .f-dashboard-chart-container .f-dashboard-chart > canvas,
  .f-dashboard .f-dashboard-container .f-dashboard-chart-container .f-dashboard-chart > canvas {
    margin: auto; }

.f-dashboard .f-dashboard-overview .f-dashboard-chart-container.f-dashboard-chart-container-fill,
.f-dashboard .f-dashboard-container .f-dashboard-chart-container.f-dashboard-chart-container-fill {
  min-height: 100%;
  max-height: 100%; }
  .f-dashboard .f-dashboard-overview .f-dashboard-chart-container.f-dashboard-chart-container-fill > div:nth-child(even),
  .f-dashboard .f-dashboard-container .f-dashboard-chart-container.f-dashboard-chart-container-fill > div:nth-child(even) {
    background-color: #efefef; }

.f-dashboard .f-dashboard-overview .f-dashboard-chart-container.f-dashboard-chart-container-capped .f-dashboard-selected,
.f-dashboard .f-dashboard-container .f-dashboard-chart-container.f-dashboard-chart-container-capped .f-dashboard-selected {
  background-color: #efefef;
  border: 2px solid #4699ce; }

.f-dashboard .f-dashboard-overview .f-dashboard-chart-container.f-dashboard-chart-container-capped .f-dashboard-chart,
.f-dashboard .f-dashboard-container .f-dashboard-chart-container.f-dashboard-chart-container-capped .f-dashboard-chart {
  max-width: 500px;
  margin: auto; }

.f-dashboard .f-dashboard-overview .p-card-content,
.f-dashboard .f-dashboard-container .p-card-content {
  display: flex;
  flex-direction: column; }
  .f-dashboard .f-dashboard-overview .p-card-content .p-tabview,
  .f-dashboard .f-dashboard-container .p-card-content .p-tabview {
    border: none;
    margin-top: 20px; }
    .f-dashboard .f-dashboard-overview .p-card-content .p-tabview .f-dashboard-tabpanel-header,
    .f-dashboard .f-dashboard-container .p-card-content .p-tabview .f-dashboard-tabpanel-header {
      cursor: pointer; }
    .f-dashboard .f-dashboard-overview .p-card-content .p-tabview .p-tabview-top > .p-tabview-nav,
    .f-dashboard .f-dashboard-container .p-card-content .p-tabview .p-tabview-top > .p-tabview-nav {
      border-bottom: none; }
    .f-dashboard .f-dashboard-overview .p-card-content .p-tabview ul.p-tabview-nav,
    .f-dashboard .f-dashboard-container .p-card-content .p-tabview ul.p-tabview-nav {
      border-bottom: none;
      padding-bottom: 10px; }
      .f-dashboard .f-dashboard-overview .p-card-content .p-tabview ul.p-tabview-nav li,
      .f-dashboard .f-dashboard-container .p-card-content .p-tabview ul.p-tabview-nav li {
        width: calc(50% - 2px); }
        .f-dashboard .f-dashboard-overview .p-card-content .p-tabview ul.p-tabview-nav li a,
        .f-dashboard .f-dashboard-container .p-card-content .p-tabview ul.p-tabview-nav li a {
          cursor: pointer;
          width: 100%;
          text-align: center; }
    .f-dashboard .f-dashboard-overview .p-card-content .p-tabview .p-tabview-panel,
    .f-dashboard .f-dashboard-container .p-card-content .p-tabview .p-tabview-panel {
      margin: 0;
      padding: 0; }
  .f-dashboard .f-dashboard-overview .p-card-content .f-dashboard-filter,
  .f-dashboard .f-dashboard-container .p-card-content .f-dashboard-filter {
    padding-right: 10px; }
    .f-dashboard .f-dashboard-overview .p-card-content .f-dashboard-filter .f-dashboard-filter-input-label,
    .f-dashboard .f-dashboard-container .p-card-content .f-dashboard-filter .f-dashboard-filter-input-label {
      width: 100%;
      text-align: right; }

.f-dashboard .f-dashboard-overview .f-dashboard-total,
.f-dashboard .f-dashboard-container .f-dashboard-total {
  cursor: pointer; }

.f-dashboard .f-dashboard-overview .f-dashboard-chart-footer,
.f-dashboard .f-dashboard-container .f-dashboard-chart-footer {
  display: flex; }
  .f-dashboard .f-dashboard-overview .f-dashboard-chart-footer .f-dashboard-total,
  .f-dashboard .f-dashboard-container .f-dashboard-chart-footer .f-dashboard-total {
    margin-top: auto;
    flex-grow: 1; }
  .f-dashboard .f-dashboard-overview .f-dashboard-chart-footer .f-dashboard-chart-export,
  .f-dashboard .f-dashboard-container .f-dashboard-chart-footer .f-dashboard-chart-export {
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
    color: #00529e;
    cursor: pointer; }
    .f-dashboard .f-dashboard-overview .f-dashboard-chart-footer .f-dashboard-chart-export .f-dashboard-chart-export-text,
    .f-dashboard .f-dashboard-container .f-dashboard-chart-footer .f-dashboard-chart-export .f-dashboard-chart-export-text {
      margin-top: auto; }

.f-dashboard .f-dashboard-overview {
  display: flex;
  flex-wrap: wrap;
  height: 100%; }
  .f-dashboard .f-dashboard-overview .f-dashboard-overview-entry {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
    max-height: 50%; }
    .f-dashboard .f-dashboard-overview .f-dashboard-overview-entry > div {
      flex-basis: unset;
      flex-grow: 1;
      width: calc(100% - 0.5em); }
  .f-dashboard .f-dashboard-overview .f-dashboard-overview-entry-tm {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
    max-height: 100%; }
    .f-dashboard .f-dashboard-overview .f-dashboard-overview-entry-tm > div {
      flex-basis: unset;
      flex-grow: 1;
      width: calc(100% - 0.5em); }

.p-overflow-hidden {
  position: initial; }

.f-fill-flex {
  width: 100%;
  height: 100%;
  display: flex; }
  .f-fill-flex-center {
    margin: auto; }

.f-profile-dialog.p-dialog > .p-dialog-content {
  padding: 0; }

.f-profile-dialog.p-dialog > .p-dialog-titlebar {
  display: none; }

.f-profile-dialog .f-profile-header {
  padding: 2em 1em 1em 1em;
  text-align: center;
  background-color: #00529e;
  color: white; }
  .f-profile-dialog .f-profile-header .f-profile-image {
    display: flex;
    margin: auto;
    border-radius: 50%;
    background: #dfdfdf;
    height: 50px;
    width: 50px;
    background-size: cover; }
    .f-profile-dialog .f-profile-header .f-profile-image .f-profile-icon {
      font-size: 3em;
      margin: auto;
      color: black; }
  .f-profile-dialog .f-profile-header .f-profile-name {
    font-size: 18px;
    margin-top: 0.5em; }

.f-profile-dialog .f-profile-content {
  padding: 0.5em 1em; }
  .f-profile-dialog .f-profile-content .f-profile-entry {
    display: flex;
    padding: 0.5em 0; }
    .f-profile-dialog .f-profile-content .f-profile-entry .f-profile-entry-label {
      width: 150px; }
    .f-profile-dialog .f-profile-content .f-profile-entry .f-profile-entry-value,
    .f-profile-dialog .f-profile-content .f-profile-entry .f-profile-phone-intern {
      margin-left: 0.5em; }

.layout-container .topbar-items > li > ul {
  width: 300px;
  right: 0; }
  .layout-container .topbar-items > li > ul .f-profile-user {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #efefef;
    padding: 0.5em 0;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 0.5em; }
    .layout-container .topbar-items > li > ul .f-profile-user.f-profile-user-button {
      cursor: pointer; }
      .layout-container .topbar-items > li > ul .f-profile-user.f-profile-user-button:hover {
        background-color: #dfdfdf; }
    .layout-container .topbar-items > li > ul .f-profile-user .f-profile-image {
      display: flex;
      margin: auto;
      border-radius: 50%;
      background: #00529e;
      height: 50px;
      width: 50px;
      background-size: cover; }
      .layout-container .topbar-items > li > ul .f-profile-user .f-profile-image .f-profile-icon {
        font-size: 3em;
        margin: auto;
        color: white; }
    .layout-container .topbar-items > li > ul .f-profile-user .f-profile-name {
      font-size: 16px; }
    .layout-container .topbar-items > li > ul .f-profile-user .f-profile-mail {
      font-size: 12px;
      color: gray; }
  .layout-container .topbar-items > li > ul .f-profile-entry {
    font-size: 1em;
    display: block;
    padding: 0.5em 2em 0.5em 1em;
    color: #212121;
    width: 100%; }
    .layout-container .topbar-items > li > ul .f-profile-entry .f-profile-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5em;
      font-size: 1.5em;
      color: #757575; }
    .layout-container .topbar-items > li > ul .f-profile-entry .f-profile-text {
      white-space: nowrap; }
  .layout-container .topbar-items > li > ul .f-profile-debug-area {
    background-color: #efefef;
    border-top: 1px solid #dfdfdf;
    margin-top: 0.5em; }
    .layout-container .topbar-items > li > ul .f-profile-debug-area .f-profile-debug {
      padding: 0.25em 2em 0em 1em; }
    .layout-container .topbar-items > li > ul .f-profile-debug-area li a:hover {
      background-color: #d8d8d8; }

.f-topbar-language {
  display: flex;
  width: 50px;
  height: 35px;
  margin-right: 1em; }
  .f-topbar-language > .p-dropdown {
    flex-grow: 1;
    color: white;
    margin-top: auto;
    background-image: linear-gradient(to bottom, #50aaff, #50aaff), linear-gradient(to bottom, #bdbdbd, #bdbdbd); }
    .f-topbar-language > .p-dropdown .p-dropdown-trigger-icon {
      color: #bdbdbd; }
    .f-topbar-language > .p-dropdown .p-dropdown-label {
      text-align: center; }

.f-global-messages {
  position: fixed;
  z-index: 10000;
  width: 25%;
  right: 0;
  top: 0; }
  .f-global-messages .f-messages .p-messages {
    margin: 1em;
    border-radius: 5px; }
    .f-global-messages .f-messages .p-messages .p-messages-wrapper {
      box-shadow: 0px 6px 5px 0px #0009;
      border-radius: 5px; }

.f-messages .p-messages {
  margin: 0; }

.f-system-state {
  height: 10px;
  width: 10px;
  border: black 1px none;
  border-radius: 50%;
  margin: 4px auto auto;
  cursor: pointer; }

.f-order-document-state {
  height: 20px;
  width: 20px;
  border: black 1px solid;
  border-radius: 50%;
  margin: auto; }

.f-repair-completion .f-repair-completion-content {
  padding: 0.5em;
  min-height: 500px; }
  .f-repair-completion .f-repair-completion-content .f-repair-completion-description {
    padding-bottom: 2em;
    font-weight: bold; }
  .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-all-winter {
    display: flex;
    flex-direction: row-reverse; }
    .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-all-winter > label {
      color: #00529e; }
    .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-all-winter > .p-checkbox {
      margin-right: 0em;
      margin-left: 0.5em; }
  .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar {
    display: flex;
    margin-top: 1em; }
    .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-title {
      display: flex; }
      .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-title > .f-tire-repair-entry-label {
        margin: auto 0; }
        .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-title > .f-tire-repair-entry-label.f-tire-repair-entry-label-clickable {
          font-weight: bold;
          color: #00529e; }
        .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-title > .f-tire-repair-entry-label.f-error {
          color: #d84a38; }
      .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-title > .f-tire-repair-entry-arrow {
        color: #00529e; }
        .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-title > .f-tire-repair-entry-arrow.f-error {
          color: #d84a38; }
      .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-title > .f-tire-repair-entry-spacer {
        width: 1.5em; }
    .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-winter {
      display: flex;
      margin: auto 0;
      flex-direction: row-reverse; }
      .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-title-bar > .f-tire-repair-entry-winter > .p-checkbox {
        margin-right: 0em;
        margin-left: 0.5em; }
  .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-info {
    display: flex;
    margin: 1em 0 0 1.5em; }
    .f-repair-completion .f-repair-completion-content .f-repair-completion-fields > .f-tire-repair-completion .f-tire-repair-tires > .f-tire-repair-entry > .f-tire-repair-entry-info > .f-tire-repair-fields {
      flex-grow: 1; }

.f-tire-card > .p-card-body > .p-card-content {
  display: flex; }
  .f-tire-card > .p-card-body > .p-card-content .f-tire-grid {
    display: flex;
    background-image: url("./trailer-axle.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
    .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column {
      display: flex; }
      .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content {
        display: flex;
        flex-direction: column;
        margin: -1em 0; }
        .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry {
          display: flex;
          flex-basis: 33.00%;
          flex-shrink: 0;
          padding: 0.5em; }
          .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 0.5em;
            background-color: rgba(255, 255, 255, 0.95);
            border: 1px solid #dbdbdb;
            border-radius: 10px; }
            .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-content .f-tire-entry-title-bar {
              display: flex;
              flex-direction: row-reverse;
              margin-bottom: 0.5em; }
              .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-content .f-tire-entry-title-bar .f-tire-entry-title {
                margin: auto 0; }
                .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-content .f-tire-entry-title-bar .f-tire-entry-title .f-tire-entry-label {
                  color: #00529e;
                  font-weight: bold;
                  font-size: 14px; }
              .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-content .f-tire-entry-title-bar .f-tire-entry-winter {
                background-color: #00529e;
                padding: 0.2em;
                border-radius: 5px; }
                .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-content .f-tire-entry-title-bar .f-tire-entry-winter .f-tire-entry-winter-label {
                  color: white;
                  font-size: 10px; }
                .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-content .f-tire-entry-title-bar .f-tire-entry-winter.f-tire-entry-winter-unselected {
                  background-color: #d5d5d5; }
          .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-tire-entry-spacer {
            flex-basis: 100%; }
          .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-content .f-tire-entry .f-input-group {
            padding-bottom: 0.1em; }
      .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column .f-tire-grid-column-spacer {
        flex-basis: 100%; }
    .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-column.f-tire-grid-right
> .f-tire-grid-column-content
> .f-tire-entry
> .f-tire-entry-content .f-tire-entry-title-bar {
      flex-direction: row; }
    .f-tire-card > .p-card-body > .p-card-content .f-tire-grid .f-tire-grid-spacer {
      flex-basis: 65%; }

.f-file-prop-dialog .f-input-group .f-input-addon {
  display: none; }

.f-file-prop-dialog .f-input-group .f-input-addon,
.f-trailer-licenseplate-dialog .f-input-group .f-input-addon,
.f-settings-tire-brand-dialog .f-input-group .f-input-addon {
  display: none; }

.f-file-prop-dialog .f-input-group .f-input-addon {
  display: none; }

.f-file-prop-dialog .f-input-group .f-input-addon,
.f-trailer-licenseplate-dialog .f-input-group .f-input-addon,
.f-devtools .f-input-group .f-input-addon {
  display: none; }

.f-inspection-dialog.p-dialog .p-dialog-content {
  overflow: auto;
  padding-top: 0em; }

.f-inspection-dialog .f-inspection-bulk-warning {
  width: 100%;
  padding-bottom: 0.5em; }
  .f-inspection-dialog .f-inspection-bulk-warning .p-message {
    width: 100%;
    margin: 0; }

.f-inspection-dialog .f-inspection-bulk-header {
  display: flex;
  flex-wrap: wrap;
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
  margin-bottom: 1em;
  background-color: #dfe5e8;
  border: 1px #dbdbdb solid; }
  .f-inspection-dialog .f-inspection-bulk-header .f-inspection-bulk-entry {
    padding: 0.25em;
    flex-basis: 25%;
    background-color: white;
    border: 1px #dbdbdb solid;
    display: flex;
    margin-bottom: auto; }
    .f-inspection-dialog .f-inspection-bulk-header .f-inspection-bulk-entry .f-inspection-bulk-icon {
      font-size: 2em;
      margin-right: 0.25em;
      margin-top: auto;
      margin-bottom: auto; }
    .f-inspection-dialog .f-inspection-bulk-header .f-inspection-bulk-entry .f-inspection-bulk-description {
      display: flex;
      flex-direction: column; }
      .f-inspection-dialog .f-inspection-bulk-header .f-inspection-bulk-entry .f-inspection-bulk-description .f-inspection-bulk-status {
        font-size: 12px; }
    .f-inspection-dialog .f-inspection-bulk-header .f-inspection-bulk-entry.f-inspection-bulk-error {
      background-color: #ffcccc;
      color: #aa0000; }
    .f-inspection-dialog .f-inspection-bulk-header .f-inspection-bulk-entry.f-inspection-bulk-warn {
      background-color: #ffffcc;
      color: #dd8800; }

.f-inspection-dialog .f-inspection-details {
  position: relative; }
  .f-inspection-dialog .f-inspection-details .f-inspection-disclaimer {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: rgba(255, 255, 255, 0.5); }
    .f-inspection-dialog .f-inspection-details .f-inspection-disclaimer .f-inspection-disclaimer-text {
      margin: auto;
      padding: 2em;
      color: rgba(0, 0, 0, 0.5);
      font-size: 2em; }

.f-grayed-out {
  color: #bdbdbd; }
  .f-grayed-out .f-input-group .f-input-content.f-input-content-readonly {
    border-color: #dfdfdf; }
  .f-grayed-out .p-radiobutton .p-radiobutton-box,
  .f-grayed-out .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #dfdfdf;
    cursor: not-allowed; }
    .f-grayed-out .p-radiobutton .p-radiobutton-box .pi-circle-on,
    .f-grayed-out .p-radiobutton .p-radiobutton-box.p-highlight .pi-circle-on {
      display: none; }
  .f-grayed-out .f-input-group .f-input-precontent,
  .f-grayed-out .f-input-group .f-input-postcontent {
    color: #bdbdbd; }
  .f-grayed-out .f-input-group .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
    display: none; }
  .f-grayed-out .f-input-group .f-input-textarea-view {
    background-color: #efefef; }

.f-inspections .f-inspections-container {
  display: flex;
  margin: -0.25em; }
  .f-inspections .f-inspections-container-vertical {
    flex-direction: column; }
  .f-inspections .f-inspections-container .f-inspections-entry {
    display: flex;
    flex-grow: 1;
    margin: 0.25em 0.25em -0.5em 0.25em;
    padding: 0.5em; }
    .f-inspections .f-inspections-container .f-inspections-entry .f-inspections-entry-fields {
      display: flex;
      flex-grow: 1;
      flex-direction: column; }
      .f-inspections .f-inspections-container .f-inspections-entry .f-inspections-entry-fields .f-inspections-entry-title {
        font-weight: bold; }
    .f-inspections .f-inspections-container .f-inspections-entry-disabled {
      color: #d5d5d5; }
      .f-inspections .f-inspections-container .f-inspections-entry-disabled .f-input-group .f-input-content.f-input-content-readonly {
        border-color: #d5d5d5; }

.f-damages,
.f-debtors {
  position: relative; }
  .f-damages .p-treetable .p-treetable-tablewrapper table,
  .f-damages .p-treetable .p-treetable-scrollable-wrapper table,
  .f-debtors .p-treetable .p-treetable-tablewrapper table,
  .f-debtors .p-treetable .p-treetable-scrollable-wrapper table {
    border: none;
    border-collapse: unset;
    border-spacing: 0px; }
    .f-damages .p-treetable .p-treetable-tablewrapper table > .p-treetable-tbody,
    .f-damages .p-treetable .p-treetable-scrollable-wrapper table > .p-treetable-tbody,
    .f-debtors .p-treetable .p-treetable-tablewrapper table > .p-treetable-tbody,
    .f-debtors .p-treetable .p-treetable-scrollable-wrapper table > .p-treetable-tbody {
      border: none; }
    .f-damages .p-treetable .p-treetable-tablewrapper table .f-order-costs-planned,
    .f-damages .p-treetable .p-treetable-scrollable-wrapper table .f-order-costs-planned,
    .f-debtors .p-treetable .p-treetable-tablewrapper table .f-order-costs-planned,
    .f-debtors .p-treetable .p-treetable-scrollable-wrapper table .f-order-costs-planned {
      background-color: #f0fff0; }
    .f-damages .p-treetable .p-treetable-tablewrapper table .f-order-costs-real,
    .f-damages .p-treetable .p-treetable-scrollable-wrapper table .f-order-costs-real,
    .f-debtors .p-treetable .p-treetable-tablewrapper table .f-order-costs-real,
    .f-debtors .p-treetable .p-treetable-scrollable-wrapper table .f-order-costs-real {
      background-color: #fff0f0; }
    .f-damages .p-treetable .p-treetable-tablewrapper table tr:nth-child(even),
    .f-damages .p-treetable .p-treetable-scrollable-wrapper table tr:nth-child(even),
    .f-debtors .p-treetable .p-treetable-tablewrapper table tr:nth-child(even),
    .f-debtors .p-treetable .p-treetable-scrollable-wrapper table tr:nth-child(even) {
      background-color: #efefef; }
      .f-damages .p-treetable .p-treetable-tablewrapper table tr:nth-child(even) > .f-order-costs-planned,
      .f-damages .p-treetable .p-treetable-scrollable-wrapper table tr:nth-child(even) > .f-order-costs-planned,
      .f-debtors .p-treetable .p-treetable-tablewrapper table tr:nth-child(even) > .f-order-costs-planned,
      .f-debtors .p-treetable .p-treetable-scrollable-wrapper table tr:nth-child(even) > .f-order-costs-planned {
        background-color: #e0efe0; }
      .f-damages .p-treetable .p-treetable-tablewrapper table tr:nth-child(even) > .f-order-costs-real,
      .f-damages .p-treetable .p-treetable-scrollable-wrapper table tr:nth-child(even) > .f-order-costs-real,
      .f-debtors .p-treetable .p-treetable-tablewrapper table tr:nth-child(even) > .f-order-costs-real,
      .f-debtors .p-treetable .p-treetable-scrollable-wrapper table tr:nth-child(even) > .f-order-costs-real {
        background-color: #efe0e0; }
  .f-damages .f-input-group .f-input-content.f-input-content-readonly,
  .f-debtors .f-input-group .f-input-content.f-input-content-readonly {
    min-height: 1.5em; }
  .f-damages .p-treetable .p-treetable-toggler,
  .f-debtors .p-treetable .p-treetable-toggler {
    vertical-align: middle;
    transform: translateY(7.5%); }
  .f-damages .f-debtor-partner .f-input-group,
  .f-debtors .f-debtor-partner .f-input-group {
    padding: 0em; }
    .f-damages .f-debtor-partner .f-input-group .f-input-content,
    .f-debtors .f-debtor-partner .f-input-group .f-input-content {
      width: 100%; }
  .f-damages .f-debtor-hh,
  .f-debtors .f-debtor-hh {
    width: 100%;
    position: relative;
    display: flex; }
    .f-damages .f-debtor-hh > .p-checkbox.p-component,
    .f-debtors .f-debtor-hh > .p-checkbox.p-component {
      margin: auto; }
    .f-damages .f-debtor-hh .f-debotr-hh-mail,
    .f-debtors .f-debtor-hh .f-debotr-hh-mail {
      display: flex;
      position: absolute;
      right: 0.5em;
      top: 50%;
      transform: translateY(-50%);
      font-size: 11px;
      margin: auto; }
  .f-damages .f-damages-checkbox,
  .f-debtors .f-damages-checkbox {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .f-damages .f-damages-checkbox .f-damages-checkbox-wrapper,
    .f-debtors .f-damages-checkbox .f-damages-checkbox-wrapper {
      display: inline; }
    .f-damages .f-damages-checkbox .f-damages-label,
    .f-debtors .f-damages-checkbox .f-damages-label {
      display: inline; }
  .f-damages .f-checkbox-partial,
  .f-debtors .f-checkbox-partial {
    width: 1.25em;
    height: 1.25em; }
  .f-damages .f-damages-label,
  .f-debtors .f-damages-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .f-damages .f-damages-label.f-damages-readonly,
    .f-debtors .f-damages-label.f-damages-readonly {
      color: #7d7d7d; }
  .f-damages .f-damages-count,
  .f-debtors .f-damages-count {
    position: relative; }
    .f-damages .f-damages-count .f-damages-fm-count,
    .f-debtors .f-damages-count .f-damages-fm-count {
      text-align: center;
      height: 1em;
      margin: auto; }
    .f-damages .f-damages-count .f-damages-fm-info,
    .f-debtors .f-damages-count .f-damages-fm-info {
      position: absolute;
      right: 0em;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.75em;
      color: #00529e; }
  .f-damages .f-damages-info .f-input-group,
  .f-damages .f-damages-material .f-input-group,
  .f-damages .f-damages-work-count .f-input-group,
  .f-damages .f-damages-costs .f-input-group,
  .f-debtors .f-damages-info .f-input-group,
  .f-debtors .f-damages-material .f-input-group,
  .f-debtors .f-damages-work-count .f-input-group,
  .f-debtors .f-damages-costs .f-input-group {
    padding: 0em; }
    .f-damages .f-damages-info .f-input-group .f-input-content,
    .f-damages .f-damages-material .f-input-group .f-input-content,
    .f-damages .f-damages-work-count .f-input-group .f-input-content,
    .f-damages .f-damages-costs .f-input-group .f-input-content,
    .f-debtors .f-damages-info .f-input-group .f-input-content,
    .f-debtors .f-damages-material .f-input-group .f-input-content,
    .f-debtors .f-damages-work-count .f-input-group .f-input-content,
    .f-debtors .f-damages-costs .f-input-group .f-input-content {
      width: 100%; }
    .f-damages .f-damages-info .f-input-group .f-input-addon,
    .f-damages .f-damages-material .f-input-group .f-input-addon,
    .f-damages .f-damages-work-count .f-input-group .f-input-addon,
    .f-damages .f-damages-costs .f-input-group .f-input-addon,
    .f-debtors .f-damages-info .f-input-group .f-input-addon,
    .f-debtors .f-damages-material .f-input-group .f-input-addon,
    .f-debtors .f-damages-work-count .f-input-group .f-input-addon,
    .f-debtors .f-damages-costs .f-input-group .f-input-addon {
      display: none; }
  .f-damages .f-damages-info .f-damages-readonly,
  .f-damages .f-damages-material .f-damages-readonly,
  .f-damages .f-damages-work-count .f-damages-readonly,
  .f-damages .f-damages-costs .f-damages-readonly,
  .f-debtors .f-damages-info .f-damages-readonly,
  .f-debtors .f-damages-material .f-damages-readonly,
  .f-debtors .f-damages-work-count .f-damages-readonly,
  .f-debtors .f-damages-costs .f-damages-readonly {
    color: #7d7d7d; }
  .f-damages .f-damages-material .f-input-content,
  .f-damages .f-damages-work-count .f-input-content,
  .f-damages .f-damages-costs .f-input-content,
  .f-debtors .f-damages-material .f-input-content,
  .f-debtors .f-damages-work-count .f-input-content,
  .f-debtors .f-damages-costs .f-input-content {
    text-align: center; }
    .f-damages .f-damages-material .f-input-content > input,
    .f-damages .f-damages-work-count .f-input-content > input,
    .f-damages .f-damages-costs .f-input-content > input,
    .f-debtors .f-damages-material .f-input-content > input,
    .f-debtors .f-damages-work-count .f-input-content > input,
    .f-debtors .f-damages-costs .f-input-content > input {
      text-align: center; }
  .f-damages .f-damages-total-sum,
  .f-debtors .f-damages-total-sum {
    display: flex;
    position: absolute;
    width: calc(100% - 2em);
    bottom: 1em;
    padding: 0.5em;
    background-color: #dfe5e8;
    border: 1px #dbdbdb solid; }
    .f-damages .f-damages-total-sum-blank,
    .f-debtors .f-damages-total-sum-blank {
      flex-grow: 1; }
    .f-damages .f-damages-total-sum-currency,
    .f-debtors .f-damages-total-sum-currency {
      min-width: 175px;
      margin-right: 0.5em; }
      .f-damages .f-damages-total-sum-currency .f-input-group,
      .f-debtors .f-damages-total-sum-currency .f-input-group {
        padding: 0em; }
        .f-damages .f-damages-total-sum-currency .f-input-group .f-input-label,
        .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-label {
          margin: auto; }
        .f-damages .f-damages-total-sum-currency .f-input-group .f-input-content,
        .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-content {
          width: 50%; }
          .f-damages .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-label,
          .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-label {
            text-align: center; }
          .f-damages .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item,
          .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item {
            padding: 0.25em; }
            .f-damages .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item .f-currency-node,
            .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item .f-currency-node {
              white-space: nowrap;
              display: flex;
              overflow-x: hidden; }
              .f-damages .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item .f-currency-node-code,
              .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item .f-currency-node-code {
                margin-top: auto;
                margin-right: 0.5em; }
              .f-damages .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item .f-currency-node-symbol,
              .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-content .p-dropdown-item .f-currency-node-symbol {
                font-size: 0.9em;
                min-width: 2em;
                max-width: 2em;
                margin-right: 2em;
                text-align: center; }
        .f-damages .f-damages-total-sum-currency .f-input-group .f-input-addon,
        .f-debtors .f-damages-total-sum-currency .f-input-group .f-input-addon {
          display: none; }
    .f-damages .f-damages-total-sum-value,
    .f-debtors .f-damages-total-sum-value {
      flex-basis: 15%;
      text-align: center;
      margin: auto; }
  .f-damages.f-damages-required > div.p-card-header,
  .f-debtors.f-damages-required > div.p-card-header {
    color: red; }

.f-damage-info-content {
  height: 500px;
  overflow: auto;
  background-color: #f2f4f6;
  padding: 1em;
  border: 1px solid #dbdbdb; }

.f-card {
  position: relative; }
  .f-card .f-card-header {
    padding: 1em;
    border: 1px solid #dbdbdb; }
    .f-card .f-card-header .f-card-header-title {
      font-size: 1.1em; }
  .f-card .f-card-content {
    padding: 1em; }
    .f-card .f-card-content .f-input-content {
      flex-grow: 1; }
    .f-card .f-card-content .f-input-addon {
      display: none; }
  .f-card .f-card-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0;
    text-align: right; }

.p-dialog.f-dialog-forward-fm .p-dialog-content {
  padding-top: 0; }
  .p-dialog.f-dialog-forward-fm .p-dialog-content .p-fieldset {
    padding-top: 0;
    border: none; }
    .p-dialog.f-dialog-forward-fm .p-dialog-content .p-fieldset .p-fieldset-legend {
      padding: 0; }
      .p-dialog.f-dialog-forward-fm .p-dialog-content .p-fieldset .p-fieldset-legend > a {
        padding-left: 0; }
      .p-dialog.f-dialog-forward-fm .p-dialog-content .p-fieldset .p-fieldset-legend .p-fieldset-legend-text {
        vertical-align: middle; }
        .p-dialog.f-dialog-forward-fm .p-dialog-content .p-fieldset .p-fieldset-legend .p-fieldset-legend-text .p-radiobutton {
          margin-top: -0.15em; }
    .p-dialog.f-dialog-forward-fm .p-dialog-content .p-fieldset .p-fieldset-content {
      padding: 0.5em; }
  .p-dialog.f-dialog-forward-fm .p-dialog-content .f-workshop-title {
    font-weight: bold;
    padding-bottom: 0.5em; }

.p-dialog.f-dialog-retire-trailer .p-dialog-titlebar,
.p-dialog.f-dialog-licenseplate .p-dialog-titlebar {
  display: none; }

.p-dialog.f-dialog-retire-trailer .p-dialog-content,
.p-dialog.f-dialog-licenseplate .p-dialog-content {
  text-align: center;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  padding-bottom: 0; }
  .p-dialog.f-dialog-retire-trailer .p-dialog-content .p-message,
  .p-dialog.f-dialog-licenseplate .p-dialog-content .p-message {
    font-size: 16px;
    margin-bottom: 1em; }
    .p-dialog.f-dialog-retire-trailer .p-dialog-content .p-message .p-message-icon,
    .p-dialog.f-dialog-licenseplate .p-dialog-content .p-message .p-message-icon {
      margin-right: 0.25em; }
  .p-dialog.f-dialog-retire-trailer .p-dialog-content .f-input-addon,
  .p-dialog.f-dialog-licenseplate .p-dialog-content .f-input-addon {
    display: none; }
  .p-dialog.f-dialog-retire-trailer .p-dialog-content .f-dialog-retire-trailer-text,
  .p-dialog.f-dialog-licenseplate .p-dialog-content .f-dialog-retire-trailer-text {
    margin-bottom: 1em; }

.p-dialog.f-dialog-partner {
  display: flex; }
  .p-dialog.f-dialog-partner .p-dialog-content {
    overflow: auto;
    min-height: 460px; }
  .p-dialog.f-dialog-partner .f-dialog-partner-item {
    display: flex;
    padding: 1em;
    margin: 0 -1em; }
    .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-icon {
      display: flex;
      margin: auto;
      flex-direction: column;
      height: 100%;
      width: 100px; }
      .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-icon .f-dialog-partner-icon-symbol {
        width: 100%;
        text-align: center;
        font-size: 3em;
        margin: auto; }
      .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-icon .f-dialog-partner-icon-subtext {
        text-align: center;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis; }
    .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-content {
      flex-grow: 1;
      position: relative;
      margin: auto;
      overflow-x: hidden; }
      .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-content .f-dialog-partner-text-big {
        font-size: 16px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin-right: 2em; }
      .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-content .f-dialog-partner-text-small {
        font-size: 12px;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin-right: 2em; }
      .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-content .f-dialog-partner-link {
        position: absolute;
        top: 0;
        right: 1em; }
        .p-dialog.f-dialog-partner .f-dialog-partner-item .f-dialog-partner-content .f-dialog-partner-link:hover {
          color: #00529e; }
    .p-dialog.f-dialog-partner .f-dialog-partner-item:hover {
      background-color: lightgrey;
      cursor: pointer; }
    .p-dialog.f-dialog-partner .f-dialog-partner-item-selected {
      background-color: #00529e;
      color: white; }
      .p-dialog.f-dialog-partner .f-dialog-partner-item-selected .f-dialog-partner-content .f-dialog-partner-link:hover {
        color: #78bdfd; }
      .p-dialog.f-dialog-partner .f-dialog-partner-item-selected:hover {
        background-color: #00529e; }

.p-dialog.f-dialog-debtor-assign .p-dialog-content {
  max-height: 75vh;
  overflow-y: auto;
  padding: 0 1em; }

.p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item {
  margin: 0 -1em;
  padding: 0.5em;
  border-top: 1px solid #dbdbdb; }
  .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-header {
    padding: 0 0.5em;
    position: relative;
    font-weight: bold; }
    .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-header .f-dialog-debtor-open {
      position: absolute;
      top: 50%;
      right: 0.25em;
      transform: translateY(-50%); }
      .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-header .f-dialog-debtor-open:hover {
        color: #00529e; }
  .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-content {
    padding: 0 0.5em; }
    .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-content .f-dialog-debtor-damage-list {
      display: flex;
      flex-direction: column;
      margin: 0.5em 0;
      font-size: 12px; }
      .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-content .f-dialog-debtor-damage-list .f-dialog-debtor-damage-entry {
        display: flex;
        flex-basis: 50%;
        border-bottom: 1px solid #dbdbdb; }
        .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-content .f-dialog-debtor-damage-list .f-dialog-debtor-damage-entry .f-dialog-debtor-damage-type {
          flex-basis: 50%; }
        .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item .f-dialog-debtor-item-content .f-dialog-debtor-damage-list .f-dialog-debtor-damage-entry .f-dialog-debtor-damage-link {
          flex-basis: 50%; }
  .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item:hover {
    background-color: lightgrey;
    cursor: pointer; }
    .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item:hover .f-dialog-debtor-item-content
.f-dialog-debtor-damage-list
.f-dialog-debtor-damage-entry {
      border-bottom: 1px solid #bdbdbd; }
  .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item-selected {
    background-color: #00529e;
    color: white; }
    .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item-selected .f-dialog-debtor-item-content .f-dialog-debtor-damage-list .f-dialog-debtor-damage-entry {
      border-bottom: 1px solid #146fc4; }
    .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item-selected .f-dialog-debtor-item-content a {
      color: #78bdfd; }
    .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item-selected .f-dialog-debtor-item-header .f-dialog-debtor-open:hover {
      color: #78bdfd; }
    .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item-selected:hover {
      background-color: #00529e; }
      .p-dialog.f-dialog-debtor-assign .f-dialog-debtor-item-selected:hover .f-dialog-debtor-item-content
.f-dialog-debtor-damage-list
.f-dialog-debtor-damage-entry {
        border-bottom: 1px solid #146fc4; }

.f-ticket-forward-info .f-ticket-forward-info-header {
  margin-bottom: 1em;
  font-weight: bold; }

.f-address-fleet-params .f-address-fleet-params-doc-partner {
  font-weight: bold;
  padding: 1em 0; }

.f-file-upload {
  display: flex;
  padding: calc(10px - 0.3em);
  margin: auto; }
  .f-file-upload .f-file-upload-info {
    flex-grow: 1;
    margin: auto;
    padding-right: 10px; }
    .f-file-upload .f-file-upload-info .f-input-content {
      flex-basis: 60%; }
    .f-file-upload .f-file-upload-info .f-input-addon {
      display: none; }
  .f-file-upload .f-file-upload-content {
    flex-basis: 35%;
    flex-shrink: 1;
    margin: auto; }
    .f-file-upload .f-file-upload-content .filepond--root {
      margin: 0; }
      .f-file-upload .f-file-upload-content .filepond--root .filepond--panel-root {
        background-color: #f0f8ff;
        border: 1px solid #bdbdbd;
        border-radius: 0px; }

.f-partner-flags {
  display: flex;
  flex-direction: column; }
  .f-partner-flags > .p-card-body {
    margin-top: 0.5em; }
  .f-partner-flags .f-partner-flags-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0.2em; }
    .f-partner-flags .f-partner-flags-header .f-partner-flags-header-cell {
      border: 1px solid #bdbdbd;
      width: 100%;
      text-align: center;
      font-weight: bold;
      padding: 0.2em; }
  .f-partner-flags .f-partner-flags-body {
    display: flex;
    justify-content: space-around;
    padding: 0.2em; }
    .f-partner-flags .f-partner-flags-body .f-partner-flag-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      .f-partner-flags .f-partner-flags-body .f-partner-flag-cell > .p-checkbox.p-component {
        margin: auto; }
      .f-partner-flags .f-partner-flags-body .f-partner-flag-cell .f-partner-flag-mail {
        display: flex;
        font-size: 11px;
        margin: auto; }

.f-partner-forward-info .p-fieldset {
  padding: 0.25em;
  padding-top: 0;
  border: none; }
  .f-partner-forward-info .p-fieldset .p-fieldset-legend {
    padding: 0; }
    .f-partner-forward-info .p-fieldset .p-fieldset-legend > a {
      padding-left: 0; }
    .f-partner-forward-info .p-fieldset .p-fieldset-legend .p-fieldset-legend-text {
      vertical-align: middle; }
      .f-partner-forward-info .p-fieldset .p-fieldset-legend .p-fieldset-legend-text .p-radiobutton {
        margin-top: -0.15em; }
  .f-partner-forward-info .p-fieldset .p-fieldset-content {
    padding: 0.5em; }

.p-grid .f-grid,
.f-grid {
  min-height: 750px;
  height: 100%;
  margin: 0; }
  .p-grid .f-grid .p-card,
  .p-grid .f-grid .f-card-files,
  .f-grid .p-card,
  .f-grid .f-card-files {
    flex-basis: 100%;
    margin: 0.25em;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 0.5em); }
    .p-grid .f-grid .p-card .p-card-header,
    .p-grid .f-grid .f-card-files .p-card-header,
    .f-grid .p-card .p-card-header,
    .f-grid .f-card-files .p-card-header {
      font-size: 1.25em;
      padding: 0.5em 1em;
      padding-bottom: 0.3em; }
    .p-grid .f-grid .p-card .p-card-body,
    .p-grid .f-grid .f-card-files .p-card-body,
    .f-grid .p-card .p-card-body,
    .f-grid .f-card-files .p-card-body {
      overflow-y: visible;
      padding: 1.25em;
      padding-top: 0.625em;
      flex-grow: 1; }
      .p-grid .f-grid .p-card .p-card-body > .p-card-content,
      .p-grid .f-grid .f-card-files .p-card-body > .p-card-content,
      .f-grid .p-card .p-card-body > .p-card-content,
      .f-grid .f-card-files .p-card-body > .p-card-content {
        height: 100%; }
  .p-grid .f-grid .f-cell,
  .p-grid .f-grid .f-scroll-cell,
  .f-grid .f-cell,
  .f-grid .f-scroll-cell {
    padding: 0;
    display: flex;
    flex-direction: column; }
    .p-grid .f-grid .f-cell .f-lightbox-image-set,
    .p-grid .f-grid .f-scroll-cell .f-lightbox-image-set,
    .f-grid .f-cell .f-lightbox-image-set,
    .f-grid .f-scroll-cell .f-lightbox-image-set {
      height: 180px; }
    .p-grid .f-grid .f-cell.f-grid,
    .p-grid .f-grid .f-scroll-cell.f-grid,
    .f-grid .f-cell.f-grid,
    .f-grid .f-scroll-cell.f-grid {
      display: flex;
      flex-direction: row; }
  .p-grid .f-grid .f-scroll-cell .p-card-body,
  .f-grid .f-scroll-cell .p-card-body {
    overflow-y: auto; }

body .p-tabview .p-tabview-panel.f-trailer-tire-tab {
  display: flex;
  max-width: 100%;
  padding: 0; }
  body .p-tabview .p-tabview-panel.f-trailer-tire-tab .f-trailer-tire-inuse {
    font-weight: bold; }
  body .p-tabview .p-tabview-panel.f-trailer-tire-tab .p-datatable-scrollable-header-table .p-datatable-thead > tr > th[class=""] {
    font-weight: normal;
    vertical-align: top; }
  body .p-tabview .p-tabview-panel.f-trailer-tire-tab .f-trailer-tire-edit {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; }
    body .p-tabview .p-tabview-panel.f-trailer-tire-tab .f-trailer-tire-edit i {
      color: #00529e; }

.f-history-table .p-card-body {
  max-height: 650px;
  max-width: 100%;
  margin: 0; }
  .f-history-table .p-card-body .f-history-search {
    margin-bottom: 0.5em;
    display: flex; }
    .f-history-table .p-card-body .f-history-search > i {
      margin: auto; }

.f-file-upload-multi {
  display: block; }
  .f-file-upload-multi .f-file-upload-info {
    width: 100%; }

.f-doc-list {
  padding: 0.5em; }
  .f-doc-list .f-doc-list-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    border-bottom: #dddddd 1px solid;
    margin: -0.8em -0.8em 0.8em -0.8em;
    z-index: 10;
    background-color: white;
    top: 0; }
    .f-doc-list .f-doc-list-header .f-doc-list-old-doc-checkbox {
      padding: 0.5em 0.8em 0.5em 0; }
  .f-doc-list .f-doc-list-entry {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    display: inline-flex;
    align-items: flex-end;
    min-width: 50%;
    width: 50%;
    max-width: 50%;
    border-bottom: #bdbdbd 1px solid;
    border-radius: 3px; }
    .f-doc-list .f-doc-list-entry .f-doc-list-entry-checkbox {
      border-radius: 2px;
      align-self: center;
      background-color: white;
      margin-left: 0.25em; }
    .f-doc-list .f-doc-list-entry:hover {
      background-color: #efefef; }
    .f-trailer-files .f-doc-list .f-doc-list-entry {
      min-width: 100%;
      width: 100%;
      max-width: 100%; }
    .f-doc-list .f-doc-list-entry .f-doc-list-link {
      display: inline-flex;
      width: 100%; }
      .f-doc-list .f-doc-list-entry .f-doc-list-link .f-doc-list-entry-icon {
        flex-basis: 1.5em;
        font-size: 2em;
        text-align: center;
        align-self: center; }
      .f-doc-list .f-doc-list-entry .f-doc-list-link .f-doc-list-entry-description {
        max-width: calc(100% - 5em);
        margin-left: 0.25em;
        display: flex;
        flex-basis: 100%;
        flex-direction: column; }
        .f-doc-list .f-doc-list-entry .f-doc-list-link .f-doc-list-entry-description .f-doc-list-entry-name {
          margin-top: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
        .f-doc-list .f-doc-list-entry .f-doc-list-link .f-doc-list-entry-description .f-doc-list-entry-info {
          display: flex;
          overflow: hidden;
          text-overflow: ellipsis; }
          .f-doc-list .f-doc-list-entry .f-doc-list-link .f-doc-list-entry-description .f-doc-list-entry-info .f-doc-list-entry-size {
            flex-basis: 50%; }
          .f-doc-list .f-doc-list-entry .f-doc-list-link .f-doc-list-entry-description .f-doc-list-entry-info .f-doc-list-entry-date {
            flex-basis: 50%;
            padding-right: 0.5em; }
            .f-doc-list .f-doc-list-entry .f-doc-list-link .f-doc-list-entry-description .f-doc-list-entry-info .f-doc-list-entry-date .f-date-display {
              text-align: right; }
  .f-doc-list .f-doc-list-entry-old .f-doc-list-link .f-doc-list-entry-icon {
    color: rgba(0, 82, 158, 0.5); }
  .f-doc-list .f-doc-list-entry-old .f-doc-list-link .f-doc-list-entry-description {
    color: rgba(0, 82, 158, 0.5); }

.f-header {
  display: flex;
  flex-wrap: wrap; }
  .f-header > div {
    display: flex;
    flex-grow: 1; }
  .f-header .f-header-label {
    padding: 0 0.25em; }
  .f-header .f-header-value {
    padding: 0 0.25em;
    color: black; }
  .f-header .f-header-space {
    padding: 0 0.2em; }
  .f-header .f-card-close {
    position: absolute;
    right: 0;
    top: 5px; }

.f-date-display {
  width: 100%;
  text-align: center; }
  .f-date-display .f-date-display-time {
    font-size: 0.9em;
    margin-left: 0.5em; }

.f-input-color .f-input-group .f-input-color-content {
  display: flex; }
  .f-input-color .f-input-group .f-input-color-content > .f-input-content {
    flex-grow: 1; }
  .f-input-color .f-input-group .f-input-color-content > .p-component {
    width: unset; }
  .f-input-color .f-input-group .f-input-color-content .f-input-color-picker {
    margin-left: 1em;
    border: 1px solid #dbdbdb; }
    .f-input-color .f-input-group .f-input-color-content .f-input-color-picker > .p-colorpicker-preview {
      height: 20px;
      width: 20px; }

.f-date {
  display: inline-flex;
  width: 100%; }
  .f-date .f-date-time {
    max-width: 40%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .f-date .f-time {
    width: 5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .f-date .f-time-error {
    width: 5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: red; }
  .f-date.f-with-time .f-date-input {
    max-width: 60%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  body .f-date-calendar > .p-overlaypanel-content {
    margin-top: 0; }
    body .f-date-calendar > .p-overlaypanel-content .p-calendar {
      width: 100%; }
      body .f-date-calendar > .p-overlaypanel-content .p-calendar .p-datepicker-inline {
        max-width: 100%;
        min-width: 100%; }
        body .f-date-calendar > .p-overlaypanel-content .p-calendar .p-datepicker-inline .p-datepicker-calendar tbody td {
          text-align: center; }
          body .f-date-calendar > .p-overlaypanel-content .p-calendar .p-datepicker-inline .p-datepicker-calendar tbody td.p-datepicker-other-month > .p-disabled {
            text-align: center; }

.f-input-group {
  width: 100%;
  display: inline-flex;
  padding-bottom: 0.6em; }
  .f-input-group .f-input-label {
    width: 40%;
    padding-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .f-input-group .f-input-label-sfx {
    width: 50%;
    padding-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .f-input-group .f-input-content-sfx {
    width: 45%;
    margin-right: auto;
    position: relative;
    top: -1px; }
    .f-input-group .f-input-content-sfx :disabled {
      border-bottom: 1px dotted gray;
      opacity: unset; }
  .f-input-group .f-input-yesno {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
  .f-input-group .f-input-precontent {
    color: black; }
  .f-input-group .f-input-switch {
    padding-right: 1em;
    padding-left: 0.8em;
    padding-bottom: 0.25em;
    position: relative;
    top: 2px; }
  .f-input-group .f-input-postcontent {
    color: black; }
  .f-input-group .f-input-content {
    width: calc(60% - 50px);
    margin-top: auto;
    position: relative;
    flex-grow: 1; }
    .f-input-group .f-input-content.f-input-content-readonly {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-bottom: #bdbdbd solid 1px; }
    .f-input-group .f-input-content .p-component {
      width: 100%; }
      .f-input-right .f-input-group .f-input-content .p-component {
        text-align: right; }
    .f-input-group .f-input-content .p-calendar {
      width: 100%; }
    .f-input-right .f-input-group .f-input-content {
      text-align: right; }
  .f-input-group .content-right {
    text-align: right; }
  .f-input-group .content-small {
    width: 105px; }
  .f-input-group .f-input-addon {
    display: flex;
    width: 45px;
    padding-left: 5px; }
    .f-input-group .f-input-addon .f-input-suffix {
      display: flex;
      margin: auto 0; }
      .f-input-group .f-input-addon .f-input-suffix.f-input-suffix-clickable {
        cursor: pointer; }
      .f-input-group .f-input-addon .f-input-suffix > i {
        margin: auto; }
    .f-input-group .f-input-addon .f-input-clear {
      margin: auto 0;
      cursor: pointer; }
  .f-input-group .material-icons {
    font-size: 1.2em;
    margin-left: -2px; }
  .f-input-group .f-input-textarea,
  .f-input-group .f-input-textarea-view {
    background-color: #f0f8ff;
    width: 100%;
    resize: none;
    margin-top: 1em;
    overflow: auto; }
  .f-input-group .f-tire-input-textarea,
  .f-input-group .f-tire-input-textarea-view {
    background-color: #f0f8ff;
    width: 100%;
    resize: none;
    margin-top: 0em;
    overflow: auto; }

.f-autocomplete .f-input-content {
  display: flex; }
  .f-autocomplete .f-input-content .f-autocomplete-match-mode {
    border-bottom: #bdbdbd solid 1px;
    color: #757575;
    padding: 0 0.5em;
    margin-top: auto; }
  .f-autocomplete .f-input-content .f-autocomplete-input {
    flex-grow: 1; }

.f-autocomplete .f-autocomplete-input .p-autocomplete-input {
  padding-right: 0px; }

.p-autocomplete-panel {
  width: 300px; }
  .p-autocomplete-panel .f-search-empty {
    color: #bdbdbd; }
    .p-autocomplete-panel .f-search-empty:hover {
      color: #efefef; }
  .p-autocomplete-panel .f-search-entry {
    display: flex;
    margin: -5px; }
    .p-autocomplete-panel .f-search-entry .f-search-entry-icon {
      display: flex;
      flex-direction: column;
      margin-right: 0.625em;
      min-width: 45px;
      max-width: 45px;
      min-height: 45px; }
      .p-autocomplete-panel .f-search-entry .f-search-entry-icon .f-search-entry-icon-symbol {
        display: block;
        font-size: 2.5em;
        margin: auto; }
        .p-autocomplete-panel .f-search-entry .f-search-entry-icon .f-search-entry-icon-symbol.f-icon-def-str {
          background-image: url("./defStr.svg");
          background-size: cover;
          height: 40px;
          width: 40px; }
      .p-autocomplete-panel .f-search-entry .f-search-entry-icon .f-search-entry-icon-subtext {
        display: block;
        font-size: 0.8em;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
    .p-autocomplete-panel .f-search-entry .f-search-entry-content {
      display: flex;
      flex-grow: 1;
      margin: auto;
      flex-direction: column;
      overflow: hidden; }
      .p-autocomplete-panel .f-search-entry .f-search-entry-content .f-search-entry-text {
        display: block;
        font-size: 0.8em;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis; }
      .p-autocomplete-panel .f-search-entry .f-search-entry-content .f-search-entry-highlight {
        font-weight: bold;
        font-size: 1em; }

.f-labeled-field,
.f-labeled-field-multiline {
  display: flex; }

.f-labeled-field-multiline {
  flex-direction: column; }
  .f-labeled-field-multiline .f-input-group {
    display: block; }
    .f-labeled-field-multiline .f-input-group .f-input-label {
      display: block;
      width: 100%;
      padding-right: 0;
      overflow: inherit;
      text-overflow: inherit;
      white-space: inherit; }
    .f-labeled-field-multiline .f-input-group .f-input-content {
      display: block;
      width: 100%;
      margin-top: 1em;
      margin-left: 0;
      white-space: pre-wrap;
      text-overflow: inherit;
      overflow: inherit; }

.f-input-label-edit {
  color: #00529e; }

.f-input-label-error {
  color: #d84a38; }

.f-tire-header {
  padding-bottom: 1em;
  font-weight: 600; }

.f-tire-rowheader {
  padding-bottom: 0.85em;
  font-weight: 600; }

.f-tire-content {
  width: 100%;
  padding-bottom: 0.85em; }
  .f-tire-content.f-input-right .p-component {
    text-align: right; }
  .f-input-right .f-tire-content .p-component {
    text-align: right; }

.f-tire-yesno {
  padding-bottom: 0.85em;
  margin-left: 10px; }

.f-view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%; }
  .f-view .f-view-content {
    position: relative;
    height: 100%; }
    .f-view .f-view-content .f-view-content-inner {
      height: 100%; }
    .f-view .f-view-content .f-loading-screen {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 20;
      display: flex;
      flex-direction: column;
      background-color: rgba(247, 247, 247, 0.9); }
      .f-view .f-view-content .f-loading-screen .f-loading-spinner {
        width: 100%;
        text-align: center;
        margin: auto; }
  .f-view .f-view-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%; }
    .f-view .f-view-container .f-view-header {
      display: flex;
      position: sticky;
      top: 3.6em;
      left: 0;
      right: 0;
      z-index: 90;
      padding: 0.0em 0.5em;
      border-bottom: 1px solid #cacaca; }
      html[data-browser*="Edge/15"] .f-view .f-view-container .f-view-header {
        top: 0px; }
      .f-view .f-view-container .f-view-header.f-view-header-small {
        padding: 0em 0.5em; }
        .f-view .f-view-container .f-view-header.f-view-header-small .f-view-header-title {
          font-size: 15px;
          white-space: nowrap; }
      .f-view .f-view-container .f-view-header .f-view-header-title {
        flex-grow: 1;
        margin: auto 0;
        padding: 0.625em 0;
        display: flex;
        white-space: nowrap;
        font-size: 19px; }
      .f-view .f-view-container .f-view-header .f-view-header-lock-msg {
        display: flex;
        margin: -1em;
        margin-left: 0.5em;
        font-size: 0.75em; }
        .f-view .f-view-container .f-view-header .f-view-header-lock-msg > .p-message {
          padding: 0.25em;
          margin: auto; }
        .f-view .f-view-container .f-view-header .f-view-header-lock-msg > i {
          margin: auto;
          margin-left: 0.25em;
          cursor: pointer; }
          .f-view .f-view-container .f-view-header .f-view-header-lock-msg > i:hover {
            color: #4699ce; }
    .f-view .f-view-container .f-view-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column; }
      .f-view .f-view-container .f-view-body > .p-grid {
        margin: 0px;
        flex-grow: 1;
        flex-direction: column; }
        .f-view .f-view-container .f-view-body > .p-grid > .p-tabview {
          display: flex;
          flex-direction: column;
          border-top: none;
          border-bottom: none;
          border-radius: 0;
          flex-grow: 1;
          max-width: 100%; }
          .f-view .f-view-container .f-view-body > .p-grid > .p-tabview > .p-tabview-nav {
            position: sticky;
            left: 0;
            right: 0;
            z-index: 90;
            border-top: 1px solid #dbdbdb;
            border-radius: 0; }
          .f-view .f-view-container .f-view-body > .p-grid > .p-tabview > .p-tabview-panels {
            flex-grow: 1; }
            .f-view .f-view-container .f-view-body > .p-grid > .p-tabview > .p-tabview-panels > .p-tabview-panel {
              height: 100%; }
        .f-view .f-view-container .f-view-body > .p-grid > .p-datatable {
          display: flex;
          flex-direction: column;
          max-width: 100%;
          min-width: 100%;
          flex-grow: 1; }
          .f-view .f-view-container .f-view-body > .p-grid > .p-datatable > .p-datatable-scrollable-wrapper,
          .f-view .f-view-container .f-view-body > .p-grid > .p-datatable > .p-datatable-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1; }
            .f-view .f-view-container .f-view-body > .p-grid > .p-datatable > .p-datatable-scrollable-wrapper > .p-datatable-scrollable-view,
            .f-view .f-view-container .f-view-body > .p-grid > .p-datatable > .p-datatable-wrapper > .p-datatable-scrollable-view {
              flex-grow: 1;
              height: 100%;
              display: flex;
              flex-direction: column; }
              .f-view .f-view-container .f-view-body > .p-grid > .p-datatable > .p-datatable-scrollable-wrapper > .p-datatable-scrollable-view > .p-datatable-scrollable-body,
              .f-view .f-view-container .f-view-body > .p-grid > .p-datatable > .p-datatable-wrapper > .p-datatable-scrollable-view > .p-datatable-scrollable-body {
                flex-grow: 1;
                max-height: unset !important; }
    .f-view .f-view-container .f-view-footer {
      border-top: 1px solid #dbdbdb;
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 90;
      border-radius: 0; }

.f-card-header,
.f-view-header {
  position: relative;
  background-color: #dfe5e8;
  border: 1px solid #d8d8d8;
  border-bottom: 0px;
  padding: 20px 10px; }

.f-card-header-title,
.f-view-header-title {
  position: relative;
  font-size: 1.5em;
  color: #00529e; }

.f-view-header-menu {
  background-color: #dfe5e8 !important;
  text-align: right;
  margin: auto; }

.f-card-footer,
.f-view-footer {
  display: flex;
  border: 1px solid #d8d8d8;
  border-top: 0px;
  min-height: 38.5px; }
  .f-card-footer .f-menu-bar-left,
  .f-view-footer .f-menu-bar-left {
    display: inline-block;
    flex-grow: 1; }
  .f-card-footer .f-menu-bar-fill,
  .f-view-footer .f-menu-bar-fill {
    display: flex;
    text-align: center;
    flex-grow: 1; }
    .f-card-footer .f-menu-bar-fill .p-menubar-root-list,
    .f-view-footer .f-menu-bar-fill .p-menubar-root-list {
      display: flex;
      flex-grow: 1; }
      .f-card-footer .f-menu-bar-fill .p-menubar-root-list .p-menuitem,
      .f-view-footer .f-menu-bar-fill .p-menubar-root-list .p-menuitem {
        flex-grow: 1; }
  .f-card-footer .f-menu-bar-right,
  .f-view-footer .f-menu-bar-right {
    display: inline-block;
    text-align: right;
    flex-grow: 1; }
  .f-card-footer .f-menu-bar-center,
  .f-view-footer .f-menu-bar-center {
    display: inline-block;
    text-align: center;
    flex-grow: 1; }

.f-card-messages .p-message {
  margin: 0;
  width: 100%; }

.f-card-damages .p-tree,
.f-card-cost .p-tree {
  padding: 0; }
  .f-card-damages .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label,
  .f-card-cost .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
    padding: 0.2em 0.2em 0.3em 0.2em; }

.f-card-damages .p-treetable .p-treetable-checkbox .p-checkbox-icon,
.f-card-cost .p-treetable .p-treetable-checkbox .p-checkbox-icon {
  color: #ffffff; }

.f-card-cost.f-debtors .p-card-body {
  max-height: calc(100% - 4em); }

.f-card-cost .p-card-body {
  max-height: calc(100% - 6em); }

.f-card-cost .f-table-wrapper {
  min-width: min-content;
  overflow: auto; }
  .f-card-cost .f-table-wrapper .f-column-group-header {
    display: flex;
    width: 100%; }
    .f-card-cost .f-table-wrapper .f-column-group-header .f-colum-group-header-entry-empty,
    .f-card-cost .f-table-wrapper .f-column-group-header .f-colum-group-header-entry {
      flex-grow: 1; }
    .f-card-cost .f-table-wrapper .f-column-group-header .f-colum-group-header-entry {
      border: 1px solid #dbdbdb;
      text-align: center;
      font-weight: bold;
      padding: 0.25em 0.5em; }

.f-card-thirdparty .p-tree {
  padding: 0; }
  .f-card-thirdparty .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
    padding: 0.2em 0.2em 0.3em 0.2em; }
    .f-card-thirdparty .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label .f-input-group {
      display: inline; }

.f-card-thirdparty .p-treetable .p-treetable-checkbox .p-checkbox-icon {
  color: #ffffff; }

.f-card-footer-content {
  position: relative;
  top: 20px;
  height: 20px;
  font-size: 1.5em;
  color: #00529e;
  padding-left: 10px; }

.f-card-close {
  float: right;
  position: relative;
  cursor: pointer;
  top: -20px;
  background-color: #dfe5e8 !important; }
  .f-card-close :hover {
    color: #4699ce; }

.f-trailer-multi-edit.p-dialog .p-dialog-content,
.f-trailer-multi-damage.p-dialog .p-dialog-content {
  overflow: auto;
  padding: 0.5em; }
  .f-trailer-multi-edit.p-dialog .p-dialog-content .f-view-content .f-view-content-inner .p-grid.f-grid,
  .f-trailer-multi-damage.p-dialog .p-dialog-content .f-view-content .f-view-content-inner .p-grid.f-grid {
    margin: 0; }

.f-trailer-multi-edit .f-grid,
.f-trailer-multi-damage .f-grid {
  min-height: 0px; }

.f-trailer-multi-edit .f-trailer-multi-selected .p-card,
.f-trailer-multi-damage .f-trailer-multi-selected .p-card {
  max-height: 150px; }
  .f-trailer-multi-edit .f-trailer-multi-selected .p-card .p-card-body,
  .f-trailer-multi-damage .f-trailer-multi-selected .p-card .p-card-body {
    overflow: auto; }

.f-trailer-multi-edit .f-trailer-multi-trailers,
.f-trailer-multi-damage .f-trailer-multi-trailers {
  display: flex; }
  .f-trailer-multi-edit .f-trailer-multi-trailers .f-trailer-icon,
  .f-trailer-multi-damage .f-trailer-multi-trailers .f-trailer-icon {
    font-size: 2em;
    margin: auto; }
  .f-trailer-multi-edit .f-trailer-multi-trailers .f-trailer-text,
  .f-trailer-multi-damage .f-trailer-multi-trailers .f-trailer-text {
    flex-basis: 100%;
    padding-left: 1em; }

.f-card-files {
  display: flex; }
  .f-card-files .p-tabview {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    overflow: auto; }
    .f-card-files .p-tabview .p-tabview-panels {
      background-color: white;
      overflow-y: auto;
      flex-basis: 100%; }

.f-lightbox .f-lightbox-image-set {
  display: flex;
  flex-flow: wrap; }
  .f-lightbox .f-lightbox-image-set .f-lightbox-image {
    position: relative;
    margin: 0.25em;
    height: 100px;
    width: 100px; }
    .f-lightbox .f-lightbox-image-set .f-lightbox-image .f-lightbox-image-checkbox {
      position: absolute;
      left: 0.25em;
      top: 0.25em;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 2px; }
      .f-lightbox .f-lightbox-image-set .f-lightbox-image .f-lightbox-image-checkbox .p-checkbox-box {
        border: 2px solid rgba(255, 255, 255, 0.5); }
    .f-lightbox .f-lightbox-image-set .f-lightbox-image .p-lightbox-image-target {
      position: absolute;
      max-height: 100px;
      max-width: 100px; }
    .f-lightbox .f-lightbox-image-set .f-lightbox-image a,
    .f-lightbox .f-lightbox-image-set .f-lightbox-image > div {
      position: relative; }
      .f-lightbox .f-lightbox-image-set .f-lightbox-image a img,
      .f-lightbox .f-lightbox-image-set .f-lightbox-image > div img {
        object-fit: cover;
        height: 100px;
        width: 100px; }
      .f-lightbox .f-lightbox-image-set .f-lightbox-image a[caption]::after,
      .f-lightbox .f-lightbox-image-set .f-lightbox-image a .f-lightbox-caption,
      .f-lightbox .f-lightbox-image-set .f-lightbox-image > div[caption]::after,
      .f-lightbox .f-lightbox-image-set .f-lightbox-image > div .f-lightbox-caption {
        font-size: 0.9em;
        padding: 0.1em; }

.f-lightbox .p-lightbox-content-wrapper {
  height: 100%;
  width: 100%; }
  .f-lightbox .p-lightbox-content-wrapper .f-lightbox-nav {
    background: none;
    border: none;
    z-index: 1;
    opacity: 0.6; }
    .f-lightbox .p-lightbox-content-wrapper .f-lightbox-nav:hover {
      opacity: 0.9; }
    .f-lightbox .p-lightbox-content-wrapper .f-lightbox-nav span {
      color: white;
      background-color: rgba(0, 0, 0, 0.75);
      border-radius: 50%; }

.f-lightbox [caption]::after,
.f-lightbox .f-lightbox-caption {
  content: attr(caption);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.2em;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.f-overlayheader {
  margin-top: -30px;
  margin-left: 4px;
  color: #00529e;
  font-size: 1.2em;
  padding: 0; }

.f-datefilter {
  overflow: hidden; }
  .f-datefilter .f-datefilter-content {
    margin: 2em 0em 0.5em 0em; }
    .f-datefilter .f-datefilter-content .f-datefilter-fields {
      margin: 0em -1em 0 1em;
      padding-right: 0; }
    .f-datefilter .f-datefilter-content .f-datefilter-autofills .f-datefilter-autofill {
      display: flex;
      padding: 0.25em 1em; }
      .f-datefilter .f-datefilter-content .f-datefilter-autofills .f-datefilter-autofill > i {
        margin-right: 0.5em; }
      .f-datefilter .f-datefilter-content .f-datefilter-autofills .f-datefilter-autofill > div {
        margin-top: auto; }
      .f-datefilter .f-datefilter-content .f-datefilter-autofills .f-datefilter-autofill:hover {
        background-color: #e8e8e8;
        cursor: pointer; }
      .f-datefilter .f-datefilter-content .f-datefilter-autofills .f-datefilter-autofill.f-datefilter-highlight {
        color: #00529e;
        background-color: #dfdfdf; }

.f-filter-multi .p-inputtext {
  cursor: pointer; }

.f-filter-multi-op .f-filter-multi-op-content {
  margin: 2.5em 0em 0.5em 0em; }
  .f-filter-multi-op .f-filter-multi-op-content .f-filter-multi-item {
    padding: 0.25em 2em; }
    .f-filter-multi-op .f-filter-multi-op-content .f-filter-multi-item.f-filter-multi-select-all {
      color: #00529e; }
    .f-filter-multi-op .f-filter-multi-op-content .f-filter-multi-item:hover {
      cursor: pointer;
      background-color: #e8e8e8; }
      .f-filter-multi-op .f-filter-multi-op-content .f-filter-multi-item:hover .p-checkbox-box {
        cursor: pointer; }
      .f-filter-multi-op .f-filter-multi-op-content .f-filter-multi-item:hover .p-checkbox-label {
        cursor: pointer; }

.f-filter-tree-op .f-filter-tree-op-content {
  margin: 2.5em 0em 0.5em 0em; }
  .f-filter-tree-op .f-filter-tree-op-content .p-tree {
    padding: 0; }
    .f-filter-tree-op .f-filter-tree-op-content .p-tree .p-tree-container {
      padding: 0; }
      .f-filter-tree-op .f-filter-tree-op-content .p-tree .p-tree-container .p-treenode .p-treenode-content {
        border-radius: 0; }
        .f-filter-tree-op .f-filter-tree-op-content .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
          padding-top: 0.1em;
          padding-bottom: 0.1em; }
        .f-filter-tree-op .f-filter-tree-op-content .p-tree .p-tree-container .p-treenode .p-treenode-content:hover {
          background-color: #e8e8e8; }
  .f-filter-tree-op .f-filter-tree-op-content .f-filter-tree-select-all {
    color: #00529e;
    padding: 0.1em 0 0.1em 1.75em;
    border: 1px solid transparent; }
    .f-filter-tree-op .f-filter-tree-op-content .f-filter-tree-select-all .p-checkbox-label {
      padding-left: 0.625em; }
    .f-filter-tree-op .f-filter-tree-op-content .f-filter-tree-select-all:hover {
      cursor: pointer;
      background-color: #e8e8e8; }
      .f-filter-tree-op .f-filter-tree-op-content .f-filter-tree-select-all:hover .p-checkbox-box {
        cursor: pointer; }
      .f-filter-tree-op .f-filter-tree-op-content .f-filter-tree-select-all:hover .p-checkbox-label {
        cursor: pointer; }

.f-filter-date:hover {
  cursor: pointer; }

.f-filter-date > .p-inputgroup > .p-inputtext:hover {
  cursor: pointer; }

.f-fieldset-group .p-fieldset-toggler {
  display: none; }

@media screen {
  .f-print,
  .f-print * {
    display: none; } }

body .f-print {
  margin: 2em;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }
  @media print {
    @page {
      size: landscape; } }

.f-print {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 8px; }
  .f-print .f-order-shipment-tables {
    margin: 0;
    height: 100%; }
  .f-print .f-header {
    border-bottom: 1px solid black;
    font-size: 18px;
    padding: 5px 0;
    color: black;
    box-shadow: none;
    margin: 0; }
    .f-print .f-header .f-header-label {
      font-weight: bold;
      padding: 0; }
    .f-print .f-header .f-header-space {
      padding: 0 0.5em;
      display: none; }
    .f-print .f-header .f-card-close {
      display: none; }
  .f-print .p-card {
    box-shadow: none;
    margin: 0; }
    .f-print .p-card .p-card-header {
      font-size: 16px;
      padding: 10px 0; }
    .f-print .p-card .p-card-body {
      padding: 0; }
  .f-print .p-datatable,
  .f-print .f-order-shipment-table {
    border-collapse: collapse; }
    .f-print .p-datatable .p-datatable-header,
    .f-print .p-datatable .p-datatable-thead > tr > th,
    .f-print .f-order-shipment-table .p-datatable-header,
    .f-print .f-order-shipment-table .p-datatable-thead > tr > th {
      font-size: 8px;
      text-align: center;
      border-bottom: 0 none; }
    .f-print .p-datatable .p-datatable-tbody,
    .f-print .f-order-shipment-table .p-datatable-tbody {
      border-bottom: 0 none;
      border-collapse: collapse; }
      .f-print .p-datatable .p-datatable-tbody > tr,
      .f-print .f-order-shipment-table .p-datatable-tbody > tr {
        height: 1em !important; }
        .f-print .p-datatable .p-datatable-tbody > tr > td,
        .f-print .f-order-shipment-table .p-datatable-tbody > tr > td {
          border: 1px solid #dddddd;
          padding: 3px;
          font-size: 8px;
          line-height: 8px; }
      .f-print .p-datatable .p-datatable-tbody > tr.p-datatable-even,
      .f-print .f-order-shipment-table .p-datatable-tbody > tr.p-datatable-even {
        background-color: #f4f4f4; }
      .f-print .p-datatable .p-datatable-tbody .p-datatable-emptymessage,
      .f-print .f-order-shipment-table .p-datatable-tbody .p-datatable-emptymessage {
        padding: 5px;
        height: 1em; }
    .f-print .p-datatable .p-datatable-scrollable-footer,
    .f-print .f-order-shipment-table .p-datatable-scrollable-footer {
      display: none; }

.f-debug {
  padding: 3px;
  overflow-wrap: break-word;
  font-size: 0.7em;
  color: grey; }

body {
  color: black; }
  body .f-context-menu {
    width: 200px;
    padding: 0em;
    font-size: 12px; }
    body .f-context-menu .p-menuitem .p-menuitem-link {
      padding: 0.5em;
      display: flex; }
      body .f-context-menu .p-menuitem .p-menuitem-link .p-menuitem-text {
        margin: auto 0;
        vertical-align: middle; }
      body .f-context-menu .p-menuitem .p-menuitem-link .p-submenu-icon {
        right: 0; }
    body .f-context-menu .p-submenu-list {
      padding: 0em;
      width: 200px; }
  body .footer {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1; }
  body .p-component-overlay {
    background-color: #dfe5e8;
    background-image: linear-gradient(to top, white, #dfe5e8); }
  body .p-dropdown-panel .p-dropdown-filter-container {
    display: block;
    border-bottom: 1px solid #bdbdbd;
    background-color: white;
    padding: 0.625em 1em; }
  body .p-dropdown-panel .p-dropdown-filter-container input {
    color: black; }
  body .p-dropdown-panel .p-dropdown-filter-container input:focus {
    border-bottom-color: black; }
  body .p-dropdown-panel .p-dropdown-filter-container .pi {
    top: .325em;
    right: .75em;
    color: black; }
  body input::-ms-clear {
    display: none; }
  body .card {
    padding: 0; }
  body .f-input-switch .p-component:disabled,
  body .f-input-switch .p-disabled {
    opacity: 1; }
  body .p-checkbox .p-checkbox-box {
    border-color: #00529e; }
    body .p-checkbox .p-checkbox-box .p-checkbox-icon.p-c.pi.pi-minus {
      background-color: #00529e;
      color: white; }
  body .p-paginator {
    background-color: white;
    border: 1px; }
    body .p-paginator > a {
      color: #274d8d; }
      body .p-paginator > a:not(.p-disabled):not(.p-highlight):hover {
        background-color: #dfe5e8; }
    body .p-paginator .p-paginator-next {
      color: #274d8d; }
    body .p-paginator .p-paginator-last {
      color: #274d8d; }
    body .p-paginator .p-paginator-prev {
      color: #274d8d; }
    body .p-paginator .p-paginator-first {
      color: #274d8d; }
    body .p-paginator .p-paginator-pages a {
      color: #274d8d; }
      body .p-paginator .p-paginator-pages a:not(.p-highlight):hover {
        background-color: #dfe5e8; }
      body .p-paginator .p-paginator-pages a.p-highlight {
        background-color: #274d8d;
        color: #ffffff; }
    body .p-paginator .pi {
      color: #274d8d; }
    body .p-paginator .p-paginator-right-content {
      color: #274d8d;
      padding-top: 4px; }
  body .p-dropdown.p-component:disabled, body .p-dropdown.p-disabled {
    opacity: 1; }
  body .p-dropdown .p-dropdown-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  body .p-dialog-content .p-dropdown .p-dropdown-label {
    color: #333333;
    max-width: none; }
  body .p-menubar {
    border: 0px none;
    color: #00529e; }
    body .p-menubar .p-menuitem-link {
      padding: 0.25em 1em; }
      body .p-menubar .p-menuitem-link .p-menuitem-icon {
        color: #00529e; }
      body .p-menubar .p-menuitem-link .p-menuitem-text {
        color: #00529e; }
    body .p-menubar .p-cancel-button .p-menuitem-icon {
      color: #d84a38; }
    body .p-menubar .p-cancel-button .p-menuitem-text {
      color: #d84a38; }
  body .p-progressbar {
    background-color: #00529e;
    height: 3px;
    width: 100%; }
    body .p-progressbar .p-progressbar-value {
      background-color: #d84a38;
      height: 0;
      border: none; }
  body .p-progressbar.f-dynamic-progress {
    background-color: grey;
    height: 2.25em;
    width: 100%; }
    body .p-progressbar.f-dynamic-progress .p-progressbar-value {
      background-color: #00529e;
      height: 100%;
      border: none; }
    body .p-progressbar.f-dynamic-progress .p-progressbar-label {
      color: white;
      margin-top: 0.5em;
      display: inherit !important; }
  body .f-datatable tr > th > .p-checkbox {
    margin: 0; }
  body .f-datatable tr > td > .p-checkbox {
    margin: 0; }
  body .p-datatable table {
    table-layout: fixed; }
  body .p-datatable .f-table-header {
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis; }
  body .p-datatable .f-cell-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  body .p-datatable .p-datatable-thead > tr > th {
    padding: 0.2em;
    text-align: center;
    background-color: #efefef; }
    body .p-datatable .p-datatable-thead > tr > th.f-order-costs-planned {
      background-color: #e0efe0; }
    body .p-datatable .p-datatable-thead > tr > th.f-order-costs-real {
      background-color: #efe0e0; }
  body .p-datatable .p-sortable-column {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: top; }
    body .p-datatable .p-sortable-column .p-column-filter .f-context-menu {
      width: 200px;
      padding: 0em;
      font-size: 0.9em; }
      body .p-datatable .p-sortable-column .p-column-filter .f-context-menu .p-menuitem .p-menuitem-link .p-submenu-icon {
        right: 0; }
    body .p-datatable .p-sortable-column .p-column-title {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: unset;
      max-width: calc(100% - 1.5em);
      margin-bottom: 0.25em; }
    body .p-datatable .p-sortable-column .p-sortable-column-icon,
    body .p-datatable .p-sortable-column .p-sortable-column-icon:hover {
      font-size: 1.5em;
      margin: 0;
      float: left;
      vertical-align: top; }
      body .p-datatable .p-sortable-column .p-sortable-column-icon > span.pi.pi-fw,
      body .p-datatable .p-sortable-column .p-sortable-column-icon:hover > span.pi.pi-fw {
        font-size: 1em; }
      body .p-datatable .p-sortable-column .p-sortable-column-icon .pi-sort::before,
      body .p-datatable .p-sortable-column .p-sortable-column-icon:hover .pi-sort::before {
        content: "swap_vert";
        color: #757575; }
      body .p-datatable .p-sortable-column .p-sortable-column-icon .pi-sort-up::before,
      body .p-datatable .p-sortable-column .p-sortable-column-icon:hover .pi-sort-up::before {
        content: "arrow_drop_up";
        color: #555555; }
      body .p-datatable .p-sortable-column .p-sortable-column-icon .pi-sort-down::before,
      body .p-datatable .p-sortable-column .p-sortable-column-icon:hover .pi-sort-down::before {
        content: "arrow_drop_down";
        color: #555555; }
    body .p-datatable .p-sortable-column > div,
    body .p-datatable .p-sortable-column > .p-inputtext {
      display: block;
      width: 100%;
      padding: 0em 0.2em; }
      body .p-datatable .p-sortable-column > div .p-inputgroup,
      body .p-datatable .p-sortable-column > .p-inputtext .p-inputgroup {
        width: 100%; }
  body .p-datatable .p-sortable-column.p-highlight {
    background-color: #d5d5d5;
    color: black;
    border-top-color: #d5d5d5; }
    body .p-datatable .p-sortable-column.p-highlight a,
    body .p-datatable .p-sortable-column.p-highlight a:hover {
      color: black; }
  body .p-datatable .f-with-addon {
    width: calc(100% - 20px); }
  body .p-datatable .f-with-two-addons {
    width: calc(100% - 40px); }
  body .p-datatable .p-paginator-top {
    border: 1px solid #cacaca;
    border-bottom: 0px none;
    background-color: #efefef; }
  body .p-datatable .p-paginator-bottom {
    border: 1px solid #cacaca;
    background-color: #efefef; }
  body .p-datatable .p-datatable-tbody {
    background-color: #ffffff;
    border: 1px solid #d8d8d8; }
    body .p-datatable .p-datatable-tbody > tr.p-highlight {
      background-color: transparent;
      color: #000; }
    body .p-datatable .p-datatable-tbody .f-order-costs-planned {
      background-color: #f0fff0; }
    body .p-datatable .p-datatable-tbody .f-order-costs-real {
      background-color: #fff0f0; }
    body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
      background-color: #efefef; }
      body .p-datatable .p-datatable-tbody > tr:nth-child(even) > .f-order-costs-planned {
        background-color: #e0efe0; }
      body .p-datatable .p-datatable-tbody > tr:nth-child(even) > .f-order-costs-real {
        background-color: #efe0e0; }
    body .p-datatable .p-datatable-tbody > tr.f-datatable-locked-row {
      background-color: #efefe0;
      color: #d5d5d5; }
    body .p-datatable .p-datatable-tbody > tr.f-datatable-disabled-row {
      color: #2f96b4; }
    body .p-datatable .p-datatable-tbody > tr.f-datatable-new-row {
      font-weight: bolder; }
    body .p-datatable .p-datatable-tbody .f-order-reminder {
      overflow: visible;
      position: relative;
      min-height: 1.5em; }
      body .p-datatable .p-datatable-tbody .f-order-reminder > i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    body .p-datatable .p-datatable-tbody > tr.p-datatable-even.p-highlight {
      background-color: #f4f4f4;
      color: #000; }
    body .p-datatable .p-datatable-tbody .p-datatable-emptymessage {
      text-align: center;
      font-size: 2em;
      color: gray;
      height: 3em; }
  body .p-datatable .p-datatable-tbody > tr > td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.4em 0.3em; }
    body .p-datatable .p-datatable-tbody > tr > td.p-selection-column {
      text-overflow: clip; }
  body .p-dataview .p-paginator-top {
    border: 1px solid #cacaca; }
  body .p-dataview .p-paginator-bottom {
    border: 1px solid #cacaca; }
  body .p-tabview {
    background-color: #f7f7f7; }
    body .p-tabview .p-tabview-nav > li .p-tabview-right-icon {
      margin-top: -0.25em; }
    body .p-tabview .p-tabview-panel {
      padding: 0.3em 0.3em; }
  body .p-multiselect-label-container {
    min-height: 18px; }
  body .p-multiselect-panel .p-multiselect-header {
    background-color: #ffffff;
    border: 0px;
    color: #00529e;
    height: 35px; }
    body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
      color: #00529e;
      background-color: white;
      font-size: 0.9em; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-close span {
        color: #00529e;
        background-color: white; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-close span:hover {
          color: red;
          -moz-transition: background-color 0.3s;
          -o-transition: background-color 0.3s;
          -webkit-transition: background-color 0.3s;
          transition: background-color 0.3s; }
    body .p-multiselect-panel .p-multiselect-header .p-checkbox .p-checkbox-box {
      border-color: gray; }
  body .p-multiselect-panel .p-multiselect-item.p-highlight {
    color: black;
    background-color: white; }
  body .p-tree {
    border: unset;
    padding: 1em 1em 1em 0em; }
    body .p-tree :focus {
      outline: none; }
    body .p-tree .p-tree-container {
      margin: 0; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content {
        padding: 0; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
          background-color: white;
          color: black; }
          body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
          body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
            color: gray; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
          background-color: #e8e8e8;
          color: #000000; }
          body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover .p-treenode-icon {
            background-color: #e8e8e8;
            color: #000000;
            color: grey; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-icon {
          color: grey; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
          background-color: #e8e8e8;
          color: #000000; }
    body .p-tree .p-tree-toggler .p-tree-toggler-icon {
      color: gray; }
  body .p-card {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 0px none;
    background: #ffffff;
    margin-bottom: 1em; }
    body .p-card .p-card-body {
      padding: 10px; }
    body .p-card .p-card-title {
      color: #00529e;
      font-size: 1.5em;
      font-weight: 400;
      margin-bottom: 0.5em;
      padding: 15px 10px; }
    body .p-card .p-card-subtitle {
      opacity: 0.7;
      margin-bottom: 0.9em;
      margin-top: -0.25em;
      font-weight: bold;
      padding: 5px 10px; }
    body .p-card .p-card-footer {
      padding: 5px 10px;
      font-size: 0.9em; }
  body .p-inputswitch .p-inputswitch-slider {
    background-color: #9e9e9e; }
    body .p-inputswitch .p-inputswitch-slider:before {
      background-color: #ffffff; }
  body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
    background-color: #9e9e9e; }
  body .p-inputswitch:not(.p-state-disabled):hover .p-inputswitch-slider {
    background-color: #9e9e9e; }
  body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background-color: #999999; }
    body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      background-color: #51a351; }
  body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled):hover .p-inputswitch-slider {
    background-color: #999999; }
  body .p-inputswitch.p-inputswitch-checked.p-inputswitch-focus .p-inputswitch-slider {
    background: #999999; }
  body .p-grid {
    width: 100%;
    margin: -5px 0px -10px 0px; }
  body .p-overlaypanel {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    min-width: 275px;
    max-height: 50%;
    overflow-y: auto; }
    body .p-overlaypanel .p-overlaypanel-close {
      background-color: white;
      border-radius: unset;
      box-shadow: unset;
      right: 5px;
      top: 3px; }
      body .p-overlaypanel .p-overlaypanel-close span {
        color: #00529e;
        background-color: transparent;
        line-height: inherit; }
        body .p-overlaypanel .p-overlaypanel-close span:hover {
          color: red;
          -moz-transition: background-color 0.3s;
          -o-transition: background-color 0.3s;
          -webkit-transition: background-color 0.3s;
          transition: background-color 0.3s; }
      body .p-overlaypanel .p-overlaypanel-close:hover {
        background-color: white;
        border-radius: unset;
        box-shadow: unset;
        color: #8197a5; }
  body .p-overlaypanel-content {
    padding: 0;
    margin-top: 12px; }
  body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled) .p-inputswitch-slider {
    background-color: #9e9e9e; }
    body .p-inputswitch.p-inputswitch-checked:not(.p-state-disabled) .p-inputswitch-slider :hover {
      background-color: #9e9e9e; }
  body .layout-container {
    height: 100%; }
    body .layout-container .topbar {
      display: flex;
      height: 50px;
      background-color: #00529e; }
      html[data-section*="PARTNER"] body .layout-container .topbar {
        background-color: #529e00; }
      html[data-section*="DATA"] body .layout-container .topbar {
        background-color: #673ab7; }
      body .layout-container .topbar .topbar-left {
        padding: 5px;
        padding-right: 20px;
        height: 50px;
        flex-basis: 50%;
        max-width: 230px;
        padding-left: 0.5em;
        background-color: #0f2765; }
        html[data-environment*="TEST"] body .layout-container .topbar .topbar-left {
          background-color: #27650f; }
      body .layout-container .topbar .topbar-info {
        display: flex;
        position: absolute;
        top: 0px;
        left: 230px;
        width: calc(100% - 500px);
        min-height: 50px;
        max-height: 50px;
        color: white;
        pointer-events: none; }
        body .layout-container .topbar .topbar-info .topbar-info-container {
          display: flex;
          margin: auto;
          flex-grow: 1;
          justify-content: center; }
          @media (max-width: 1000px) {
            body .layout-container .topbar .topbar-info .topbar-info-container {
              justify-content: left;
              padding-left: 2em; } }
        body .layout-container .topbar .topbar-info .topbar-info-container-bcm {
          display: flex;
          margin: auto;
          flex-grow: 1;
          justify-content: left; }
          @media (max-width: 1000px) {
            body .layout-container .topbar .topbar-info .topbar-info-container-bcm {
              justify-content: left;
              padding-left: 2em; } }
        body .layout-container .topbar .topbar-info .topbar-info-test {
          display: none; }
          html[data-environment*="TEST"] body .layout-container .topbar .topbar-info .topbar-info-test {
            display: block;
            max-height: 100%;
            font-size: 3em;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            line-height: 1em; }
            @media (max-width: 1500px) {
              html[data-environment*="TEST"] body .layout-container .topbar .topbar-info .topbar-info-test {
                font-size: 2.5em; } }
            @media (max-width: 1000px) {
              html[data-environment*="TEST"] body .layout-container .topbar .topbar-info .topbar-info-test {
                font-size: 2em; } }
            @media (max-width: 850px) {
              html[data-environment*="TEST"] body .layout-container .topbar .topbar-info .topbar-info-test {
                display: none; } }
        body .layout-container .topbar .topbar-info .topbar-info-bcm {
          display: none;
          display: block;
          max-height: 100%;
          font-size: 3em;
          font-weight: bold;
          color: white;
          background: red;
          text-transform: uppercase;
          letter-spacing: 2px;
          line-height: 1em; }
          @media (max-width: 1500px) {
            body .layout-container .topbar .topbar-info .topbar-info-bcm {
              font-size: 2.5em; } }
          @media (max-width: 1000px) {
            body .layout-container .topbar .topbar-info .topbar-info-bcm {
              font-size: 2em; } }
          @media (max-width: 850px) {
            body .layout-container .topbar .topbar-info .topbar-info-bcm {
              display: none; } }
        body .layout-container .topbar .topbar-info .topbar-info-dev {
          display: flex;
          font-size: 12px;
          line-height: 12px;
          flex-direction: column;
          justify-content: center;
          margin-left: 1em; }
          body .layout-container .topbar .topbar-info .topbar-info-dev .topbar-dev-title {
            text-decoration: underline; }
          body .layout-container .topbar .topbar-info .topbar-info-dev .topbar-dev-user,
          body .layout-container .topbar .topbar-info .topbar-info-dev .topbar-dev-role {
            display: flex; }
            body .layout-container .topbar .topbar-info .topbar-info-dev .topbar-dev-user .topbar-dev-value,
            body .layout-container .topbar .topbar-info .topbar-info-dev .topbar-dev-role .topbar-dev-value {
              margin-left: 1em;
              font-weight: bold; }
          @media (max-width: 600px) {
            body .layout-container .topbar .topbar-info .topbar-info-dev {
              display: none; } }
      body .layout-container .topbar .topbar-right {
        position: relative;
        display: flex;
        flex-grow: 1;
        flex-direction: row-reverse;
        padding: 5px; }
        body .layout-container .topbar .topbar-right #menu-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%); }
          body .layout-container .topbar .topbar-right #menu-button:hover {
            transform: translateY(-50%) scale(1.1); }
      body .layout-container .topbar .logo {
        padding-left: 5px;
        background: url("./logo.svg") center left no-repeat;
        background-size: 100% auto;
        height: 100%; }
    body .layout-container .topbar-items > li > ul {
      top: 40px; }
    body .layout-container .layout-menu {
      top: 50px;
      width: 230px;
      background-color: #efefef;
      box-shadow: 1px 0 3px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.3);
      -moz-transition: margin-left 0.3s;
      -o-transition: margin-left 0.3s;
      -webkit-transition: margin-left 0.3s;
      transition: margin-left 0.3s; }
      body .layout-container .layout-menu .menuitem-badge {
        right: 4em; }
      body .layout-container .layout-menu .f-menu-disabled {
        color: #d5d5d5; }
        body .layout-container .layout-menu .f-menu-disabled .material-icons {
          color: #d5d5d5; }
    body .layout-container .layout-main {
      padding: 50px 0 0 0;
      margin-left: 230px;
      height: 100%;
      display: flex;
      flex-direction: column; }
      body .layout-container .layout-main .layout-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0;
        padding-bottom: 1.5em; }
    body .layout-container .layout-rightpanel {
      top: 50px;
      z-index: 100; }
  body .f-monitor-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 4.5em; }
  body .p-button.p-button-link {
    color: #0b7ad1;
    background: transparent;
    border: transparent;
    box-shadow: none; }
  body .p-button.p-button-link:enabled:hover {
    background: transparent;
    color: #0b7ad1;
    border-color: transparent; }
  body .p-button.p-button-link:enabled:hover .p-button-label {
    text-decoration: underline; }
  body .p-button.p-button-link:enabled:focus {
    background: transparent;
    box-shadow: none;
    border-color: transparent; }
  body .p-button.p-button-link:enabled:active {
    background: transparent;
    color: #0b7ad1;
    border-color: transparent; }
